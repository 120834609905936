import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
// import DocumentDetails from './document_details/document_details.js'
import './_actions_informations.scss'
import DocumentDetails from './document_details/document_details'
import EmailsTab from './emails_tab/emails_tab'
import InfoTab from './info_tab/info_tab'

export default function ActionsInformation(props) {
  const {
    token,
    buildingId,
    serviceId,
    projectId,
    projectServiceId,
    buildingData,
    singleBuildingData,
    handleChange,
    isInfoTabEnabled,
    isMsgTabEnabled,
    isDocTabEnabled,
    emailModalRef,
    documentSelectorRef,
    urlServiceData,
  } = props

  useEffect(() => {}, [
    serviceId,
    projectId,
    projectServiceId,
    buildingId,
    buildingData,
  ])

  const unselectedTabButtonproperty =
    'tab-button-style actions-information-tab-buttons-size center-content flex-row vietnam-semi-bold'
  const selectedTabButtonproperty =
    'selected-tab-button-style actions-information-tab-buttons-size center-content flex-row vietnam-semi-bold'
  const selectedImageColor =
    'actions-information-tab-buttons-image-size white-image'
  const unselectedImageColor =
    'actions-information-tab-buttons-image-size grey-image'
  return (
    <Grid
      container
      item
      sm={12}
      md={12}
      lg={12}
      alignItems="flex-start"
      justify="center"
    >
      <div className="residence-line-between margin-top-20" />

      <Grid
        container
        item
        md={12}
        xs={12}
        spacing={window.screen.width < 600 ? 0 : 2}
        justify="center"
        alignItems="center"
      >
        <div className="width-auto flex-row center-content">
          <div className="actions-information-tabs flex-row horizontal-center">
            <button
              type="button"
              className={
                isInfoTabEnabled
                  ? selectedTabButtonproperty
                  : unselectedTabButtonproperty
              }
              onClick={() => handleChange('info')}
            >
              <img
                alt=""
                src={require('../../../../assets/svg/icon/business-and-trade.svg')}
                className={
                  isInfoTabEnabled ? selectedImageColor : unselectedImageColor
                }
              />
              <p className="margin-left-5">Informatie</p>
            </button>
            <button
              type="button"
              className={
                isMsgTabEnabled
                  ? selectedTabButtonproperty
                  : unselectedTabButtonproperty
              }
              onClick={() => handleChange('emails')}
            >
              <img
                alt=""
                src={require('../../../../assets/svg/icon/chat.svg')}
                className={
                  isMsgTabEnabled ? selectedImageColor : unselectedImageColor
                }
              />

              <p className="margin-left-5">Berichten</p>
            </button>
            <button
              type="button"
              className={
                isDocTabEnabled
                  ? selectedTabButtonproperty
                  : unselectedTabButtonproperty
              }
              onClick={() => handleChange('documents')}
            >
              <img
                alt=""
                className={
                  isDocTabEnabled ? selectedImageColor : unselectedImageColor
                }
                src={require('../../../../assets/svg/icon/google-docs.svg')}
              />

              <p className="margin-left-5">Documenten</p>
            </button>
          </div>
        </div>
        <div className="width-auto horizontal-center vertical-start">
          {isDocTabEnabled ? (
            <DocumentDetails
              token={token}
              serviceId={serviceId}
              projectId={projectId}
              emailModalRef={emailModalRef}
              documentSelectorRef={documentSelectorRef}
            />
          ) : isMsgTabEnabled ? (
            <EmailsTab
              token={token}
              singleBuildingData={singleBuildingData}
              projectServiceId={projectServiceId}
            />
          ) : (
            <InfoTab
              token={token}
              singleBuildingData={singleBuildingData}
              buildingId={buildingId}
              serviceId={projectServiceId}
              buildingData={buildingData}
              projectId={projectId}
              urlServiceData={urlServiceData}
            />
          )}
        </div>
      </Grid>
    </Grid>
  )
}
