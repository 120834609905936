import React, {
  useState,
  useEffect,
  createRef,
  useImperativeHandle,
  forwardRef,
} from 'react'
import { Grid } from '@material-ui/core'
import axios from 'axios'
import './_step_four.scss'
import { CustomInput } from '../../../shared_components/custom_input/custom_input'
import { CustomSelect } from '../../../shared_components/custom_select/custom_select'
import Popup from '../../../shared_components/custom_popup/custom_popup'

export default function StepFour(props) {
  const { token, isButtonClick, ebpFormId } = props
  const [fileDetailsRef, setFileDetailsRef] = useState([])
  const [roofIsolationFiles, setRoofIsolationFiles] = useState([])
  const [ventilationApplianceFiles, setVentilationApplianceFiles] = useState([])
  const [installationFiles, setInstallationFiles] = useState([])
  const [heatingFiles, setHeatingFiles] = useState([])
  const [PD1Files, setPD1Files] = useState([])
  const [M1Files, setM1Files] = useState([])
  const [HD1Files, setHD1Files] = useState([])
  const [showPopupState, setShowPopupState] = useState(false)
  const [popupMessageState, setPopupMessageState] = useState({})

  useEffect(() => {
    let data = []
    const tempArray = []
    Array(7)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setFileDetailsRef(tempArray)
    axios
      .post(
        process.env.REACT_APP_API_URL + `/ebp_form/epb_step4_admin_get`,
        { ebp_form_id: ebpFormId },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.message === 'Success!') {
          data = response.data.data
          data.sort(function (a, b) {
            return -(b.id - a.id || b.name.localeCompare(a.name))
          })
          data.forEach((item) => {
            if (item.id === 1) {
              setRoofIsolationFiles(item.ebp_supporting_pieces)
            } else if (item.id === 2) {
              setVentilationApplianceFiles(item.ebp_supporting_pieces)
            } else if (item.id === 3) {
              setInstallationFiles(item.ebp_supporting_pieces)
            } else if (item.id === 4) {
              setHeatingFiles(item.ebp_supporting_pieces)
            } else if (item.id === 5) {
              setPD1Files(item.ebp_supporting_pieces)
            } else if (item.id === 6) {
              setM1Files(item.ebp_supporting_pieces)
            } else if (item.id === 7) {
              setHD1Files(item.ebp_supporting_pieces)
            }
          })
        }
      })
      .catch((error) => {})
  }, [ebpFormId])
  useEffect(() => {
    if (fileDetailsRef.length > 0) {
      fileDetailsRef.map((item) => {
        item.current.getValue().map((element) => {
          axios
            .post(
              process.env.REACT_APP_API_URL + `/ebp_form/epb_step4_admin_store`,
              {
                ebp_form_id: ebpFormId,
                record_id: element.id,
                ebp_supporting_pieces_category_id:
                  element.ebp_supporting_pieces_category_id,
                provisionally_entered: element.provisionally_entered,
                status: element.status,
                type: element.type,
                note: element.note,
              },
              {
                headers: {
                  authorization: `bearer ${token}`,
                },
              },
            )
            .then((response) => {
              if (response.data.message === 'Success!') {
                let responseMessage = {
                  isSuccess: true,
                  heading: 'Succesrecord opgeslagen',
                  message: '',
                  popupState: setShowPopupState,
                }
                setShowPopupState(true)
                setPopupMessageState(responseMessage)
              }
            })
            .catch((error) => {})
        })
      })
    }
  }, [isButtonClick])

  return (
    <Grid container className="fade-in-translate">
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Isolatiematerialen
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <FileDetails
          ref={fileDetailsRef[0]}
          filesArray={roofIsolationFiles}
          token={token}
          categoryId={1}
        />
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Buitenschrijnwerk
            </p>
          </Grid>
        </Grid>
      </Grid>
      <FileDetails
        ref={fileDetailsRef[1]}
        filesArray={ventilationApplianceFiles}
        token={token}
        categoryId={2}
      />{' '}
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Luchtdichtheidsmeting
            </p>
          </Grid>
        </Grid>
      </Grid>
      <FileDetails
        ref={fileDetailsRef[2]}
        filesArray={installationFiles}
        token={token}
        categoryId={3}
      />{' '}
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Ventilatie
            </p>
          </Grid>
        </Grid>
      </Grid>
      <FileDetails
        ref={fileDetailsRef[3]}
        filesArray={heatingFiles}
        token={token}
        categoryId={4}
      />{' '}
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Verwarming & sanitair warm water
            </p>
          </Grid>
        </Grid>
      </Grid>
      <FileDetails
        ref={fileDetailsRef[4]}
        filesArray={PD1Files}
        token={token}
        categoryId={5}
      />{' '}
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Hernieuwbare energie
            </p>
          </Grid>
        </Grid>
      </Grid>
      <FileDetails
        ref={fileDetailsRef[5]}
        filesArray={M1Files}
        token={token}
        categoryId={6}
      />{' '}
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Verlichting
            </p>
          </Grid>
        </Grid>
      </Grid>
      <FileDetails
        ref={fileDetailsRef[6]}
        filesArray={HD1Files}
        token={token}
        categoryId={7}
      />{' '}
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      {showPopupState === true ? <Popup data={popupMessageState} /> : null}
    </Grid>
  )
}

const FileDetails = forwardRef((props, ref) => {
  const { filesArray } = props
  const [fileDetailsRef, setFileDetailsRef] = useState([])
  const statusData = [
    { id: 1, title: 'te controleren' },
    { id: 2, title: 'te ontvangen' },
    { id: 3, title: 'gecontroleerd OK' },
    { id: 4, title: 'gecontroleerd niet OK' },
  ]
  const typeData = [
    { id: 1, title: 'EPB Document' },
    { id: 2, title: 'PID Document' },
    { id: 3, title: 'EPB & PID' },
  ]

  useImperativeHandle(ref, () => ({
    getValue() {
      filesArray.map((item, index) => {
        item.provisionally_entered =
          fileDetailsRef[index * 4].current.getValue()
        item.status = statusData.find(
          (status) =>
            status.id ===
            parseInt(fileDetailsRef[index * 4 + 1].current.getValue()),
        )?.title
        item.type = typeData.find(
          (type) =>
            type.id ===
            parseInt(fileDetailsRef[index * 4 + 2].current.getValue()),
        )?.title
        item.note = fileDetailsRef[index * 4 + 3].current.getValue()
      })
      return filesArray
    },
  }))

  useEffect(() => {
    if (filesArray !== undefined && filesArray.length > 0) {
      let tempArray = []
      Array(filesArray.length * 4)
        .fill()
        .map((item) => {
          tempArray.push(createRef(0))
        })
      setFileDetailsRef(tempArray)
    }
  }, [filesArray])

  function downloadImage(documentName, documentOriginalName) {
    if (documentName) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/documents/download_file/${documentName}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          const imageUrl = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = imageUrl
          a.download = documentOriginalName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(imageUrl)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    filesArray !== undefined &&
    filesArray.length > 0 &&
    filesArray.map((item, index) => (
      <Grid container md={12}>
        <Grid container>
          <div className="margin-top-10" />
        </Grid>
        {/* <div className="margin-left-100" /> */}
        <Grid container xs={1} sm={1} md={1} alignItems="flex-end">
          <div className="margin-left-100" />
          <a
            href={
              process.env.REACT_APP_API_URL + `/uploads/${item.document_name}`
            }
            target="_blank"
            rel="noopener noreferrer"
            className={'step-four-delete-image cursor-pointer '}
            //   onClick={() => handleChange('info')}
          >
            <img alt="" src={require('../../../../assets/svg/icon/view.svg')} />
          </a>
        </Grid>
        <Grid container xs={2} sm={2} md={2}>
          <Grid container xs={8} sm={6} md={2} alignItems="flex-end">
            <a
              // href={process.env.REACT_APP_API_URL+`/documents/download_file/${item.document_name}`}
              // download
              className={'step-four-delete-image cursor-pointer'}
              onClick={() =>
                downloadImage(item.document_name, item.document_orignal_name)
              }
            >
              <img
                alt=""
                src={require('../../../../assets/svg/icon/download.svg')}
                className=""
              />
            </a>
          </Grid>
          <Grid container md={10}>
            <p className="step-four-header vietnam-regular">Documentnaam</p>
            <p className="step-four-data vietnam-regular">
              {item.document_orignal_name}
            </p>
          </Grid>
        </Grid>
        <Grid container md={2}>
          <p className="step-four-header vietnam-regular ">
            Voorlopig ingegeven
          </p>
          <CustomInput
            value={item.provisionally_entered}
            ref={fileDetailsRef[index * 4]}
          />
        </Grid>
        <Grid container md={2}>
          <p className="step-four-header vietnam-regular ">
            Effectieve uitvoering
          </p>
          <p className="step-four-data vietnam-regular ">
            {item.effective_implementation}
          </p>
        </Grid>
        <Grid container md={1}>
          <p className="step-four-header vietnam-regular ">Status</p>
          <CustomSelect
            value={item.status}
            data={statusData}
            ref={fileDetailsRef[index * 4 + 1]}
          />
        </Grid>
        <Grid container md={1}>
          <p className="step-four-header vietnam-regular ">Type</p>
          <Grid container className="width-auto">
            <CustomSelect
              value={item.type}
              data={typeData}
              ref={fileDetailsRef[index * 4 + 2]}
            />
          </Grid>
        </Grid>
        <Grid container md={2}>
          <p className="step-four-header vietnam-regular ">Opmerking</p>
          <CustomInput value={item.note} ref={fileDetailsRef[index * 4 + 3]} />
        </Grid>
        <Grid container>
          <div className="margin-top-10" />
        </Grid>
      </Grid>
    ))
  )
})
