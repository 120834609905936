import React, { useRef, useState, useEffect, createRef } from 'react'
import { Grid } from '@material-ui/core'
import { DocumentDropzone } from '../step_two/step_two'
import axios from 'axios'

import './_step_three.scss'

export default function StepThree(props) {
  const {
    token,
    stepThreeData,
    architectFileName,
    builderFileName,
    projectServiceId,
  } = props
  const [fileNames, setFileNames] = useState([])
  const [statementRef, setStatementRef] = useState([])
  const [formData, setFormData] = useState(new FormData())

  useEffect(() => {
    const tempArray = []
    Array(2)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setStatementRef(tempArray)
    if (JSON.stringify(stepThreeData) !== '{}') {
      const tempFormData = new FormData()
      let serviceDocumentCategoryId =
        stepThreeData.service_document_categories.length > 0
          ? stepThreeData.service_document_categories[0].id
          : 0
      let serviceDocumentId =
        stepThreeData.service_document_categories.length > 0
          ? stepThreeData.service_document_categories[0].service_documents
              .length > 0
            ? stepThreeData.service_document_categories[0].service_documents[0]
                .id
            : 0
          : 0
      tempFormData.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData.append('projectServiceId', projectServiceId)
      tempFormData.append('serviceDocumentId', serviceDocumentId)
      setFormData(tempFormData)
      if (stepThreeData.service_document_categories.length > 0) {
        setFileNames(
          stepThreeData.service_document_categories[0].service_documents,
        )
      }
    }
  }, [stepThreeData])
  return (
    <Grid container className="fade-in-translate">
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        ></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Startverklaring
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <div className="flex-row width-auto">
        <DocumentDropzone
          fileNames={fileNames}
          setFileNames={setFileNames}
          path={
            process.env.REACT_APP_API_URL + '/service_document/upload_document'
          }
          formData={formData}
          token={token}
          projectServiceId={projectServiceId}
          serviceId={2}
        />
      </div>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        ></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Ondertekende startverklaring
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <StatementDetails backendFileName={builderFileName} />
          <StatementDetails backendFileName={architectFileName} />
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
    </Grid>
  )
}

export const StatementDetails = (props, ref) => {
  const { backendFileName, selectDate } = props
  const [fileName, setFileName] = useState('')
  const [originalFileName, setOriginalFileName] = useState('')
  const [date, setDate] = useState('')

  useEffect(() => {
    if (
      backendFileName !== undefined &&
      backendFileName !== null &&
      backendFileName !== '' &&
      backendFileName.name !== undefined &&
      backendFileName.name !== null
    ) {
      if (selectDate) {
        setFileName(backendFileName.viewName)
        setOriginalFileName(backendFileName.name)
        setDate('Opgemaakt op ' + backendFileName.date)
      } else {
        setFileName(backendFileName.viewName)
        setOriginalFileName(backendFileName.name)
      }
    }
  }, [backendFileName])

  function downloadImage(documentName, documentOriginalName) {
    if (documentName) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/documents/download_file/${documentName}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          const imageUrl = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = imageUrl
          a.download = documentOriginalName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(imageUrl)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <Grid container>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid container xs={12} sm={3} md={2}>
          <a
            // href={
            //   fileName && fileName !== '' &&
            //   process.env.REACT_APP_API_URL+`/documents/download_file/${fileName}`
            // }
            // download
            onClick={() => downloadImage(fileName, originalFileName)}
            className={`${
              fileName && fileName !== ''
                ? 'selected-tab-button-style'
                : 'disabled-button-style'
            } cursor-pointer flex-row vertical-center`}
          >
            <img
              alt=""
              src={
                fileName && fileName !== ''
                  ? require('../../../../assets/svg/icon/download.svg')
                  : require('../../../../assets/svg/icon/download_grey.svg')
              }
              className={
                fileName && fileName !== ''
                  ? ''
                  : 'width-2 height-2 margin-left-10'
              }
            />
            <p className="vietnam-semi-bold center-text width-auto">{`${
              fileName && fileName !== '' ? 'Download' : ''
            }`}</p>
          </a>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <p className="step-two-description vietnam-regular">
              {selectDate ? date : fileName || ''}
            </p>
          </Grid>
        </Grid>
      </Grid>
      {/* { backendFileName!==undefined && backendFileName!==null && backendFileName!=='' && backendFileName.viewName!==null && backendFileName.viewName!=='' ?  
        <div className='flex-row'>
          <Grid className="flex" justify="space-evenly" alignItems="center">
            <img
              alt=""
              className="step-three-file-icon"
              src={
                fileName.split('.').includes('pdf')
                  ? require('../../../../assets/svg/doc/pdf.svg')
                  : fileName.split('.').includes('doc')
                  ? require('../../../../assets/svg/doc/doc.svg')
                  : fileName.split('.').includes('xls')
                  ? require('../../../../assets/svg/doc/xls.svg')
                  : fileName.split('.').includes('jpg') 
                  ? require('../../../../assets/svg/doc/jpg.svg')
                  : require('../../../../assets/svg/doc/other.svg')
              }
            />
            <p className="step-three-file-name margin-left-10 vietnam-regular">
              {fileName}
            </p>
          </Grid>
          <Grid>
            <div className="margin-left-20" />
          </Grid>
          <Grid>
            <a
            href={backendFileName.viewName!==''&& process.env.REACT_APP_API_URL+`/uploads/${backendFileName.viewName}`}
            rel="noopener noreferrer"
            target="_blank"
              className={'step-three-delete-image cursor-pointer'}
              //   onClick={() => handleChange('info')}
            >
              <img
                alt=""
                src={require('../../../../assets/svg/icon/view.svg')}
                className=""
              />
            </a>
          </Grid>
          <Grid>
            <div className="margin-left-20" />
          </Grid>
          <Grid>
            <a
            href={backendFileName.viewName !==''&& process.env.REACT_APP_API_URL+`/documents/download_file/${backendFileName.viewName}`}
            download
            className={'step-three-delete-image cursor-pointer'}
              //   onClick={() => handleChange('info')}
            >
              <img
                alt=""
                src={require('../../../../assets/svg/icon/download.svg')}
                className=""
              />
            </a>
          </Grid>
          </div>   
          :
          <Grid container xs={11} sm={11}>
            <p className='font-color-black font-size-16 main-font-bold'>geen bestanden geüpload</p>
          </Grid>  
    } */}
    </Grid>
  )
}
StatementDetails.defaultProps = {
  data: {},
  projectId: 1,
}
