import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@material-ui/core'
import './_add_client.scss'
import CheckIcon from '../../../assets/svg/icon_check_white.svg'
import TrashIcon from '../../../assets/png/icon/trash.png'
import UploadIcon from '../../../assets/png/icon/upload.png'
import {
  addClientCompanyForm,
  addClientContactForm,
} from '../../shared/sample_data'
import axios from 'axios'
import Popup from '../../shared/popup/custom_popup.js'
import PropTypes from 'prop-types'
import PDFIcon from '../../../assets/svg/doc/pdf.svg'

export default function AddClient(props) {
  const { setCompanyId, setContactId, companyId, setShowService } = props
  const [animateState, setAnimateState] = useState(false)
  const [companyFormState, setCompanyFormState] = useState(
    JSON.parse(JSON.stringify(addClientCompanyForm)),
  )
  const [contactFormState, setContactFormState] = useState(
    JSON.parse(JSON.stringify(addClientContactForm)),
  )
  const [showResponseState, setShowResponseState] = useState(false)
  const [responseMessageState, setResponseMessageState] = useState({})
  const [companyImageState, setCompanyImageState] = useState(null)
  const [companyImageName, setCompanyImageName] = useState(null)

  // company form state vars
  const [companyName, setCompanyName] = useState('')
  const [companyBtw, setCompanyBtw] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [companyNr, setCompanyNr] = useState('')
  const [companyBus, setCompanyBus] = useState('')
  const [companyZip, setCompanyZip] = useState('')
  const [companyCity, setCompanyCity] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [companyTelephone, setCompanyTelephone] = useState('')
  const [companyBic, setCompanyBic] = useState('')
  const [companyIban, setCompanyIban] = useState('')

  const [companyNameError, setCompanyNameError] = useState(false)
  const [companyBtwError, setCompanyBtwError] = useState(false)
  const [companyAddressError, setCompanyAddressError] = useState(false)
  const [companyNrError, setCompanyNrError] = useState(false)
  const [companyBusError, setCompanyBusError] = useState(false)
  const [companyZipError, setCompanyZipError] = useState(false)
  const [companyCityError, setCompanyCityError] = useState(false)
  const [companyEmailError, setCompanyEmailError] = useState(false)
  const [companyTelephoneError, setCompanyTelephoneError] = useState(false)
  const [companyBicError, setCompanyBicError] = useState(false)
  const [companyIbanError, setCompanyIbanError] = useState(false)
  const [submitButtonClick, setSubmitButtonClick] = useState(false)
  const [adminFile, setAdminFile] = useState(null)
  const [fileUploadProgress, setFileUploadProgress] = useState(0)
  const [buttonDisableState, setButtonDisableState] = useState(false)
  const adminImageRef = useRef(null)
  const imageInputRef = useRef(null)

  const emailRegex = /[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+/
  const token = localStorage.getItem('authToken')

  useEffect(() => {
    setContactFormState(JSON.parse(JSON.stringify(addClientContactForm)))
    if (companyId > 0) {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/admin/company/get/${companyId}`,
          {},
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          const data = response.data
          if (data !== null && data !== undefined) {
            const companyData = data.company
            setCompanyImageState(
              companyData.profile_picture !== null
                ? process.env.REACT_APP_API_URL +
                    `/uploads/${companyData.profile_picture}`
                : null,
            )
            setAdminFile({ name: companyData.document_original })
            const contactData = data.user
            if (companyData !== null && companyData !== undefined) {
              const prevContactFormState = [...contactFormState]
              if (contactData !== null && contactData !== undefined) {
                if (contactData.length > 0) {
                  prevContactFormState.forEach((item) => {
                    if (item.name === 'contactId') {
                      item.value =
                        contactData[0].id !== null &&
                        contactData[0].id !== undefined
                          ? parseInt(contactData[0].id)
                          : 0
                      setContactId(item.value > 0 ? item.value : 0)
                    } else if (item.name === 'contactName') {
                      item.value =
                        contactData[0].lastName !== null
                          ? contactData[0].lastName
                          : ''
                    } else if (item.name === 'contactFirstName') {
                      item.value =
                        contactData[0].firstName !== null
                          ? contactData[0].firstName
                          : ''
                    } else if (item.name === 'contactPhone') {
                      item.value =
                        contactData[0].phone !== null &&
                        contactData[0].phone !== undefined
                          ? contactData[0].phone
                          : ''
                    } else if (item.name === 'contactProfession') {
                      item.value =
                        contactData[0].profession !== null
                          ? contactData[0].profession
                          : ''
                    } else if (item.name === 'contactEmail') {
                      item.value =
                        contactData[0].email !== null
                          ? contactData[0].email
                          : ''
                    } else if (item.name === 'contactPassword') {
                      item.value =
                        contactData[0].password !== null
                          ? contactData[0].password
                          : ''
                    } else if (item.name === 'userRole') {
                      item.value =
                        contactData[0].roleId !== null &&
                        contactData[0].roleId !== undefined
                          ? contactData[0].roleId.toString()
                          : '0'
                    }
                  })
                  setContactFormState(prevContactFormState)
                }
              }
              setCompanyImageName(companyData.profile_picture)
              setCompanyId(companyData.id)
              setCompanyName(companyData.name)
              setCompanyBtw(companyData.BTW)
              setCompanyAddress(companyData.companyAddress)
              setCompanyNr(companyData.no)
              setCompanyBus(companyData.bus)
              setCompanyZip(companyData.postalCode)
              setCompanyCity(companyData.city)
              setCompanyEmail(companyData.generalEmail)
              setCompanyTelephone(companyData.generalTelephone)
              companyData.bic !== null && setCompanyBic(companyData.bic)
              companyData.iban !== null && setCompanyIban(companyData.iban)
            }
          }
        })
        .catch((error) => {
          let responseMessage = {
            isSuccess: false,
            heading: 'Geen record gevonden',
            message: 'Er is iets fout gegaan',
            popupState: setShowResponseState,
          }
          setShowResponseState(true)
          setResponseMessageState(responseMessage)
        })
    }
  }, [companyId])

  const handleCompanyInputChange = (item, event) => {
    const prevCompanyState = [...companyFormState]
    const currentObj = prevCompanyState.find((a) => a.id === item.id)
    if (currentObj !== undefined) {
      if (event !== undefined) {
        const eventValue = event.target.value
        if (eventValue.trim() === '') {
          item.errorValue = 'Field required'
        } else {
          if (item.type === 'email') {
            if (emailRegex.test(eventValue) === true) {
              item.errorValue = ''
            } else {
              item.errorValue = 'Email required'
            }
          } else if (item.type === 'password') {
            if (eventValue.trim().length < 8) {
              item.errorValue = 'Password must be 8 digits long'
            } else {
              item.errorValue = ''
            }
          } else {
            item.errorValue = ''
          }
        }
        currentObj.value = event.target.value
      } else {
        currentObj.value = ''
      }
      setCompanyFormState(prevCompanyState)
    }
  }

  const handleContactInputChange = (item, event) => {
    const prevContactState = [...contactFormState]
    const currentObj = prevContactState.find((a) => a.id === item.id)
    if (currentObj !== undefined) {
      if (event !== undefined) {
        const eventValue = event.target.value
        if (eventValue.trim() === '') {
          currentObj.errorValue = 'Field required'
        } else {
          if (currentObj.type === 'email') {
            if (emailRegex.test(eventValue) === true) {
              currentObj.errorValue = ''
            } else {
              currentObj.errorValue = 'Email required'
            }
          } else if (currentObj.id === 4) {
            if (
              /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm.test(
                event.target.value,
              )
            ) {
              currentObj.errorValue = ''
            } else {
              currentObj.errorValue = 'number incorrect'
            }
          } else if (currentObj.type === 'password') {
            if (eventValue.trim().length < 8) {
              currentObj.errorValue = 'Password must be 8 digits long'
            } else {
              currentObj.errorValue = ''
            }
          } else if (currentObj.type === 'select') {
            if (eventValue === '5') {
              setShowService(true)
            } else {
              setShowService(false)
            }
            currentObj.errorValue = ''
          } else {
            currentObj.errorValue = ''
          }
        }
        currentObj.value = eventValue
      } else {
        currentObj.value = ''
      }
      setContactFormState(prevContactState)
    }
  }

  const handleSubmitClick = () => {
    const formValid = validateForms()
    const prevContactFormState = [...contactFormState]
    let contactInfo = {}
    if (formValid === true && isCompanyFieldsValid()) {
      let contactFormFilled = false
      prevContactFormState.forEach((item) => {
        if (item.name === 'contactId') {
          contactInfo.id =
            item.value !== null && item.value !== undefined
              ? parseInt(item.value)
              : 0
        } else if (item.name === 'contactName') {
          contactInfo.lastName = item.value
        } else if (item.name === 'contactFirstName') {
          contactInfo.firstName = item.value
        } else if (item.name === 'contactPhone') {
          contactInfo.phone = item.value
        } else if (item.name === 'contactProfession') {
          contactInfo.profession = item.value
        } else if (item.name === 'contactEmail') {
          contactInfo.email = item.value
        } else if (item.name === 'contactPassword') {
          contactInfo.password = item.value
        } else if (item.name === 'userRole') {
          contactInfo.roleId =
            item.value !== null && item.value !== undefined
              ? parseInt(item.value)
              : 0
        }
        if (item.value !== '' && item.value !== '0') {
          contactFormFilled = true
        }
      })

      let data = {
        companyInfo: {
          id: typeof companyId === 'number' ? companyId : 0,
          name: companyName,
          BTW: companyBtw,
          companyAddress: companyAddress,
          no: companyNr,
          bus: companyBus,
          postalCode: companyZip,
          city: companyCity,
          generalEmail: companyEmail,
          generalTelephone: companyTelephone,
          profile_picture: companyImageName,
          bic: companyBic,
          iban: companyIban,
          private: false,
        },
        contactInfo: contactFormFilled === true ? contactInfo : null,
      }
      setButtonDisableState(true)
      setSubmitButtonClick(true)
      axios
        .post(process.env.REACT_APP_API_URL + `/admin/company/add`, data, {
          headers: { authorization: `bearer ${token}` },
        })
        .then((response) => {
          const data = response.data
          let responseMessage = {
            success: true,
            heading: '',
            message: '',
          }
          setSubmitButtonClick(false)
          if (data !== undefined) {
            if (data.companyId > 0) {
              const companyIdControl = prevContactFormState.find(
                (a) => a.name === 'companyId',
              )
              if (companyIdControl !== undefined && companyIdControl !== null) {
                companyIdControl.value = data.companyId
              }
              setCompanyId(data.companyId)
            }
            if (data.message !== null && data.message !== undefined) {
              if (contactFormFilled === true) {
                if (data.message.userId > 0) {
                  const contactIdControl = prevContactFormState.find(
                    (a) => a.name === 'contactId',
                  )
                  if (
                    contactIdControl !== undefined &&
                    contactIdControl !== null
                  ) {
                    contactIdControl.value = data.message.userId
                  }
                  setShowResponseState(true)
                  responseMessage = {
                    isSuccess: true,
                    heading: 'Succesrecord opgeslagen',
                    message: 'Bedrijf en contactpersoon succesvol toegevoegd',
                    popupState: setShowResponseState,
                  }
                  setResponseMessageState(responseMessage)
                  setContactId(data.message.userId)
                  setContactFormState(prevContactFormState)
                } else {
                  setButtonDisableState(false)
                  setShowResponseState(true)
                  responseMessage = {
                    isSuccess: false,
                    heading: 'Record niet opgeslagen',
                    message: data.message.message,
                    popupState: setShowResponseState,
                  }
                  setResponseMessageState(responseMessage)
                }
              } else {
                setShowResponseState(true)
                responseMessage = {
                  isSuccess: true,
                  heading: 'Succesrecord opgeslagen',
                  message: 'Bedrijf succesvol toegevoegd',
                  popupState: setShowResponseState,
                }
                setResponseMessageState(responseMessage)
              }
            }
          }
        })
        .catch((error) => {
          setButtonDisableState(false)
          setSubmitButtonClick(false)
          setShowResponseState(true)
          let responseMessage = {
            isSuccess: false,
            heading: 'Record niet opgeslagen',
            message: error.response.data.message
              ? error.response.data.message
              : 'Er is iets misgegaan',
            popupState: setShowResponseState,
          }
          setResponseMessageState(responseMessage)
        })
    }
    setAnimateState(true)
    setTimeout(() => {
      setAnimateState(false)
    }, 2000)
  }

  const validateForms = () => {
    const prevContactState = [...contactFormState]
    let isValid = true
    const filledContactFields = prevContactState.filter((a) =>
      a.value !== null
        ? a.value.toString().trim() !== '' && a.value.toString().trim() !== '0'
        : false,
    )
    if (filledContactFields.length > 0) {
      const emptyContactFields = prevContactState.filter((a) =>
        a.value !== null ? a.value.toString().trim() === '' : true,
      )
      if (emptyContactFields !== undefined) {
        emptyContactFields.forEach((item) => {
          isValid = false
          item.errorValue = 'Field required'
        })
      }
    } else {
      prevContactState.forEach((item) => {
        item.errorValue = ''
      })
    }
    const errorContactFields = prevContactState.filter(
      (a) => a.errorValue.length > 0,
    )
    if (errorContactFields.length > 0) {
      isValid = false
    }
    setContactFormState(prevContactState)
    return isValid
  }

  const handleUploadImageClick = () => {
    if (imageInputRef.current !== undefined) {
      setButtonDisableState(false)
      imageInputRef.current.click()
    }
  }
  const handleImageUploadChange = (event) => {
    if (event !== undefined) {
      setButtonDisableState(false)
      const file = URL.createObjectURL(event.target.files[0])
      setCompanyImageState(file)
      callUploadApi(event.target.files[0])
    }
  }

  const callUploadApi = (fileName) => {
    const formData = new FormData()

    formData.append('imageToUpload', fileName)
    axios
      .post(process.env.REACT_APP_API_URL + `/user/new_user_image`, formData, {
        headers: {
          authorization: `bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          let filename = response.data.filename
          setCompanyImageName(filename)
        }
      })
      .catch((error) => {})
  }

  const handleDeleteImageClick = () => {
    if (imageInputRef.current !== undefined) {
      imageInputRef.current.value = ''
    }
    setButtonDisableState(false)
    setCompanyImageState(null)
  }

  const handleCompanyName = (event) => {
    companyNameError && setCompanyNameError(false)
    setButtonDisableState(false)
    setCompanyName(event.target.value)
  }

  const handleCompanyBtw = (event) => {
    companyBtwError && setCompanyBtwError(false)
    setButtonDisableState(false)
    setCompanyBtw(event.target.value)
  }

  const handleCompanyAddress = (event) => {
    companyAddressError && setCompanyAddressError(false)
    setButtonDisableState(false)
    setCompanyAddress(event.target.value)
  }

  const handleCompanyNr = (event) => {
    companyNrError && setCompanyNrError(false)
    setButtonDisableState(false)
    setCompanyNr(event.target.value)
  }

  const handleCompanyBus = (event) => {
    if (event.target.validity.valid) {
      companyBusError && setCompanyBusError(false)
    } else {
      !companyBusError && setCompanyBusError(true)
    }
    setButtonDisableState(false)
    setCompanyBus(event.target.value)
  }

  const handleCompanyZip = (event) => {
    if (event.target.validity.valid) {
      companyZipError && setCompanyZipError(false)
    } else {
      !companyZipError && setCompanyZipError(true)
    }
    setButtonDisableState(false)
    setCompanyZip(event.target.value)
  }

  const handleCompanyCity = (event) => {
    companyCityError && setCompanyNameError(false)
    setButtonDisableState(false)
    setCompanyCity(event.target.value)
  }

  const handleCompanyEmail = (event) => {
    if (event.target.validity.valid) {
      companyEmailError && setCompanyEmailError(false)
    } else {
      !companyEmailError && setCompanyEmailError(true)
    }
    setButtonDisableState(false)
    setCompanyEmail(event.target.value)
  }

  const handleCompanyTelephone = (event) => {
    if (event.target.validity.valid) {
      companyTelephoneError && setCompanyTelephoneError(false)
    } else {
      !companyTelephoneError && setCompanyTelephoneError(true)
    }
    setButtonDisableState(false)
    setCompanyTelephone(event.target.value)
  }

  const handleCompanyBic = (event) => {
    companyBicError && setCompanyBicError(false)
    setButtonDisableState(false)
    setCompanyBic(event.target.value)
  }

  const handleCompanyIban = (event) => {
    companyIbanError && setCompanyIbanError(false)
    setButtonDisableState(false)
    setCompanyIban(event.target.value)
  }

  function isCompanyFieldsValid() {
    // check to see if the fields are not empty and for the fields that have regex, to see if they have valid content
    let isValid = true
    if (companyName === '') {
      isValid = false
      setCompanyNameError(true)
    }
    if (companyBtw === '') {
      isValid = false
      setCompanyBtwError(true)
    }
    if (companyAddress === '' || companyAddressError) {
      isValid = false
      setCompanyAddressError(true)
    }
    if (companyNr === '') {
      isValid = false
      setCompanyNrError(true)
    }
    if (companyZip === '' || companyZipError) {
      isValid = false
      setCompanyZipError(true)
    }
    if (companyCity === '') {
      isValid = false
      setCompanyCityError(true)
    }
    if (companyEmail === '' || companyEmailError) {
      isValid = false
      setCompanyEmailError(true)
    }
    if (companyTelephone === '' || companyTelephoneError) {
      isValid = false
      setCompanyTelephoneError(true)
    }
    if (parseInt(contactFormState[contactFormState.length - 1].value) === 4) {
      if (companyBic === '') {
        isValid = false
        setCompanyBicError(true)
      }
      if (companyIban === '') {
        isValid = false
        setCompanyIbanError(true)
      }
    }
    return isValid
  }

  const handleAdminFileChange = (event) => {
    event.preventDefault()
    setButtonDisableState(false)
    const files = event.target.files
    if (files !== null && files !== undefined) {
      if (files.length > 0) {
        if (setAdminFile !== null && setAdminFile !== undefined) {
          if (files[0] !== null && files[0] !== undefined) {
            let formData = new FormData()
            formData.append('company_id', companyId)
            formData.append('docsInput', files[0])
            formData.append('file_name', files[0].name)
            axios
              .post(
                process.env.REACT_APP_API_URL +
                  `/admin/company/upload_document`,
                formData,
                {
                  headers: {
                    authorization: `bearer ${token}`,
                  },
                  onUploadProgress: (progress) => {
                    const { total, loaded } = progress
                    const totalSize = total / 1000000
                    const totalLoaded = loaded / 1000000
                    const percentage = (totalLoaded / totalSize) * 100
                    setFileUploadProgress(percentage.toFixed(2))
                  },
                },
              )
              .then((response) => {
                setFileUploadProgress(0)
                const responseData = response.data
                if (responseData !== null && responseData !== undefined) {
                  if (responseData.success === true) {
                    setAdminFile(files[0])
                  } else {
                    setButtonDisableState(false)
                    setAdminFile(null)
                  }
                } else {
                  setButtonDisableState(false)
                  setAdminFile(null)
                }
              })
              .catch((error) => {
                setButtonDisableState(false)
                // let responseMessage = {
                //     isSuccess: false,
                //     heading: 'Record not saved',
                //     message: 'please fill form first',
                //     popupState: setShowPopupState
                //   }
                // setShowPopupState(true)
                // setPopupMessageState(responseMessage)
                setAdminFile(null)
              })
          } else {
            setButtonDisableState(false)
            setAdminFile(null)
          }
        }
      }
    }
  }

  const handleDeleteAdminImageClick = () => {
    setButtonDisableState(false)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/admin/company/delete_document',
        {
          company_id: companyId,
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {})
      .catch((error) => {})
    setAdminFile({ ...adminFile, name: null })
  }

  return (
    <Grid container>
      <div className="add-client-main-div">
        <Grid container>
          <Grid container item xs={12} sm={12} md={6} lg={5}>
            <Grid container>
              <div>
                <span className="main-font-semi-bold font-color-black font-size-18">
                  Bedrijf
                </span>
              </div>
            </Grid>
            <Grid container className="margin-top-20">
              <Grid item xs={6}>
                {companyImageState ? (
                  <div className="add-client-img-div">
                    <img
                      src={companyImageState}
                      alt=""
                      className="width-auto object-fit-contain"
                    />
                  </div>
                ) : (
                  <div
                    className="add-client-img-div center-content"
                    style={{ border: 'dotted 2px #9B9B9B' }}
                  >
                    <p className="main-font-semi-bold font-size-14 font-color-light-grey">
                      Company logo here
                    </p>
                  </div>
                )}
              </Grid>
              <Grid item xs={6}>
                <div className="add-client-div-icon">
                  <input
                    type="file"
                    className="cursor-pointer add-client-display-none"
                    ref={imageInputRef}
                    onChange={handleImageUploadChange}
                    accept=".png,.svg,.jpg,.jpeg"
                  />
                  <button
                    className="cursor-pointer"
                    onClick={handleUploadImageClick}
                  >
                    <img
                      src={UploadIcon}
                      className="add-client-icon-button"
                      alt="Upload"
                    />
                  </button>
                  <button
                    className="cursor-pointer"
                    onClick={handleDeleteImageClick}
                  >
                    <img
                      src={TrashIcon}
                      className="add-client-icon-button"
                      alt="Delete"
                    />
                  </button>
                </div>
              </Grid>
            </Grid>
            <Grid container item>
              <div className="flex-column width-auto">
                <span className="main-font-regular font-color-grey margin-top-20">
                  Bedrijf
                </span>
                <input
                  placeholder=""
                  value={companyName}
                  className="input-custom-control margin-top-10"
                  onChange={handleCompanyName}
                  style={{
                    border: companyNameError
                      ? 'solid 1px red'
                      : 'solid 1px #DADADA',
                  }}
                />
              </div>
              <div className="flex-column width-auto">
                <span className="main-font-regular font-color-grey margin-top-10">
                  BTW
                </span>
                <input
                  placeholder=""
                  value={companyBtw}
                  className="input-custom-control margin-top-10"
                  onChange={handleCompanyBtw}
                  style={{
                    border: companyBtwError
                      ? 'solid 1px red'
                      : 'solid 1px #DADADA',
                  }}
                />
              </div>
              <div className="flex-row width-auto horizontal-between">
                <div className="flex-column width-60-p">
                  <span className="main-font-regular font-color-grey margin-top-10">
                    Adres
                  </span>
                  <input
                    placeholder=""
                    value={companyAddress}
                    className="input-custom-control margin-top-10"
                    onChange={handleCompanyAddress}
                    style={{
                      border: companyAddressError
                        ? 'solid 1px red'
                        : 'solid 1px #DADADA',
                    }}
                  />
                </div>
                <div className="flex-column width-19-p">
                  <span className="main-font-regular font-color-grey margin-top-10">
                    Nr
                  </span>
                  <input
                    placeholder=""
                    value={companyNr}
                    className="input-custom-control margin-top-10"
                    onChange={handleCompanyNr}
                    style={{
                      border: companyNrError
                        ? 'solid 1px red'
                        : 'solid 1px #DADADA',
                    }}
                  />
                </div>
                <div className="flex-column width-19-p">
                  <span className="main-font-regular font-color-grey margin-top-10">
                    Bus
                  </span>
                  <input
                    placeholder=""
                    value={companyBus}
                    className="input-custom-control margin-top-10"
                    onChange={handleCompanyBus}
                    style={{
                      border: companyBusError
                        ? 'solid 1px red'
                        : 'solid 1px #DADADA',
                    }}
                  />
                </div>
              </div>
              <div className="flex-row width-auto horizontal-between">
                <div className="flex-column width-29-p">
                  <span className="main-font-regular font-color-grey margin-top-10">
                    Postcode
                  </span>
                  <input
                    placeholder=""
                    value={companyZip}
                    className="input-custom-control margin-top-10"
                    pattern="[0-9]+"
                    onChange={handleCompanyZip}
                    autoComplete="new-password"
                    style={{
                      border: companyZipError
                        ? 'solid 1px red'
                        : 'solid 1px #DADADA',
                    }}
                  />
                </div>
                <div className="flex-column width-69-p">
                  <span className="main-font-regular font-color-grey margin-top-10">
                    Gemeente
                  </span>
                  <input
                    placeholder=""
                    value={companyCity}
                    className="input-custom-control margin-top-10"
                    onChange={handleCompanyCity}
                    style={{
                      border: companyCityError
                        ? 'solid 1px red'
                        : 'solid 1px #DADADA',
                    }}
                  />
                </div>
              </div>
              <div className="flex-column width-auto">
                <span className="main-font-regular font-color-grey margin-top-10">
                  Kantoor e-mail
                </span>
                <input
                  placeholder=""
                  value={companyEmail}
                  className="input-custom-control margin-top-10"
                  pattern="[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+"
                  autoComplete="new-password"
                  onChange={handleCompanyEmail}
                  style={{
                    border: companyEmailError
                      ? 'solid 1px red'
                      : 'solid 1px #DADADA',
                  }}
                />
              </div>
              <div className="flex-column width-auto">
                <span className="main-font-regular font-color-grey margin-top-10">
                  Kantoor tel.
                </span>
                <input
                  placeholder=""
                  value={companyTelephone}
                  className="input-custom-control margin-top-10"
                  pattern="^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$"
                  autoComplete="new-password"
                  onChange={handleCompanyTelephone}
                  style={{
                    border: companyTelephoneError
                      ? 'solid 1px red'
                      : 'solid 1px #DADADA',
                  }}
                />
              </div>
              {contactFormState.length > 0 &&
                parseInt(
                  contactFormState[contactFormState.length - 1].value,
                ) === 4 && (
                  <Grid>
                    <div className="flex-row width-auto">
                      <div className="flex-column width-auto margin-right-10">
                        <span className="main-font-regular font-color-grey margin-top-10">
                          BIC
                        </span>
                        <input
                          placeholder=""
                          value={companyBic}
                          className="input-custom-control margin-top-10"
                          autoComplete="new-password"
                          onChange={handleCompanyBic}
                          style={{
                            border: companyBicError
                              ? 'solid 1px red'
                              : 'solid 1px #DADADA',
                          }}
                        />
                      </div>
                      <div className="flex-column width-auto">
                        <span className="main-font-regular font-color-grey margin-top-10">
                          IBAN
                        </span>
                        <input
                          placeholder=""
                          value={companyIban}
                          className="input-custom-control margin-top-10"
                          autoComplete="new-password"
                          onChange={handleCompanyIban}
                          style={{
                            border: companyIbanError
                              ? 'solid 1px red'
                              : 'solid 1px #DADADA',
                          }}
                        />
                      </div>
                    </div>
                    {companyId > 0 && (
                      <Grid
                        container
                        className="margin-top-30"
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <div className="flex-row">
                          <input
                            type="file"
                            ref={adminImageRef}
                            className="ventilation-service-file-input"
                            accept="application/pdf"
                            onChange={(event) => handleAdminFileChange(event)}
                          />
                          {adminFile !== null &&
                          adminFile !== undefined &&
                          adminFile.name !== null ? (
                            <button
                              className="cursor-pointer"
                              onClick={handleDeleteAdminImageClick}
                            >
                              <img
                                src={TrashIcon}
                                className="add-client-icon-button"
                                alt="Delete"
                              />
                            </button>
                          ) : (
                            <button
                              className="cursor-pointer"
                              onClick={() => adminImageRef.current.click()}
                            >
                              <img
                                src={UploadIcon}
                                alt=""
                                className="add-client-icon-button"
                              />
                            </button>
                          )}
                        </div>
                        {adminFile !== null &&
                        adminFile !== undefined &&
                        adminFile.name !== null ? (
                          <div className="vertical-center margin-left-15">
                            <img
                              className="ventilation-service-button-icon"
                              alt=""
                              src={PDFIcon}
                            />
                            <span className="main-font-semi-bold font-color-black font-size-14 margin-left-5 margin-right-5 width-p-50">
                              {adminFile.name}
                            </span>
                          </div>
                        ) : null}
                        {fileUploadProgress !== 0 && (
                          <div class="lds-roller margin-left-15">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <span className="height-auto width-auto center-content absolute">
                              <p className="main-font-semi-bold font-size-12 font-color-blue">
                                {fileUploadProgress}%
                              </p>
                            </span>
                          </div>
                        )}
                      </Grid>
                    )}
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid container item xs={12} sm={12} md={6} lg={5}>
            <Grid container className="margin-bottom-25">
              <div>
                <span className="main-font-semi-bold font-color-black font-size-18">
                  Contactpersoon 1
                </span>
              </div>
            </Grid>
            {contactFormState !== null
              ? contactFormState.map((item) => {
                  return item.id > 1 ? (
                    item.type === 'select' ? (
                      <Grid container key={item.id} className="font-size-14">
                        <span className="main-font-regular font-color-grey margin-top-10">
                          {item.label}
                          {item.errorValue.length > 0 &&
                          animateState === true ? (
                            <span className="font-color-red">*</span>
                          ) : null}
                        </span>
                        <select
                          className={`input-custom-control margin-top-10 
                                                    ${
                                                      item.errorValue.length >
                                                        0 &&
                                                      animateState === true
                                                        ? 'input-error-border'
                                                        : ''
                                                    } `}
                          value={item.value}
                          onChange={(event) =>
                            handleContactInputChange(item, event)
                          }
                        >
                          <option selected disabled hidden value=""></option>
                          {item.options.map((option) => {
                            return (
                              <option key={option.id} value={option.id}>
                                {option.title}
                              </option>
                            )
                          })}
                        </select>
                        {item.errorValue.length > 0 && animateState === true ? (
                          <span className="font-color-red font-size-14 margin-left-10 margin-top-5">
                            {item.errorValue}
                          </span>
                        ) : null}
                      </Grid>
                    ) : (
                      <Grid container key={item.id} className="font-size-14">
                        <span className="main-font-regular font-color-grey margin-top-10">
                          {item.label}
                          {item.errorValue.length > 0 &&
                          animateState === true ? (
                            <span className="font-color-red">*</span>
                          ) : null}
                        </span>
                        <input
                          placeholder={item.placeholder}
                          type={item.type}
                          value={item.value}
                          pattern={item.pattern && item.pattern}
                          onChange={(event) =>
                            handleContactInputChange(item, event)
                          }
                          className={`input-custom-control margin-top-10 
                                                    ${
                                                      item.errorValue.length >
                                                        0 &&
                                                      animateState === true
                                                        ? 'input-error-border'
                                                        : ''
                                                    } `}
                        />
                        {item.errorValue.length > 0 && animateState === true ? (
                          <span className="font-color-red font-size-14 margin-left-10 margin-top-5">
                            {item.errorValue}
                          </span>
                        ) : null}
                      </Grid>
                    )
                  ) : null
                })
              : null}
          </Grid>
        </Grid>
        <Grid container className="margin-top-20">
          <Grid item xs={12}>
            <div className="add-client-div-save font-size-14">
              <button
                className="add-client-button-save"
                disabled={submitButtonClick === true ? true : false}
                disabled={buttonDisableState}
                onClick={handleSubmitClick}
              >
                <img src={CheckIcon} className="add-client-check-icon" alt="" />
                Save
              </button>
            </div>
          </Grid>
        </Grid>
        {showResponseState === true ? (
          <Popup data={responseMessageState} />
        ) : null}
      </div>
    </Grid>
  )
}

AddClient.propTypes = {
  setContactId: PropTypes.func,
  setCompanyId: PropTypes.func,
  companyId: PropTypes.number,
  setShowService: PropTypes.func,
}

AddClient.defaultProps = {
  setCompanyId: function () {},
  setContactId: function () {},
  setShowService: function () {},
  companyId: 0,
}
