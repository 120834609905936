import React, { useEffect, useState } from 'react'
import { Grid, Container } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './_project_application.scss'
import ProjectApplicationService from './project_application_service/project_application_service'
import ProjectApplicationBuildingType from './project_application_buildingtype/project_application_buildingtype'
import ProjectApplicationInformation from './project_application_information/project_application_information'
import ProjectApplicationContactInfo from './project_application_contactinfo/project_application_contactinfo'
import StepSeven from './step_seven/step_seven'
import StepFive from './step_five/step_five'
import StepSix from './step_six/step_six'
import FormState from './form_state/form_state'
import Header from '../dashboard/header/header'

export default function ProjectApplication() {
  const [stepState, setStepState] = useState(1)
  const [serviceState, setServiceState] = useState({})
  const [userRole, setUserRole] = useState('')
  const [serviceObjectArray, setServiceObjectArray] = useState([])
  const [selectedTypeState, setSelectedTypeState] = useState([])
  const [buildingTypeState, setBuildingTypeState] = useState([])
  const [hasStepTwo, setStepTwoVisibility] = useState(false)
  const [selectedUser, setSelectedUser] = useState(0)
  const [appInfoData, addAppInfoData] = useState({
    buildingIdState: 0,
    moreInfoState: '',
    projectNameState: '',
    addressState: '',
    no: '',
    bus: '',
    mailState: '',
    zipcodeState: '',
    cityState: '',
  })
  const [appInfoOnSwitchState, setAppInfoOnSwitchState] = useState(false)
  const [billInfoData, addBillInfoData] = useState([])
  const [resultArray, setResultArray] = useState([])
  const [isAddButtonClick, setAddButtonState] = useState(false)
  const [stepSevenData, setStepSevenData] = useState('')
  const [isStepSixButtonClick, setStepSixButtonState] = useState(false)

  const parameters = useParams()
  const token = localStorage.getItem('authToken')

  const handleChangeAppInfoForm = (evt) => {
    const value = evt.target.value
    if (evt.target.name === 'zipcodeState') {
      if (value.length < 5) {
        addAppInfoData({
          ...appInfoData,
          [evt.target.name]: value,
        })
      }
    } else {
      addAppInfoData({
        ...appInfoData,
        [evt.target.name]: value,
      })
    }
  }

  useEffect(() => {
    if (isStepSixButtonClick) {
      let buildingId = 0
      const data = {
        services: JSON.parse(localStorage.getItem('services')),
        id: buildingId,
        title: appInfoData.projectNameState,
        address: appInfoData.addressState,
        city: appInfoData.cityState,
        zip: appInfoData.zipcodeState,
        description: appInfoData.moreInfoState,
        no: appInfoData.no,
        bus: appInfoData.bus,
        userId: selectedUser.id,
        isbilling: parameters.value,
      }
      axios
        .post(process.env.REACT_APP_API_URL + `/buildlings/add`, data, {
          headers: { authorization: `bearer ${token}` },
        })
        .then((response) => {
          if (response.data.result.id !== undefined) {
            localStorage.setItem('buildingId', response.data.result.id)
            buildingId = response.data.result.id
            if (response.data.projectData !== null) {
              localStorage.setItem('projectId', response.data.projectData.id)
            }
            billInfoData.buildingIdState = buildingId
            axios
              .post(
                process.env.REACT_APP_API_URL +
                  `/company_billing/store_billing_by_id`,
                {
                  id: selectedUser.id,
                  dataArray: billInfoData,
                },
                { headers: { authorization: `bearer ${token}` } },
              )
              .then((response) => {
                addBillInfoData(response.data.data)
                if (response.data.message === 'Billing data saved') {
                  createFacturate(response.data.data, buildingId)
                }
              })
              .catch((error) => {})
          }
        })
        .catch((error) => {})
    }
  }, [isStepSixButtonClick])

  useEffect(() => {
    if (resultArray.length > 0) {
      let selectedServiceIds = JSON.parse(
        localStorage.getItem('selectedServiceIds'),
      )
      if (isAddButtonClick) {
        if (
          !resultArray.includes(false) &&
          resultArray.length === selectedServiceIds.length * 2
        ) {
          axios
            .post(
              process.env.REACT_APP_API_URL + `/projects/stepSixStore`,
              {},
              { headers: { authorization: `bearer ${token}` } },
            )

            .then((response) => {
              setStepSevenData(response.data.messages)
            })
            .catch((error) => {})
        }
      } else {
        if (
          !resultArray.includes(false) &&
          resultArray.length === selectedServiceIds.length
        ) {
          axios
            .post(
              process.env.REACT_APP_API_URL + `/projects/stepSixStore`,
              {},
              { headers: { authorization: `bearer ${token}` } },
            )

            .then((response) => {
              setStepSevenData(response.data.messages)
            })
            .catch((error) => {})
        }
      }
    }
  }, [resultArray])

  const createFacturate = (companyData, buildingId) => {
    let selectedServiceIds = JSON.parse(
      localStorage.getItem('selectedServiceIds'),
    )
    billInfoData.map((_, index) => {
      selectedServiceIds.map((item) => {
        axios
          .post(
            process.env.REACT_APP_API_URL + `/invoice/create`,
            {
              buildingId: buildingId,
              serviceId: item,
              isbilling: parameters.value,
              company_billing_id: companyData[index].id,
              is_cancelled: false,
            },
            { headers: { authorization: `bearer ${token}` } },
          )
          .then((response) => {
            if (response.data === 'Created Successfully') {
              setResultArray([...resultArray, resultArray.push(true)])
            } else {
              setResultArray([...resultArray, false])
            }
          })
          .catch((error) => {})
      })
    })
  }

  return (
    <>
      <div className="height-100-p width-100-p background-color-faded-faint-grey absolute" />

      <Grid container className="background-color-faded-faint-grey relative">
        <Header isLogoEnabled hasButton={false} toShowHamburger={true} />
        <FormState
          stepState={stepState}
          setStepState={setStepState}
          hasStepTwo={hasStepTwo}
        />
        <Grid container item xs={12}>
          <Container maxWidth="lg">
            {stepState === 1 ? (
              <ProjectApplicationService
                serviceState={serviceState}
                setServiceState={setServiceState}
                setStepNumber={setStepState}
                userRole={userRole}
                setUserRole={setUserRole}
                serviceObjectArray={serviceObjectArray}
                setServiceObjectArray={setServiceObjectArray}
                hasStepTwo={hasStepTwo}
                setStepTwoVisibility={setStepTwoVisibility}
                buildingTypeState={buildingTypeState}
                setBuildingTypeState={setBuildingTypeState}
              />
            ) : stepState === 2 && hasStepTwo ? (
              <ProjectApplicationBuildingType
                selectedTypeState={selectedTypeState}
                setSelectedTypeState={setSelectedTypeState}
                setStepNumber={setStepState}
                buildingTypeState={buildingTypeState}
                setBuildingTypeState={setBuildingTypeState}
              />
            ) : stepState === 3 ? (
              <ProjectApplicationInformation
                appInfoData={appInfoData}
                addData={addAppInfoData}
                onSwitchState={appInfoOnSwitchState}
                setOnSwitchState={setAppInfoOnSwitchState}
                handleChange={handleChangeAppInfoForm}
                setStepNumber={setStepState}
              />
            ) : stepState === 4 ? (
              <ProjectApplicationContactInfo
                setStepNumber={setStepState}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            ) : stepState === 5 ? (
              <StepFive
                setStepNumber={setStepState}
                data={billInfoData}
                addData={addBillInfoData}
                isAddButtonClick={isAddButtonClick}
                setAddButtonState={setAddButtonState}
                selectedUser={selectedUser}
              />
            ) : stepState === 6 ? (
              <StepSix
                stepSevenData={stepSevenData}
                setStepSevenData={setStepSevenData}
                setStepNumber={setStepState}
                setStepSixButtonState={setStepSixButtonState}
                selectedUser={selectedUser}
              />
            ) : stepState === 7 ? (
              // stepSevenData.message_1 !== undefined && (
              <StepSeven data={stepSevenData} />
            ) : // )
            null}
          </Container>
        </Grid>
      </Grid>
    </>
  )
}
