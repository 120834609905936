import React, { useState, useEffect, useRef } from 'react'
import { Grid, Hidden } from '@material-ui/core'
// import Header from '../Header/header.js';
import { useHistory, useParams } from 'react-router-dom'
import './_ventilation_service_client.scss'
import Step1 from './step_1/step_1'
import Step2 from './step_2/step_2'
import Step3 from '../ventilation_service_admin/step_3/step_3'
import { ventilationTypes } from '../../shared/sample_data'
import axios from 'axios'
import Popup from '../../shared_components/custom_popup/custom_popup'
import { CustomSelect } from '../../shared_components/custom_select/custom_select'
// import DashboardSidebar from '../dashboard/dashboard_sidebar/dashboard_sidebar';
import { isTokenValid } from '../../shared/shared_functions'

export default function VentilationServiceClient(props) {
  const { buildingData, setBuildingData } = props
  const [stepState, setStepState] = useState(1)
  const [stepThreeData, setStepThreeData] = useState({})
  const [stepOneEPBFileState, setstepOneEPBFileState] = useState(null)
  const [stepOneAdviseFileState, setstepOneAdviseFileState] = useState(null)
  const [stepOneVentTypeState, setStepOneVentTypeState] = useState('')
  const [stepOneBrandState, setStepOneBrandState] = useState('')
  const [stepOneTypeState, setStepOneTypeState] = useState('')
  const stepOneVentTypesState = ventilationTypes
  const [stepTwoWorkFileState, setStepTwoWorkFileState] = useState([])
  const [stepTwoQualityFileState, setStepTwoQualityFileState] = useState([])
  const [stepThreeWorkFileState, setStepThreeWorkFileState] = useState(null)
  const [stepThreeQualityFileState, setStepThreeQualityFileState] =
    useState(null)
  const [showPopupState, setShowPopupState] = useState(false)
  const [popupMessageState, setPopupMessageState] = useState({})
  const [errorState, setErrorState] = useState(false)
  const [projectId, setProjectId] = useState(0)
  const [projectServiceId, setProjectServiceId] = useState(0)
  const [ventilationFormId, setVentilationFormId] = useState(0)
  const [showMenuState, setShowMenuState] = useState(false)
  const parameters = useParams()
  const loggedInUserDetails = JSON.parse(
    localStorage.getItem('loggedInUserDetails'),
  )
  const userRole = loggedInUserDetails.role.title

  const token = localStorage.getItem('authToken')
  const history = useHistory()
  const dropDownData = [
    { id: 1, title: '1. Startgegevens' },
    { id: 2, title: '2. Ventilatievoorontwerp' },
    { id: 3, title: '3. Ventilatiemeting' },
  ]

  useEffect(() => {
    isTokenValid(history)
  }, [])

  useEffect(() => {
    let newId = parameters.id.split('-')
    setProjectId(newId[1])
    setProjectServiceId(newId[0])
  }, [parameters.id])

  useEffect(() => {
    if (projectServiceId > 0) {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/ventilation_service_form/get`,
          { projectServiceId: projectServiceId },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.data.message === 'Success!') {
            let data = response.data.data[0]
            if (data.id !== undefined) {
              setVentilationFormId(data.id)
              setStepOneBrandState(data.brand)
              setStepOneTypeState(data.type)
              setStepOneVentTypeState(data.type_of_ventilation)
              setstepOneEPBFileState({
                viewName: data.epc_software_document,
                name: data.epc_software_document_orignal_name,
              })
              setstepOneAdviseFileState({
                viewName: data.adviesrapport_document,
                name: data.adviesrapport_document_orignal_name,
              })
              setBuildingData(data.building)
            } else {
              setBuildingData(data.building)
            }
          }
        })
        .catch((error) => {})

      axios
        .post(
          process.env.REACT_APP_API_URL + `/service_document/get`,
          { serviceId: 3, projectServiceId: projectServiceId },
          {
            headers: {
              authorization: `bearer ${token}`,
            },
          },
        )
        .then((response) => {
          if (response.data.success === true && response.data.data.length > 0) {
            let data = response.data.data
            if (data[1].service_document_categories.length > 0) {
              if (
                data[1].service_document_categories[0].service_documents
                  .length > 0
              ) {
                setStepTwoWorkFileState(
                  data[1].service_document_categories[0].service_documents[0],
                )
              }
              if (
                data[1].service_document_categories[1].service_documents
                  .length > 0
              ) {
                setStepTwoQualityFileState(
                  data[1].service_document_categories[1].service_documents[0],
                )
              }
            }
            setStepThreeData(data[2])
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear()
            history.push('/')
          }
        })
    }
  }, [projectServiceId])

  const handleSubmitFormClick = () => {
    if (stepState === 1 && userRole !== 'Extern') {
      if (
        stepOneVentTypeState.length > 0 &&
        stepOneBrandState.length > 0 &&
        stepOneTypeState.length > 0
      ) {
        const data = {
          ventilationFormId: ventilationFormId > 0 && ventilationFormId,
          type_of_ventilation: stepOneVentTypeState,
          brand: stepOneBrandState,
          type: stepOneTypeState,
          projectId: projectId,
          projectServiceId: projectServiceId,
        }
        axios
          .post(
            process.env.REACT_APP_API_URL + `/ventilation_service_form/store`,
            data,
            { headers: { authorization: `bearer ${token}` } },
          )
          .then((response) => {
            const responseData = response.data
            if (responseData.message === 'Success!') {
              let responseMessage = {
                isSuccess: true,
                heading: 'Succesrecord opgeslagen',
                message: '',
                popupState: setShowPopupState,
              }
              setShowPopupState(true)
              setPopupMessageState(responseMessage)
              setStepState(2)
            } else {
              let responseMessage = {
                isSuccess: false,
                heading: 'Record niet opgeslagen',
                message: 'Er is iets fout gegaan',
                popupState: setShowPopupState,
              }
              setShowPopupState(true)
              setPopupMessageState(responseMessage)
            }
          })
          .catch((error) => {
            let responseMessage = {
              isSuccess: false,
              heading: 'Record niet opgeslagen',
              message: 'Er is iets fout gegaan',
              popupState: setShowPopupState,
            }
            setShowPopupState(true)
            setPopupMessageState(responseMessage)
          })
      } else {
        setErrorState(true)
        setTimeout(() => {
          setErrorState(false)
        }, 2000)
      }
    } else if (stepState === 2) {
      setStepState(3)
    } else if (stepState === 3) {
    }
  }

  return (
    <Grid container>
      <div className="height-auto width-auto margin-top-20 animatable-400 fade-in-translate">
        <Hidden xsDown>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid container item xs={9}>
              <div
                className={`ventilation-service-tab-div ${
                  stepState === 1
                    ? 'ventilation-service-selected-border animatable-600 ease-in-out-quart'
                    : ''
                }`}
              >
                <span
                  className="ventilation-service-tab-item main-font-semi-bold cursor-pointer"
                  onClick={() => {
                    setStepState(1)
                  }}
                >
                  Startgegevens
                </span>
              </div>
              <div
                className={`ventilation-service-tab-div ${
                  stepState === 2
                    ? 'ventilation-service-selected-border animatable-600 ease-in-out-quart'
                    : ''
                }`}
              >
                <span
                  className="ventilation-service-tab-item main-font-semi-bold cursor-pointer"
                  onClick={() => {
                    setStepState(2)
                  }}
                >
                  Ventilatievoorontwerp
                </span>
              </div>
              <div
                className={`ventilation-service-tab-div ${
                  stepState === 3
                    ? 'ventilation-service-selected-border animatable-600 ease-in-out-quart'
                    : ''
                }`}
              >
                <span
                  className="ventilation-service-tab-item main-font-semi-bold cursor-pointer"
                  onClick={() => {
                    setStepState(3)
                  }}
                >
                  Ventilatiemeting
                </span>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div>
                <button
                  className="epb-service-button cursor-pointer"
                  style={{ opacity: userRole === 'Extern' ? 0.2 : 1 }}
                  onClick={handleSubmitFormClick}
                >
                  Bewaren
                </button>
              </div>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid container justify="center" alignItems="center">
            <Grid container xs={5} sm={5} className="margin-top-10">
              <CustomSelect
                data={dropDownData}
                setParentSelectedValue={setStepState}
                value={stepState}
              />
            </Grid>
            <Grid container xs={5} sm={5} justify="center">
              <button
                style={{ opacity: userRole === 'Extern' ? 0.2 : 1 }}
                className="epb-service-button cursor-pointer"
                onClick={handleSubmitFormClick}
              >
                Bewaren
              </button>
            </Grid>
          </Grid>
        </Hidden>
        <Grid container>
          {stepState === 1 ? (
            <Step1
              epbFileState={stepOneEPBFileState}
              setEPBFileState={setstepOneEPBFileState}
              adviseFileState={stepOneAdviseFileState}
              setAdviseFileState={setstepOneAdviseFileState}
              ventType={stepOneVentTypeState}
              setVentType={setStepOneVentTypeState}
              brand={stepOneBrandState}
              setBrand={setStepOneBrandState}
              type={stepOneTypeState}
              setType={setStepOneTypeState}
              ventTypeOptions={stepOneVentTypesState}
              error={errorState}
              projectId={projectId}
              projectServiceId={projectServiceId}
              hasEPB={buildingData.hasEPB}
              userRole={userRole}
            />
          ) : stepState === 2 ? (
            <Step2
              workFiles={stepTwoWorkFileState}
              qualityFiles={stepTwoQualityFileState}
              token={token}
              projectId={projectId}
              stepNumber={stepState}
              projectServiceId={projectServiceId}
              userRole={userRole}
            />
          ) : stepState === 3 ? (
            <Step3
              stepThreeData={stepThreeData}
              workFile={stepThreeWorkFileState}
              qualityFile={stepThreeQualityFileState}
              setWorkFile={setStepThreeWorkFileState}
              setQualityFile={setStepThreeQualityFileState}
              projectId={projectId}
              projectServiceId={projectServiceId}
            />
          ) : null}
        </Grid>
        {showPopupState === true ? <Popup data={popupMessageState} /> : null}
      </div>
    </Grid>
  )
}
