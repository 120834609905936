import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { debounce } from 'lodash'
import { Grid, Hidden } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import './_services.scss'
import './calendar.css'

import Header from '../header/header'
import { serviceFilterManifestDefaultStructure } from '../../shared/sample_data'
import DeleteConfirmation from '../../shared/delete_confirm/delete_confirm'
import ServiceMobile from './services_mobile/services_mobile'
import ServicesTab from './services_tab/services_tab'

function CheckBox(props) {
  const [buttonState, setButtonState] = useState(false)
  const { type, index, changeFilter } = props

  const handleButton = () => {
    changeFilter(type, index)
    setButtonState(!buttonState)
  }

  return (
    <div className="services-checkbox" onClick={handleButton}>
      <div
        className="services-checkbox-empty center-content animatable-400 ease-in-out-quart"
        style={{
          background: buttonState ? 'rgb(176, 236, 182)' : 'rgba(0,0,0,0)',
        }}
      >
        <img
          src={require('../../../assets/svg/icon/check_white.svg')}
          alt="check white"
          className="height-80-p width-80-p object-fit-contain"
        />
      </div>
    </div>
  )
}

const StatusDisplay = forwardRef((props, ref) => {
  const [showStatus, setShowStatus] = useState(false)
  const [type, setType] = useState(0)

  useEffect(() => {
    if (type !== 0) {
      setShowStatus(true)
      handleTypeChange()
    }
  }, [type])

  const handleTypeChange = debounce(() => {
    setShowStatus(false)
    setType(0)
  }, 3000)

  useImperativeHandle(ref, () => ({
    setValue: (value) => {
      setShowStatus(value)
    },
    showDatum: () => {
      setType(1)
    },
    showCustomer: () => {
      setType(2)
    },
    showCategory: () => {
      setType(3)
    },
  }))

  return (
    <div
      className="services-status-display-container vertical-center animatable-400 ease-in-out-quart"
      style={{
        opacity: showStatus ? 1 : 0,
        transform: showStatus ? 'translateX(0px)' : 'translateX(100%)',
      }}
    >
      <p className="main-font-semi-bold font-size-14 services-status-display">
        {type === 1
          ? 'Bijgewerkt Inspectie Datum'
          : type === 2
          ? 'Bijgewerkt Verantw.'
          : 'Bijgewerkt Status'}
      </p>
    </div>
  )
})

function FilterDropdown(props) {
  const [filterState, setFilterState] = useState(false)
  const [controlBlur, setControlBlur] = useState(false)

  const { servicesData, serviceCategoryData, changeFilter } = props
  const filterRef = useRef(0)

  const handleFilter = () => {
    if (!controlBlur) {
      if (!filterState) {
        filterRef.current.focus()
      }
      setControlBlur(true)
      setFilterState(!filterState)
    } else {
      setControlBlur(false)
    }
  }

  const handleFilterBlur = () => {
    setFilterState(false)
  }

  return (
    <div className="services-filter-container">
      <div className="services-filter center-content" onClick={handleFilter}>
        <p className="main-font-regular font-size-14 font-color-grey">
          Filters
        </p>
        <img
          src={require('../../../assets/svg/icon/down_arrow.svg')}
          alt="arrow down"
          className="services-filter-arrow margin-left-10"
        />
      </div>
      <div
        className="services-filter-selection-container animatable-400 ease-in-out-quart overflow-hidden"
        tabIndex="1"
        onBlur={handleFilterBlur}
        style={{
          height: filterState
            ? `${
                (parseInt(servicesData.length / 3) +
                  (servicesData.length % 3)) *
                  35 +
                (parseInt(serviceCategoryData.length / 3) +
                  (serviceCategoryData.length % 3)) *
                  35 +
                150
              }px`
            : '0px',
          opacity: filterState ? 1 : 0,
          left: window.screen.width < 1500 ? '-300px' : '0px',
        }}
        ref={filterRef}
      >
        <div
          className="flex-row width-auto"
          style={{
            justifyContent:
              window.screen.width < 1500 ? 'center' : 'flex-start',
          }}
        >
          <div className="services-filter-selection-arrow">
            <img
              src={require('../../../assets/svg/icon/corner.png')}
              alt="triangle"
              className="height-auto width-auto object-fit-contain"
            />
          </div>
        </div>
        <div className="services-filter-selection">
          <Grid container item sm={12}>
            <Grid container item sm={4}>
              <div className="flex-row vertical-center">
                <CheckBox type={1} index={0} changeFilter={changeFilter} />
                <p className="main-font-regular font-size-14 font-color-black margin-left-15">
                  Alle dossiers
                </p>
              </div>
            </Grid>
            <Grid container item sm={4}>
              <div className="flex-row vertical-center">
                <CheckBox type={1} index={1} changeFilter={changeFilter} />
                <p className="main-font-regular font-size-14 font-color-black margin-left-15">
                  Mijn dossiers
                </p>
              </div>
            </Grid>
            <Grid container item sm={12}>
              <div className="margin-top-25" />
            </Grid>
            <Grid container item sm={12}>
              <div className="services-filter-selection-line" />
            </Grid>
            <Grid container item sm={12}>
              <div className="margin-top-25" />
            </Grid>
            <Grid container item sm={12}>
              {serviceCategoryData.length > 0 &&
                serviceCategoryData.map((item, index) => (
                  <Grid container item sm={4}>
                    <div className="flex-row vertical-center margin-bottom-15">
                      <CheckBox
                        type={3}
                        index={index}
                        changeFilter={changeFilter}
                      />
                      <p className="main-font-regular font-size-14 font-color-black margin-left-15">
                        {item.title}
                      </p>
                    </div>
                  </Grid>
                ))}
            </Grid>
            <Grid container item sm={12}>
              <div className="services-filter-selection-line" />
            </Grid>
            <Grid container item sm={12}>
              <div className="margin-top-25" />
            </Grid>
            <Grid container item sm={12}>
              {servicesData.length > 0 &&
                servicesData.map((item, index) => (
                  <Grid container item sm={4}>
                    <div className="flex-row vertical-center margin-bottom-15">
                      <CheckBox
                        type={4}
                        index={index}
                        changeFilter={changeFilter}
                      />
                      <p className="main-font-regular font-size-14 font-color-black margin-left-15">
                        {item.title}
                      </p>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

const PageNavigator = forwardRef((props, ref) => {
  const [scrollButton, setScrollButton] = useState(false)

  const { scrollAreaRef } = props

  useImperativeHandle(ref, () => ({
    setValue: (value) => {
      setScrollButton(value)
    },
    getValue: () => {
      return scrollButton
    },
  }))

  const handlePageUp = () => {
    if (scrollButton) {
      scrollAreaRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <button
      type="button"
      onClick={handlePageUp}
      className="services-scrolltop animatable-400 ease-in-out-quart"
      style={{
        opacity: scrollButton ? 1 : 0,
        transform: scrollButton ? 'translateY(0px)' : 'translateY(-15px)',
      }}
    >
      <img
        src={require('../../../assets/svg/icon/arrow_up.svg')}
        alt="arrow up"
        className="services-scrolltop-arrow"
      />
    </button>
  )
})

function ArrowLeft(props) {
  const { rotate } = props
  return (
    <img
      src={require('../../../assets/svg/icon/arrow_left.svg')}
      alt="arrow left"
      style={{
        height: '15px',
        width: '15px',
        transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
      className="object-fit-contain"
    />
  )
}

function Service(props) {
  // state vars for modal control
  const [statusFilter, setStatusFilter] = useState(false)
  const [statusControlBlur, setStatusControlBlur] = useState(false)
  const [responsibleFilter, setResponsibleFilter] = useState(false)
  const [responsibleControlBlur, setResponsibleConrtrolBlur] = useState(false)
  const [dateFilter, setDateFilter] = useState(false)
  const [dateControlBlur, setDateControlBlur] = useState(false)

  // state vars for date/time picker
  const [date, setDate] = useState(new Date())
  const [startTimeHour, setStartTimeHour] = useState('')
  const [startTimeMinute, setStartTimeMinute] = useState('')
  const [endTimeHour, setEndTimeHour] = useState('')
  const [endTimeMinute, setEndTimeMinute] = useState('')

  // state vars for dropdown fields that change intial selected value
  const [responsible, setResponsible] = useState('-')

  // standard state vars
  const [mouseActive, setMouseActive] = useState(false)
  const [instancedData, setInstancedData] = useState({})
  const [renderDom, setRenderDom] = useState(false)
  const [unmountState, setUnmountState] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [buttonDisableState, setButtonDisableState] = useState(false)
  // refs for modal dom control
  const nameFilterRef = useRef(0)
  const responsibleFilterRef = useRef(0)
  const dateFilterRef = useRef(0)

  // data from parent
  const {
    buildingData,
    changeCategory,
    deleteService,
    buildingIndex,
    projectIndex,
    serviceIndex,
    responsibleList,
    zIndex,
    statusRef,
    searchDataPool,
    setSearchDataPool,
    data,
  } = props
  const history = useHistory()
  const token = localStorage.getItem('authToken')

  useEffect(() => {
    if (data?.id) {
      if (data?.billing_start_time !== null) {
        let billingStartTime = data?.billing_start_time.split(':')
        setStartTimeHour(billingStartTime[0])
        setStartTimeMinute(billingStartTime[1])
      } else {
        setStartTimeHour('')
        setStartTimeMinute('')
      }

      if (data?.billing_end_time !== null) {
        let billingEndTime = data?.billing_end_time.split(':')
        setEndTimeHour(billingEndTime[0])
        setEndTimeMinute(billingEndTime[1])
      } else {
        setEndTimeHour('')
        setEndTimeMinute('')
      }

      setInstancedData(data)
      setRenderDom(true)
    }
  }, [data])

  useEffect(() => {
    if (data?.assigned_admin_id !== null && responsibleList.length > 0) {
      const userName = responsibleList.filter(
        (admin) => admin.id === data?.assigned_admin_id,
      )
      if (userName?.length > 0) {
        setResponsible(userName[0] && userName[0].firstName)
      } else {
        setResponsible('-')
      }
    } else {
      setResponsible('-')
    }
  }, [data, responsibleList])

  // functions for animating and controlling the filters
  const handleStatusFilter = () => {
    if (!statusControlBlur) {
      if (!statusFilter) {
        nameFilterRef.current.focus()
      }
      setStatusControlBlur(true)
      setStatusFilter(!statusFilter)
    } else {
      setStatusControlBlur(false)
    }
  }

  const handleStatusFilterBlur = () => {
    setStatusFilter(false)
  }

  const handleResponsibleFilter = () => {
    if (!responsibleControlBlur) {
      if (!responsibleFilter) {
        responsibleFilterRef.current.focus()
      }
      setResponsibleConrtrolBlur(true)
      setResponsibleFilter(!responsibleFilter)
    } else {
      setResponsibleConrtrolBlur(false)
    }
  }

  const handleResponsibleFilterBlur = () => {
    setResponsibleFilter(false)
  }

  const handleDateFilter = () => {
    if (!dateControlBlur) {
      if (!dateFilter) {
        dateFilterRef.current.focus()
      }
      setDateControlBlur(true)
      setDateFilter(!responsibleFilter)
    } else {
      setDateControlBlur(false)
    }
  }

  const handleDateFilterBlur = () => {
    if (!mouseActive) {
      setDateFilter(false)
    }
  }

  // functions for input times and date mechanics
  const handleStartTimeHour = (event) => {
    let newData = JSON.parse(JSON.stringify(instancedData))
    setButtonDisableState(false)
    if (event.target.validity.valid) {
      let newTime = event.target.value
      if (parseInt(newTime) > 23) {
        newTime = '23'
      }
      newData.billing_start_time = `${newTime}:${startTimeMinute}`
      setInstancedData(newData)
      setStartTimeHour(newTime)
    } else {
      setStartTimeHour(startTimeHour)
    }
  }

  const handleStartTimeMinute = (event) => {
    let newData = JSON.parse(JSON.stringify(instancedData))
    setButtonDisableState(false)
    if (event.target.validity.valid) {
      let newTime = event.target.value
      if (parseInt(newTime) > 59) {
        newTime = '59'
      }
      newData.billing_start_time = `${startTimeHour}:${newTime}`
      setInstancedData(newData)
      setStartTimeMinute(newTime)
    } else {
      setStartTimeMinute(startTimeMinute)
    }
  }

  const handleEndTimeHour = (event) => {
    let newData = JSON.parse(JSON.stringify(instancedData))
    setButtonDisableState(false)
    if (event.target.validity.valid) {
      let newTime = event.target.value
      if (parseInt(newTime) > 23) {
        newTime = '23'
      }
      newData.billing_end_time = `${newTime}:${endTimeMinute}`
      setInstancedData(newData)
      setEndTimeHour(newTime)
    } else {
      setEndTimeHour(endTimeHour)
    }
  }

  const handleEndTimeMinute = (event) => {
    let newData = JSON.parse(JSON.stringify(instancedData))
    setButtonDisableState(false)
    if (event.target.validity.valid) {
      let newTime = event.target.value
      if (parseInt(newTime) > 59) {
        newTime = '59'
      }
      newData.billing_end_time = `${endTimeHour}:${newTime}`
      setInstancedData(newData)
      setEndTimeMinute(newTime)
    } else {
      setEndTimeMinute(endTimeMinute)
    }
  }

  function updateDate(value) {
    let newData = JSON.parse(JSON.stringify(instancedData))
    newData.billing_date = value
    setInstancedData(newData)
    setDate(value)
  }

  function deleteServiceData() {
    deleteService(buildingIndex, projectIndex, serviceIndex, setUnmountState)
  }

  function setAdmin(id) {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          '/project_services/update_assigned_admin_id',
        {
          id: instancedData.id,
          assigned_admin_id: id,
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        statusRef.current.showCustomer()
        // succcess
      })
      .catch((error) => {})
  }

  function updateDateTime(eventId) {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/project_services/update_service_date',
        {
          id: instancedData.id,
          billing_date: new Date(
            date.split('-')[0],
            date.split('-')[1] - 1,
            date.split('-')[2],
            12,
          ),
          eventId: eventId,
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        let newData = JSON.parse(JSON.stringify(searchDataPool))
        const currentDate = new Date(
          date.split('-')[0],
          date.split('-')[1] - 1,
          date.split('-')[2],
          12,
        )
        newData[serviceIndex].billing_date = `${currentDate.getFullYear()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getDate()}`
        setSearchDataPool(newData)
        // success
      })
      .catch((error) => {})
  }

  function updateStartTime(eventId) {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          '/project_services/update_service_start_time',
        {
          id: instancedData.id,
          billing_start_time: `${startTimeHour}:${startTimeMinute}:00`,
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        // success
      })
      .catch((error) => {})
  }

  function updateEndTime(eventId) {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          '/project_services/update_service_end_time',
        {
          id: instancedData.id,
          billing_end_time: `${endTimeHour}:${endTimeMinute}:00`,
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        // success
      })
      .catch((error) => {})
  }

  function saveEvent() {
    setButtonDisableState(true)
    if (instancedData.eventId === null) {
      const userDetails = JSON.parse(
        localStorage.getItem('loggedInUserDetails'),
      )
      let data = {
        user_id: userDetails.id,
        service_id: instancedData.service.id,
        message: 'inspectie datum',
        email: instancedData?.project?.building?.user?.email,
        start_date: new Date(
          date.split('-')[0],
          date.split('-')[1] - 1,
          date.split('-')[2],
          12,
        ),
        end_date: new Date(
          date.split('-')[0],
          date.split('-')[1] - 1,
          date.split('-')[2],
          12,
        ),
        start_time: `${startTimeHour}:${startTimeMinute}:00`,
        end_time: `${endTimeHour}:${endTimeMinute}:00`,
        is_full_day: false,
        company_name: instancedData?.project?.building?.fileName,
        service_name: instancedData.service.title,
        skip_email: false,
      }
      axios
        .post(process.env.REACT_APP_API_URL + '/events/add', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            updateDateTime(response.data.id)
            updateStartTime()
            updateEndTime()
            setDateControlBlur(false)
            setDateFilter(false)
            statusRef.current.showDatum()
          }
        })
        .catch((error) => {
          setButtonDisableState(false)
        })
    } else {
      const userDetails = JSON.parse(
        localStorage.getItem('loggedInUserDetails'),
      )
      let data = {
        id: instancedData.eventId,
        user_id: userDetails?.id,
        service_id: instancedData?.service?.id,
        message: 'inspectie datum',
        email: instancedData?.project?.building?.user?.email,
        start_date: new Date(
          date.split('-')[0],
          date.split('-')[1] - 1,
          date.split('-')[2],
          12,
        ),
        end_date: new Date(
          date.split('-')[0],
          date.split('-')[1] - 1,
          date.split('-')[2],
          12,
        ),
        start_time: `${startTimeHour}:${startTimeMinute}:00`,
        end_time: `${endTimeHour}:${endTimeMinute}:00`,
        is_full_day: false,
        company_name: instancedData?.project?.building?.fileName,
        service_name: instancedData?.service?.title,
      }
      axios
        .post(process.env.REACT_APP_API_URL + '/events/update', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            updateDateTime(instancedData.eventId)
            updateStartTime()
            updateEndTime()
            setDateControlBlur(false)
            setDateFilter(false)
            statusRef.current.showDatum()
          }
        })
        .catch((error) => {
          setButtonDisableState(false)
        })
    }
  }

  // function goToInvoice() {
  //   // first we check if an invoice status is 2 or above, which means it's created
  //   if(instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].invoice_statuses_id === 1) {
  //     const token = localStorage.getItem("authToken")
  //     axios.post(process.env.REACT_APP_API_URL+`/invoice/update_invoice_status`, {
  //           facturate_id: instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id,
  //           invoice_statuses_id: 2,
  //           is_cancelled: false,
  //       }, {
  //         headers: {
  //           authorization: `bearer ${token}`,
  //         },
  //       }).then((response) => {
  //       if(response.data.success){
  //         // invoice has been succesfully created so we now route user to the invoice
  //         if(instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].is_billing){
  //           history.push(`/invoice-draft/${instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
  //         }
  //         else{
  //           history.push(`/quotations-draft/${instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
  //         }
  //       }
  //     }).catch(error => {

  //     })
  //   }
  //   else {
  //     // invoice already exists so just route to invoice
  //     if(instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].is_billing){
  //       history.push(`/invoice-draft/${instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
  //     }
  //     else{
  //       history.push(`/quotations-draft/${instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
  //     }

  // }

  // }

  function generateFileNumber() {
    let buildingId = instancedData?.project?.building?.id.toString()
    let serviceId = instancedData?.service?.id.toString()
    let newBuildingDigits = '0000'
    let newServiceDigits = '0000'
    newBuildingDigits = newBuildingDigits.slice(0, 4 - buildingId.length)
    let newbuildingId = newBuildingDigits.concat(buildingId)
    newServiceDigits = newServiceDigits.slice(0, 4 - serviceId.length)
    let newserviceId = newServiceDigits.concat(serviceId)

    return `${newbuildingId}-${newserviceId}`
  }

  const classes = useStyles()

  return (
    <Grid container>
      {/* we use renderDom here to control when to render, we don't want to pass anything other than a complete object since objects are volatile and create exceptions */}
      {renderDom && (
        <div
          className={`fade-in-right flex-row width-auto vertical-center margin-bottom-10 margin-right-10 margin-left-10 ${
            unmountState && 'fade-out-right'
          }`}
          style={{ animationTimingFunction: 'cubic-bezier(0.87, 0, 0.13, 1)' }}
        >
          <div className="services-details-name-box flex-row relative">
            <div
              className={`absolute services-radio${
                instancedData?.invoices[0]?.is_billing ? '-filled' : '-outline'
              }`}
            />
            <div
              className="height-auto width-auto flex-row cursor-pointer vertical-center"
              onClick={handleStatusFilter}
            >
              <div
                className="services-details-name-color flex-shrink-disable"
                style={{
                  background:
                    instancedData?.service_status_id === 0
                      ? '#B2D5FF'
                      : instancedData?.service_status_id === 1
                      ? '#508CD0'
                      : instancedData?.service_status_id === 2
                      ? '#9A8A55'
                      : instancedData?.service_status_id === 3
                      ? '#7BB84D'
                      : instancedData?.service_status_id === 4
                      ? '#686886'
                      : instancedData?.service_status_id === 5
                      ? '#B30419'
                      : instancedData?.service_status_id === 6
                      ? '#F2BBAD'
                      : instancedData?.service_status_id === 7
                      ? '#FBDCCE'
                      : instancedData?.service_status_id === 8
                      ? '#AFDFDB'
                      : instancedData?.service_status_id === 9
                      ? '#8cc9ba'
                      : '#8cc9ba',
                }}
              />
              <p className="main-font-medium font-size-12 font-color-black text-ellipsis width-85-p">
                {(instancedData?.service && instancedData?.service?.title) ||
                  ''}
              </p>
            </div>
            <div
              className="services-details-name-filter-container animatable-400 ease-in-out-quart"
              ref={nameFilterRef}
              tabIndex="2"
              onBlur={handleStatusFilterBlur}
              style={{
                height: statusFilter
                  ? !instancedData?.invoices[0]?.is_billing
                    ? '400px'
                    : '300px'
                  : '10px',
                opacity: statusFilter ? 1 : 0,
              }}
            >
              <div className="flex-row width-auto horizontal-start">
                <div className="services-details-name-filter-arrow">
                  <img
                    src={require('../../../assets/svg/icon/corner.png')}
                    alt="triangle"
                    className="height-auto width-auto object-fit-contain"
                  />
                </div>
              </div>
              <div className="services-details-name-filter vertical-center">
                <Grid
                  container
                  item
                  sm={12}
                  direction="column"
                  alignItems="flex-end"
                >
                  <div
                    className="flex-row vertical-center cursor-pointer"
                    onClick={() => {
                      setStatusFilter(false)
                      changeCategory(
                        buildingIndex,
                        projectIndex,
                        serviceIndex,
                        1,
                        setUnmountState,
                      )
                    }}
                  >
                    <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                      Ontvangen
                    </p>
                    <div className="services-details-name-filter-bubble background-color-faded-blue" />
                  </div>
                  <div className="flex-row">
                    <div className="services-details-name-filter-line" />
                  </div>
                  {!instancedData?.invoices[0]?.is_billing && (
                    <React.Fragment>
                      <div
                        className="flex-row vertical-center cursor-pointer"
                        onClick={() => {
                          setStatusFilter(false)
                          changeCategory(
                            buildingIndex,
                            projectIndex,
                            serviceIndex,
                            6,
                            setUnmountState,
                          )
                        }}
                      >
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                          Offerte
                        </p>
                        <div
                          className="services-details-name-filter-bubble"
                          style={{ background: 'rgb(242, 187, 173)' }}
                        />
                      </div>
                      <div className="flex-row">
                        <div className="services-details-name-filter-line" />
                      </div>
                      <div
                        className="flex-row vertical-center cursor-pointer"
                        onClick={() => {
                          setStatusFilter(false)
                          changeCategory(
                            buildingIndex,
                            projectIndex,
                            serviceIndex,
                            7,
                            setUnmountState,
                          )
                        }}
                      >
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                          Offerte aanvaard
                        </p>
                        <div
                          className="services-details-name-filter-bubble "
                          style={{ background: 'rgb(251, 220, 206)' }}
                        />
                      </div>
                      <div className="flex-row">
                        <div className="services-details-name-filter-line" />
                      </div>
                    </React.Fragment>
                  )}
                  <div
                    className="flex-row vertical-center cursor-pointer"
                    onClick={() => {
                      setStatusFilter(false)
                      changeCategory(
                        buildingIndex,
                        projectIndex,
                        serviceIndex,
                        2,
                        setUnmountState,
                      )
                    }}
                  >
                    <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                      In behandeling
                    </p>
                    <div className="services-details-name-filter-bubble background-color-faded-tan" />
                  </div>
                  <div className="flex-row">
                    <div className="services-details-name-filter-line" />
                  </div>
                  <div
                    className="flex-row vertical-center cursor-pointer"
                    onClick={() => {
                      setStatusFilter(false)
                      changeCategory(
                        buildingIndex,
                        projectIndex,
                        serviceIndex,
                        3,
                        setUnmountState,
                      )
                    }}
                  >
                    <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                      Afgewerkt
                    </p>
                    <div className="services-details-name-filter-bubble background-color-faded-green" />
                  </div>
                  <div className="flex-row">
                    <div className="services-details-name-filter-line" />
                  </div>
                  <div
                    className="flex-row vertical-center cursor-pointer"
                    onClick={() => {
                      setStatusFilter(false)
                      changeCategory(
                        buildingIndex,
                        projectIndex,
                        serviceIndex,
                        8,
                        setUnmountState,
                      )
                    }}
                  >
                    <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                      Gefactureerd
                    </p>
                    <div
                      className="services-details-name-filter-bubble "
                      style={{ background: 'rgb(175, 223, 219)' }}
                    />
                  </div>
                  <div className="flex-row">
                    <div className="services-details-name-filter-line" />
                  </div>
                  <div
                    className="flex-row vertical-center cursor-pointer"
                    onClick={() => {
                      setStatusFilter(false)
                      changeCategory(
                        buildingIndex,
                        projectIndex,
                        serviceIndex,
                        9,
                        setUnmountState,
                      )
                    }}
                  >
                    <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                      Betaald
                    </p>
                    <div
                      className="services-details-name-filter-bubble"
                      style={{ background: 'rgb(140, 201, 186)' }}
                    />
                  </div>
                  <div className="flex-row">
                    <div className="services-details-name-filter-line" />
                  </div>
                  <div
                    className="flex-row vertical-center cursor-pointer"
                    onClick={() => {
                      setStatusFilter(false)
                      changeCategory(
                        buildingIndex,
                        projectIndex,
                        serviceIndex,
                        4,
                        setUnmountState,
                      )
                    }}
                  >
                    <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                      On hold
                    </p>
                    <div className="services-details-name-filter-bubble background-color-faded-purple" />
                  </div>
                  <div className="flex-row">
                    <div className="services-details-name-filter-line" />
                  </div>
                  <div
                    className="flex-row vertical-center cursor-pointer"
                    onClick={() => {
                      setStatusFilter(false)
                      changeCategory(
                        buildingIndex,
                        projectIndex,
                        serviceIndex,
                        5,
                        setUnmountState,
                      )
                    }}
                  >
                    <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                      Geannuleerd
                    </p>
                    <div className="services-details-name-filter-bubble background-color-faded-pink" />
                  </div>
                </Grid>
              </div>
            </div>
          </div>
          <div className="services-details-file-box center-content">
            <p className="main-font-medium font-size-12 font-color-black">
              #{instancedData && instancedData?.dossierNumber}
            </p>
          </div>
          <div className="services-details-file-name-box center-content">
            <p className="main-font-medium font-size-12 font-color-black">
              {instancedData?.project?.building?.fileName || ''}
            </p>
          </div>
          <div className="services-details-customer-box center-content relative">
            <div className="height-auto width-auto center-content">
              <p className="main-font-medium font-size-12 font-color-black">
                {instancedData?.project?.building?.user?.company?.name || ''}
              </p>
            </div>
          </div>
          <div className="services-details-responsible-box center-content relative">
            <div
              className="height-auto width-auto center-content cursor-pointer"
              onClick={handleResponsibleFilter}
            >
              <p className="main-font-medium font-size-12 font-color-black">
                {responsible}
              </p>
            </div>
            <div
              className="services-details-responsible-filter-container animatable-400 ease-in-out-quart"
              ref={responsibleFilterRef}
              tabIndex="2"
              onBlur={handleResponsibleFilterBlur}
              style={{
                transform: responsibleFilter ? 'scaleY(1)' : 'scaleY(0)',
                opacity: responsibleFilter ? 1 : 0,
              }}
            >
              <div className="flex-row width-auto">
                <div className="services-details-responsible-filter-arrow">
                  <img
                    src={require('../../../assets/svg/icon/corner.png')}
                    alt="corner"
                    className="height-auto width-auto object-fit-contain"
                  />
                </div>
              </div>
              <div className="services-details-responsible-filter">
                <Grid
                  container
                  item
                  sm={12}
                  direction="column"
                  alignItems="flex-end"
                >
                  {responsibleList.map((item, index) => (
                    <>
                      <div
                        className="flex-row vertical-center cursor-pointer"
                        onClick={() => {
                          setAdmin(item.id)
                          setResponsible(item.firstName)
                          setResponsibleFilter(false)
                        }}
                      >
                        <p className="main-font-semi-bold font-size-14 font-color-black">
                          {item.firstName}
                        </p>
                      </div>
                      {index !== responsibleList.length - 1 && (
                        <div className="flex-row">
                          <div className="services-details-name-filter-line" />
                        </div>
                      )}
                    </>
                  ))}
                </Grid>
              </div>
            </div>
          </div>
          <div className="services-details-doc-box center-content">
            <img
              src={require('../../../assets/svg/icon/icon_folder_darkgrey.svg')}
              alt="folder icon"
              className="services-details-icon"
            />
            <p className="main-font-medium font-size-12 font-color-black">
              {instancedData?.servicesDocuments &&
                instancedData?.no_of_documents}
            </p>
          </div>
          <div className="services-details-message-box center-content">
            <img
              src={require('../../../assets/svg/icon/icon_chat_darkgrey.svg')}
              alt="chat icon"
              className="services-details-icon"
            />
            <p className="main-font-medium font-size-12 font-color-black">
              {instancedData?.servicesDocuments &&
                instancedData?.no_of_messages}
            </p>
          </div>
          <div
            className="services-details-date-box relative"
            style={{ zIndex: zIndex || 0 }}
          >
            <div
              className="height-auto width-auto center-content cursor-pointer"
              onClick={handleDateFilter}
            >
              <p className="main-font-medium font-size-12 font-color-black">
                {instancedData?.billing_date !== null
                  ? typeof instancedData?.billing_date === 'string'
                    ? instancedData?.billing_date.includes('-')
                      ? instancedData?.billing_date
                          .substring(0, 10)
                          .replace(/-/g, '-')
                      : instancedData?.billing_date
                          .substring(0, 13)
                          .replace(/-/g, '-')
                    : date.getDate() +
                      ' - ' +
                      (date.getMonth() + 1) +
                      ' - ' +
                      date.getFullYear()
                  : 'Tijd'}
              </p>
            </div>
            <div
              className="services-details-date-filter-container animatable-400 ease-in-out-quart cursor-pointer"
              ref={dateFilterRef}
              tabIndex="3"
              onBlur={handleDateFilterBlur}
              style={{
                height: dateFilter ? '215px' : '10px',
                opacity: dateFilter ? 1 : 0,
              }}
              onMouseOver={() => {
                setMouseActive(true)
              }}
              onMouseLeave={() => {
                setMouseActive(false)
              }}
            >
              <div className="flex-row width-auto">
                <div className="services-details-date-filter-arrow">
                  <img
                    src={require('../../../assets/svg/icon/corner.png')}
                    alt="corner"
                    className="height-auto width-auto object-fit-contain"
                  />
                </div>
              </div>
              <div className="services-details-date-filter flex-column vertical-center">
                {dateFilter && (
                  <form className={classes.container} noValidate>
                    <Grid container justify="center">
                      <TextField
                        id="date"
                        type="date"
                        value={date}
                        onChange={(e) => {
                          updateDate(e.target.value)
                          setButtonDisableState(false)
                        }}
                        defaultValue={date}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </form>
                )}
                <Grid container justify="center">
                  <div className="width-auto flex-row margin-top-15 margin-left-25 horizontal-center">
                    <div className="width-auto flex-column">
                      <p className="main-font-light font-size-14 font-color-grey">
                        Van
                      </p>
                      <div className="flex-row services-details-date-filter-input width-auto center-content margin-top-5">
                        <input
                          className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                          type="text"
                          pattern="[0-9]*"
                          placeholder="12"
                          maxLength={2}
                          value={startTimeHour}
                          onChange={handleStartTimeHour}
                        />
                        <p className="main-font-medium font-color-black font-size-14">
                          :
                        </p>
                        <input
                          className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                          type="text"
                          pattern="[0-9]*"
                          placeholder="30"
                          maxLength={2}
                          serviceFilterManifestDefaultStructure
                          value={startTimeMinute}
                          onChange={handleStartTimeMinute}
                        />
                      </div>
                    </div>
                    <div className="width-auto flex-column">
                      <p className="main-font-light font-size-14 font-color-grey">
                        Tot
                      </p>
                      <div className="flex-row services-details-date-filter-input width-auto center-content margin-top-5">
                        <input
                          className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                          type="text"
                          pattern="[0-9]*"
                          placeholder="14"
                          maxLength={2}
                          value={endTimeHour}
                          onChange={handleEndTimeHour}
                        />
                        <p className="main-font-medium font-color-black font-size-14">
                          :
                        </p>
                        <input
                          className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                          type="text"
                          pattern="[0-9]*"
                          placeholder="30"
                          maxLength={2}
                          value={endTimeMinute}
                          onChange={handleEndTimeMinute}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <div
                  className="services-details-date-filter-save center-content cursor-pointer"
                  onClick={() => {
                    saveEvent()
                  }}
                  disabled={buttonDisableState}
                >
                  <p className="main-font-semi-bold font-size-14 font-color-white">
                    Save
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="services-details-timeline-box center-content">
            <p className="main-font-medium font-size-12 font-color-black">
              {instancedData?.form_progress || '-'}
            </p>
          </div>
          <div
            className="services-details-icons cursor-pointer"
            onClick={() => {
              history.push(
                `/details/${instancedData?.id}-${instancedData?.project?.building?.id}`,
                instancedData,
              )
            }}
          >
            <img
              src={require('../../../assets/svg/icon/view.svg')}
              alt="view"
              className="height-auto width-auto object-fit-contain"
            />
          </div>
          {/* <div className="services-details-icons cursor-pointer" onClick = {() => { goToInvoice() }}>
              <img
                src={require('../../../assets/svg/icon/factuur.svg')}
                alt="factuur"
                className="height-auto width-auto object-fit-contain"
              />
            </div> */}
          <div
            className="services-details-icons cursor-pointer"
            onClick={() => {
              setDeleteModal(true)
            }}
          >
            <img
              src={require('../../../assets/svg/icon/trash.svg')}
              alt="trash"
              className="height-auto width-auto object-fit-contain"
            />
          </div>
        </div>
      )}
      {deleteModal && (
        <DeleteConfirmation
          id={1}
          setOpenState={setDeleteModal}
          handleDeleteClick={deleteServiceData}
        />
      )}
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

export default function Services(props) {
  const [scrollButton, setScrollButton] = useState(false)
  const [dataPool, setDataPool] = useState([])
  const [searchDataPool, setSearchDataPool] = useState([])
  const [originalServiceData, setOriginalServiceData] = useState([])
  const [adminData, setAdminData] = useState([])
  const [servicesData, setServicesData] = useState([])
  const [serviceCategoryData, setServiceCategoryData] = useState([])
  const [filterSearchManifest, setFilterSearchManifest] = useState(
    serviceFilterManifestDefaultStructure,
  )
  const [loader, setLoader] = useState(false)
  const [selectedTab, setSelectedTab] = useState(1)
  const [dataCount, setDataCount] = useState(0)
  const [pageState, setPageState] = useState(0)

  const pageNavRef = useRef(0)
  const statusRef = useRef(0)
  const scrollAreaRef = useRef(0)
  const searchRef = useRef(0)
  const history = useHistory()
  const token = localStorage.getItem('authToken')
  const userData = JSON.parse(localStorage.getItem('loggedInUserDetails'))

  useEffect(() => {
    // axios
    //     .post(
    //       process.env.REACT_APP_API_URL+'/dashboard/adminData',
    //       {},
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       },
    //     )
    //     .then((response) => {
    //       setOriginalServiceData(response.data)
    //       setDataPool(response.data)
    //       setSearchDataPool(response.data)
    //     })
    //     .catch((error) => {
    //         // localStorage.clear();
    //         // history.push("/")
    //     })
    getServicesData()
  }, [])

  useEffect(() => {
    getAdminData()
    getAllServices()
  }, [])

  // useEffect(() => {
  //   if(filterSearchManifest.servicesCategory) {
  //     filterDataPool(filterSearchManifest)
  //   }
  // }, [selectedTab])

  const handlePageChange = (value) => {
    setPageState(value)
    getServicesData(null, null, null, value)
  }

  const getServicesData = (param, filterManifest, tab, pageUpdate) => {
    const getAdminFiles = filterManifest
      ? filterManifest.files?.find((file) => file?.id === 1)
      : filterSearchManifest?.files?.find((file) => file?.id === 1)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/dashboard/adminDataNew',
        {
          searchParam: param || searchRef.current.value,
          servicesFilter: filterManifest
            ? filterManifest?.services
            : filterSearchManifest?.services,
          servicesCategory: filterManifest
            ? filterManifest?.servicesCategory
            : filterSearchManifest?.servicesCategory,
          servicesStatus: tab ? tab - 1 : selectedTab - 1,
          adminId: getAdminFiles?.enabled ? userData?.id : null,
          offset:
            30 * (typeof pageUpdate === 'number' ? pageUpdate : pageState),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        setOriginalServiceData(response.data.rows)
        setDataPool(response.data.rows)
        setSearchDataPool(response.data.rows)
        setDataCount(response.data.count)
        setLoader(false)
        pageUpdate ? setPageState(pageUpdate) : setPageState(0)
        scrollAreaRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      })
      .catch((error) => {
        localStorage.clear()
        history.push('/')
      })
  }

  const handleSearch = (event) => {
    debouncedSearch(event.target.value)
  }

  const debouncedSearch = debounce((value) => {
    // const searchPromise = new Promise((resolve, reject) => {
    //   const resolved = true;
    //   if(resolved) {
    //     setLoader(true)
    //     resolve('success')
    //   }
    //   else {
    //     reject('failed')
    //   }
    // })
    // searchPromise.then(() => {
    //   handleSearchFilter(value)
    // })
    getServicesData(value, null, null)
  }, 500)

  function handleSearchFilter(string) {
    const searchParams = string.toLowerCase()
    const newDataPool = []
    let projectList = []
    let serviceList = []
    let dataExists = false
    const originalData = JSON.parse(JSON.stringify(dataPool))

    originalData.forEach((building) => {
      // if title matches with search param, don't loop further
      if (
        building.title.toLowerCase().includes(searchParams) ||
        building.user.company.name.toLowerCase().includes(searchParams)
      ) {
        dataExists = true
        newDataPool.push(building)
      }
      if (!dataExists) {
        building.projects.forEach((project, projectIndex) => {
          // if title matches with search param, don't loop further
          if (
            project.title !== null &&
            project.title.toLowerCase().includes(searchParams)
          ) {
            dataExists = true
            projectList.push(project)
          }
          if (!dataExists) {
            project.projectServices.forEach((service, serviceIndex) => {
              let serviceEventDate = service.billing_date
              if (serviceEventDate) {
                serviceEventDate = serviceEventDate.split(' ')[0]
                serviceEventDate = serviceEventDate.replace(/-/g, '/')
              }
              if (
                service.service.title.toLowerCase().includes(searchParams) ||
                (service.fileNumber &&
                  generateFileNumber(building.id, service.id).includes(
                    searchParams,
                  )) ||
                (serviceEventDate && serviceEventDate.includes(searchParams))
              ) {
                serviceList.push(service)
              }
            })

            if (serviceList.length > 0) {
              projectList.push(project)
              projectList[projectList.length - 1].projectServices = serviceList
              serviceList = []
            }
          }

          dataExists = false
        })
      }

      if (projectList.length > 0) {
        newDataPool.push(building)
        newDataPool[newDataPool.length - 1].projects = projectList
        projectList = []
      }
      dataExists = false
    })
    setTimeout(() => {
      setLoader(false)
    }, 100)
    setSearchDataPool(newDataPool)
  }

  function generateFileNumber(originBuildingId, originServiceId) {
    let buildingId = originBuildingId.toString()
    let serviceId = originServiceId.toString()
    let newBuildingDigits = '0000'
    let newServiceDigits = '0000'
    newBuildingDigits = newBuildingDigits.slice(0, 4 - buildingId.length)
    let newbuildingId = newBuildingDigits.concat(buildingId)
    newServiceDigits = newServiceDigits.slice(0, 4 - serviceId.length)
    let newserviceId = newServiceDigits.concat(serviceId)

    return `${newbuildingId}-${newserviceId}`
  }

  function changeCategory(buildingIndex, projectIndex, serviceIndex, type) {
    let singleData = JSON.parse(JSON.stringify(searchDataPool))
    let newDataPool = JSON.parse(JSON.stringify(dataPool))
    let newOriginalData = JSON.parse(JSON.stringify(originalServiceData))
    const projectServiceId = singleData[serviceIndex].id

    if (singleData[serviceIndex].service_status_id === type) {
    } else {
      setTimeout(() => {
        singleData[serviceIndex].service_status_id = type
        if (type === 6) {
          singleData[serviceIndex].invoices.forEach((invoice) => {
            invoice.is_billing = true
          })
        }

        setSearchDataPool(singleData)
        setDataPool(singleData)
        setOriginalServiceData(singleData)
        axios
          .post(
            process.env.REACT_APP_API_URL +
              '/project_services/update_service_status',
            {
              id: singleData[serviceIndex].id,
              service_status_id: singleData[serviceIndex].service_status_id,
            },
            {
              headers: {
                authorization: `bearer ${token}`,
              },
            },
          )
          .then((response) => {
            if (singleData[serviceIndex].service_status_id === 6) {
              singleData[serviceIndex].invoices.map((invoice) => {
                axios
                  .post(
                    process.env.REACT_APP_API_URL +
                      `/invoice/update_billing_state`,
                    {
                      facturate_id: parseInt(invoice.id),
                      is_billing: true,
                    },
                    {
                      headers: {
                        authorization: `bearer ${token}`,
                      },
                    },
                  )
                  .then((response) => {
                    if (response.data.success) {
                      statusRef.current.showCategory()
                    }
                  })
                  .catch((error) => {})
              })
            } else {
              statusRef.current.showCategory()
            }
            // success
          })
          .catch((error) => {})
      }, 500)
    }
  }

  function deleteService(
    buildingIndex,
    projectIndex,
    serviceIndex,
    setAnimation,
  ) {
    let singleData = JSON.parse(JSON.stringify(searchDataPool))
    let newDataPool = JSON.parse(JSON.stringify(dataPool))
    let newOriginalData = JSON.parse(JSON.stringify(originalServiceData))
    const projectServiceId = singleData[serviceIndex]?.id
    axios
      .post(
        process.env.REACT_APP_API_URL + '/project_services/delete',
        {
          projectId: singleData[serviceIndex]?.projectId || 0,
          serviceId: singleData[serviceIndex]?.serviceId || 0,
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        // success
        const dataPoolServiceIndex = newDataPool.findIndex(
          (service) => service.id === projectServiceId,
        )
        newDataPool.splice(dataPoolServiceIndex, 1)

        const dataPoolServiceIndex2 = newOriginalData.findIndex(
          (service) => service.id === projectServiceId,
        )
        newOriginalData.splice(dataPoolServiceIndex2, 1)

        singleData.splice(serviceIndex, 1)

        setSearchDataPool(singleData)
        setDataPool(newDataPool)
        setOriginalServiceData(newOriginalData)
      })
      .catch((error) => {})
  }

  function getAdminData() {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/user/all_admin',
        {},
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        const superAdmin = response.data.data.filter(
          (admin) => admin.roleId === 4,
        )
        setAdminData(superAdmin)
      })
      .catch((error) => {})
  }

  function getAllServices() {
    let serviceFilterManifestData = { ...filterSearchManifest }

    axios
      .post(
        process.env.REACT_APP_API_URL + '/service/all',
        {},
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        let serviceManifestData = []
        let responseData = response.data
        responseData.sort(function (a, b) {
          return a.id - b.id || b.name.localeCompare(a.name)
        })
        responseData.forEach((_, index) => {
          serviceManifestData.push({
            id: index,
            enabled: false,
          })
        })

        serviceFilterManifestData.services = serviceManifestData
        setServicesData(responseData)
      })
      .then(() => {
        // we do another request to make sure that the service data has been obtained and succesfully stored
        axios
          .post(
            process.env.REACT_APP_API_URL +
              '/serviceCategory/get/category_has_service',
            {},
            {
              headers: {
                authorization: `bearer ${token}`,
              },
            },
          )
          .then((response) => {
            let serviceManifestData = []
            response.data.forEach((item) => {
              serviceManifestData.push({
                id: item.id,
                enabled: false,
              })
            })

            serviceFilterManifestData.servicesCategory = serviceManifestData
            setFilterSearchManifest(serviceFilterManifestData)
            setServiceCategoryData(response.data)
          })
          .catch((error) => {})
      })
      .catch((error) => {})
  }

  const handleScroll = (event) => {
    if (event.target.scrollTop > 0) {
      if (!scrollButton) {
        pageNavRef.current.setValue(true)
      }
    } else {
      pageNavRef.current.setValue(false)
    }
  }

  function changeFilter(type, index) {
    // type param is used to diffrentiate between each property, basically each array category
    // index is to change the value of the respective index within that array category
    let updatedFilterManifest = JSON.parse(JSON.stringify(filterSearchManifest))

    if (type === 1) {
      updatedFilterManifest.files[index].enabled =
        !updatedFilterManifest.files[index].enabled
    } else if (type === 3) {
      updatedFilterManifest.servicesCategory[index].enabled =
        !updatedFilterManifest.servicesCategory[index].enabled
    } else if (type === 4) {
      updatedFilterManifest.services[index].enabled =
        !updatedFilterManifest.services[index].enabled
    }

    const filterDataPromise = new Promise((resolve, reject) => {
      const resolved = true
      if (resolved) {
        setLoader(true)
        resolve('success')
      } else {
        reject('promise is rejected')
      }
    })

    filterDataPromise
      .then((resolved) => {
        // filterDataPool(updatedFilterManifest);
        // setFilterSearchManifest(updatedFilterManifest);
        getServicesData(null, updatedFilterManifest, null)
        setFilterSearchManifest(updatedFilterManifest)
      })
      .catch((rejection) => {})
  }

  function filterDataPool(updatedFilterManifest) {
    // we use the manifest to update the datapool
    const newDataPool = JSON.parse(JSON.stringify(originalServiceData))
    let updatedDataPool = []
    const userData = JSON.parse(localStorage.getItem('loggedInUserDetails'))
    let matchFound = false

    if (updatedFilterManifest.files[1].enabled) {
      // first we empty previous service data
      newDataPool.forEach((item) => {
        // we keep buildings and projects but not the services
        item.projects.forEach((item) => {
          item.projectServices = []
        })
      })

      matchFound = true
      // if my files is selected then apply filter, regardless if previous checkbox is selected
      originalServiceData.forEach((building, buildingIndex) => {
        building.projects.forEach((project, projectIndex) => {
          project.projectServices.forEach((service, serviceIndex) => {
            if (service.assigned_admin_id === userData.id) {
              newDataPool[buildingIndex].projects[
                projectIndex
              ].projectServices.push(service)
            }
          })
        })
      })
    }

    matchFound = false
    updatedDataPool = JSON.parse(JSON.stringify(newDataPool))
    // first we empty previous service data
    newDataPool.forEach((item) => {
      // we keep buildings and projects but not the services
      item.projects.forEach((item) => {
        item.projectServices = []
      })
    })

    updatedFilterManifest.status.forEach((item, index) => {
      if (item.enabled) {
        matchFound = true
        updatedDataPool.forEach((building, buildingIndex) => {
          building.projects.forEach((project, projectIndex) => {
            project.projectServices.forEach((service, serviceIndex) => {
              if (service.service_status_id === item.id) {
                newDataPool[buildingIndex].projects[
                  projectIndex
                ].projectServices.push(service)
              }
            })
          })
        })
      }
    })

    if (matchFound) {
      updatedDataPool = JSON.parse(JSON.stringify(newDataPool))
      matchFound = false
    }

    // first we empty previous service data
    newDataPool.forEach((item) => {
      // we keep buildings and projects but not the services
      item.projects.forEach((item) => {
        item.projectServices = []
      })
    })

    updatedFilterManifest.servicesCategory.forEach((item) => {
      if (item.enabled) {
        matchFound = true
        updatedDataPool.forEach((building, buildingIndex) => {
          building.projects.forEach((project, projectIndex) => {
            project.projectServices.forEach((service, serviceIndex) => {
              if (service.service.serviceCategoryId === item.id) {
                newDataPool[buildingIndex].projects[
                  projectIndex
                ].projectServices.push(service)
              }
            })
          })
        })
      }
    })

    if (matchFound) {
      updatedDataPool = JSON.parse(JSON.stringify(newDataPool))
      matchFound = false
    }

    // first we empty previous service data
    newDataPool.forEach((item) => {
      // we keep buildings and projects but not the services
      item.projects.forEach((item) => {
        item.projectServices = []
      })
    })

    updatedFilterManifest.services.forEach((item) => {
      if (item.enabled) {
        matchFound = true
        updatedDataPool.forEach((building, buildingIndex) => {
          building.projects.forEach((project, projectIndex) => {
            project.projectServices.forEach((service, serviceIndex) => {
              if (service.serviceId === item.id + 1) {
                newDataPool[buildingIndex].projects[
                  projectIndex
                ].projectServices.push(service)
              }
            })
          })
        })
      }
    })

    if (matchFound) {
      updatedDataPool = JSON.parse(JSON.stringify(newDataPool))
      matchFound = false
    }

    if (selectedTab !== 1) {
      updatedDataPool.forEach((building) => {
        building.projects.forEach((project) => {
          project.projectServices = project.projectServices.filter(
            (service) => service.service_status_id === selectedTab - 2,
          )
        })
      })
    }

    setTimeout(() => {
      setLoader(false)
    }, 100)
    searchRef.current.value = ''
    setDataPool(updatedDataPool)
    setSearchDataPool(updatedDataPool)
  }

  function changeTabs(tab) {
    // if(selectedTab !== tab) {
    //   if(tab === 1) {
    //     const changeTabPromise = new Promise((resolved, rejected) => {
    //       const success = true;
    //       if(success) {
    //         setLoader(true)
    //         resolved(true)
    //       }
    //       else {
    //         rejected('failed')
    //       }
    //     })
    //     changeTabPromise.then(resolved => {
    //       setTimeout(() => {
    //         setLoader(false)
    //       }, 100);
    //       setSelectedTab(tab)
    //     })
    //   }
    //   else {
    //     const changeTabPromise = new Promise((resolved, rejected) => {
    //       const success = true;
    //       if(success) {
    //         setLoader(true)
    //         resolved(true)
    //       }
    //       else {
    //         rejected('failed')
    //       }
    //     })

    //     changeTabPromise.then(resolved => {
    //       const newServiceData = JSON.parse(JSON.stringify(originalServiceData))
    //       newServiceData.forEach(building => {
    //         building.projects.forEach(project => {
    //           project.projectServices = project.projectServices.filter(service => service.service_status_id === (tab - 2))
    //         })
    //       })

    //       setTimeout(() => {
    //         setLoader(false)
    //       }, 100);
    //       setSelectedTab(tab)
    //     })
    //   }
    // }
    getServicesData(null, null, tab)
    setSelectedTab(tab)
  }

  const renderPagination = () => {
    const pages = Math.ceil(dataCount / 30)
    if (pages > 5) {
      // render the first page and last page unconditionally
      // if the selected page is the first then render the next other wise, render current page and next two
      const isFirstPage = pageState === 0 ? true : false
      const isSecondPage = pageState === 1 ? true : false
      const isLastPage = pageState === pages - 1 ? true : false
      const isSecondlastPage = pageState === pages - 2 ? true : false

      return (
        <>
          <button
            type="button"
            className="services-pagination-item-container"
            onClick={() =>
              handlePageChange(pageState !== 0 ? pageState - 1 : pageState)
            }
          >
            <img
              src={require('../../../assets/svg/icon/arrow_left_blue.svg')}
              alt="arrow left"
              className="pagination-arrow-left"
            />
          </button>
          {!isSecondPage && (
            <button
              type="button"
              className="services-pagination-item-container"
              onClick={() => handlePageChange(0)}
            >
              <div
                className="services-pagination-item"
                style={{ opacity: pageState === 0 ? 1 : 0.2 }}
              />
              <p
                className="main-font-medium font-size-14 font-color-white relative"
                style={{ color: pageState === 0 ? 'white' : '#5cb555' }}
              >
                1
              </p>
            </button>
          )}
          {(isLastPage || (!isLastPage && !isFirstPage)) && !isSecondPage && (
            <button
              type="button"
              className="services-pagination-item-container"
            >
              <div
                className="services-pagination-item"
                style={{ opacity: 0 }}
              />
              <p
                className="main-font-medium font-size-14 font-color-white relative"
                style={{ color: '#5cb555' }}
              >
                ...
              </p>
            </button>
          )}
          {Array(Math.ceil(isFirstPage || isLastPage ? 2 : 3))
            .fill()
            .map((_, index) => (
              <button
                type="button"
                className="services-pagination-item-container"
                onClick={() =>
                  handlePageChange(
                    isFirstPage
                      ? pageState + index + 1
                      : isLastPage
                      ? pageState + index - 2
                      : pageState + (index - 1),
                  )
                }
              >
                <div
                  className="services-pagination-item"
                  style={{
                    opacity:
                      pageState ===
                      (isFirstPage
                        ? pageState + index + 1
                        : isLastPage
                        ? pageState + index - 2
                        : pageState + (index - 1))
                        ? 1
                        : 0.2,
                  }}
                />
                <p
                  className="main-font-medium font-size-14 font-color-white relative"
                  style={{
                    color:
                      pageState ===
                      (isFirstPage
                        ? pageState + index + 1
                        : isLastPage
                        ? pageState + index - 2
                        : pageState + (index - 1))
                        ? 'white'
                        : '#5cb555',
                  }}
                >
                  {isFirstPage
                    ? pageState + index + 2
                    : isLastPage
                    ? pageState + index - 1
                    : index + pageState}
                </p>
              </button>
            ))}
          {(isFirstPage || (!isLastPage && !isFirstPage)) &&
            !isSecondlastPage && (
              <button
                type="button"
                className="services-pagination-item-container"
              >
                <div
                  className="services-pagination-item"
                  style={{ opacity: 0 }}
                />
                <p
                  className="main-font-medium font-size-14 font-color-white relative"
                  style={{ color: '#5cb555' }}
                >
                  ...
                </p>
              </button>
            )}
          {!isSecondlastPage && (
            <button
              type="button"
              className="services-pagination-item-container"
              onClick={() => handlePageChange(pages - 1)}
            >
              <div
                className="services-pagination-item"
                style={{ opacity: pageState === pages - 1 ? 1 : 0.2 }}
              />
              <p
                className="main-font-medium font-size-14 font-color-white relative"
                style={{ color: pageState === pages - 1 ? 'white' : '#5cb555' }}
              >
                {pages}
              </p>
            </button>
          )}
          <button
            type="button"
            className="services-pagination-item-container"
            onClick={() =>
              handlePageChange(
                pageState !== pages - 1 ? pageState + 1 : pageState,
              )
            }
          >
            <img
              src={require('../../../assets/svg/icon/arrow_left_blue.svg')}
              alt="arrow right"
              className="pagination-arrow-right"
            />
          </button>
        </>
      )
    } else {
      return Array(Math.ceil(pages))
        .fill()
        .map((_, index) => (
          <button
            type="button"
            className="services-pagination-item-container"
            onClick={() => handlePageChange(index)}
          >
            <div
              className="services-pagination-item"
              style={{ opacity: pageState === index ? 1 : 0.2 }}
            />
            <p
              className="main-font-medium font-size-14 font-color-white relative"
              style={{ color: pageState === index ? 'white' : '#5cb555' }}
            >
              {index + 1}
            </p>
          </button>
        ))
    }
  }

  return (
    <Grid container>
      <Grid container>
        <Hidden smDown>
          <Header />
        </Hidden>
        <Hidden mdUp>
          <Header toShowHamburger isLogoEnabled />
        </Hidden>
      </Grid>
      <Grid container item sm={12}>
        <div className="services-container">
          <Grid container item sm={12} className="height-disable">
            <Grid container item sm={12}>
              <div className="margin-left-15 flex-column services-details-container fade-in-right">
                <div className="margin-top-15 width-auto" />
                <div className="width-auto flex-row horizontal-start">
                  <div className="services-search-container vertical-center margin-left-30 margin-right-30">
                    <div className="services-search-icon margin-left-15">
                      <img
                        src={require('../../../assets/svg/icon/search.svg')}
                        alt="search"
                        className="height-auto width-auto object-fit-contain"
                      />
                    </div>
                    <input
                      className="width-auto margin-left-15 margin-right-15 main-font-regular font-size-14 font-color-grey"
                      placeholder="Zoeken"
                      onChange={handleSearch}
                      ref={searchRef}
                    />
                  </div>
                  <Hidden smDown>
                    <FilterDropdown
                      servicesData={servicesData}
                      serviceCategoryData={serviceCategoryData}
                      changeFilter={changeFilter}
                    />
                    <button
                      type="button"
                      className="services-form center-content cursor-pointer margin-left-15"
                      onClick={() => {
                        history.push('/project-application/true')
                      }}
                    >
                      <h1 className="main-font-semi-bold font-size-14 font-color-white margin-disable">
                        Nieuwe aanvraag
                      </h1>
                    </button>
                  </Hidden>
                </div>
                {/* tabs here */}
                <ServicesTab
                  selectedTab={selectedTab}
                  changeTabs={changeTabs}
                />
                <Hidden smDown>
                  <div className="flex-row vertical-end service-title-container margin-right-25">
                    <div className="services-details-name flex-row vertical-center">
                      <p className="font-color-grey main-font-regular font-size-12">
                        Dienst
                      </p>
                    </div>
                    <div className="services-details-file horizontal-center">
                      <p className="font-color-grey main-font-regular font-size-12">
                        Dossier
                      </p>
                    </div>
                    <div className="services-details-file-name horizontal-center">
                      <p className="font-color-grey main-font-regular font-size-12">
                        Dossiernaam
                      </p>
                    </div>
                    <div className="services-details-customer horizontal-center">
                      <p className="font-color-grey main-font-regular font-size-12">
                        Klant
                      </p>
                    </div>
                    <div className="services-details-responsible horizontal-center">
                      <p className="font-color-grey main-font-regular font-size-12">
                        Verantw.
                      </p>
                    </div>
                    <div className="services-details-doc horizontal-center">
                      <p className="font-color-grey main-font-regular font-size-12">
                        Doc.
                      </p>
                    </div>
                    <div className="services-details-message horizontal-center">
                      <p className="font-color-grey main-font-regular font-size-12">
                        Bericht
                      </p>
                    </div>
                    <div className="services-details-date horizontal-center">
                      <p className="font-color-grey main-font-regular font-size-12 center-text">
                        Inspectie Datum
                      </p>
                    </div>
                    <div className="services-details-timeline horizontal-center">
                      <p className="font-color-grey main-font-regular font-size-12">
                        Tijdslijn
                      </p>
                    </div>
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <div className="flex-row vertical-end">
                    <div className="services-details-name flex-row vertical-center">
                      <img
                        src={
                          selectedTab === 1
                            ? require('../../../assets/svg/icon/all_icon_color.svg')
                            : selectedTab === 2
                            ? require('../../../assets/svg/icon/loader.svg')
                            : selectedTab === 3
                            ? require('../../../assets/svg/icon/load.svg')
                            : selectedTab === 4
                            ? require('../../../assets/svg/icon/check_green.svg')
                            : selectedTab === 5
                            ? require('../../../assets/svg/icon/onhold.svg')
                            : selectedTab === 6
                            ? require('../../../assets/svg/icon/cancel.svg')
                            : selectedTab === 7
                            ? require('../../../assets/svg/icon/offerte_color.svg')
                            : selectedTab === 8
                            ? require('../../../assets/svg/icon/offerteaanvaard_color.svg')
                            : selectedTab === 9
                            ? require('../../../assets/svg/icon/gefactureerd_color.svg')
                            : require('../../../assets/svg/icon/betaald_color.svg')
                        }
                        alt="title icons"
                        className="services-title-icon margin-top-4 margin-right-10"
                      />
                      <h1
                        className="main-font-semi-bold font-size-18 font-color-blue"
                        style={{
                          color:
                            selectedTab === 1
                              ? '#7da5c2'
                              : selectedTab === 2
                              ? '#508CD0'
                              : selectedTab === 3
                              ? '#9A8A55'
                              : selectedTab === 4
                              ? '#7BB84D'
                              : selectedTab === 5
                              ? '#686886'
                              : selectedTab === 6
                              ? '#B30419'
                              : selectedTab === 7
                              ? '#A7796D'
                              : selectedTab === 8
                              ? '#AC9489'
                              : selectedTab === 9
                              ? '#698886'
                              : '#325758',
                        }}
                      >
                        {`${
                          selectedTab === 1
                            ? 'Alle'
                            : selectedTab === 2
                            ? 'Ontvangen'
                            : selectedTab === 3
                            ? 'In behandeling'
                            : selectedTab === 4
                            ? 'Afgewerkt'
                            : selectedTab === 5
                            ? 'On hold'
                            : selectedTab === 6
                            ? 'Geannuleerd'
                            : selectedTab === 7
                            ? 'Offerte'
                            : selectedTab === 8
                            ? 'Offerte aanvaard'
                            : selectedTab === 9
                            ? 'Gefactureerd'
                            : 'Betaald'
                        } `}
                      </h1>
                    </div>
                    <div className="width-auto horizontal-end">
                      <button
                        type="button"
                        className="services-form center-content cursor-pointer margin-left-5 margin-right-15 "
                        onClick={() => {
                          history.push('/project-application/true')
                        }}
                        style={{
                          background:
                            selectedTab === 1
                              ? '#7da5c2'
                              : selectedTab === 2
                              ? '#508CD0'
                              : selectedTab === 3
                              ? '#9A8A55'
                              : selectedTab === 4
                              ? '#7BB84D'
                              : selectedTab === 5
                              ? '#686886'
                              : selectedTab === 6
                              ? '#B30419'
                              : selectedTab === 7
                              ? '#A7796D'
                              : selectedTab === 8
                              ? '#AC9489'
                              : selectedTab === 9
                              ? '#698886'
                              : '#325758',
                        }}
                      >
                        <img
                          alt="add"
                          src={require('../../../assets/svg/icon/Add.svg')}
                          className="services-details-icon margin-left-5"
                        />
                      </button>
                    </div>
                  </div>
                </Hidden>
                <div className="flex-row width-auto">
                  <div className="margin-top-15" />
                </div>

                {!loader ? (
                  <>
                    <div
                      className="services-category-item-container"
                      onScroll={handleScroll}
                      ref={scrollAreaRef}
                    >
                      {searchDataPool.length > 0 &&
                        searchDataPool.map(
                          (projectService, projectServiceIndex) => {
                            return projectService.service !== null &&
                              projectService.service.title !== undefined ? (
                              <Grid container>
                                <Hidden smDown>
                                  <Service
                                    statusRef={statusRef}
                                    buildingData={
                                      projectService?.project?.building
                                    }
                                    data={projectService}
                                    changeCategory={changeCategory}
                                    deleteService={deleteService}
                                    buildingIndex={projectServiceIndex}
                                    searchDataPool={searchDataPool}
                                    setSearchDataPool={setSearchDataPool}
                                    projectIndex={projectServiceIndex}
                                    serviceIndex={projectServiceIndex}
                                    responsibleList={adminData}
                                    zIndex={
                                      (searchDataPool.length -
                                        projectServiceIndex) *
                                      10
                                    }
                                  />
                                </Hidden>
                                <Hidden mdUp>
                                  <ServiceMobile
                                    buildingData={
                                      projectService?.project?.building
                                    }
                                    data={projectService}
                                    changeCategory={changeCategory}
                                    deleteService={deleteService}
                                    buildingIndex={projectServiceIndex}
                                    searchDataPool={searchDataPool}
                                    setSearchDataPool={setSearchDataPool}
                                    projectIndex={projectServiceIndex}
                                    serviceIndex={projectServiceIndex}
                                    responsibleList={adminData}
                                  />
                                </Hidden>
                              </Grid>
                            ) : null
                          },
                        )}
                    </div>
                    <div className="flex-row horizontal-center vertical-center width-auto margin-top-20">
                      {renderPagination()}
                    </div>
                    <div className="flex-row width-auto">
                      <div className="margin-top-20" />
                    </div>
                  </>
                ) : (
                  <div className="height-auto width-auto center-content">
                    <img
                      src={require('../../../assets/svg/icon/loading.gif')}
                      className="services-loader"
                      alt="loader"
                    />
                  </div>
                )}
              </div>
              <StatusDisplay ref={statusRef} />
              <PageNavigator ref={pageNavRef} scrollAreaRef={scrollAreaRef} />
            </Grid>
          </Grid>
          {/* {originalServiceData.length > 0 && originalServiceData ? (

          ) : (
            <div className = "height-auto width-auto center-content">
              <img src = {require('../../../assets/svg/icon/loading.gif')} className = "services-loader" alt = "loader" />
            </div>
          )} */}
        </div>
      </Grid>
    </Grid>
  )
}

Service.propTypes = {
  data: PropTypes.object,
}

Service.defaultProps = {
  data: {
    id: 0,
    name: 'EPB-verslaggeving',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 'Bedrijfsnaam',
    responsible: '-',
    doc: 2,
    message: 6,
    price: '345',
    date: 'Tijd',
    timeline: 'Startverklaring',
    type: 1,
  },
}
ArrowLeft.propTypes = {
  rotate: PropTypes.bool,
}

ArrowLeft.defaultProps = {
  rotate: false,
}

Service.propTypes = {
  data: PropTypes.object,
  responsibleList: PropTypes.array,
}

Service.defaultProps = {
  data: {
    id: 0,
    name: 'EPB-verslaggeving',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 'Bedrijfsnaam',
    responsible: null,
    doc: 2,
    message: 6,
    price: '345',
    date: 'Tijd',
    timeline: 'Startverklaring',
    type: 1,
    responsible_list: [
      {
        id: 0,
        name: 'Cristine',
      },
      {
        id: 1,
        name: 'Albert',
      },
    ],
  },
  responsibleList: [],
}

Services.propTypes = {
  services: PropTypes.array,
}

Services.defaultProps = {
  services: [],
}

CheckBox.propTypes = {
  type: PropTypes.number,
  index: PropTypes.number,
  changeFilter: PropTypes.func,
}

CheckBox.defaultProps = {
  type: 1,
  index: 0,
  changeFilter: () => {},
}
