import React, { useEffect, useState } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import './_epb_service_admin.scss'
import StepOne from './step_one/step_one'
import StepTwo from './step_two/step_two'
import StepThree from './step_three/step_three'
import StepFour from './step_four/step_four'
import StepFive from './step_five/step_five'
import axios from 'axios'
import { isLocalDataValid } from '../../shared/shared_functions'
import { CustomSelect } from '../../shared_components/custom_select/custom_select'

export default function EPBServiceAdmin(props) {
  const { setBuildingData } = props
  const token = localStorage.getItem('authToken')
  const [projectId, setProjectId] = useState(0)
  const [projectServiceId, setProjectServiceId] = useState(0)
  const [ebpFormId, setEpbFormId] = useState(0)
  const [stepNumber, setStepNumber] = useState(1)
  const [isButtonClick, setButtonClick] = useState(false)
  const [stepOneData, setStepOneData] = useState({})
  const [stepTwoData, setStepTwoData] = useState({})
  const [stepThreeData, setStepThreeData] = useState({})
  const [stepFourData, setStepFourData] = useState({})
  const [stepFiveData, setStepFiveData] = useState({})
  const [signedDeclarationFile, setSignedDeclarationFile] = useState('')
  const [startStatementBuilder, setStartStatementBuilder] = useState('')
  const [startStatementArchitect, setStartStatementArchitect] = useState('')
  const [ventilatievoorontwerp, setVentilatievoorontwerp] = useState(0)
  const parameters = useParams()
  const history = useHistory()
  const loggedInUserDetails = JSON.parse(
    localStorage.getItem('loggedInUserDetails'),
  )
  const userRole = loggedInUserDetails.role.title

  const dropDownData = [
    { id: 1, title: '1. Startgegevens' },
    { id: 2, title: '2. Adviesrapport' },
    { id: 3, title: '3. Startverklaring' },
    { id: 4, title: '4. Stavingsstukken' },
    { id: 5, title: '5. Aangifte' },
  ]

  useEffect(() => {
    if (projectServiceId > 0) {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/ebp_form/get`,
          {
            projectServiceId: parseInt(projectServiceId),
          },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          let data = response.data
          if (data.details_of_people !== undefined) {
            setEpbFormId(data.id)
            setVentilatievoorontwerp(data.ventilatievoorontwerp)
            setStepOneData(data)
            setBuildingData(data.building)
            if (
              data.ondertekende_aangifte_document !== undefined &&
              data.ondertekende_aangifte_document !== null
            ) {
              setSignedDeclarationFile({
                viewName: data.ondertekende_aangifte_document,
                name: data.ondertekende_aangifte_document_orignal_name,
              })
            }
            if (
              data.startverklaring_bouwheer_document_orignal_name !==
                undefined &&
              data.startverklaring_bouwheer_document_orignal_name !== null
            ) {
              setStartStatementBuilder({
                viewName: data.startverklaring_bouwheer_document,
                name: data.startverklaring_bouwheer_document_orignal_name,
              })
            }
            if (data.startverklaring_architecten_document_orignal_name) {
              setStartStatementArchitect({
                name: data.startverklaring_architecten_document_orignal_name,
                viewName: data.startverklaring_architecten_document,
              })
            }
          } else {
            setBuildingData(data.building)
          }
        })
        .catch((error) => {})
    }
  }, [projectServiceId, stepNumber])

  useEffect(() => {
    if (!isLocalDataValid()) {
      localStorage.clear()
      history.push('/')
    }
    let url = parameters.id.split('-')
    setProjectServiceId(url[0])
    setProjectId(url[1])
  }, [parameters.id])

  useEffect(() => {
    let data = []
    axios
      .post(
        process.env.REACT_APP_API_URL + `/service_document/get`,
        { serviceId: 2, projectServiceId: projectServiceId },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.success === true && response.data.data.length > 0) {
          data = response.data.data
          // setStepOneData(data[0])
          setStepTwoData(data[1])
          setStepThreeData(data[2])
          setStepFourData(data[3])
          setStepFiveData(data[4])
        }
      })
      .catch((error) => {
        // if(error.response.status === 401) {
        //   localStorage.clear();
        //   history.push("/")
        // }
      })
  }, [projectServiceId, stepNumber])

  useEffect(() => {
    setEpbFormId(ebpFormId)
  }, [ebpFormId])

  return (
    <Grid container>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Hidden smDown>
        <Grid container>
          <Grid md={1}></Grid>
          <Grid container md={8} alignItems="center">
            <Grid md={2}>
              <span
                className={`animatable-600 animatable-fade-in-out-quart epb-service-tab-${
                  stepNumber === 1 ? 'selected' : 'unSelected'
                } vietnam-regular cursor-pointer `}
                onClick={() => {
                  setStepNumber(1)
                }}
              >
                Startgegevens
              </span>
            </Grid>
            <Grid md={2}>
              <span
                className={`animatable-600 animatable-fade-in-out-quart epb-service-tab-${
                  stepNumber === 2 ? 'selected' : 'unSelected'
                } vietnam-regular cursor-pointer `}
                onClick={() => {
                  setStepNumber(2)
                }}
              >
                Adviesrapport
              </span>
            </Grid>
            <Grid md={2}>
              <span
                className={`animatable-600 animatable-fade-in-out-quart epb-service-tab-${
                  stepNumber === 3 ? 'selected' : 'unSelected'
                } vietnam-regular cursor-pointer `}
                onClick={() => {
                  setStepNumber(3)
                }}
              >
                Startverklaring
              </span>
            </Grid>
            <Grid md={2}>
              <span
                className={`animatable-600 animatable-fade-in-out-quart epb-service-tab-${
                  stepNumber === 4 ? 'selected' : 'unSelected'
                } vietnam-regular cursor-pointer `}
                onClick={() => {
                  setStepNumber(4)
                }}
              >
                Stavingsstukken
              </span>
            </Grid>
            <Grid md={2}>
              <div
                className={`animatable-600 animatable-fade-in-out-quart epb-service-tab-${
                  stepNumber === 5 ? 'selected' : 'unSelected'
                } vietnam-regular cursor-pointer `}
                onClick={() => {
                  setStepNumber(5)
                }}
              >
                Aangifte
              </div>
            </Grid>
          </Grid>
          <Grid container md={2} justify="flex-end" alignItems="center">
            <button
              className="epb-service-button"
              onClick={() => {
                setButtonClick(!isButtonClick)
              }}
            >
              Bewaren
            </button>
          </Grid>
          <Grid container md={1}></Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container justify="center" alignItems="center">
          <Grid container xs={5} sm={5}>
            <CustomSelect
              data={dropDownData}
              setParentSelectedValue={setStepNumber}
              value={stepNumber}
            />
          </Grid>
          <Grid container xs={5} sm={5} justify="flex-end">
            <button
              className="epb-service-button"
              onClick={() => {
                setButtonClick(!isButtonClick)
              }}
            >
              Bewaren
            </button>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      {stepNumber === 1 ? (
        <StepOne
          token={token}
          projectServiceId={projectServiceId}
          isButtonClick={
            stepNumber === 1 && userRole !== 'Extern' ? isButtonClick : false
          }
          stepOneData={stepOneData}
          projectId={projectId}
          epbFormId={ebpFormId}
          setEpbFormId={setEpbFormId}
          setSignedDeclarationFile={setSignedDeclarationFile}
          setStartStatementArchitect={setStartStatementArchitect}
          setStartStatementBuilder={setStartStatementBuilder}
          setVentilatievoorontwerp={setVentilatievoorontwerp}
          userRole={userRole}
          inputDisableState={true}
        />
      ) : stepNumber === 2 ? (
        <StepTwo
          token={token}
          stepTwoData={stepTwoData}
          setStepTwoData={setStepTwoData}
          isButtonClick={stepNumber === 2 && isButtonClick}
          projectId={projectId}
          ebpFormId={ebpFormId}
          ventilatievoorontwerp={ventilatievoorontwerp}
          projectServiceId={projectServiceId}
        />
      ) : stepNumber === 3 ? (
        <StepThree
          token={token}
          stepThreeData={stepThreeData}
          setStepThreeData={setStepThreeData}
          isButtonClick={stepNumber === 3 && isButtonClick}
          projectId={projectId}
          ebpFormId={ebpFormId}
          architectFileName={startStatementArchitect}
          builderFileName={startStatementBuilder}
          projectServiceId={projectServiceId}
        />
      ) : stepNumber === 4 ? (
        <StepFour
          token={token}
          stepFourData={stepFourData}
          setStepFourData={setStepFourData}
          isButtonClick={stepNumber === 4 && isButtonClick}
          projectId={projectId}
          ebpFormId={ebpFormId}
          projectServiceId={projectServiceId}
        />
      ) : stepNumber === 5 ? (
        <StepFive
          token={token}
          stepFiveData={stepFiveData}
          setStepFiveData={setStepFiveData}
          isButtonClick={stepNumber === 5 && isButtonClick}
          projectId={projectId}
          ebpFormId={ebpFormId}
          signedDeclarationFile={signedDeclarationFile}
          projectServiceId={projectServiceId}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Grid>
  )
}
