import React, { useState, useEffect } from 'react'
import './_project_application_buildingtype.scss'
import { Grid } from '@material-ui/core'
import axios from 'axios'

export default function ProjectApplicationBuildingType(props) {
  const { setStepNumber, buildingTypeState, setBuildingTypeState } = props
  const [disableSubmitState, setDisableSubmitState] = useState(true)
  const token = localStorage.getItem('authToken')
  useEffect(() => {
    var data = []
    var finalData = []
    axios
      .post(
        process.env.REACT_APP_API_URL + `/service/services_meta_by_sids`,
        {
          serviceIds: JSON.parse(localStorage.getItem('selectedServiceIds')),
        },
        { headers: { authorization: `bearer ${token}` } },
      )

      .then((response) => {
        data = response.data
        let services = JSON.parse(localStorage.getItem('services'))

        data.forEach((element) => {
          if (services !== null) {
            services.forEach((s) => {
              if (element.id === s.serviceId) {
                if (s.serviceMetaId !== 0) {
                  element = { ...element, value: s.serviceMetaId }
                } else {
                  element = { ...element, value: 0 }
                }
              }
            })
          }
          finalData.push(element)
        })
        setBuildingTypeState(finalData)
      })
      .catch((error) => {})
  }, [])

  const checkSelectedType = () => {
    var isSubmitEnable = false
    buildingTypeState.forEach((item) => {
      if (item.value > 0 || item.servicesMeta.length === 0) {
        isSubmitEnable = true
      } else {
        isSubmitEnable = false
      }
    })
    if (isSubmitEnable) {
      setDisableSubmitState(false)
    } else {
      setDisableSubmitState(true)
    }
  }

  useEffect(() => {
    checkSelectedType()
  }, [])

  const handleBuildingTypeChange = (stateNumber, serviceId) => {
    const prevBuildingState = [...buildingTypeState]
    const prevObj = prevBuildingState.find((a) => a.id === serviceId)
    if (prevObj !== null && prevObj !== undefined) {
      prevObj.value = stateNumber
    }

    setBuildingTypeState(prevBuildingState)
    checkSelectedType()
  }

  const handleTypeSubmit = () => {
    var selectedTypeIds = []
    let selectedServicesId = JSON.parse(
      localStorage.getItem('selectedServiceIds'),
    )
    let finalObjectList = []
    buildingTypeState.forEach((item) => {
      if (item.value > 0) {
        selectedTypeIds.push(item.value)
      }
    })

    buildingTypeState.forEach((service) => {
      selectedServicesId.forEach((id) => {
        if (service.id === id) {
          if (service.servicesMeta.length > 0) {
            service.servicesMeta.forEach((type) => {
              selectedTypeIds.forEach((selectedType) => {
                if (selectedType === type.id) {
                  var obj = {}
                  obj['serviceId'] = id
                  obj['serviceMetaId'] = selectedType
                  obj['documents'] = []
                  finalObjectList.push(obj)
                }
              })
            })
          } else {
            var obj = {}
            obj['serviceId'] = id
            obj['serviceMetaId'] = 0
            obj['documents'] = []
            finalObjectList.push(obj)
          }
        }
      })
    })

    localStorage.setItem('services', null)

    if (finalObjectList.length > 0) {
      localStorage.setItem('services', JSON.stringify(finalObjectList))
      setStepNumber(3)
    }
  }

  return (
    <Grid container item>
      <div className="project-application-service-size relative">
        {buildingTypeState.length > 0
          ? buildingTypeState.map((item) =>
              item.servicesMeta.length > 0 ? (
                <Grid container>
                  <Grid item xs={12}>
                    <span className="font-color-black font-size-18 margin-top-25 vietnam-bold">
                      {item.title}
                    </span>
                  </Grid>
                  {item.servicesMeta.map((service) => {
                    return (
                      <Grid container>
                        <Grid item xs={12} key={item.id}>
                          <div className="flex margin-top-15 flex-row vertical-center">
                            <div
                              className={`project-application-radio${
                                item.value === service.id
                                  ? '-filled'
                                  : '-outline'
                              }`}
                              onClick={() => {
                                handleBuildingTypeChange(service.id, item.id)
                              }}
                            />
                            <span className="font-color-black margin-left-10 vietnam-medium font-size-14">
                              {service.title}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              ) : null,
            )
          : React.Fragment}
      </div>
      <button
        disabled={disableSubmitState}
        onClick={handleTypeSubmit}
        type="button"
        className={`project-application-billinfo-submit vietnam-semibold project-application-submit
          ${disableSubmitState === true ? 'cursor-disable' : 'cursor-pointer'}`}
      >
        Volgende
        <img
          alt="arrow_right"
          className="width-1 height-1"
          src={require('../../../assets/svg/icon/arrow_right.svg')}
        />
      </button>
    </Grid>
  )
}
