import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { delay } from 'lodash'
import axios from 'axios'
import { useDropzone } from 'react-dropzone'
import Picker from 'emoji-picker-react'

import './_emails_tab.scss'

export default function EmailsTab(props) {
  const [message, setMessage] = useState('')
  const [messageConversationId, setMessageConversationId] = useState(0)
  const [newMessageState, setNewMessageState] = useState(false)
  const [messageList, setMessageList] = useState([])
  const [updateConversation, setUpdateConversation] = useState(false)
  const [inactiveState, setInactiveState] = useState(true)

  const { singleBuildingData, projectServiceId } = props
  const currentUser = JSON.parse(localStorage.getItem('loggedInUserDetails'))
  const messageLogRef = useRef(0)
  const [selectedFiles, setFiles] = useState([])
  const [viewAttachFile, setViewAttachFile] = useState(false)
  const [buttonDisableState, setButtonDisableState] = useState(false)
  const [showChosenEmoji, setShowChosenEmoji] = useState(false)
  const [emojiClicked, setEmojiClicked] = useState(false)
  const onEmojiClick = (event, emojiObject) => {
    setButtonDisableState(false)
    setMessage(message + '' + emojiObject.emoji)
    // setMessageDisplay(messageDisplay+emojiObject.emoji);
  }

  const handleEmoji = () => {
    setShowChosenEmoji(!showChosenEmoji)
  }

  useEffect(() => {
    if (typeof projectServiceId === 'number' && projectServiceId !== 0) {
      // to prevent null and undefined errors
      setMessageList([])
      setMessageConversationId(0)
      setNewMessageState(false)
      doMessagesExist()
      hasAssignedAdmin()
    }
  }, [singleBuildingData, projectServiceId])

  useEffect(() => {
    if (messageConversationId !== 0) {
      getMessages()
    }
  }, [messageConversationId])

  useEffect(() => {
    if (
      messageConversationId !== 0 &&
      typeof messageConversationId === 'object'
    ) {
      getMessages()
      delay(() => {
        setUpdateConversation(!updateConversation)
      }, 5000)
    }
  }, [updateConversation, messageConversationId])

  const handleKeyPress = ({ keyCode }) => {
    if (keyCode === 13 && message !== '') {
      if (
        typeof messageConversationId.id === 'number' &&
        messageConversationId !== 0
      ) {
        // when there is an existing conversation
        if (
          (message != '' && message.trim() !== '') ||
          selectedFiles.length !== 0
        ) {
          pushMessage()
        } else {
          setMessage('')
        }
      } else {
        // when there is no existing conversation however the assigned_admin_id is correct
        if (
          (message != '' && message.trim() !== '') ||
          selectedFiles.length !== 0
        ) {
          sendNewMessage()
        } else {
          setMessage('')
        }
      }
    }
  }

  const handleMessage = (event) => {
    setButtonDisableState(false)
    setMessage(event.target.value)
  }

  function doMessagesExist() {
    const token = localStorage.getItem('authToken')
    axios
      .post(
        process.env.REACT_APP_API_URL + '/message/get_by_service_id',
        {
          project_service_id: projectServiceId,
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data !== null) {
          // messages exist
          setMessageConversationId(response.data)
          setInactiveState(false)
        } else {
          // no existing messages
          const loginDetails = JSON.parse(
            localStorage.getItem('loggedInUserDetails'),
          )
          if (hasAssignedAdmin(loginDetails.id)) {
            setNewMessageState(true)
            setInactiveState(false)
          } else {
            setNewMessageState(false)
          }
        }
      })
      .catch((error) => {})
  }

  function getMessages() {
    const token = localStorage.getItem('authToken')
    axios
      .post(
        process.env.REACT_APP_API_URL + '/message/get_messages',
        {
          message_conversation_id: messageConversationId.id,
          sender_id: singleBuildingData.userId,
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        setMessageList(response.data)
        !newMessageState && setNewMessageState(true)
      })
      .catch((error) => {})
  }

  function sendMessage() {
    const formData = new FormData()
    const token = localStorage.getItem('authToken')
    formData.append('message_conversation_id', messageConversationId.id)
    formData.append('subject', 'sample')
    formData.append('text', message)
    formData.append('sender_id', currentUser.id)
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('docsInput', selectedFiles[i])
    }
    axios
      .post(process.env.REACT_APP_API_URL + '/message/send_email', formData, {
        headers: {
          authorization: `bearer ${token}`,
        },
      })
      .then((response) => {
        // success
        setFiles([])
      })
      .catch((error) => {})
  }

  const pushMessage = () => {
    sendMessage()
    setViewAttachFile(false)
    let attachmentObj = []
    let date = new Date()
    for (let i = 0; i < selectedFiles.length; i++) {
      const temp = {
        fileName: selectedFiles[i].name,
        file: selectedFiles[i],
        createdAt: null,
      }
      attachmentObj.push(temp)
    }
    const messageObject = {
      body: message,
      messageAttachment: attachmentObj,
      sender_id: currentUser.id,
      sent: true,
      date: `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`, // insert converted date object to string here
    }
    messageList.push(messageObject)
    setMessage('')
    setTimeout(() => {
      messageLogRef.current.scrollTo({
        top: messageLogRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }, 100)
  }

  const handleAttachment = () => {
    setButtonDisableState(false)
  }

  const handleCancelAttachment = (index) => {
    let temp = []

    selectedFiles.filter((item, ind) => {
      if (ind !== index) {
        temp.push(item)
      }
    })
    if (temp.length == 0) {
      setViewAttachFile(false)
    }

    setFiles(temp)
  }

  const onDrop = useCallback((files) => {
    if (selectedFiles.length > 0) {
      let temp = { ...selectedFiles, files }
      setFiles(temp)
    } else {
      setFiles(files)
    }
    setViewAttachFile(true)
  }, [])
  const accept =
    'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,image/jpeg, image/png, video/webm, video/x-misvideo, video/mpeg, video/mp4, .dwg'
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept })

  function hasAssignedAdmin(id) {
    let matchFound = false
    singleBuildingData.projects.forEach((project) => {
      project.projectServices.forEach((services) => {
        if (services.id === projectServiceId) {
          if (services.assigned_admin_id === id) {
            matchFound = true
          }
        }
      })
    })
    return matchFound
  }

  function sendNewMessage() {
    // if there is an admin already assigned then the chat can be viewed but the admin cannot send messages
    const formData = new FormData()
    const loginDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'))

    formData.append('subject', 'sample')
    formData.append('text', message)
    formData.append('project_service_id', projectServiceId)
    formData.append('from_userId', loginDetails.id)
    formData.append('to_userId', singleBuildingData.userId)
    formData.append('sender_id', loginDetails.id)
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('docsInput', selectedFiles[i])
    }
    const token = localStorage.getItem('authToken')
    if (hasAssignedAdmin(loginDetails.id)) {
      axios
        .post(
          process.env.REACT_APP_API_URL + '/message/send_new_email',
          formData,
          {
            headers: {
              authorization: `bearer ${token}`,
            },
          },
        )
        .then((response) => {
          // success
          setMessage('')
          setFiles([])
          doMessagesExist()
        })
        .catch((error) => {})
    }
  }

  const handleMessageSend = () => {
    setButtonDisableState(false)
    if (
      typeof messageConversationId.id === 'number' &&
      messageConversationId !== 0
    ) {
      // when there is an existing conversation
      if (
        (message != '' && message.trim() !== '') ||
        selectedFiles.length !== 0
      ) {
        pushMessage()
      } else {
        setMessage('')
      }
    } else {
      // when there is no existing conversation however the assigned_admin_id is correct
      if (
        (message != '' && message.trim() !== '') ||
        selectedFiles.length !== 0
      ) {
        sendNewMessage()
      } else {
        setMessage('')
      }
    }
  }

  function cleanTimeString(dateTime) {
    let newDateTime = dateTime.split(' ')
    newDateTime = {
      date: newDateTime[0].split('-'),
      time: newDateTime[1].split(':'),
    }

    newDateTime.date = `${newDateTime.date[2]}/${newDateTime.date[1]}/${newDateTime.date[0]}`
    newDateTime.time = `${newDateTime.time[0]}:${newDateTime.time[1]}`

    return `${newDateTime.date} - ${newDateTime.time}`
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={11}
      justify="center"
      alignItems="flex-start"
      spacing={1}
      className="height-auto"
    >
      <Grid
        container
        item
        md={12}
        xs={12}
        sm={12}
        justify="center"
        alignItems="flex-start"
      >
        <div
          className="emails-tab-conversation-inner-container"
          ref={messageLogRef}
        >
          {inactiveState ? (
            <div className="height-auto width-auto center-content">
              <p className="main-font-semi-bold font-size-14 font-color-light-grey center-text">
                Berichten versturen is<br></br>nog niet mogelijk.<br></br>De
                uitvoerder van deze<br></br>dienst is nog niet<br></br>
                toegewezen.
              </p>
            </div>
          ) : (
            <>
              {messageList.length > 0 &&
                messageList.map((item) => (
                  <div className="flex-row width-auto fade-in-right">
                    {item.sender_id !== currentUser.id ? (
                      <div
                        className="flex-row padding-25"
                        style={{
                          padding:
                            window.screen.width < 600 ? '10px 0px' : '25px',
                        }}
                      >
                        <div
                          className="message-panel-profile margin-left-15 overflow-hidden flex-shrink-disable"
                          style={{
                            marginLeft:
                              window.screen.width < 600 ? '0px' : '15px',
                          }}
                        >
                          {messageConversationId !== null &&
                            messageConversationId.to_user !== null &&
                            messageConversationId.to_user.company !== null && (
                              <img
                                src={
                                  messageConversationId.to_user.company
                                    .profile_picture
                                    ? process.env.REACT_APP_API_URL +
                                      `/uploads/${messageConversationId.to_user.company.profile_picture}`
                                    : require('../../../../../assets/svg/user.svg')
                                }
                                alt="profile pic"
                                className="height-auto width-auto object-fit-contain flex-shrink-disable"
                              />
                            )}
                        </div>
                        <div className="flex-column">
                          {item.body !== '' && (
                            <div
                              className="message-panel-message"
                              style={{
                                width: window.screen.width < 600 && 'auto',
                              }}
                            >
                              <p className="main-font-regular font-size-12 font-color-white line-height-16">
                                {item.body}
                              </p>
                            </div>
                          )}
                          {item.messageAttachment !== null &&
                            item.messageAttachment.length > 0 &&
                            item.messageAttachment.map((attach, index) => (
                              <>
                                <div
                                  className="message-panel-chatAttachments-from"
                                  style={{
                                    width: window.screen.width < 600 && 'auto',
                                  }}
                                >
                                  <div className="message-panel-chatAttachments-img">
                                    <a
                                      href={
                                        process.env.REACT_APP_API_URL +
                                        `/uploads/${attach.fileName}`
                                      }
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      className="main-font-semi-bold font-size-14 font-color-black"
                                    >
                                      {attach.fileName.split('.')[1] ===
                                        'pdf' ||
                                      attach.fileName.split('.')[1] === 'doc' ||
                                      attach.fileName.split('.')[1] ===
                                        'docx' ||
                                      attach.fileName.split('.')[1] ===
                                        'xls' ? (
                                        <div className="flex-row vertical-center">
                                          <img
                                            src={
                                              attach.fileName.split('.')[1] ===
                                              'pdf'
                                                ? require('../../../../../assets/svg/doc/pdf.svg')
                                                : attach.fileName
                                                    .split('.')[1]
                                                    .includes('doc') ||
                                                  attach.fileName
                                                    .split('.')
                                                    .includes('docx')
                                                ? require('../../../../../assets/svg/doc/doc.svg')
                                                : attach.fileName
                                                    .split('.')[1]
                                                    .includes('xls')
                                                ? require('../../../../../assets/svg/doc/xls.svg')
                                                : null
                                            }
                                            alt="attachment pic"
                                            className="message-panel-chatAttachments-file"
                                          />
                                          <p className="main-font-bold font-size-14 font-color-white margin-left-10">
                                            {attach.fileName.substring(
                                              13,
                                              attach.fileName.length,
                                            )}
                                          </p>
                                        </div>
                                      ) : (
                                        <img
                                          src={
                                            attach.fileName
                                              .split('.')[1]
                                              .includes('jpg')
                                              ? process.env.REACT_APP_API_URL +
                                                '/uploads/' +
                                                attach.fileName
                                              : attach.fileName
                                                  .split('.')[1]
                                                  .includes('png') ||
                                                attach.fileName
                                                  .split('.')[1]
                                                  .includes('svg')
                                              ? process.env.REACT_APP_API_URL +
                                                '/uploads/' +
                                                attach.fileName
                                              : null
                                          }
                                          alt="attachment pic"
                                          className="height-auto width-auto object-fit-cover"
                                        />
                                      )}
                                    </a>
                                  </div>
                                </div>
                              </>
                            ))}
                          <p className="main-font-regular font-size-9 font-color-grey margin-left-25 margin-top-5">
                            {`${messageConversationId.to_user.firstName} ${messageConversationId.to_user.lastName}`}{' '}
                            - {cleanTimeString(item.date)}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="flex-row width-auto horizontal-end padding-25 fade-in-left"
                        style={{
                          padding:
                            window.screen.width < 600 ? '10px 0px' : '25px',
                        }}
                      >
                        <div className="flex-column">
                          {item.body !== '' && (
                            <div
                              className="message-panel-message-alt flex-row horizontal-end"
                              style={{
                                width: window.screen.width < 600 && 'auto',
                              }}
                            >
                              <p className="main-font-regular font-size-12 font-color-white line-height-16">
                                {item.body}
                              </p>
                            </div>
                          )}
                          {item.messageAttachment !== null &&
                            item.messageAttachment.length > 0 &&
                            item.messageAttachment.map((attach, index) => (
                              <>
                                <div
                                  className="message-panel-chatAttachments-to"
                                  style={{
                                    width: window.screen.width < 600 && 'auto',
                                  }}
                                >
                                  <div className="message-panel-chatAttachments-img">
                                    <a
                                      href={
                                        process.env.REACT_APP_API_URL +
                                        `/uploads/${attach.fileName}`
                                      }
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      className="main-font-semi-bold font-size-14 font-color-black"
                                    >
                                      {attach.fileName.split('.')[1] ===
                                        'pdf' ||
                                      attach.fileName.split('.')[1] === 'doc' ||
                                      attach.fileName.split('.')[1] ===
                                        'docx' ||
                                      attach.fileName.split('.')[1] ===
                                        'xls' ? (
                                        <div className="flex-row vertical-center">
                                          <img
                                            src={
                                              attach.fileName.split('.')[1] ===
                                              'pdf'
                                                ? require('../../../../../assets/svg/doc/pdf.svg')
                                                : attach.fileName
                                                    .split('.')[1]
                                                    .includes('doc') ||
                                                  attach.fileName
                                                    .split('.')
                                                    .includes('docx')
                                                ? require('../../../../../assets/svg/doc/doc.svg')
                                                : attach.fileName
                                                    .split('.')[1]
                                                    .includes('xls')
                                                ? require('../../../../../assets/svg/doc/xls.svg')
                                                : null
                                            }
                                            alt="attachment pic"
                                            className="message-panel-chatAttachments-file"
                                          />
                                          <p className="main-font-bold font-size-14 font-color-white margin-left-10">
                                            {attach.fileName.substring(
                                              13,
                                              attach.fileName.length,
                                            )}
                                          </p>
                                        </div>
                                      ) : (
                                        <img
                                          src={
                                            attach.fileName
                                              .split('.')[1]
                                              .includes('jpg')
                                              ? process.env.REACT_APP_API_URL +
                                                '/uploads/' +
                                                attach.fileName
                                              : attach.fileName
                                                  .split('.')[1]
                                                  .includes('png') ||
                                                attach.fileName
                                                  .split('.')[1]
                                                  .includes('svg')
                                              ? process.env.REACT_APP_API_URL +
                                                '/uploads/' +
                                                attach.fileName
                                              : null
                                          }
                                          alt="attachment pic"
                                          className="height-auto width-auto object-fit-cover"
                                        />
                                      )}
                                    </a>
                                  </div>
                                </div>
                              </>
                            ))}
                          <div className="flex-row width-auto horizontal-end">
                            <div className="flex-row width-auto horizontal-between vertical-center margin-top-5">
                              <div className="flex-row">
                                {messageConversationId.sent ? (
                                  <img
                                    src={require('../../../../../assets/svg/icon/loading.gif')}
                                    alt="loading"
                                    className="message-panel-log-status"
                                  />
                                ) : (
                                  <img
                                    src={require('../../../../../assets/svg/icon/check_blue.svg')}
                                    alt="checked"
                                    className="message-panel-log-status"
                                  />
                                )}
                              </div>
                              <div className="flex-row">
                                <p className="main-font-regular font-size-9 font-color-grey margin-right-25 margin-top-5">
                                  {`${messageConversationId.from_user.firstName} ${messageConversationId.from_user.lastName}`}{' '}
                                  - {cleanTimeString(item.date)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="message-panel-profile margin-left-15 overflow-hidden flex-shrink-disable"
                          style={{
                            marginRight:
                              window.screen.width < 600 ? '0px' : '15px',
                          }}
                        >
                          {messageConversationId !== null &&
                            messageConversationId.from_user !== null &&
                            messageConversationId.from_user.company !==
                              null && (
                              <img
                                src={
                                  messageConversationId.from_user.company
                                    .profile_picture
                                    ? process.env.REACT_APP_API_URL +
                                      `/uploads/${messageConversationId.from_user.company.profile_picture}`
                                    : require('../../../../../assets/svg/user.svg')
                                }
                                alt="profile pic"
                                className="height-auto width-auto object-fit-contain flex-shrink-disable"
                              />
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </>
          )}
        </div>
      </Grid>
      {viewAttachFile === true && (
        <div className="message-panel-attachments">
          {selectedFiles.map((item, index) => (
            <div className="message-panel-attachments-section">
              <div className="message-panel-attachments-img">
                <a
                  href={URL.createObjectURL(item)}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="main-font-semi-bold font-size-14 font-color-black"
                >
                  <img
                    src={
                      item.path.split('.')[1] === 'pdf'
                        ? require('../../../../../assets/svg/doc/pdf.svg')
                        : item.path.split('.')[1].includes('doc')
                        ? require('../../../../../assets/svg/doc/doc.svg')
                        : item.path.split('.')[1].includes('xls')
                        ? require('../../../../../assets/svg/doc/xls.svg')
                        : item.path.split('.')[1].includes('jpg')
                        ? URL.createObjectURL(item)
                        : item.path.split('.')[1].includes('png')
                        ? URL.createObjectURL(item)
                        : require('../../../../../assets/svg/doc/other.svg')
                    }
                    alt="attachment pic"
                    className="height-auto width-auto object-fit-cover"
                  />
                </a>
              </div>
              <div className="message-panel-attachments-cross">
                <img
                  src={require('../../../../../assets/svg/close.svg')}
                  alt="close"
                  onClick={() => handleCancelAttachment(index)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <Grid container item md={12} xs={12} sm={12} justify="center">
        <div className="message-panel-input-container flex-row vertical-center flex-shrink-disable fade-in-bottom margin-top-15">
          <div
            className="message-panel-input-profile margin-left-25 margin-right-15 flex-shrink-disable overflow-hidden"
            style={{ marginLeft: window.screen.width < 600 && '0px' }}
          >
            {currentUser !== null && currentUser.company !== null && (
              <img
                src={
                  currentUser.company.profile_picture
                    ? process.env.REACT_APP_API_URL +
                      `/uploads/${currentUser.company.profile_picture}`
                    : require('../../../../../assets/svg/user.svg')
                }
                alt="profile pic"
                className="height-auto width-auto object-fit-contain"
              />
            )}
          </div>
          <div className="message-panel-input vertical-center flex-row">
            <div
              className="message-panel-input-emoji flex-shrink-disable outline-none"
              tabIndex="1"
              onBlur={() => {
                if (emojiClicked === false) {
                  setShowChosenEmoji(false)
                }
              }}
            >
              <img
                src={require('../../../../../assets/svg/icon/emoji.svg')}
                alt="emoji"
                className="height-auto width-auto object-fit-contain"
                onClick={handleEmoji}
              />
              <div
                className="message-panel-emoji"
                onMouseEnter={() => {
                  setEmojiClicked(true)
                }}
                onMouseLeave={() => {
                  setEmojiClicked(false)
                }}
                style={{
                  display: showChosenEmoji ? 'flex' : 'none',
                  left: window.screen.width < 600 && '0%',
                }}
              >
                <Picker onEmojiClick={onEmojiClick} />
              </div>
            </div>
            <input
              className="width-auto main-font-regular font-size-12 font-color-grey line-height-16 italic"
              placeholder="schrijf hier iets"
              onChange={handleMessage}
              onKeyDown={handleKeyPress}
              disabled={!newMessageState}
              value={message}
            />
            <div {...getRootProps({ style: { outline: 'none' } })}>
              <img
                src={require('../../../../../assets/svg/icon/attachment.svg')}
                className="message-panel-input-attachment"
                alt="attachment flex-shrink-disable"
                onClick={handleAttachment}
              />
              <input className="height-auto width-auto" {...getInputProps()} />
            </div>
          </div>
          <button
            type="button"
            className="message-panel-input-button margin-right-25 margin-left-15 flex-shrink-disable center-content"
            style={{
              marginRight: window.screen.width < 600 && '0px',
              width: window.screen.width < 600 && '45px',
            }}
            disabled={!newMessageState}
            disabled={buttonDisableState}
            onClick={handleMessageSend}
          >
            <img
              src={require('../../../../../assets/svg/icon/send.svg')}
              alt="paper plane"
              className="message-panel-input-aero"
            />
            <Hidden xsDown>
              <h1 className="main-font-semi-bold font-size-12 font-color-white margin-left-10">
                Verzenden
              </h1>
            </Hidden>
          </button>
        </div>
        <Hidden smUp>
          <div className="width-auto flex-row margin-top-25" />
        </Hidden>
      </Grid>
    </Grid>
  )
}
