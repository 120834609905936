import React, { useState } from 'react'
import { Grid, Dialog } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import './_new_password_modal.scss'
import LoginLogo from '../../../assets/png/logo_color/login_logo.png'
import ArrowRight from '../../../assets/svg/icon/arrow_right_white.svg'
import axios from 'axios'

export default function NewPasswordModal(props) {
  const { modalState } = props
  const userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'))
  const [formErrorState, setFormErrorState] = useState({
    email: '',
    password: '',
  })
  const [passwordState, setPasswordState] = useState('')
  const [animateState, setAnimateState] = useState(false)
  const [buttonDisableState, setButtonDisableState] = useState(false)
  const token = localStorage.getItem('authToken')
  const history = useHistory()

  const handleLoginClick = () => {
    const values = { ...formErrorState }
    setButtonDisableState(true)
    if (passwordState.length === 0) {
      values.password = 'Password is required'
      setFormErrorState(values)
    }
    if (passwordState.length > 0 && values.password.length === 0) {
      const data = {
        password: passwordState,
        login_first: true,
      }
      axios
        .put(
          process.env.REACT_APP_API_URL + `/user/update/${userDetails.id}`,
          data,
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.data.data[0]) {
            history.push('/dashboard')
          }
        })
        .catch((error) => {
          setButtonDisableState(false)
        })
    }
    setAnimateState(true)
    setTimeout(() => {
      setAnimateState(false)
    }, 2500)
  }

  const onPasswordChange = (event) => {
    setPasswordState(event.target.value)
    setButtonDisableState(false)
    const values = { ...formErrorState }
    if (event.target.value.length === 0) {
      values.password = 'Password is required'
      setFormErrorState(values)
    } else if (event.target.value.length < 8) {
      values.password = 'Password must be 8 digits long'
      setFormErrorState(values)
    } else {
      values.password = ''
      setFormErrorState(values)
    }
  }

  const keyDownHandler = ({ keyCode }) => {
    if (keyCode === 13) {
      handleLoginClick()
    }
  }

  return (
    <Dialog open={modalState} disableBackdropClick disableEscapeKeyDown>
      <Grid container item className="modal-view-size" alignItems="center">
        <div className="modal-view-size ">
          <Grid
            container
            alignItems="center"
            justify="center"
            className="margin-top-20"
          >
            <img
              // src={require('../../assets/svg/logo_color/7a.svg')}
              src={LoginLogo}
              alt="logo color"
              className="loginpage-logo object-fit-content"
            />
          </Grid>
          <Grid container justify="center">
            <p className="vietnam-bold font-size-30 font-color-black main-font-bold margin-top-10">
              Welkom
            </p>
          </Grid>
          <Grid container item justify="center">
            <p className="vietnam-regular font-size-14 center-text font-color-grey margin-top-10">
              op het DocuBuild-platform
            </p>
          </Grid>
          <Grid container item justify="center">
            <p className="vietnam-regular font-size-14 center-text font-color-grey">
              Gelieven hier uw nieuw wachtwoord in te stellen
            </p>
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            className="margin-top-25"
          >
            <Grid item xs={8} sm={8} md={6} lg={6}>
              <input
                placeholder="Wachtwoord"
                type="password"
                onChange={(event) => onPasswordChange(event)}
                onKeyDown={keyDownHandler}
                className={`${
                  formErrorState.password.length > 0 && animateState === true
                    ? 'input-error-border '
                    : ''
                }
                                    input-custom-control`}
              />
              {formErrorState.password.length > 0 && animateState === true ? (
                <span className="font-color-red font-size-15 margin-left-10 margin-top-5">
                  {formErrorState.password}
                </span>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            className="margin-top-25 margin-bottom-25"
          >
            <Grid item xs={8} sm={8} md={6} lg={6}>
              <button
                className="core-custom-button vietnam-semibold cursor-pointer relative"
                onClick={handleLoginClick}
                disabled={buttonDisableState}
                type="submit"
              >
                Log In
                <img
                  alt="arrow_right"
                  src={ArrowRight}
                  className="float-item-right loginpage-arrow-right absolute"
                />
              </button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Dialog>
  )
}
