import React from 'react'
import { Grid } from '@material-ui/core'

import './_mobile_view.scss'

export default function MobileView() {
  return (
    <Grid container>
      <div className="message-panel-container center-content">
        <p className="main-font-semi-bold font-size-14 font-color-light-grey">
          Niet toegankelijk op mobile
        </p>
      </div>
    </Grid>
  )
}
