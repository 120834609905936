import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import axios from 'axios'
import InfoGreyIcon from '../../../../assets/svg/icon/info_circle_grey.svg'
import InfoBlueIcon from '../../../../assets/svg/icon/info_circle_blue.svg'
import './_step_2.scss'

export default function Step2(props) {
  const [infoHoverNumber, setInfoHoverNumber] = useState(0)
  const { stepNumber } = props
  const { workFiles, qualityFiles } = props

  function downloadImage(documentName, documentOriginalName) {
    if (documentName) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/documents/download_file/${documentName}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          const imageUrl = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = imageUrl
          a.download = documentOriginalName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(imageUrl)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <div className={`height-auto width-auto animatable-400 fade-in-translate`}>
      <div className="ventilation-service-blue-strip margin-top-20 vertical-center">
        <Grid container>
          <Grid
            container
            xs={1}
            sm={1}
            md={1}
            justify="center"
            alignItems="center"
            style={{ position: 'relative', zIndex: 999999 }}
          >
            {window.screen.width > 600 && (
              <Grid container justify="flex-end" xs={10} sm={10} md={10}>
                <img
                  alt="info"
                  className="epb-service-Category-heading-image-size"
                  src={InfoBlueIcon}
                  onMouseOver={() => {
                    setInfoHoverNumber(2)
                  }}
                  onMouseLeave={() => {
                    setInfoHoverNumber(0)
                  }}
                />
                {infoHoverNumber === 2 && (
                  <div className=" flex-row fade-in-right">
                    <img
                      src={require('../../../../assets/svg/icon/corner.png')}
                      alt="corner"
                      className="absolute step-four-info-arrow"
                    />
                    <div
                      className="step-four-info-modal"
                      style={{ alignSelf: 'flex-start' }}
                    >
                      <div className="step-four-info-highlight-box width-auto">
                        <ul>
                          <li className="font-color-black vietnam-regular font-size-14 line-height-15">
                            Het doel van het ventilatievoorontwerp is om de
                            opdrachtgever bij de aanvang van het bouwproces te
                            informeren van de voorzieningen die nodig zijn om te
                            voldoen aan de ventilatievoorschriften. De ligging
                            en afmetingen van de kanalen zijn sterk afhankelijk
                            van het exacte type kanalen en de keuze voor een
                            vertakt netwerk of collectornetwerk. De
                            uiteindelijke beslissing van de positie en type
                            kanalen ligt bij de installateur van het systeem, in
                            samenspraak met de bouwheer en architect.{' '}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            )}
          </Grid>
          <Grid container item xs={11} sm={11} md={11}>
            <div className="vertical-center">
              <span className="main-font-regular font-color-black font-size-16">
                Ventilatievoorontwerp
              </span>
            </div>
          </Grid>
        </Grid>
      </div>

      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={6} sm={3} md={2}>
          <a
            // href={
            //   workFiles.fileName !==undefined && workFiles.fileName !== '' && workFiles.fileName !== null &&
            //   process.env.REACT_APP_API_URL+`/documents/download_file/${workFiles.fileName}`
            // }
            // download
            onClick={() =>
              downloadImage(workFiles.fileName, workFiles.documentTitle)
            }
            className={`${
              workFiles.fileName !== undefined &&
              workFiles.fileName !== null &&
              workFiles.fileName !== ''
                ? 'selected-tab-button-style'
                : 'disabled-button-style'
            } cursor-pointer flex-row vertical-center`}
          >
            <img
              alt=""
              src={
                workFiles.fileName !== undefined &&
                workFiles.fileName !== null &&
                workFiles.fileName !== ''
                  ? require('../../../../assets/svg/icon/download.svg')
                  : require('../../../../assets/svg/icon/download_grey.svg')
              }
              className={
                workFiles.fileName !== undefined &&
                workFiles.fileName !== null &&
                workFiles.fileName !== ''
                  ? ''
                  : 'width-2 height-2 margin-left-10'
              }
            />

            <p className="vietnam-semi-bold center-text width-auto">{`${
              workFiles.fileName !== undefined &&
              workFiles.fileName !== '' &&
              workFiles.fileName !== null
                ? 'Download'
                : ''
            }`}</p>
          </a>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <p className="step-two-description vietnam-regular">
              {workFiles.fileName !== undefined &&
                workFiles.fileName !== '' &&
                workFiles.fileName !== null &&
                `Opgemaakt op ${workFiles.createdAt}`}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <div className="ventilation-service-blue-strip margin-top-20 vertical-center">
        <Grid container>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={11} sm={11} md={11}>
            <span className="main-font-regular font-color-black font-size-16">
              Attest van het kwaliteitskader
            </span>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={6} sm={3} md={2}>
          <a
            // href={
            //   qualityFiles.fileName !==undefined && qualityFiles.fileName !== null && qualityFiles.fileName !== '' &&
            //   process.env.REACT_APP_API_URL+`/documents/download_file/${qualityFiles.fileName}`
            // }
            // download
            onClick={() =>
              downloadImage(qualityFiles.fileName, qualityFiles.documentTitle)
            }
            className={`${
              qualityFiles.fileName !== undefined &&
              qualityFiles.fileName !== null &&
              qualityFiles.fileName !== ''
                ? 'selected-tab-button-style'
                : 'disabled-button-style'
            } cursor-pointer flex-row vertical-center`}
          >
            <img
              alt=""
              src={
                qualityFiles.fileName !== undefined &&
                qualityFiles.fileName !== null &&
                qualityFiles.fileName !== ''
                  ? require('../../../../assets/svg/icon/download.svg')
                  : require('../../../../assets/svg/icon/download_grey.svg')
              }
              className={
                qualityFiles.fileName !== undefined &&
                qualityFiles.fileName !== null &&
                qualityFiles.fileName !== ''
                  ? ''
                  : 'width-2 height-2 margin-left-10'
              }
            />

            <p className="vietnam-semi-bold center-text width-auto">{`${
              qualityFiles.fileName !== undefined &&
              qualityFiles.fileName !== null &&
              qualityFiles.fileName !== ''
                ? 'Download'
                : ''
            }`}</p>
          </a>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <p className="step-two-description vietnam-regular">
              {qualityFiles.fileName !== undefined &&
                qualityFiles.fileName !== null &&
                qualityFiles.fileName !== '' &&
                `Opgemaakt op ${qualityFiles.createdAt}`}
            </p>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="margin-top-30">
        <Grid item xs={1} sm={1} md={1}></Grid>
        <Grid item xs={10} sm={10} md={10}>
          <div className="ventilation-service-step2-horizontal-line"></div>
        </Grid>
        <Grid item xs={1} sm={1} md={1}></Grid>
      </Grid>
      <Grid container className="margin-top-20">
        <Grid item xs={1} sm={1} md={1}></Grid>
        <Grid item xs={10} sm={10} md={10}>
          <div className="ventilation-service-step2-info-div">
            <div className="height-auto width-5-p">
              <img
                src={InfoGreyIcon}
                alt=""
                className="ventilation-service-step2-info-icon"
              />
            </div>
            <div className="flex-column height-auto width-90-p font-color-semi-dark-grey italic font-size-14">
              <span>
                Op het einde van de werken komen we een ventilatiemeting
                uitvoeren, gelieve voldoende aandacht te besteden aan volgende
                zaken zodat een extra plaatsbezoek (wat kosten met zich
                meebrengt) vermeden wordt.
              </span>
              <span className="margin-top-10">
                - De debieten moeten voldoen aan de minimum debieten. Kleine
                verschillen proberen wij, mits uw goedkeuring, aan te passen.
                Lukt dit niet op korte termijn, dan is het aan de plaatser van
                het ventilatiesysteem om dit in orde te brengen. We kunnen het
                ook zo laten maar de boete bedraagt € 4 per ontbrekende m3/u.
              </span>
              <span className="margin-top-10">
                - De ventilatiemonden moeten voorzien zijn van een rooster. Deze
                roosters moeten toegankelijk zijn om te meten. Er wordt een
                trechter geplaatst over deze roosters. Het is dus niet mogelijk
                om roosters te meten die bvb zijn ingewerkt in kasten.
              </span>
              <span className="margin-top-10">
                - De spleten onder de deuren worden gemeten, de spleet moet 1 cm
                bedragen (70 cm2). Mochten de spleten niet voldoende groot zijn,
                dan resulteert ook dit in een boete. Gelieve reeds op voorhand
                een controle hiervan te doen aub. Als er nog geen binnendeuren
                staan is dit geen probleem. We moeten de werkelijke toestand
                rapporteren.
              </span>
              <span className="margin-top-10">
                - De lengte van de raamroosters wordt gemeten. Gelieve ook een
                stavingsstuk te bezorgen van het exacte merk en type van deze
                roosters. Dit moet eveneens worden doorgegeven aan het
                kwaliteitskader.
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={1} sm={1} md={1}></Grid>
      </Grid>
    </div>
  )
}

Step2.propTypes = {
  workFiles: PropTypes.array,
  qualityFiles: PropTypes.array,
}

Step2.defaultProps = {
  workFiles: [],
  qualityFiles: [],
}
