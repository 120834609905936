// @format
// @flow

import * as React from 'react'
import Core from './components/core'

function App() {
  return <Core />
}

export default App
