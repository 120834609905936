import React, { createRef, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import axios from 'axios'
import './_step_two.scss'
import { CustomCheckBox } from '../../../shared_components/custom_checkbox/custom_checkbox'
import Popup from '../../../shared_components/custom_popup/custom_popup'

export default function StepTwo(props) {
  const {
    token,
    setStepNumber,
    epbFormId,
    isButtonClick,
    ventilatievoorontwerp,
    stepTwoData,
    adviceReportSectionState,
    userRole,
  } = props
  const [documentDate, setDocumentDate] = useState('')
  const [documentName, setDocumentName] = useState('')
  const [documentOriginalName, setDocumentOriginalName] = useState('')
  const [showPopupState, setShowPopupState] = useState(false)
  const [popupMessageState, setPopupMessageState] = useState({})
  const [infoHoverNumber, setInfoHoverNumber] = useState(0)
  const [ventilationPreliminaryDesign, setVentilationPreliminaryDesign] =
    useState([])
  const [ventilationDesignRef, setVentilationDesignRef] = useState([])

  useEffect(() => {
    if (JSON.stringify(stepTwoData) !== '{}') {
      if (
        stepTwoData.service_document_categories[0].service_documents.length > 0
      ) {
        setDocumentDate(
          restructureDate(
            stepTwoData.service_document_categories[0].service_documents[0]
              .createdAt,
          ),
        )
        setDocumentName(
          stepTwoData.service_document_categories[0].service_documents[0]
            .fileName,
        )
        setDocumentOriginalName(
          stepTwoData.service_document_categories[0].service_documents[0]
            .documentTitle,
        )
      }
    }
  }, [stepTwoData])

  useEffect(() => {}, [documentDate, adviceReportSectionState])

  useEffect(() => {
    if (ventilationDesignRef.length > 0) {
      let ventilatievoorontwerp = 0
      if (ventilationDesignRef[0].current.getValue()) {
        ventilatievoorontwerp = 1
      } else if (ventilationDesignRef[1].current.getValue()) {
        ventilatievoorontwerp = 2
      } else {
        ventilatievoorontwerp = 3
      }
      axios
        .post(
          process.env.REACT_APP_API_URL +
            `/ebp_form/store_ventilatievoorontwerp`,
          {
            ebp_form_id: epbFormId,
            ventilatievoorontwerp: ventilatievoorontwerp,
          },
          {
            headers: {
              authorization: `bearer ${token}`,
            },
          },
        )
        .then((response) => {
          if (response.data.message === 'Success!') {
            let responseMessage = {
              isSuccess: true,
              heading: 'Succesrecord opgeslagen',
              message: '',
              popupState: setShowPopupState,
            }
            setShowPopupState(true)
            setPopupMessageState(responseMessage)
          }
        })
        .catch((error) => {
          let responseMessage = {
            isSuccess: false,
            heading: 'Instellingen konden niet worden opgeslagen',
            message: 'Er is iets fout gegaan',
            popupState: setShowPopupState,
          }
          setShowPopupState(true)
          setPopupMessageState(responseMessage)
        })
    }
  }, [isButtonClick])

  const [showMore, setShowMore] = useState(true)

  useEffect(() => {
    const tempArray = []
    Array(3)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setVentilationDesignRef(tempArray)
  }, [])

  function disableAllButtons() {
    ventilationDesignRef[0].current.disableButton()
    ventilationDesignRef[1].current.disableButton()
    ventilationDesignRef[2].current.disableButton()
  }

  function downloadImage(documentName) {
    if (documentName) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/documents/download_file/${documentName}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          const imageUrl = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = imageUrl
          a.download = documentOriginalName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(imageUrl)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <Grid container className="fade-in-translate animatable-400 ">
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
          style={{ position: 'relative', zIndex: 999999 }}
        >
          {window.screen.width > 600 && (
            <Grid container justify="flex-end" xs={10} sm={10} md={10}>
              <img
                alt="info"
                className="epb-service-Category-heading-image-size"
                src={require('../../../../assets/svg/icon/info.svg')}
                onMouseOver={() => {
                  setInfoHoverNumber(1)
                }}
                onMouseLeave={() => {
                  setInfoHoverNumber(0)
                }}
              />
              {infoHoverNumber === 1 && (
                <div className="flex-row fade-in-right">
                  <img
                    src={require('../../../../assets/svg/icon/corner.png')}
                    alt="corner"
                    className="absolute step-four-info-arrow"
                  />
                  <div
                    className="step-four-info-modal"
                    style={{ alignSelf: 'flex-start' }}
                  >
                    <div className="step-four-info-highlight-box width-auto">
                      <ul>
                        <li className="font-color-black vietnam-regular font-size-14 line-height-15">
                          Adviesrapport voor EPB waarin je een overzicht vindt
                          van de eisen inzake isolatie en hygiënische
                          ventilatie. Dit kan je bezorgen aan de aannemer,
                          architect, dakwerker, raamleverancier en de plaatser
                          van het ventilatiesysteem.{' '}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          )}
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              EPB adviesrapport
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={6} sm={3} md={2}>
          <a
            // href={documentName!==''&& process.env.REACT_APP_API_URL+`/documents/download_file/${documentName}`}
            // download
            onClick={() => downloadImage(documentName)}
            className={`${
              documentName !== ''
                ? 'enabled-button-style'
                : 'disabled-button-style'
            } cursor-pointer flex-row vertical-center`}
            justify="center"
          >
            <img
              alt=""
              src={
                documentName !== ''
                  ? require('../../../../assets/svg/icon/download.svg')
                  : require('../../../../assets/svg/icon/download_grey.svg')
              }
              className={
                documentName !== '' ? '' : 'width-2 height-2 margin-left-10'
              }
            />

            <p className="vietnam-semi-bold center-text width-auto">{`${
              documentName !== '' ? 'Download' : ''
            }`}</p>
          </a>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <p className="step-two-description vietnam-regular">
              {documentDate !== '' && `Opgemaakt op ${documentDate}`}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
          style={{ position: 'relative', zIndex: 999999 }}
        >
          {window.screen.width > 600 && (
            <Grid container justify="flex-end" xs={10} sm={10} md={10}>
              <img
                alt="info"
                className="epb-service-Category-heading-image-size"
                src={require('../../../../assets/svg/icon/info.svg')}
                onMouseOver={() => {
                  setInfoHoverNumber(2)
                }}
                onMouseLeave={() => {
                  setInfoHoverNumber(0)
                }}
              />
              {infoHoverNumber === 2 && (
                <div className="flex-row fade-in-right">
                  <img
                    src={require('../../../../assets/svg/icon/corner.png')}
                    alt="corner"
                    className="absolute step-four-info-arrow"
                  />
                  <div
                    className="step-four-info-modal"
                    style={{ alignSelf: 'flex-start' }}
                  >
                    <div className="step-four-info-highlight-box width-auto">
                      <ul>
                        <li className="font-color-black vietnam-regular font-size-14 line-height-15">
                          De stavingsstukken van de gebruikte materialen,
                          installaties en technieken zijn de bouwstenen van uw
                          EPB-dossier en van groot belang bij deze
                          eindberekening (ook eindaangifte genoemd). Op basis
                          van de ontvangen stavingsstukken zal de verslaggever
                          namelijk zijn berekening uitvoeren. Indien geen
                          stavingsstukken worden afgeleverd, dient de
                          EPB-verslaggever zijn berekening uit te voeren op
                          basis van waarden bij ontstentenis. Dit zijn meestal
                          ongunstige waarden, wat helaas resulteert in een
                          minder goed of zelfs slecht resultaat.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          )}
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Stavingstukken
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="step-two-other-heading vietnam-regular">
              Opladen van de ondertekende startverklaring
            </p>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container xs={10} sm={6} md={2}>
            <button
              type="button"
              className={
                'selected-tab-button-style cursor-pointer flex-row center-content vietnam-semi-bold'
              }
              onClick={() => {
                setStepNumber(4)
              }}
              justify="center"
            >
              Naar stavingstukken
            </button>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      {adviceReportSectionState !== undefined && adviceReportSectionState && (
        <Grid container>
          <Grid
            container
            className="epb-service-Category-heading"
            alignItems="center"
          >
            <Grid
              container
              xs={1}
              sm={1}
              md={1}
              justify="center"
              alignItems="center"
            >
              <Grid container justify="flex-end" xs={10} sm={10} md={10}>
                {/* <img
                  alt = 'info'
                  className="epb-service-Category-heading-image-size"
                  src={require('../../../../assets/svg/icon/info.svg')}
                /> */}
              </Grid>
            </Grid>

            <Grid container xs={11} sm={11} md={11}>
              <Grid container>
                <p className="epb-service-Category-heading-label vietnam-regular">
                  EPB adviesrapport
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <Grid xs={1} sm={1} md={1} />
            <Grid container xs={11} sm={11} md={11}>
              <Grid container>
                <div className="margin-top-10" />
              </Grid>
              <Grid container xs={10} sm={6} md={2}>
                <button
                  type="button"
                  className={
                    'selected-tab-button-style cursor-pointer flex-row center-content vietnam-semi-bold'
                  }
                  onClick={() => {
                    setStepNumber(1)
                  }}
                  justify="center"
                >
                  Naar startgegevens
                </button>
              </Grid>
              <Grid container>
                <div className="margin-top-10" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid
          container
          className="epb-service-Category-heading"
          alignItems="center"
        >
          <Grid container md={1} />
          <Grid container md={11}>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Ventilatievoorontwerp
              <img
                alt="arrow"
                className="margin-left-5 epb-service-Category-heading-arrow-size"
                src={require(`../../../../assets/svg/icon/${
                  showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
                }`)}
                onClick={() => {
                  setShowMore(!showMore)
                }}
              />
            </p>
          </Grid>
        </Grid>
        <Grid container>
          <div className="margin-top-10" />
        </Grid>
        {showMore && (
          <Grid container>
            <Grid container>
              <Grid xs={1} sm={1} md={1} />
              <Grid container xs={11} sm={11} md={11}>
                <p className="main-font-regular font-size-14 font-color-black">
                  Mag het ventilatievoorontwerp opgemaakt worden a.d.h.v.
                  bovenstaande EPB adviesrapport?
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-bottom-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid xs={1} sm={1} md={1} />
              <Grid container xs={11} sm={11} md={11}>
                <Grid>
                  <CustomCheckBox
                    label="Ja"
                    ref={ventilationDesignRef[0]}
                    disableAllButtons={disableAllButtons}
                    value={
                      ventilatievoorontwerp !== undefined &&
                      ventilatievoorontwerp !== null &&
                      ventilatievoorontwerp === 1 &&
                      ventilatievoorontwerp
                    }
                    disabled={userRole === 'Extern'}
                  />
                </Grid>
                <Grid>
                  <div className="margin-right-20" />
                </Grid>
                <Grid>
                  <CustomCheckBox
                    label="Nee, ander ventilatiesysteem"
                    ref={ventilationDesignRef[1]}
                    disableAllButtons={disableAllButtons}
                    value={
                      ventilatievoorontwerp !== undefined &&
                      ventilatievoorontwerp !== null &&
                      ventilatievoorontwerp === 2 &&
                      ventilatievoorontwerp
                    }
                    disabled={userRole === 'Extern'}
                  />
                </Grid>
                <Grid>
                  <div className="margin-right-20" />
                </Grid>
                <Grid>
                  <CustomCheckBox
                    label="Nee, graag nog wachten tot definitief ventilatiesysteem gekend is"
                    ref={ventilationDesignRef[2]}
                    disableAllButtons={disableAllButtons}
                    value={
                      ventilatievoorontwerp !== undefined &&
                      ventilatievoorontwerp !== null &&
                      ventilatievoorontwerp === 3 &&
                      ventilatievoorontwerp
                    }
                    disabled={userRole === 'Extern'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {showPopupState === true ? <Popup data={popupMessageState} /> : null}
    </Grid>
  )
}

export function restructureDate(value) {
  let date = value.split(' ')[0].split('-')
  return date[2] + '/' + date[1] + '/' + date[0]
}
