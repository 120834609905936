import React, { useState, useEffect } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { Link } from 'react-router-dom'

import './_error.scss'

import Header from '../../dashboard/header/header'

export default function Error() {
  const [animation, setAnimation] = useState(false)

  useEffect(() => {
    setAnimation(true)
  }, [])

  return (
    <Grid container>
      <Grid container>
        <Hidden smDown>
          <Header />
        </Hidden>
        <Hidden smUp>
          <Header toShowHamburger />
        </Hidden>
      </Grid>
      <div className="error-container center-content">
        <div className="flex-column vertical-center margin-bottom-100">
          <img
            src={require('../../../assets/svg/icon/icon_search_blue_dark.svg')}
            alt="search dark blue"
            className="error-img animatable-800 ease-in-out-quart rotate-out-center"
            style={{
              opacity: animation ? 1 : 0,
              height: animation ? '130px' : '0px',
              width: animation ? '130px' : '0px',
            }}
          />
          <h1 className="main-font-bold font-size-48 font-color-navy-blue margin-bottom-10">
            404 ERROR
          </h1>
          <p className="main-font-medium font-size-18 font-color-navy-blue">
            PAGE NOT FOUND
          </p>
          <Link to="/dashboard">
            <button type="button" className="error-button center-content">
              <p className="main-font-semi-bold font-size-16 font-color-white margin-left-15 margin-right-15">
                BACK TO HOME
              </p>
            </button>
          </Link>
        </div>

        <div className="error-border-right" />
        <div className="error-border-bottom" />
      </div>
    </Grid>
  )
}
