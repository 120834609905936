import React, { useState, useEffect, useRef, createRef } from 'react'
import { Grid } from '@material-ui/core'
import axios from 'axios'
import { restructureDate } from '../step_two/step_two'
import './_step_three.scss'
import Popup from '../../../shared_components/custom_popup/custom_popup'

export default function StepThree(props) {
  const {
    token,
    architectFileName,
    builderFileName,
    stepThreeData,
    projectServiceId,
    userRole,
    epbFormId,
  } = props
  const [documentDate, setDocumentDate] = useState('')
  const [documentName, setDocumentName] = useState('')
  const [documentOriginalName, setDocumentOriginalName] = useState('')
  const [startStatementClient, setStartStatementClient] = useState([])
  const [initialStatementArchitect, setInitialStatementArchitect] = useState([])
  const [statementRef, setStatementRef] = useState([])
  const [formData, setFormData] = useState(new FormData())

  useEffect(() => {
    if (architectFileName !== undefined && architectFileName !== '') {
      setInitialStatementArchitect(architectFileName)
    }
    if (builderFileName !== undefined && builderFileName !== '') {
      setStartStatementClient(builderFileName)
    }
  }, [architectFileName, builderFileName])

  useEffect(() => {
    const tempArray = []
    Array(2)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setStatementRef(tempArray)
  }, [])
  useEffect(() => {
    if (stepThreeData !== undefined && JSON.stringify(stepThreeData) !== '{}') {
      if (
        stepThreeData.service_document_categories[0].service_documents.length >
        0
      ) {
        setDocumentDate(
          restructureDate(
            stepThreeData.service_document_categories[0].service_documents[0]
              .createdAt,
          ),
        )
        setDocumentName(
          stepThreeData.service_document_categories[0].service_documents[0]
            .fileName,
        )
        setDocumentOriginalName(
          stepThreeData.service_document_categories[0].service_documents[0]
            .documentTitle,
        )
      }
      const tempFormData = new FormData()
      let serviceDocumentCategoryId =
        stepThreeData.service_document_categories.length > 0
          ? stepThreeData.service_document_categories[1].id
          : 0
      let serviceDocumentId =
        stepThreeData.service_document_categories.length > 0
          ? stepThreeData.service_document_categories[1].service_documents
              .length > 0
            ? stepThreeData.service_document_categories[1].service_documents[0]
                .id
            : 0
          : 0
      tempFormData.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData.append('projectServiceId', projectServiceId)
      tempFormData.append('serviceDocumentId', serviceDocumentId)
      setFormData(tempFormData)
    }
  }, [stepThreeData])

  useEffect(() => {}, [documentDate])

  function downloadImage(documentName) {
    if (documentName) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/documents/download_file/${documentName}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          const imageUrl = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = imageUrl
          a.download = documentOriginalName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(imageUrl)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <Grid container className="animatable-400 fade-in-translate">
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        ></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Startverklaring
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <Grid container xs={10} sm={6} md={2}>
            <a
              // href={documentName!==''&& process.env.REACT_APP_API_URL+`/documents/download_file/${documentName}`}
              // download
              onClick={() => downloadImage(documentName)}
              className={`${
                documentName !== ''
                  ? 'enabled-button-style'
                  : 'disabled-button-style'
              } cursor-pointer flex-row vertical-center`}
              justify="center"
            >
              <img
                alt=""
                src={
                  documentName !== ''
                    ? require('../../../../assets/svg/icon/download.svg')
                    : require('../../../../assets/svg/icon/download_grey.svg')
                }
                className={
                  documentName !== '' ? '' : 'width-2 height-2 margin-left-10'
                }
              />

              <p className="vietnam-semi-bold center-text width-auto">{`${
                documentName !== '' ? 'Download' : ''
              }`}</p>
            </a>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <p className="step-three-description vietnam-regular">
              {documentDate !== '' && `Opgemaakt op ${documentDate}`}
            </p>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container xs={10} sm={6} md={6}>
            <p className="step-three-description vietnam-regular">
              Gelieve ons de laatste pagina hiervan ondertekend terug te sturen.
              Hiermee is jullie EPB-dossier klaar voor uitvoering van de werken.
              Aan de berekening in de startverklaring kunnen er nog wijzigingen
              worden aangebracht. Je kan dus gerust een initieel gekozen
              materiaal vervangen door een gelijkwaardig alternatief.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        ></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Ondertekende startverklaring
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="step-three-other-heading vietnam-regular">
              Opladen van de ondertekende startverklaring
            </p>
          </Grid>

          <StatementDetails
            backendFileName={builderFileName.viewName}
            file={startStatementClient}
            setFile={setStartStatementClient}
            uploadPath={
              process.env.REACT_APP_API_URL +
              '/ebp_form/startverklaring_bouwheer_document'
            }
            deletePath={
              process.env.REACT_APP_API_URL +
              '/ebp_form/startverklaring_bouwheer_document_delete'
            }
            buttonText="Startverklaring bouwheer"
            ref={statementRef[0]}
            token={token}
            projectServiceId={projectServiceId}
            formData={formData}
            buttonDisableState={userRole === 'Extern'}
            epbFormId={epbFormId}
          />
          <StatementDetails
            backendFileName={architectFileName.viewName}
            file={initialStatementArchitect}
            setFile={setInitialStatementArchitect}
            uploadPath={
              process.env.REACT_APP_API_URL +
              '/ebp_form/startverklaring_architecten_document'
            }
            deletePath={
              process.env.REACT_APP_API_URL +
              '/ebp_form/startverklaring_architecten_document_delete'
            }
            token={token}
            buttonText="Startverklaring architecten"
            ref={statementRef[1]}
            projectServiceId={projectServiceId}
            formData={formData}
            buttonDisableState={userRole === 'Extern'}
            epbFormId={epbFormId}
          />

          <Grid container>
            <div className="margin-top-10" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
    </Grid>
  )
}

export const StatementDetails = (props, ref) => {
  const {
    buttonText,
    token,
    file,
    setFile,
    uploadPath,
    deletePath,
    formData,
    backendFileName,
    projectServiceId,
    buttonDisableState,
    epbFormId,
  } = props
  const fileRef = useRef(null)
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [showPopupState, setShowPopupState] = useState(false)
  const [popupMessageState, setPopupMessageState] = useState({})
  const [fileUploadProgress, setFileUploadProgress] = useState(0)

  useEffect(() => {
    if (
      backendFileName !== undefined &&
      backendFileName !== null &&
      backendFileName !== ''
    ) {
      setUploadedFileName(backendFileName)
    }
  }, [backendFileName])

  useEffect(() => {
    if (
      file !== undefined &&
      file !== null &&
      file.documentTitle !== undefined
    ) {
      setFile({ id: file.id, name: file.documentTitle })
    }
  }, [file])

  const handleErrorPopupState = () => {
    let responseMessage = {
      isSuccess: false,
      heading: 'Bestand kon niet worden geselecteerd',
      message: 'Verwijder eerst het vorige bestand',
      popupState: setShowPopupState,
    }
    setShowPopupState(true)
    setPopupMessageState(responseMessage)
  }

  const handleSuccessPopupState = () => {
    let responseMessage = {
      isSuccess: true,
      heading: 'Bestand succesvol geüpload',
      message: '',
      popupState: setShowPopupState,
    }
    setShowPopupState(true)
    setPopupMessageState(responseMessage)
  }

  const handleFileChange = (event) => {
    event.preventDefault()
    const files = event.target.files
    if (epbFormId > 0) {
      if (files !== null && files !== undefined) {
        if (files.length > 0) {
          if (files[0] !== null && files[0] !== undefined) {
            if (formData.has('docsInput')) {
              formData.delete('docsInput')
            }
            formData.append('docsInput', files[0])
            axios
              .post(uploadPath, formData, {
                headers: { authorization: `bearer ${token}` },
                onUploadProgress: (progress) => {
                  const { total, loaded } = progress
                  const totalSize = total / 1000000
                  const totalLoaded = loaded / 1000000
                  const percentage = (totalLoaded / totalSize) * 100
                  setFileUploadProgress(percentage.toFixed(2))
                },
              })
              .then((response) => {
                setFileUploadProgress(0)
                const responseData = response.data
                if (responseData !== null && responseData !== undefined) {
                  if (responseData.success === true) {
                    handleSuccessPopupState()
                    setFile({
                      id: responseData.serviceDocumentId,
                      name: responseData.fileName,
                    })
                    setUploadedFileName(responseData.file)
                  } else {
                    handleErrorPopupState()
                    setFile(null)
                  }
                } else {
                  handleErrorPopupState()
                  setFile(null)
                }
              })
              .catch((error) => {
                handleErrorPopupState()
                setFile(null)
              })
          } else {
            setFile(null)
          }
        }
      }
    } else {
      let responseMessage = {
        isSuccess: false,
        heading:
          "Het document kan nog niet worden opgeslagen. Gelieven eerst enkele 'Startgegevens' in te vullen.",
        message: '',
        popupState: setShowPopupState,
      }
      setShowPopupState(true)
      setPopupMessageState(responseMessage)
    }
  }

  const deleteFile = () => {
    if (file.id > 0) {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/service_document/delete`,
          { serviceDocumentId: file.id },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.data.success) {
            handleSuccessPopupState()
            setFile(response.data.data)
          }
        })
        .catch((error) => {
          handleErrorPopupState()
        })
    } else if (deletePath !== undefined) {
      axios
        .post(
          deletePath,
          { projectServiceId: projectServiceId },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.data.success) {
            handleSuccessPopupState()
            setFile(response.data.data)
          }
        })
        .catch((error) => {
          handleErrorPopupState()
        })
    }
  }

  return (
    <Grid container alignItems="center">
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container xs={10} sm={6} md={3}>
        {file !== undefined &&
        file !== null &&
        file.name !== undefined &&
        file.name !== null ? (
          showPopupState && <Popup data={popupMessageState} />
        ) : (
          <input
            type="file"
            ref={fileRef}
            className="ventilation-service-file-input"
            onChange={(event) => handleFileChange(event)}
          />
        )}
        {showPopupState && <Popup data={popupMessageState} />}
        <button
          type="button"
          disabled={buttonDisableState}
          className={`${
            !buttonDisableState
              ? 'enabled-button-style'
              : 'disabled-button-style'
          } cursor-pointer flex-row vertical-center`}
          onClick={() => {
            file !== undefined &&
            file !== null &&
            file.name !== undefined &&
            file.name !== null
              ? handleErrorPopupState()
              : fileRef.current.click()
          }}
        >
          <img
            alt=""
            src={require('../../../../assets/svg/icon/upload.svg')}
            className=""
          />
          <p className="center-text vietnam-semi-bold width-auto">
            {buttonText}
          </p>
        </button>
      </Grid>
      <Grid>
        <div className="margin-left-20" />
      </Grid>
      {file !== undefined &&
        file !== null &&
        file.name !== undefined &&
        file.name !== null && (
          <div className="flex-row margin-top-10">
            <Grid className="flex" justify="space-evenly" alignItems="center">
              <img
                alt=""
                className="step-three-file-icon"
                src={
                  file.name.split('.').includes('pdf')
                    ? require('../../../../assets/svg/doc/pdf.svg')
                    : file.name.split('.').includes('doc')
                    ? require('../../../../assets/svg/doc/doc.svg')
                    : file.name.split('.').includes('xls')
                    ? require('../../../../assets/svg/doc/xls.svg')
                    : file.name.split('.').includes('jpg')
                    ? require('../../../../assets/svg/doc/jpg.svg')
                    : require('../../../../assets/svg/doc/other.svg')
                }
              />
              <p className="step-three-file-name margin-left-10 vietnam-regular">
                {file.name}
              </p>
            </Grid>
            <Grid>
              <div className="margin-left-20" />
            </Grid>
            <Grid>
              <a
                href={
                  uploadedFileName !== '' &&
                  process.env.REACT_APP_API_URL + `/uploads/${uploadedFileName}`
                }
                rel="noopener noreferrer"
                target="_blank"
                className={'step-three-delete-image   cursor-pointer'}
                //   onClick={() => handleChange('info')}
              >
                <img
                  alt=""
                  src={require('../../../../assets/svg/icon/view.svg')}
                  className="margin-top-1"
                />
              </a>
            </Grid>
            <Grid>
              <div className="margin-left-20" />
            </Grid>
            <Grid>
              <button
                type="button"
                className={'step-three-delete-image cursor-pointer'}
                onClick={() => deleteFile()}
                disabled={buttonDisableState}
                style={{
                  opacity:
                    buttonDisableState !== undefined &&
                    buttonDisableState &&
                    0.2,
                }}
              >
                <img
                  alt=""
                  src={require('../../../../assets/svg/icon/trash.svg')}
                  className=""
                />
              </button>
            </Grid>
          </div>
        )}
      {fileUploadProgress !== 0 && (
        <div className="flex-row vertical-center">
          <p className="main-font-semi-bold vietnam-bold font-size-12 font-color-sky-blue">
            Upload
          </p>
          <div className="slider">
            <div
              className="slider-thumb"
              style={{ width: fileUploadProgress * 2 }}
            />
          </div>
          <p className="main-font-semi-bold font-size-12 font-color-sky-blue">
            {fileUploadProgress}%
          </p>
        </div>
      )}
    </Grid>
  )
}

export const StatementDetailsForMultiple = (props, ref) => {
  const {
    buttonText,
    token,
    files,
    setFiles,
    uploadPath,
    deletePath,
    formData,
    backendFileNames,
    projectServiceId,
    buttonDisableState,
    epbFormId,
  } = props
  const fileRef = useRef(null)
  const [showPopupState, setShowPopupState] = useState(false)
  const [popupMessageState, setPopupMessageState] = useState({})
  const [fileUploadProgress, setFileUploadProgress] = useState(0)

  useEffect(() => {}, [files])

  const handleErrorPopupState = () => {
    let responseMessage = {
      isSuccess: false,
      heading: 'Bestand kon niet worden geselecteerd',
      message: 'Verwijder eerst het vorige bestand',
      popupState: setShowPopupState,
    }
    setShowPopupState(true)
    setPopupMessageState(responseMessage)
  }

  const handleSuccessPopupState = () => {
    let responseMessage = {
      isSuccess: true,
      heading: 'Bestand succesvol geüpload',
      message: '',
      popupState: setShowPopupState,
    }
    setShowPopupState(true)
    setPopupMessageState(responseMessage)
  }

  const handleFileChange = (event) => {
    event.preventDefault()
    const targetFiles = event.target.files
    if (epbFormId > 0) {
      if (targetFiles !== null && targetFiles !== undefined) {
        if (targetFiles.length > 0) {
          let targetFilesLength = targetFiles.length
          if (targetFiles[0] !== null && targetFiles[0] !== undefined) {
            if (formData.has('docsInput')) {
              formData.delete('docsInput')
            }
            for (var i = 0; i < targetFilesLength; i++) {
              formData.append('docsInput', targetFiles[i])
            }
            axios
              .post(uploadPath, formData, {
                headers: { authorization: `bearer ${token}` },
                onUploadProgress: (progress) => {
                  const { total, loaded } = progress
                  const totalSize = total / 1000000
                  const totalLoaded = loaded / 1000000
                  const percentage = (totalLoaded / totalSize) * 100
                  setFileUploadProgress(percentage.toFixed(2))
                },
              })
              .then((response) => {
                setFileUploadProgress(0)
                const responseData = response.data
                if (responseData !== null && responseData !== undefined) {
                  if (responseData.success === true) {
                    let tempUploadFiles = JSON.parse(JSON.stringify(files))
                    handleSuccessPopupState()
                    responseData.data.forEach((item) => {
                      tempUploadFiles.push({
                        id: item.serviceDocumentId,
                        name: item.fileName,
                        uploadedName: item.file,
                      })
                    })
                    setFiles(tempUploadFiles)
                  } else {
                    handleErrorPopupState()
                    setFiles(null)
                  }
                } else {
                  handleErrorPopupState()
                  setFiles(null)
                }
              })
              .catch((error) => {
                handleErrorPopupState()
                setFiles(null)
              })
          } else {
            setFiles(null)
          }
        }
      }
    } else {
      let responseMessage = {
        isSuccess: false,
        heading:
          "Het document kan nog niet worden opgeslagen. Gelieven eerst enkele 'Startgegevens' in te vullen.",
        message: '',
        popupState: setShowPopupState,
      }
      setShowPopupState(true)
      setPopupMessageState(responseMessage)
    }
  }

  const deleteFile = (index) => {
    let tempFiles = JSON.parse(JSON.stringify(files))
    if (files[index].id > 0) {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/service_document/delete`,
          { serviceDocumentId: files[index].id },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.data.success) {
            handleSuccessPopupState()
            tempFiles.splice(index, 1)
            setFiles(tempFiles)
          }
        })
        .catch((error) => {
          handleErrorPopupState()
        })
    } else if (deletePath !== undefined) {
      axios
        .post(
          deletePath,
          { projectServiceId: projectServiceId },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.data.success) {
            handleSuccessPopupState()
            setFiles(response.data.data)
          }
        })
        .catch((error) => {
          handleErrorPopupState()
        })
    }
  }

  return (
    <Grid container alignItems="flex-start">
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container xs={10} sm={6} md={3}>
        <input
          type="file"
          ref={fileRef}
          multiple
          className="ventilation-service-file-input"
          onChange={(event) => handleFileChange(event)}
        />
        <button
          type="button"
          disabled={buttonDisableState}
          className={`${
            buttonDisableState !== undefined && buttonDisableState
              ? 'disabled-button-style'
              : 'enabled-button-style'
          } cursor-pointer flex-row vertical-center`}
          onClick={() => {
            fileRef.current.click()
          }}
        >
          <img
            alt=""
            src={require('../../../../assets/svg/icon/upload.svg')}
            className=""
          />
          <p className="center-text vietnam-semi-bold width-auto">
            {buttonText}
          </p>
        </button>
      </Grid>
      <Grid>
        <div className="margin-left-20" />
      </Grid>
      <Grid container xs={10} sm={6} md={8}>
        {files !== undefined &&
          files !== null &&
          files.length > 0 &&
          files.map((file, index) => (
            <div className="flex-row width-auto vertical-center margin-top-5 margin-bottom-5">
              <Grid className="flex" justify="space-evenly" alignItems="center">
                <img
                  alt=""
                  className="step-three-file-icon"
                  src={
                    file.name.split('.').includes('pdf')
                      ? require('../../../../assets/svg/doc/pdf.svg')
                      : file.name.split('.').includes('doc')
                      ? require('../../../../assets/svg/doc/doc.svg')
                      : file.name.split('.').includes('xls')
                      ? require('../../../../assets/svg/doc/xls.svg')
                      : file.name.split('.').includes('jpg')
                      ? require('../../../../assets/svg/doc/jpg.svg')
                      : require('../../../../assets/svg/doc/other.svg')
                  }
                />
                <p className="step-three-file-name margin-left-10 vietnam-regular">
                  {file.name}
                </p>
              </Grid>
              <Grid>
                <div className="margin-left-20" />
              </Grid>
              <Grid>
                <a
                  href={
                    file.uploadedName !== '' &&
                    process.env.REACT_APP_API_URL +
                      `/uploads/${file.uploadedName}`
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                  className={'step-three-delete-image   cursor-pointer'}
                  //   onClick={() => handleChange('info')}
                >
                  <img
                    alt=""
                    src={require('../../../../assets/svg/icon/view.svg')}
                    className="margin-top-1"
                  />
                </a>
              </Grid>
              <Grid>
                <div className="margin-left-20" />
              </Grid>
              <Grid>
                <button
                  type="button"
                  className={'step-three-delete-image cursor-pointer'}
                  onClick={() => deleteFile(index)}
                  disabled={buttonDisableState}
                  style={{
                    opacity:
                      buttonDisableState !== undefined &&
                      buttonDisableState &&
                      0.2,
                  }}
                >
                  <img
                    alt=""
                    src={require('../../../../assets/svg/icon/trash.svg')}
                    className=""
                  />
                </button>
              </Grid>
            </div>
          ))}
        {fileUploadProgress !== 0 && (
          <div className="flex-row vertical-center">
            <p className="main-font-semi-bold vietnam-bold font-size-12 font-color-sky-blue">
              Upload
            </p>
            <div className="slider">
              <div
                className="slider-thumb"
                style={{ width: fileUploadProgress * 2 }}
              />
            </div>
            <p className="main-font-semi-bold font-size-12 font-color-sky-blue">
              {fileUploadProgress}%
            </p>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

StatementDetails.defaultProps = {
  data: {},
  projectId: 1,
}
