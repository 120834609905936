import React, { useState } from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import './_custom_popup.scss'
import CheckWhiteIcon from '../../../assets/svg/icon/check_white.svg'
import CrossWhiteIcon from '../../../assets/svg/icon/cross_white.svg'

const useStyles = makeStyles({
  paper: {
    width: '60%',
    maxHeight: '60%',
    height: '300px',
  },
})
export default function Popup(props) {
  const { data } = props
  const classes = useStyles()
  const [popupState, setPopupState] = useState(true)

  const handleButtonClick = () => {
    setPopupState(false)
    if (data.popupState !== undefined && data.popupState !== null) {
      data.popupState(false)
    }
  }

  return (
    <Dialog
      open={popupState}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      scroll="paper"
      classes={{ paper: classes.paper }}
    >
      <div className="width-auto height-60 center-content flex-column custom-popup-main-div">
        {data.isSuccess === true ? (
          <div className="margin-top-10 custom-popup-success-div">
            <img
              src={CheckWhiteIcon}
              alt="success"
              className="custom-popup-success-icon"
            />
          </div>
        ) : (
          <div className="margin-top-10 custom-popup-error-div">
            <img src={CrossWhiteIcon} alt="error" />
          </div>
        )}

        <div className="margin-top-30 center-content">
          <span className="main-font-bold center-text">{data.heading}</span>
        </div>
        <div className="margin-top-30 center-content">
          <span className="main-font-bold center-text">{data.message}</span>
        </div>
        <div className="width-50-p width-mobile-auto margin-top-25">
          <button
            className="core-custom-button main-font-semibold cursor-pointer"
            onClick={handleButtonClick}
          >
            Okay
          </button>
        </div>
      </div>
    </Dialog>
  )
}

Popup.propTypes = {
  data: PropTypes.shape({
    isSuccess: PropTypes.bool,
    heading: PropTypes.string,
    message: PropTypes.string,
    popupState: PropTypes.func,
  }),
}

Popup.defaultProps = {
  data: {
    isSuccess: true,
    heading: '',
    message: '',
    popupState: function () {},
  },
}
