import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { restructureDate } from '../../../epb_forms/epb_form_client/step_two/step_two'
import { StatementDetailsForMultiple } from '../../../epb_forms/epb_form_client/step_three/step_three'
import axios from 'axios'
import './_step_three.scss'
export default function StepThree(props) {
  const { token, projectId, projectServiceId, userRole, formId } = props
  const [documentDateList, setDocumentDateList] = useState([])
  const [documentNameList, setDocumentNameList] = useState([])
  const [documentOriginalNameList, setDocumentOriginalNameList] = useState([])
  const [formData, setFormData] = useState(new FormData())
  const [uploadFiles, setUploadFiles] = useState([])

  useEffect(() => {
    let data = []
    let tempDocumentDateList = []
    let tempDocumentNameList = []
    let tempOriginalDocumentNameList = []
    axios
      .post(
        process.env.REACT_APP_API_URL + `/service_document/get`,
        { serviceId: 4, projectServiceId: projectServiceId },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.success === true) {
          data = response.data.data
          if (
            data[1].service_document_categories[0].service_documents.length > 0
          ) {
            data[1].service_document_categories[0].service_documents.map(
              (item) => {
                tempDocumentDateList.push(restructureDate(item.createdAt))
                tempDocumentNameList.push(item.fileName)
                tempOriginalDocumentNameList.push(item.documentTitle)
              },
            )
            setDocumentDateList(tempDocumentDateList)
            setDocumentNameList(tempDocumentNameList)
            setDocumentOriginalNameList(tempOriginalDocumentNameList)
          }
          let tempFormData = new FormData()
          let serviceDocumentCategoryId =
            data[1].service_document_categories.length > 0
              ? data[1].service_document_categories[1].id
              : 0
          let serviceDocumentId =
            data[1].service_document_categories.length > 0
              ? data[1].service_document_categories[1].service_documents
                  .length > 0
                ? data[1].service_document_categories[1].service_documents[0].id
                : 0
              : 0
          tempFormData.append(
            'serviceDocumentCategoryId',
            serviceDocumentCategoryId,
          )
          tempFormData.append('projectServiceId', projectServiceId)
          tempFormData.append('serviceDocumentId', serviceDocumentId)
          setFormData(tempFormData)
          if (
            data[1].service_document_categories.length > 0 &&
            data[1].service_document_categories[1].service_documents.length > 0
          ) {
            let tempUploadFiles = []
            data[1].service_document_categories[1].service_documents.forEach(
              (item) => {
                tempUploadFiles.push({
                  id: item.id,
                  name: item.documentTitle,
                  uploadedName: item.fileName,
                })
              },
            )
            setUploadFiles(tempUploadFiles)
          }
        }
      })
      .catch((error) => {})
  }, [])
  // useEffect(() => {}, [documentDate])

  function downloadImage(documentName, documentOriginalName) {
    if (documentName) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/documents/download_file/${documentName}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          const imageUrl = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = imageUrl
          a.download = documentOriginalName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(imageUrl)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <Grid container className="animatable-400 fade-in-translate">
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        ></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Werfverslagen
            </p>
          </Grid>
        </Grid>
      </Grid>

      {documentNameList.map((item, index) => (
        <Grid container>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>

          <Grid container xs={1} sm={1} md={1} />
          <Grid container xs={11} sm={11} md={11}>
            {/* <Grid> */}
            <a
              // href={
              //   item !== '' &&
              //   process.env.REACT_APP_API_URL+`/documents/download_file/${item}`
              // }
              // download
              onClick={() =>
                downloadImage(item, documentOriginalNameList[index])
              }
              className={
                'selected-tab-button-style step-three-upload-button-1 cursor-pointer flex-row vertical-center '
              }
            >
              <img
                alt=""
                src={require('../../../../assets/svg/icon/download.svg')}
                className=""
              />

              <p className="vietnam-semi-bold center-text width-auto">
                Dowload werfverslagen {index + 1}
              </p>
            </a>
            {/* </Grid> */}
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container md={3}>
              <p className="step-two-description center-text vietnam-regular">
                {documentDateList[index] !== '' &&
                  `Opgemaakt op ${documentDateList[index]}`}
              </p>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        ></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Postinterventiedossier
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <StatementDetailsForMultiple
            backendFileNames={uploadFiles}
            buttonText="Documenten opladen"
            files={uploadFiles}
            setFiles={setUploadFiles}
            uploadPath={
              process.env.REACT_APP_API_URL +
              '/service_document/upload_multiple_document'
            }
            deletePath={
              process.env.REACT_APP_API_URL +
              '/safety_service_form/postinterventie_document_del'
            }
            token={token}
            projectId={projectId}
            formData={formData}
            buttonDisableState={userRole === 'Extern'}
            epbFormId={formId}
          />
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
    </Grid>
  )
}
