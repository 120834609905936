import React, { useState, useEffect } from 'react'
import './_view_private_client.scss'
import { Grid } from '@material-ui/core'
import axios from 'axios'
import PropTypes from 'prop-types'
import { privateCompanyForm } from '../../shared/sample_data.js'
import EditIcon from '../../../assets/svg/icon_edit_grey.svg'

export default function ViewPrivateClient(props) {
  const { setViewState, companyId } = props
  const [privateClientData, setPrivateClientData] = useState(0)
  const [companyFormState, setCompanyFormState] = useState([])

  const token = localStorage.getItem('authToken')

  useEffect(() => {
    if (companyId) {
      setCompanyFormState(JSON.parse(JSON.stringify(privateCompanyForm)))
      getPrivateCompany()
    }
  }, [companyId])

  function getPrivateCompany() {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/admin/company/get/${companyId}`,
        {},
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        setPrivateClientData(response.data)
      })
      .catch((error) => {})
  }

  return (
    <Grid container>
      <div className="private-client-main-div">
        <Grid container>
          <div>
            <span className="main-font-semi-bold font-color-black font-size-18">
              Prive persoon
            </span>
          </div>
        </Grid>
        <Grid container>
          <div className="flex-row width-auto margin-top-30" />
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">Naam</p>
            <p
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              style={{ border: 'none' }}
            >
              {privateClientData.user && privateClientData.user[0].lastName}
            </p>
          </div>
          <div className="flex-column width-auto">
            <p className="main-font-light font-size-14 font-color-grey">
              Voornaam
            </p>
            <p
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              style={{ border: 'none' }}
            >
              {privateClientData.user && privateClientData.user[0].firstName}
            </p>
          </div>
          <div className="flex-row width-auto horizontal-between">
            <div className="flex-column width-60-p">
              <span className="main-font-regular font-color-grey margin-top-10">
                Adres
              </span>
              <p
                className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
                style={{ border: 'none' }}
              >
                {privateClientData.company?.companyAddress}
              </p>
            </div>
            <div className="flex-column width-19-p">
              <span className="main-font-regular font-color-grey margin-top-10">
                Nr
              </span>
              <p
                className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
                style={{ border: 'none' }}
              >
                {privateClientData.company?.no}
              </p>
            </div>
            <div className="flex-column width-19-p">
              <span className="main-font-regular font-color-grey margin-top-10">
                Bus
              </span>
              <p
                className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
                style={{ border: 'none' }}
              >
                {privateClientData.company?.bus}
              </p>
            </div>
          </div>
          <div className="flex-row width-auto horizontal-between">
            <div className="flex-column width-29-p">
              <span className="main-font-regular font-color-grey margin-top-10">
                Postcode
              </span>
              <p
                className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
                style={{ border: 'none' }}
              >
                {privateClientData.company?.postalCode}
              </p>
            </div>
            <div className="flex-column width-69-p">
              <span className="main-font-regular font-color-grey margin-top-10">
                Gemeente
              </span>
              <p
                className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
                style={{ border: 'none' }}
              >
                {privateClientData.company?.city}
              </p>
            </div>
          </div>
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">
              Telefoon
            </p>
            <p
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              style={{ border: 'none' }}
            >
              {privateClientData.user && privateClientData.user[0].phone}
            </p>
          </div>
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">
              Profession
            </p>
            <p
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              style={{ border: 'none' }}
            >
              {privateClientData.user && privateClientData.user[0].profession}
            </p>
          </div>
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">
              E-mail
            </p>
            <p
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              style={{ border: 'none' }}
            >
              {privateClientData.user && privateClientData.user[0].email}
            </p>
          </div>
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">
              Wachtwoord
            </p>
            <input
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              type="password"
              value={
                privateClientData.user && privateClientData.user[0].password
              }
              disabled
              style={{ border: 'none' }}
            />
          </div>
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">
              Gebruikersgroep
            </p>
            <p
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              style={{ border: 'none' }}
            >
              {privateClientData.user &&
                (privateClientData.user[0].roleId === 1
                  ? 'Verkopen & Verhuren'
                  : privateClientData.user[0].roleId === 2
                  ? 'Bouwen & Verbouwen'
                  : privateClientData.user[0].roleId === 3
                  ? 'Admin'
                  : privateClientData.user[0].roleId === 4
                  ? 'Superadmin'
                  : privateClientData.user[0].roleId === 6
                  ? 'Externe'
                  : '-')}
            </p>
          </div>
        </Grid>
        <div className="private-client-div-save font-size-14 margin-top-20">
          <button
            className="view-client-edit-button font-color-grey"
            onClick={() => {
              setViewState(false)
            }}
          >
            <img src={EditIcon} className="private-client-check-icon" alt="" />
            Edit
          </button>
        </div>
      </div>
    </Grid>
  )
}

ViewPrivateClient.propTypes = {
  setContactId: PropTypes.func,
  setCompanyId: PropTypes.func,
  companyId: PropTypes.number,
  setShowService: PropTypes.func,
  setPopupState: PropTypes.func,
  setPopupMessageState: PropTypes.func,
}

ViewPrivateClient.defaultProps = {
  setCompanyId: function () {},
  setContactId: function () {},
  setShowService: function () {},
  companyId: 0,
  setPopupState: function () {},
  setPopupMessageState: function () {},
}
