import React, {
  useState,
  useEffect,
  createRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Grid } from '@material-ui/core'
import { CustomInput } from '../../../shared_components/custom_input/custom_input'
import { CustomDateInput } from '../../../shared_components/custom_date_input/custom_date_input'
import { CustomCheckBox } from '../../../shared_components/custom_checkbox/custom_checkbox'
import Popup from '../../../shared_components/custom_popup/custom_popup'
import axios from 'axios'
const isScreenSmall = window.screen.width < 850 ? true : false

const PersonDetails = (props) => {
  const {
    personDetails,
    personDetailsRef,
    setPersonDetailsRef,
    hasError,
    isButtonClick,
    inputDisableState,
  } = props
  const [showMore, setShowMore] = useState(true)

  useEffect(() => {
    const tempArray = []
    Array(10)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setPersonDetailsRef(tempArray)
  }, [])

  useEffect(() => {}, [isButtonClick, personDetails])

  return (
    <Grid container>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          {hasError && (
            <img
              alt="alert"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/alert.svg')}
            />
          )}
        </Grid>
        <Grid container xs={11} sm={11} md={11}>
          <p className="epb-service-Category-heading-label vietnam-regular">
            Gegevens aangifteplichtige(n)
            <img
              alt="arrow"
              className="margin-left-5 epb-service-Category-heading-arrow-size "
              src={require(`../../../../assets/svg/icon/${
                showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
              }`)}
              onClick={() => {
                setShowMore(!showMore)
              }}
            />
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        className={`animatable-400 fade-in-out-quart`}
        style={{
          height: showMore ? (isScreenSmall ? '900px' : '320px') : '10px',
          opacity: showMore ? 1 : 0,
        }}
      >
        {showMore && (
          <Grid container>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid xs={1} sm={1} md={1} />
              <Grid container xs={11} sm={11} md={11}>
                <Grid container md={3} direction="column">
                  <CustomInput
                    label="Naam / voornaam"
                    value={personDetails.name_1}
                    name={personDetails.name_1}
                    ref={personDetailsRef[0]}
                    disabled={inputDisableState}
                  />
                </Grid>
                <Grid container md={3} direction="column">
                  <CustomInput
                    label="Rijksregisternummer"
                    value={personDetails.national_Insurance_number_1}
                    name={personDetails.national_Insurance_number_1}
                    ref={personDetailsRef[1]}
                    disabled={inputDisableState}
                  />
                </Grid>
                <Grid container md={3} direction="column">
                  <CustomInput
                    label="Adres"
                    value={personDetails.address_1}
                    name={personDetails.address_1}
                    ref={personDetailsRef[2]}
                    disabled={inputDisableState}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid xs={1} sm={1} md={1} />
              <Grid container xs={11} sm={11} md={11}>
                <Grid container md={3} direction="column">
                  <CustomInput
                    label="Naam / voornaam"
                    value={personDetails.name_2}
                    name={personDetails.name_2}
                    ref={personDetailsRef[3]}
                    disabled={inputDisableState}
                  />
                </Grid>
                <Grid container md={3} direction="column">
                  <CustomInput
                    label="Rijksregisternummer"
                    value={personDetails.national_Insurance_number_2}
                    name={personDetails.national_Insurance_number_2}
                    ref={personDetailsRef[4]}
                    disabled={inputDisableState}
                  />
                </Grid>
                <Grid container md={3} direction="column">
                  <CustomInput
                    label="Adres"
                    value={personDetails.address_2}
                    name={personDetails.address_2}
                    ref={personDetailsRef[5]}
                    disabled={inputDisableState}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid xs={1} sm={1} md={1} />
              <Grid container xs={11} sm={11} md={11}>
                <Grid container md={3} direction="column">
                  <CustomInput
                    label="Bedrijfsnaam + zaakvoerder"
                    ref={personDetailsRef[6]}
                    value={personDetails.company_name_of_manager}
                    disabled={inputDisableState}
                  />
                </Grid>
                <Grid container md={3} direction="column">
                  <CustomInput
                    label="Adres hoofdzetel"
                    ref={personDetailsRef[7]}
                    value={personDetails.head_office_address}
                    disabled={inputDisableState}
                  />
                </Grid>
                <Grid container md={3} direction="column">
                  <CustomInput
                    label="Rechtsvorm"
                    ref={personDetailsRef[8]}
                    value={personDetails.legal_form}
                    disabled={inputDisableState}
                  />
                </Grid>
                <Grid container md={3} direction="column">
                  <CustomInput
                    label="KBO-nummer"
                    ref={personDetailsRef[9]}
                    value={personDetails.kbo_number}
                    disabled={inputDisableState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const BuildingApplicationData = (props) => {
  const {
    buildingApplicationData,
    buildingApplicationRef,
    setBuildingApplicationRef,
    hasError,
    inputDisableState,
  } = props
  const [showMore, setShowMore] = useState(true)
  useEffect(() => {
    const tempArray = []
    Array(3)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setBuildingApplicationRef(tempArray)
  }, [])
  return (
    <Grid container>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          {hasError && (
            <img
              alt="alert"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/alert.svg')}
            />
          )}
        </Grid>
        <Grid container xs={11} sm={11} md={11}>
          <p className="epb-service-Category-heading-label vietnam-regular">
            Gegevens bouwaanvraag
            <img
              alt="arrow"
              className="margin-left-5 epb-service-Category-heading-arrow-size"
              src={require(`../../../../assets/svg/icon/${
                showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
              }`)}
              onClick={() => {
                setShowMore(!showMore)
              }}
            />
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        className={`animatable-400 fade-in-out-quart`}
        style={{
          height: showMore ? (isScreenSmall ? '270px' : '120px') : '10px',
          opacity: showMore ? 1 : 0,
        }}
      >
        {showMore && (
          <Grid container alignItems="center">
            <Grid xs={1} sm={1} md={1} />
            <Grid container xs={11} sm={11} md={11}>
              <Grid container md={3} direction="column">
                <CustomDateInput
                  label="Datum bouwaanvraag"
                  ref={buildingApplicationRef[0]}
                  value={buildingApplicationData.date_of_building_application}
                  disabled={inputDisableState}
                />
              </Grid>
              <Grid container md={3} direction="column">
                <CustomDateInput
                  label="Datum goedkeuring"
                  ref={buildingApplicationRef[1]}
                  value={buildingApplicationData.date_of_approval}
                  disabled={inputDisableState}
                />
              </Grid>
              <Grid container md={3} direction="column">
                <CustomInput
                  label="EPB-of OMV nummer"
                  ref={buildingApplicationRef[2]}
                  value={buildingApplicationData.epb_or_ovm_number}
                  disabled={inputDisableState}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const VentilationPreliminaryDesign = (props) => {
  const {
    setVentilationPreliminaryDesign,
    ventilationDesignRef,
    setVentilationDesignRef,
    hasError,
    inputDisableState,
  } = props

  const [showMore, setShowMore] = useState(true)

  useEffect(() => {
    const tempArray = []
    Array(3)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setVentilationDesignRef(tempArray)
  }, [])

  function disableAllButtons() {
    ventilationDesignRef[0].current.disableButton()
    ventilationDesignRef[1].current.disableButton()
    ventilationDesignRef[2].current.disableButton()
  }

  return (
    <Grid container>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          {hasError && (
            <img
              alt="alert"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/alert.svg')}
            />
          )}
        </Grid>
        <Grid container xs={11} sm={11} md={11}>
          <p className="epb-service-Category-heading-label vietnam-regular">
            Gegevens bouwaanvraag
            <img
              alt="arrow"
              className="margin-left-5 epb-service-Category-heading-arrow-size"
              src={require(`../../../../assets/svg/icon/${
                showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
              }`)}
              onClick={() => {
                setShowMore(!showMore)
              }}
            />
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid
        container
        className={`animatable-400 fade-in-out-quart`}
        style={{
          height: showMore ? (isScreenSmall ? '50px' : '50px') : '10px',
          opacity: showMore ? 1 : 0,
        }}
      >
        {showMore && (
          <Grid container alignItems="center">
            <Grid xs={1} sm={1} md={1} />
            <Grid container xs={11} sm={11} md={11}>
              <Grid>
                <CustomCheckBox
                  label="Ja"
                  ref={ventilationDesignRef[0]}
                  value={true}
                  disableAllButtons={disableAllButtons}
                  disabled={inputDisableState}
                />
              </Grid>
              <Grid>
                <div className="margin-right-20" />
              </Grid>
              <Grid>
                <CustomCheckBox
                  label="Nee, ander ventilatiesysteem"
                  ref={ventilationDesignRef[1]}
                  disableAllButtons={disableAllButtons}
                  value={false}
                  disabled={inputDisableState}
                />
              </Grid>
              <Grid>
                <div className="margin-right-20" />
              </Grid>
              <Grid>
                <CustomCheckBox
                  label="Nee, wacht tot het definitieve ventilatiesysteem bekend is"
                  ref={ventilationDesignRef[2]}
                  disableAllButtons={disableAllButtons}
                  value={false}
                  disabled={inputDisableState}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const EpbWishes = (props) => {
  const {
    epbWishes,
    setEpbWishes,
    epbWishesRef,
    setEpbWishesRef,
    hasError,
    showMore,
    setShowMore,
    inputDisableState,
  } = props

  useEffect(() => {
    const tempArray = []
    Array(3)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setEpbWishesRef(tempArray)
  }, [])

  function disableAllButtons() {
    epbWishesRef[0].current.disableButton()
    epbWishesRef[1].current.disableButton()
  }

  return (
    <Grid container>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          {hasError && (
            <img
              alt="alert"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/alert.svg')}
            />
          )}
        </Grid>
        <Grid container xs={11} sm={11} md={11}>
          <p className="epb-service-Category-heading-label vietnam-regular">
            EPB-wensen
            <img
              alt="arrow"
              className="margin-left-5 epb-service-Category-heading-arrow-size"
              src={require(`../../../../assets/svg/icon/${
                showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
              }`)}
              onClick={() => {
                setShowMore(!showMore)
              }}
            />
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid
        container
        className={`animatable-400 fade-in-out-quart`}
        style={{
          height: showMore ? (isScreenSmall ? '50px' : '50px') : '10px',
          opacity: showMore ? 1 : 0,
        }}
      >
        {showMore && (
          <Grid container alignItems="center">
            <Grid xs={1} sm={1} md={1} />
            <Grid container xs={11} sm={11} md={11}>
              <Grid>
                <CustomCheckBox
                  label="Volgens bouwkundig plan"
                  ref={epbWishesRef[0]}
                  disableAllButtons={disableAllButtons}
                  value={epbWishes.according_to_architectural_plan}
                  disabled={inputDisableState}
                />
              </Grid>
              <Grid>
                <div className="margin-right-20" />
              </Grid>
              <Grid>
                <CustomCheckBox
                  label="Graag voorstel van EPB-verslaggever"
                  ref={epbWishesRef[1]}
                  disableAllButtons={disableAllButtons}
                  value={epbWishes.please_suggest_an_EPB_reporter}
                  disabled={inputDisableState}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const Insulations = forwardRef((props, ref) => {
  const { value, disabled } = props
  const [insulationRef, setInsulationRef] = useState([])
  const [insulations, setInsulations] = useState({
    structure: '',
    type: '',
    thickness: '',
  })
  useEffect(() => {
    const tempArray = []
    Array(3)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setInsulationRef(tempArray)
    if (value !== undefined) {
      insulations.structure = value.structure
      insulations.type = value.type
      insulations.thickness = value.thickness
      setInsulations(insulations)
    }
  }, [value])

  useImperativeHandle(ref, () => ({
    getInsulations() {
      insulations.structure = insulationRef[0].current.getValue()
      insulations.type = insulationRef[1].current.getValue()
      insulations.thickness = insulationRef[2].current.getValue()
      return {
        structure: insulationRef[0].current.getValue(),
        type: insulationRef[1].current.getValue(),
        thickness: insulationRef[2].current.getValue(),
      }
    },
    areFieldsFilled() {
      return (
        insulationRef[0].current.getValue() !== '' &&
        insulationRef[1].current.getValue() !== '' &&
        insulationRef[2].current.getValue() !== ''
      )
    },
  }))

  return (
    <Grid container>
      <Grid container md={3} direction="column">
        <CustomInput
          label="Opbouw"
          ref={insulationRef[0]}
          value={insulations.structure}
          disabled={disabled}
        />
      </Grid>
      <Grid container md={3} direction="column">
        <CustomInput
          label="Type"
          ref={insulationRef[1]}
          value={insulations.type}
          disabled={disabled}
        />
      </Grid>
      <Grid container md={3} direction="column">
        <CustomInput
          label="Dikte"
          ref={insulationRef[2]}
          value={insulations.thickness}
          type="number"
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
})

const InsulationMaterials = (props) => {
  const {
    insulationMaterials,
    insulationMaterialRef,
    setInsulationMaterialRef,
    hasError,
    inputDisableState,
  } = props

  const [showMore, setShowMore] = useState(true)

  useEffect(() => {
    const tempArray = []
    Array(13)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setInsulationMaterialRef(tempArray)
  }, [])
  return (
    <Grid container>
      <Grid container alignItems="center">
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          {hasError && (
            <img
              alt="alert"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/alert.svg')}
            />
          )}
        </Grid>
        <Grid className="epb-service-Category-heading center-content padding-left-5 padding-right-5">
          <p className="epb-service-Category-heading-label vietnam-regular">
            Isolatiematerialen
            <img
              alt="arrow"
              className="margin-left-5 epb-service-Category-heading-arrow-size"
              src={require(`../../../../assets/svg/icon/${
                showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
              }`)}
              onClick={() => {
                setShowMore(!showMore)
              }}
            />
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid
        container
        className={`animatable-400 fade-in-out-quart`}
        style={{
          height: showMore ? (isScreenSmall ? '3500px' : '1450px') : '10px',
          opacity: showMore ? 1 : 0,
        }}
      >
        {showMore && (
          <Grid container>
            <Grid container alignItems="center">
              <Grid container md={1} xs={1} sm={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container md={11} xs={11} sm={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Vloerisolatie
                  </p>
                </Grid>
                <Insulations
                  ref={insulationMaterialRef[0]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[0].insulations[0]
                  }
                  disabled={inputDisableState}
                />
                <Insulations
                  ref={insulationMaterialRef[1]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[0].insulations[1]
                  }
                  disabled={inputDisableState}
                />
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Tussenvloerisolatie
                  </p>
                </Grid>
                <Insulations
                  ref={insulationMaterialRef[2]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[1].insulations[0]
                  }
                  disabled={inputDisableState}
                />
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Vloerisolatie kelder
                  </p>
                </Grid>
                <Insulations
                  ref={insulationMaterialRef[3]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[2].insulations[0]
                  }
                  disabled={inputDisableState}
                />
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Muurisolatie
                  </p>
                </Grid>
                <Insulations
                  ref={insulationMaterialRef[4]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[3].insulations[0]
                  }
                  disabled={inputDisableState}
                />
                <Insulations
                  ref={insulationMaterialRef[5]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[3].insulations[1]
                  }
                  disabled={inputDisableState}
                />
                <Insulations
                  ref={insulationMaterialRef[6]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[3].insulations[2]
                  }
                  disabled={inputDisableState}
                />
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Muurisolatie kelder
                  </p>
                </Grid>
                <Insulations
                  ref={insulationMaterialRef[7]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[4].insulations[0]
                  }
                  disabled={inputDisableState}
                />
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Scheidingsmuurisolatie
                  </p>
                </Grid>
                <Insulations
                  ref={insulationMaterialRef[8]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[5].insulations[0]
                  }
                  disabled={inputDisableState}
                />
                <Insulations
                  ref={insulationMaterialRef[9]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[5].insulations[1]
                  }
                  disabled={inputDisableState}
                />
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Dakisolatie - Hellend dak
                  </p>
                </Grid>
                <Insulations
                  ref={insulationMaterialRef[10]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[6].insulations[0]
                  }
                  disabled={inputDisableState}
                />
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Dakisolatie - Plat dak
                  </p>
                </Grid>
                <Insulations
                  ref={insulationMaterialRef[11]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[7].insulations[0]
                  }
                  disabled={inputDisableState}
                />
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Isolatie oversteek
                  </p>
                </Grid>
                <Insulations
                  ref={insulationMaterialRef[12]}
                  value={
                    insulationMaterials.length > 0 &&
                    insulationMaterials[8].insulations[0]
                  }
                  disabled={inputDisableState}
                />
              </Grid>
              <Grid container>
                <div className="margin-top-10" />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const Options = forwardRef((props, ref) => {
  const { optionsValue, disabled } = props
  const [optionsRef, setOptionsRef] = useState([])
  const [options, setOptions] = useState({
    pvc: false,
    wood: true,
    aluminium: true,
  })
  useEffect(() => {
    const tempArray = []
    Array(3)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setOptionsRef(tempArray)
    if (
      optionsValue !== undefined &&
      optionsValue !== null &&
      optionsValue !== ''
    ) {
      options.pvc = optionsValue.pvc
      options.wood = optionsValue.wood
      options.aluminium = optionsValue.aluminium
      setOptions(options)
    }
  }, [optionsValue])

  useImperativeHandle(ref, () => ({
    getOptions() {
      return {
        pvc: optionsRef[0].current.getValue(),
        wood: optionsRef[1].current.getValue(),
        aluminium: optionsRef[2].current.getValue(),
      }
    },
  }))
  function disableAllButtons() {
    optionsRef[0].current.disableButton()
    optionsRef[1].current.disableButton()
    optionsRef[2].current.disableButton()
  }
  return (
    <Grid container>
      <Grid>
        <CustomCheckBox
          label="PVC"
          ref={optionsRef[0]}
          disableAllButtons={disableAllButtons}
          value={options.pvc}
          disabled={disabled}
        />
      </Grid>
      <Grid>
        <div className="margin-right-20" />
      </Grid>
      <Grid>
        <CustomCheckBox
          label="Hout"
          ref={optionsRef[1]}
          disableAllButtons={disableAllButtons}
          value={options.wood}
          disabled={disabled}
        />
      </Grid>
      <Grid>
        <div className="margin-right-20" />
      </Grid>
      <Grid>
        <CustomCheckBox
          label="Aluminium"
          ref={optionsRef[2]}
          disableAllButtons={disableAllButtons}
          value={options.aluminium}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
})

const JoineryDetails = forwardRef((props, ref) => {
  const { value, disabled } = props
  const [joineryDetailsRef, setJoineryDetailsRef] = useState([])
  useEffect(() => {
    const tempArray = []
    Array(3)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setJoineryDetailsRef(tempArray)
    if (value !== undefined && value !== '' && value !== null) {
      details.brand = value.brand
      details.type = value.type
      setDetails(details)
    }
  }, [value])
  const [details, setDetails] = useState({
    brand: '',
    type: '',
  })
  useImperativeHandle(ref, () => ({
    getDetails() {
      return {
        brand: joineryDetailsRef[0].current.getValue(),
        type: joineryDetailsRef[1].current.getValue(),
      }
    },
  }))
  return (
    <Grid container>
      <Grid container md={6} direction="column">
        <CustomInput
          label="Merk"
          ref={joineryDetailsRef[0]}
          value={details.brand}
          disabled={disabled}
        />
      </Grid>
      <Grid container md={6} direction="column">
        <CustomInput
          label="Type"
          ref={joineryDetailsRef[1]}
          value={details.type}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
})

const Joinery = (props) => {
  const {
    joinery,
    joineryDetailsRef,
    setJoineryDetailsRef,
    hasError,
    inputDisableState,
  } = props
  const [showMore, setShowMore] = useState(true)
  useEffect(() => {
    const tempArray = []
    Array(11)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setJoineryDetailsRef(tempArray)
  }, [joinery])

  function disableAllButtons() {
    joineryDetailsRef[3].current.disableButton()
    joineryDetailsRef[4].current.disableButton()
    joineryDetailsRef[5].current.disableButton()
  }

  return (
    <Grid container>
      <Grid container alignItems="center">
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          {hasError && (
            <img
              alt="alert"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/alert.svg')}
            />
          )}
        </Grid>
        <Grid className="epb-service-Category-heading center-content padding-left-5 padding-right-5">
          <p className="epb-service-Category-heading-label vietnam-regular">
            Schrijnwerk
            <img
              alt="arrow"
              className="margin-left-5 epb-service-Category-heading-arrow-size"
              src={require(`../../../../assets/svg/icon/${
                showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
              }`)}
              onClick={() => {
                setShowMore(!showMore)
              }}
            />
          </p>
        </Grid>
      </Grid>

      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid
        container
        className={`animatable-400 fade-in-out-quart`}
        style={{
          height: showMore ? (isScreenSmall ? '1050px' : '700px') : '10px',
          opacity: showMore ? 1 : 0,
        }}
      >
        {showMore && (
          <Grid container>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Ramen
                  </p>
                </Grid>
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Options
                  ref={joineryDetailsRef[0]}
                  optionsValue={{
                    pvc: joinery.length > 0 && joinery[0].windows_PVC,
                    wood: joinery.length > 0 && joinery[0].Windows_wood,
                    aluminium:
                      joinery.length > 0 && joinery[0].windows_aluminium,
                  }}
                  disabled={inputDisableState}
                />
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container>
                  <Grid container md={6}>
                    <JoineryDetails
                      ref={joineryDetailsRef[1]}
                      value={{
                        brand:
                          joinery[0] !== undefined
                            ? joinery[0].windows_brand
                            : '',
                        type:
                          joinery[0] !== undefined
                            ? joinery[0].windows_type
                            : '',
                      }}
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid container md={3} direction="column">
                    <CustomInput
                      label="Glas"
                      ref={joineryDetailsRef[2]}
                      value={joinery.length > 0 ? joinery[0].windows_glass : ''}
                      type="number"
                      disabled={inputDisableState}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container>
                  <Grid>
                    <CustomCheckBox
                      label="Alle ramenvernieuwd"
                      ref={joineryDetailsRef[3]}
                      disableAllButtons={disableAllButtons}
                      value={
                        joinery.length > 0 &&
                        joinery[0].windows_all_windows_renewed
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Sommige ramen vernieuwd"
                      ref={joineryDetailsRef[4]}
                      disableAllButtons={disableAllButtons}
                      value={
                        joinery.length > 0 &&
                        joinery[0].windows_some_windows_renewed
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Geen ramen vernieuwd"
                      ref={joineryDetailsRef[5]}
                      disableAllButtons={disableAllButtons}
                      value={
                        joinery.length > 0 &&
                        joinery[0].windows_no_windows_renewed
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Dakvenster
                  </p>
                </Grid>
                <Grid container md={6}>
                  <JoineryDetails
                    ref={joineryDetailsRef[6]}
                    value={{
                      brand:
                        joinery.length > 0 ? joinery[0].roof_window_brand : '',
                      type:
                        joinery.length > 0 ? joinery[0].roof_window_type : '',
                    }}
                    disabled={inputDisableState}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Buitendeuren
                  </p>
                </Grid>
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Options
                  ref={joineryDetailsRef[7]}
                  optionsValue={{
                    pvc: joinery.length > 0 && joinery[0].exterior_doors_PVC,
                    wood: joinery.length > 0 && joinery[0].exterior_doors_Wood,
                    aluminium:
                      joinery.length > 0 && joinery[0].exterior_doors_aluminium,
                  }}
                  disabled={inputDisableState}
                />
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container md={6}>
                  <JoineryDetails
                    ref={joineryDetailsRef[8]}
                    value={{
                      brand:
                        joinery.length > 0
                          ? joinery[0].exterior_doors_brand
                          : '',
                      type:
                        joinery.length > 0
                          ? joinery[0].exterior_doors_type
                          : '',
                    }}
                    disabled={inputDisableState}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line " />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Poort
                  </p>
                </Grid>
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Options
                  ref={joineryDetailsRef[9]}
                  optionsValue={{
                    pvc: joinery.length > 0 && joinery[0].gate_PVC,
                    wood: joinery.length > 0 && joinery[0].gate_wood,
                    aluminium: joinery.length > 0 && joinery[0].gate_aluminium,
                  }}
                  disabled={inputDisableState}
                />
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container md={6}>
                  <JoineryDetails
                    ref={joineryDetailsRef[10]}
                    value={{
                      brand: joinery.length > 0 ? joinery[0].gate_brand : '',
                      type: joinery.length > 0 ? joinery[0].gate_type : '',
                    }}
                    disabled={inputDisableState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const Ventilation = (props) => {
  const {
    ventilations,
    ventilationRef,
    setVentilationRef,
    hasError,
    inputDisableState,
  } = props
  const [showMore, setShowMore] = useState(true)
  useEffect(() => {
    const tempArray = []
    Array(7)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setVentilationRef(tempArray)
  }, [])

  function disableAllButtons() {
    ventilationRef[0].current.disableButton()
    ventilationRef[1].current.disableButton()
    ventilationRef[2].current.disableButton()
    ventilationRef[3].current.disableButton()
  }

  return (
    <Grid container>
      <Grid container alignItems="center">
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          {hasError && (
            <img
              alt="alert"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/alert.svg')}
            />
          )}
        </Grid>
        <Grid className="epb-service-Category-heading center-content padding-left-5 padding-right-5">
          <p className="epb-service-Category-heading-label vietnam-regular">
            Ventilatie
            <img
              alt="arrow"
              className="margin-left-5 epb-service-Category-heading-arrow-size"
              src={require(`../../../../assets/svg/icon/${
                showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
              }`)}
              onClick={() => {
                setShowMore(!showMore)
              }}
            />
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        className={`animatable-400 fade-in-out-quart`}
        style={{
          height: showMore ? (isScreenSmall ? '300px' : '150px') : '10px',
          opacity: showMore ? 1 : 0,
        }}
      >
        {showMore && (
          <Grid container>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container>
                  <Grid>
                    <CustomCheckBox
                      label="Systeem A"
                      ref={ventilationRef[0]}
                      disableAllButtons={disableAllButtons}
                      value={
                        ventilations.length > 0 && ventilations[0].system_A
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Systeem B"
                      ref={ventilationRef[1]}
                      disableAllButtons={disableAllButtons}
                      value={
                        ventilations.length > 0 && ventilations[0].system_B
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Systeem C+"
                      ref={ventilationRef[2]}
                      disableAllButtons={disableAllButtons}
                      value={
                        ventilations.length > 0 && ventilations[0].system_C
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Systeem D"
                      ref={ventilationRef[3]}
                      disableAllButtons={disableAllButtons}
                      value={
                        ventilations.length > 0 && ventilations[0].system_D
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container>
                  <Grid container md={3} direction="column">
                    <CustomInput
                      label="Merk"
                      ref={ventilationRef[4]}
                      value={
                        ventilations.length > 0 ? ventilations[0].brand : ''
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid container md={3} direction="column">
                    <CustomInput
                      label="Type"
                      ref={ventilationRef[5]}
                      value={
                        ventilations.length > 0 ? ventilations[0].type : ''
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid container md={3} direction="column">
                    <CustomInput
                      label="Reductiefactor:"
                      ref={ventilationRef[6]}
                      value={
                        ventilations.length > 0
                          ? ventilations[0].reductiefactor
                          : ''
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const Heating = (props) => {
  const { heating, heatingRef, setHeatingRef, hasError, inputDisableState } =
    props
  const [showMore, setShowMore] = useState(true)

  useEffect(() => {
    const tempArray = []
    Array(14)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setHeatingRef(tempArray)
  }, [])

  function disableAllButtonsFirst() {
    heatingRef[0].current.disableButton()
    heatingRef[1].current.disableButton()
    heatingRef[2].current.disableButton()
  }

  function disableAllButtonsSecond() {
    heatingRef[4].current.disableButton()
    heatingRef[5].current.disableButton()
    heatingRef[6].current.disableButton()
  }

  function disableAllButtonsThird() {
    heatingRef[9].current.disableButton()
    heatingRef[10].current.disableButton()
  }

  function disableAllButtonsFourth() {
    heatingRef[11].current.disableButton()
    heatingRef[12].current.disableButton()
  }

  return (
    <Grid container>
      <Grid container alignItems="center">
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          {hasError && (
            <img
              alt="alert"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/alert.svg')}
            />
          )}
        </Grid>
        <Grid className="epb-service-Category-heading center-content padding-left-5 padding-right-5">
          <p className="epb-service-Category-heading-label vietnam-regular">
            Verwarming
            <img
              alt="arrow"
              className="margin-left-5 epb-service-Category-heading-arrow-size"
              src={require(`../../../../assets/svg/icon/${
                showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
              }`)}
              onClick={() => {
                setShowMore(!showMore)
              }}
            />
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        className={`animatable-400 fade-in-out-quart`}
        style={{
          height: showMore ? (isScreenSmall ? '800px' : '600px') : '10px',
          opacity: showMore ? 1 : 0,
        }}
      >
        {showMore && (
          <Grid container>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container>
                  <Grid>
                    <CustomCheckBox
                      label="Gas"
                      ref={heatingRef[0]}
                      disableAllButtons={disableAllButtonsFirst}
                      value={heating.length > 0 && heating[0].gas}
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Mazout"
                      ref={heatingRef[1]}
                      disableAllButtons={disableAllButtonsFirst}
                      value={heating.length > 0 && heating[0].fuel_oil}
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Andere"
                      ref={heatingRef[2]}
                      disableAllButtons={disableAllButtonsFirst}
                      value={heating.length > 0 && heating[0].others_1}
                      disabled={inputDisableState}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container md={3}>
                  <CustomInput
                    label="Andere"
                    ref={heatingRef[3]}
                    value={heating.length > 0 ? heating[0].others_text_1 : ''}
                    disabled={inputDisableState}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container>
                  <Grid>
                    <CustomCheckBox
                      label="Klassieke CV"
                      ref={heatingRef[4]}
                      disableAllButtons={disableAllButtonsSecond}
                      value={heating.length > 0 && heating[0].classic_CV}
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Warmtepomp"
                      ref={heatingRef[5]}
                      disableAllButtons={disableAllButtonsSecond}
                      value={heating.length > 0 && heating[0].heat_pump}
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Andere"
                      ref={heatingRef[6]}
                      disableAllButtons={disableAllButtonsSecond}
                      value={heating.length > 0 && heating[0].others_2}
                      disabled={inputDisableState}
                    />
                  </Grid>
                </Grid>
                <Grid container md={3}>
                  <CustomInput
                    label="Andere"
                    ref={heatingRef[7]}
                    value={heating.length > 0 ? heating[0].others_text_2 : ''}
                    disabled={inputDisableState}
                  />
                </Grid>
                <Grid container md={6}>
                  <JoineryDetails
                    ref={heatingRef[8]}
                    value={{
                      brand: heating.length > 0 ? heating[0].brand_1 : '',
                      type: heating.length > 0 ? heating[0].type_1 : '',
                    }}
                    disabled={inputDisableState}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container>
                  <Grid>
                    <CustomCheckBox
                      label="Radiatoren"
                      ref={heatingRef[9]}
                      disableAllButtons={disableAllButtonsThird}
                      value={heating.length > 0 && heating[0].radiators}
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Vloerverwarming"
                      ref={heatingRef[10]}
                      disableAllButtons={disableAllButtonsThird}
                      value={
                        heating.length > 0 && heating[0].underfloor_heating
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <p className="epb-service-insulation-category-name vietnam-regular">
                    Indien renovatie, wordt de bestaande CV-ketel behouden?
                  </p>
                </Grid>
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container>
                  <Grid>
                    <CustomCheckBox
                      label="Ja"
                      ref={heatingRef[11]}
                      disableAllButtons={disableAllButtonsFourth}
                      value={heating.length > 0 && heating[0].yes}
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Nee"
                      ref={heatingRef[12]}
                      disableAllButtons={disableAllButtonsFourth}
                      value={heating.length > 0 && heating[0].no}
                      disabled={inputDisableState}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container md={6}>
                  <JoineryDetails
                    ref={heatingRef[13]}
                    value={
                      heating.length > 0
                        ? { brand: heating[0].brand_2, type: heating[0].type_2 }
                        : ''
                    }
                    disabled={inputDisableState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const RenewableEnergy = (props) => {
  const {
    renewableEnergy,
    renewableEnergyRef,
    setRenewableEnergyRef,
    hasError,
    inputDisableState,
  } = props
  const [showMore, setShowMore] = useState(true)

  useEffect(() => {
    const tempArray = []
    Array(4)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setRenewableEnergyRef(tempArray)
  }, [])

  function disableAllButtons() {
    renewableEnergyRef[0].current.disableButton()
    renewableEnergyRef[1].current.disableButton()
    renewableEnergyRef[2].current.disableButton()
  }

  return (
    <Grid container>
      <Grid container alignItems="center">
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          {hasError && (
            <img
              alt="alert"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/alert.svg')}
            />
          )}
        </Grid>
        <Grid className="epb-service-Category-heading center-content padding-left-5 padding-right-5">
          <p className="epb-service-Category-heading-label vietnam-regular">
            Hernieuwbare energie
            <img
              alt="arrow"
              className="margin-left-5 epb-service-Category-heading-arrow-size"
              src={require(`../../../../assets/svg/icon/${
                showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
              }`)}
              onClick={() => {
                setShowMore(!showMore)
              }}
            />
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        className={`animatable-400 fade-in-out-quart`}
        style={{
          height: showMore ? (isScreenSmall ? '120px' : '120px') : '10px',
          opacity: showMore ? 1 : 0,
        }}
      >
        {showMore && (
          <Grid container className="fade-in-translate animatable-100">
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container alignItems="center">
              <Grid container xs={1} sm={1} md={1} className="height-auto">
                <div className="epb-service-insulation-start-line" />
              </Grid>
              <Grid container xs={11} sm={11} md={11} direction="column">
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container>
                  <Grid>
                    <CustomCheckBox
                      label="Zonneboiler"
                      ref={renewableEnergyRef[0]}
                      disableAllButtons={disableAllButtons}
                      value={
                        renewableEnergy.length > 0 &&
                        renewableEnergy[0].solar_water_heater
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Zonnepanelen"
                      ref={renewableEnergyRef[1]}
                      disableAllButtons={disableAllButtons}
                      value={
                        renewableEnergy.length > 0 &&
                        renewableEnergy[0].solar_panels
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                  <Grid>
                    <div className="margin-right-20" />
                  </Grid>
                  <Grid>
                    <CustomCheckBox
                      label="Wamtepomp"
                      ref={renewableEnergyRef[2]}
                      disableAllButtons={disableAllButtons}
                      value={
                        renewableEnergy.length > 0 &&
                        renewableEnergy[0].heat_pump
                      }
                      disabled={inputDisableState}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
                <Grid container md={3}>
                  <CustomInput
                    label="Hoeveel panelen"
                    ref={renewableEnergyRef[3]}
                    value={
                      renewableEnergy.length > 0
                        ? renewableEnergy[0].how_many_panals
                        : ''
                    }
                    type="number"
                    disabled={inputDisableState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default function StepOne(props) {
  const {
    token,
    isButtonClick,
    projectId,
    projectServiceId,
    epbFormId,
    setEpbFormId,
    stepOneData,
    setAdviceReportSectionState,
    userRole,
  } = props
  const [personDetails, setPersonDetails] = useState({})
  const [buildingApplicationData, setBuildingApplicationData] = useState({})
  const [ventilationPreliminaryDesign, setVentilationPreliminaryDesign] =
    useState({})
  const [epbWishes, setEpbWishes] = useState({})

  const [insulationMaterials, setInsulationMaterials] = useState([])
  const [joinery, setJoinery] = useState({})
  const [ventilations, setVentilations] = useState({})
  const [heating, setHeating] = useState({})
  const [renewableEnergy, setRenewableEnergy] = useState({})
  const [personDetailsRef, setPersonDetailsRef] = useState([])
  const [buildingApplicationRef, setBuildingApplicationRef] = useState([])
  const [ventilationDesignRef, setVentilationDesignRef] = useState([])
  const [epbWishesRef, setEpbWishesRef] = useState([])
  const [insulationMaterialRef, setInsulationMaterialRef] = useState([])
  const [joineryDetailsRef, setJoineryDetailsRef] = useState([])
  const [ventilationRef, setVentilationRef] = useState([])
  const [heatingRef, setHeatingRef] = useState([])
  const [renewableEnergyRef, setRenewableEnergyRef] = useState([])
  const [showPopupState, setShowPopupState] = useState(false)
  const [popupMessageState, setPopupMessageState] = useState({})
  const [errorStateList, setErrorListState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])
  const [forceUpdateState, setForceUpdateState] = useState(false)
  const [toShowSubsections, setSubsectionState] = useState(true)
  const [inputDisableState, setInputDisableState] = useState(false)

  useEffect(() => {
    if (userRole !== undefined && userRole === 'Extern') {
      setInputDisableState(true)
    }
    if (JSON.stringify(stepOneData) !== '{}') {
      if (stepOneData.details_of_people.length > 0) {
        setPersonDetails(stepOneData.details_of_people[0])
      }
      if (stepOneData.building_application_data.length > 0) {
        setBuildingApplicationData(stepOneData.building_application_data[0])
      }
      if (stepOneData.ebp_wishes.length > 0) {
        setEpbWishes(stepOneData.ebp_wishes[0])
      }
      if (stepOneData.ventilation_preliminary_designs.length > 0) {
        setVentilationPreliminaryDesign(
          stepOneData.ventilation_preliminary_designs[0],
        )
      }
      if (stepOneData.insulation_materials.length > 0) {
        setInsulationMaterials(stepOneData.insulation_materials)
      }
      if (stepOneData.joineries.length > 0) {
        setJoinery(stepOneData.joineries)
      }
      if (stepOneData.ventilations.length > 0) {
        setVentilations(stepOneData.ventilations)
      }
      if (stepOneData.heatings.length > 0) {
        setHeating(stepOneData.heatings)
      }
      if (stepOneData.renewable_energies.length > 0) {
        setRenewableEnergy(stepOneData.renewable_energies)
      }
      // fillErrorList(stepOneData)
      isDataValid(stepOneData)
    }
  }, [stepOneData])

  useEffect(() => {
    let tempPersonDetails = {
      name_1: '',
      national_Insurance_number_1: '',
      address_1: '',
      name_2: '',
      national_Insurance_number_2: '',
      address_2: '',
      company_name_of_manager: '',
      head_office_address: '',
      legal_form: '',
      kbo_number: 0,
    }
    let tempBuildingDetails = {
      date_of_building_application: '',
      date_of_approval: '',
      epb_or_ovm_number: 0,
    }
    let tempVentilationDesign = {
      and: true,
      no_ventilation_system: false,
      no_please_wait_until_the_definitive_ventilation_system_is_known: false,
    }
    let tempEpbWishes = {
      according_to_architectural_plan: '',
      please_suggest_an_EPB_reporter: '',
    }
    let tempInsulationMaterial = [
      { group: 'Floor insulation', insulations: [] },
      { group: 'Interfloor insulation', insulations: [] },
      { group: 'Basement floor insulation', insulations: [] },
      { group: 'Muurisolatie', insulations: [] },
      { group: 'Muurisolatie Cellar', insulations: [] },
      { group: 'Partition wall insulation', insulations: [] },
      { group: 'Roof insulation - Pitched roof', insulations: [] },
      { group: 'Roof insulation - Flat roof', insulations: [] },
      { group: 'Insulation crossing', insulations: [] },
    ]
    let tempJoinery = {
      windows_PVC: '',
      Windows_wood: '',
      windows_aluminium: '',
      windows_brand: '',
      windows_type: '',
      windows_glass: '',
      windows_all_windows_renewed: '',
      windows_some_windows_renewed: '',
      windows_no_windows_renewed: '',
      roof_window_brand: '',
      roof_window_type: '',
      exterior_doors_PVC: '',
      exterior_doors_Wood: '',
      exterior_doors_aluminium: '',
      exterior_doors_brand: '',
      exterior_doors_type: '',
      gate_PVC: '',
      gate_wood: '',
      gate_aluminium: '',
      gate_brand: '',
      gate_type: '',
    }
    let tempVentilations = {
      system_A: '',
      system_B: '',
      system_C: '',
      system_D: '',
      brand: '',
      type: '',
      reductiefactor: '',
    }
    let tempHeating = {
      gas: '',
      fuel_oil: '',
      others_1: '',
      others_text_1: '',
      classic_CV: '',
      heat_pump: '',
      others_2: '',
      others_text_2: '',
      brand_1: '',
      type_1: '',
      radiators: '',
      underfloor_heating: '',
      yes: '',
      no: '',
      brand_2: '',
      type_2: '',
    }
    let tempRenewableEnergy = {
      solar_water_heater: '',
      solar_panels: '',
      heat_pump: '',
      how_many_panals: 0,
    }
    if (personDetailsRef.length > 0) {
      tempPersonDetails.name_1 = personDetailsRef[0].current.getValue()
      tempPersonDetails.national_Insurance_number_1 =
        personDetailsRef[1].current.getValue()
      tempPersonDetails.address_1 = personDetailsRef[2].current.getValue()
      tempPersonDetails.name_2 = personDetailsRef[3].current.getValue()
      tempPersonDetails.national_Insurance_number_2 =
        personDetailsRef[4].current.getValue()
      tempPersonDetails.address_2 = personDetailsRef[5].current.getValue()
      tempPersonDetails.company_name_of_manager =
        personDetailsRef[6].current.getValue()
      tempPersonDetails.head_office_address =
        personDetailsRef[7].current.getValue()
      tempPersonDetails.legal_form = personDetailsRef[8].current.getValue()
      tempPersonDetails.kbo_number = personDetailsRef[9].current.getValue()
      setPersonDetails(personDetails)
    }
    if (buildingApplicationRef.length > 0) {
      tempBuildingDetails.date_of_building_application =
        buildingApplicationRef[0].current.getValue()
      tempBuildingDetails.date_of_approval =
        buildingApplicationRef[1].current.getValue()
      tempBuildingDetails.epb_or_ovm_number =
        buildingApplicationRef[2].current.getValue()
      setBuildingApplicationData(buildingApplicationData)
    }
    if (ventilationDesignRef.length > 0) {
      tempVentilationDesign.and = true
      tempVentilationDesign.no_ventilation_system = false
      tempVentilationDesign.no_please_wait_until_the_definitive_ventilation_system_is_known = false
      setVentilationPreliminaryDesign(ventilationPreliminaryDesign)
    }
    if (epbWishesRef.length > 0) {
      tempEpbWishes.according_to_architectural_plan =
        epbWishesRef[0].current.getValue()
      tempEpbWishes.please_suggest_an_EPB_reporter =
        epbWishesRef[1].current.getValue()
      setEpbWishes(epbWishes)
    }
    if (insulationMaterialRef.length > 0) {
      tempInsulationMaterial[0].insulations.push(
        insulationMaterialRef[0].current.getInsulations(),
      )
      tempInsulationMaterial[0].insulations.push(
        insulationMaterialRef[1].current.getInsulations(),
      )
      tempInsulationMaterial[1].insulations.push(
        insulationMaterialRef[2].current.getInsulations(),
      )
      tempInsulationMaterial[2].insulations.push(
        insulationMaterialRef[3].current.getInsulations(),
      )
      tempInsulationMaterial[3].insulations.push(
        insulationMaterialRef[4].current.getInsulations(),
      )
      tempInsulationMaterial[3].insulations.push(
        insulationMaterialRef[5].current.getInsulations(),
      )
      tempInsulationMaterial[3].insulations.push(
        insulationMaterialRef[6].current.getInsulations(),
      )
      tempInsulationMaterial[4].insulations.push(
        insulationMaterialRef[7].current.getInsulations(),
      )
      tempInsulationMaterial[5].insulations.push(
        insulationMaterialRef[8].current.getInsulations(),
      )
      tempInsulationMaterial[5].insulations.push(
        insulationMaterialRef[9].current.getInsulations(),
      )
      tempInsulationMaterial[6].insulations.push(
        insulationMaterialRef[10].current.getInsulations(),
      )
      tempInsulationMaterial[7].insulations.push(
        insulationMaterialRef[11].current.getInsulations(),
      )
      tempInsulationMaterial[8].insulations.push(
        insulationMaterialRef[12].current.getInsulations(),
      )
      setInsulationMaterials(tempInsulationMaterial)
    }
    if (joineryDetailsRef.length > 0) {
      tempJoinery.windows_PVC = joineryDetailsRef[0].current.getOptions().pvc
      tempJoinery.Windows_wood = joineryDetailsRef[0].current.getOptions().wood
      tempJoinery.windows_aluminium =
        joineryDetailsRef[0].current.getOptions().aluminium
      tempJoinery.windows_brand =
        joineryDetailsRef[1].current.getDetails().brand
      tempJoinery.windows_type = joineryDetailsRef[1].current.getDetails().type
      tempJoinery.windows_glass = joineryDetailsRef[2].current.getValue()
      tempJoinery.windows_all_windows_renewed =
        joineryDetailsRef[3].current.getValue()
      tempJoinery.windows_some_windows_renewed =
        joineryDetailsRef[4].current.getValue()
      tempJoinery.windows_no_windows_renewed =
        joineryDetailsRef[5].current.getValue()
      tempJoinery.roof_window_brand =
        joineryDetailsRef[6].current.getDetails().brand
      tempJoinery.roof_window_type =
        joineryDetailsRef[6].current.getDetails().type
      tempJoinery.exterior_doors_PVC =
        joineryDetailsRef[7].current.getOptions().pvc
      tempJoinery.exterior_doors_Wood =
        joineryDetailsRef[7].current.getOptions().wood
      tempJoinery.exterior_doors_aluminium =
        joineryDetailsRef[7].current.getOptions().aluminium
      tempJoinery.exterior_doors_brand =
        joineryDetailsRef[8].current.getDetails().brand
      tempJoinery.exterior_doors_type =
        joineryDetailsRef[8].current.getDetails().type
      tempJoinery.gate_PVC = joineryDetailsRef[9].current.getOptions().pvc
      tempJoinery.gate_wood = joineryDetailsRef[9].current.getOptions().wood
      tempJoinery.gate_aluminium =
        joineryDetailsRef[9].current.getOptions().aluminium
      tempJoinery.gate_brand = joineryDetailsRef[10].current.getDetails().brand
      tempJoinery.gate_type = joineryDetailsRef[10].current.getDetails().type
      setJoinery(joinery)
    }
    if (ventilationRef.length > 0) {
      tempVentilations.system_A = ventilationRef[0].current.getValue()
      tempVentilations.system_B = ventilationRef[1].current.getValue()
      tempVentilations.system_C = ventilationRef[2].current.getValue()
      tempVentilations.system_D = ventilationRef[3].current.getValue()
      tempVentilations.brand = ventilationRef[4].current.getValue()
      tempVentilations.type = ventilationRef[5].current.getValue()
      tempVentilations.reductiefactor = ventilationRef[6].current.getValue()
      setVentilations(tempVentilations)
    }
    if (heatingRef.length > 0) {
      tempHeating.gas = heatingRef[0].current.getValue()
      tempHeating.fuel_oil = heatingRef[1].current.getValue()
      tempHeating.others_1 = heatingRef[2].current.getValue()
      tempHeating.others_text_1 = heatingRef[3].current.getValue()
      tempHeating.classic_CV = heatingRef[4].current.getValue()
      tempHeating.heat_pump = heatingRef[5].current.getValue()
      tempHeating.others_2 = heatingRef[6].current.getValue()
      tempHeating.others_text_2 = heatingRef[7].current.getValue()
      tempHeating.brand_1 = heatingRef[8].current.getDetails().brand
      tempHeating.type_1 = heatingRef[8].current.getDetails().type
      tempHeating.radiators = heatingRef[9].current.getValue()
      tempHeating.underfloor_heating = heatingRef[10].current.getValue()
      tempHeating.yes = heatingRef[11].current.getValue()
      tempHeating.no = heatingRef[12].current.getValue()
      tempHeating.brand_2 = heatingRef[13].current.getDetails().brand
      tempHeating.type_2 = heatingRef[13].current.getDetails().type
      setVentilations(ventilations)
    }
    if (renewableEnergyRef.length > 0) {
      tempRenewableEnergy.solar_water_heater =
        renewableEnergyRef[0].current.getValue()
      tempRenewableEnergy.solar_panels =
        renewableEnergyRef[1].current.getValue()
      tempRenewableEnergy.heat_pump = renewableEnergyRef[2].current.getValue()
      tempRenewableEnergy.how_many_panals =
        renewableEnergyRef[3].current.getValue()
      setRenewableEnergy(renewableEnergy)

      let data = {
        details_of_people: [tempPersonDetails],
        building_application_data: [tempBuildingDetails],
        ventilation_preliminary_designs: [tempVentilationDesign],
        ebp_wishes: [tempEpbWishes],
        insulation_materials: [tempInsulationMaterial],
        joineries: [tempJoinery],
        ventilations: [tempVentilations],
        heatings: [tempHeating],
        renewable_energies: [tempRenewableEnergy],
      }
      fillErrorList(data)
      isDataValid(data)
      axios
        .post(
          process.env.REACT_APP_API_URL + `/ebp_form/add`,
          {
            epbFormId: epbFormId,
            service_id: 2,
            projectId: parseInt(projectId),
            projectServiceId: parseInt(projectServiceId),
            details_of_people: tempPersonDetails,
            building_application_data: tempBuildingDetails,
            ventilation_preliminary_designs: tempVentilationDesign,
            ebp_wishes: tempEpbWishes,
            insulation_materials: tempInsulationMaterial,
            joineries: tempJoinery,
            ventilations: tempVentilations,
            heatings: tempHeating,
            renewable_energies: tempRenewableEnergy,
          },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (
            response.data.message === 'Add Successfully' ||
            response.data.message === 'Updated!'
          ) {
            let data = response.data.info
            let responseMessage = {
              isSuccess: true,
              heading: 'Succesrecord opgeslagen',
              message: '',
              popupState: setShowPopupState,
            }
            setShowPopupState(true)
            setPopupMessageState(responseMessage)
            setEpbFormId(data.id)
            setForceUpdateState(!forceUpdateState)
          }
        })
        .catch((error) => {
          let responseMessage = {
            isSuccess: false,
            heading: 'Instellingen konden niet worden opgeslagen',
            message: 'Er is iets fout gegaan',
            popupState: setShowPopupState,
          }
          setShowPopupState(true)
          setPopupMessageState(responseMessage)
        })
    }
  }, [isButtonClick])

  useEffect(() => {}, [errorStateList])

  function fillErrorList(data) {
    let tempDataList = [...errorStateList]
    if (
      data.details_of_people[0].name_1 === '' ||
      data.details_of_people[0].name_1 === undefined ||
      data.details_of_people[0].national_Insurance_number_1 === '' ||
      data.details_of_people[0].national_Insurance_number_1 === undefined ||
      data.details_of_people[0].address_1 === '' ||
      data.details_of_people[0].address_1 === undefined ||
      data.details_of_people[0].name_2 === '' ||
      data.details_of_people[0].name_2 === undefined ||
      data.details_of_people[0].national_Insurance_number_2 === '' ||
      data.details_of_people[0].national_Insurance_number_2 === undefined ||
      data.details_of_people[0].address_2 === '' ||
      data.details_of_people[0].address_2 === undefined ||
      data.details_of_people[0].company_name_of_manager === '' ||
      data.details_of_people[0].company_name_of_manager === undefined ||
      data.details_of_people[0].legal_form === '' ||
      data.details_of_people[0].legal_form === undefined ||
      data.details_of_people[0].head_office_address === '' ||
      data.details_of_people[0].head_office_address === undefined ||
      data.details_of_people[0].kbo_number === '' ||
      data.details_of_people[0].kbo_number === undefined
    ) {
      tempDataList[0] = true
    } else {
      tempDataList[0] = false
    }

    if (
      data.building_application_data[0].date_of_building_application === '' ||
      data.building_application_data[0].date_of_building_application ===
        undefined ||
      data.building_application_data[0].date_of_approval === '' ||
      data.building_application_data[0].date_of_approval === undefined ||
      data.building_application_data[0].epb_or_ovm_number === '' ||
      data.building_application_data[0].epb_or_ovm_number === undefined
    ) {
      tempDataList[1] = true
    } else {
      tempDataList[1] = false
    }

    if (
      !data.ventilation_preliminary_designs[0].and &&
      !data.ventilation_preliminary_designs[0].no_ventilation_system &&
      !data.ventilation_preliminary_designs[0]
        .no_please_wait_until_the_definitive_ventilation_system_is_known
    ) {
      tempDataList[2] = true
    } else {
      tempDataList[2] = false
    }

    if (
      !data.ebp_wishes[0].according_to_architectural_plan &&
      !data.ebp_wishes[0].please_suggest_an_EPB_reporter
    ) {
      tempDataList[3] = true
    } else {
      tempDataList[3] = false
    }
    if (data.insulation_materials[0].id !== undefined) {
      let isValid = true
      data.insulation_materials.forEach((item) => {
        if (isValid) {
          for (var i = 0; i < item.insulations.length; i++) {
            if (
              item.insulations[i].structure === undefined ||
              item.insulations[i].structure === null ||
              item.insulations[i].structure === '' ||
              item.insulations[i].thickness === undefined ||
              item.insulations[i].thickness === null ||
              item.insulations[i].thickness === '' ||
              item.insulations[i].type === undefined ||
              item.insulations[i].type === null ||
              item.insulations[i].type === ''
            ) {
              isValid = false
              tempDataList[4] = true
              break
            } else {
              tempDataList[4] = false
            }
          }
        }
      })
    } else {
      if (
        !insulationMaterialRef[0].current.areFieldsFilled() ||
        !insulationMaterialRef[1].current.areFieldsFilled() ||
        !insulationMaterialRef[2].current.areFieldsFilled() ||
        !insulationMaterialRef[3].current.areFieldsFilled() ||
        !insulationMaterialRef[4].current.areFieldsFilled() ||
        !insulationMaterialRef[5].current.areFieldsFilled() ||
        !insulationMaterialRef[6].current.areFieldsFilled() ||
        !insulationMaterialRef[7].current.areFieldsFilled() ||
        !insulationMaterialRef[8].current.areFieldsFilled() ||
        !insulationMaterialRef[9].current.areFieldsFilled() ||
        !insulationMaterialRef[10].current.areFieldsFilled() ||
        !insulationMaterialRef[11].current.areFieldsFilled() ||
        !insulationMaterialRef[12].current.areFieldsFilled()
      ) {
        tempDataList[4] = true
      } else {
        tempDataList[4] = false
      }
    }

    if (
      (!data.joineries[0].windows_PVC &&
        !data.joineries[0].Windows_wood &&
        !data.joineries[0].windows_aluminium) ||
      data.joineries[0].windows_brand === '' ||
      data.joineries[0].windows_type === '' ||
      data.joineries[0].windows_glass === '' ||
      (!data.joineries[0].windows_all_windows_renewed &&
        !data.joineries[0].windows_some_windows_renewed &&
        !data.joineries[0].windows_no_windows_renewed) ||
      data.joineries[0].roof_window_brand === '' ||
      data.joineries[0].roof_window_type === '' ||
      (!data.joineries[0].exterior_doors_PVC &&
        !data.joineries[0].exterior_doors_Wood &&
        !data.joineries[0].exterior_doors_aluminium) ||
      data.joineries[0].exterior_doors_brand === '' ||
      data.joineries[0].exterior_doors_type === '' ||
      (!data.joineries[0].gate_PVC &&
        !data.joineries[0].gate_wood &&
        !data.joineries[0].gate_aluminium) ||
      data.joineries[0].gate_brand === '' ||
      data.joineries[0].gate_type === ''
    ) {
      tempDataList[5] = true
    } else {
      tempDataList[5] = false
    }
    if (
      data.ventilations[0].system_C === '' ||
      data.ventilations[0].system_C === undefined ||
      data.ventilations[0].system_D === '' ||
      data.ventilations[0].system_D === undefined ||
      data.ventilations[0].brand === '' ||
      data.ventilations[0].brand === undefined ||
      data.ventilations[0].type === '' ||
      data.ventilations[0].type === undefined ||
      data.ventilations[0].reductiefactor === '' ||
      data.ventilations[0].reductiefactor === undefined
    ) {
      tempDataList[6] = true
    } else {
      tempDataList[6] = false
    }

    if (
      data.heatings[0].gas === '' ||
      data.heatings[0].gas === undefined ||
      data.heatings[0].fuel_oil === '' ||
      data.heatings[0].fuel_oil === undefined ||
      data.heatings[0].others_1 === '' ||
      data.heatings[0].others_1 === undefined ||
      data.heatings[0].others_text_1 === '' ||
      data.heatings[0].others_text_1 === undefined ||
      data.heatings[0].classic_CV === '' ||
      data.heatings[0].classic_CV === undefined ||
      data.heatings[0].heat_pump === '' ||
      data.heatings[0].heat_pump === undefined ||
      data.heatings[0].others_2 === '' ||
      data.heatings[0].others_2 === undefined ||
      data.heatings[0].others_text_2 === '' ||
      data.heatings[0].others_text_2 === undefined ||
      data.heatings[0].brand_1 === '' ||
      data.heatings[0].brand_1 === undefined ||
      data.heatings[0].type_1 === '' ||
      data.heatings[0].type_1 === undefined ||
      data.heatings[0].radiators === '' ||
      data.heatings[0].radiators === undefined ||
      data.heatings[0].underfloor_heating === '' ||
      data.heatings[0].underfloor_heating === undefined ||
      data.heatings[0].yes === '' ||
      data.heatings[0].yes === undefined ||
      data.heatings[0].no === '' ||
      data.heatings[0].no === undefined ||
      data.heatings[0].brand_2 === '' ||
      data.heatings[0].brand_2 === undefined ||
      data.heatings[0].type_2 === '' ||
      data.heatings[0].type_2 === undefined
    ) {
      tempDataList[7] = true
    } else {
      tempDataList[7] = false
    }

    if (
      data.renewable_energies[0].solar_water_heater === '' ||
      data.renewable_energies[0].solar_water_heater === undefined ||
      data.renewable_energies[0].solar_panels === '' ||
      data.renewable_energies[0].solar_panels === undefined ||
      data.renewable_energies[0].heat_pump === '' ||
      data.renewable_energies[0].heat_pump === undefined ||
      data.renewable_energies[0].how_many_panals === '' ||
      data.renewable_energies[0].how_many_panals === undefined
    ) {
      tempDataList[8] = true
    } else {
      tempDataList[8] = false
    }
    setErrorListState(tempDataList)
  }

  function isDataValid(data) {
    let isValid = true
    if (
      data.details_of_people === undefined ||
      data.details_of_people.length === 0 ||
      data.details_of_people[0].name_1 === '' ||
      data.details_of_people[0].name_1 === undefined ||
      data.details_of_people[0].national_Insurance_number_1 === '' ||
      data.details_of_people[0].national_Insurance_number_1 === undefined ||
      data.details_of_people[0].address_1 === '' ||
      data.details_of_people[0].address_1 === undefined ||
      data.details_of_people[0].name_2 === '' ||
      data.details_of_people[0].name_2 === undefined ||
      data.details_of_people[0].national_Insurance_number_2 === '' ||
      data.details_of_people[0].national_Insurance_number_2 === undefined ||
      data.details_of_people[0].address_2 === '' ||
      data.details_of_people[0].address_2 === undefined ||
      data.details_of_people[0].company_name_of_manager === '' ||
      data.details_of_people[0].company_name_of_manager === undefined ||
      data.details_of_people[0].legal_form === '' ||
      data.details_of_people[0].legal_form === undefined ||
      data.details_of_people[0].head_office_address === '' ||
      data.details_of_people[0].head_office_address === undefined ||
      data.details_of_people[0].kbo_number === '' ||
      data.details_of_people[0].kbo_number === undefined
    ) {
      setAdviceReportSectionState(true)
      return false
    }

    if (
      data.building_application_data !== undefined ||
      data.building_application_data.length === 0 ||
      data.building_application_data[0].date_of_building_application === '' ||
      data.building_application_data[0].date_of_building_application ===
        undefined ||
      data.building_application_data[0].date_of_approval === '' ||
      data.building_application_data[0].date_of_approval === undefined ||
      data.building_application_data[0].epb_or_ovm_number === '' ||
      data.building_application_data[0].epb_or_ovm_number === undefined
    ) {
      setAdviceReportSectionState(true)
      return false
    }

    if (
      data.ventilation_preliminary_designs !== undefined ||
      data.ventilation_preliminary_designs.length === 0 ||
      (!data.ventilation_preliminary_designs[0].and &&
        !data.ventilation_preliminary_designs[0].no_ventilation_system &&
        !data.ventilation_preliminary_designs[0]
          .no_please_wait_until_the_definitive_ventilation_system_is_known)
    ) {
      setAdviceReportSectionState(true)
      return false
    }

    if (
      data.ebp_wishes !== undefined ||
      data.ebp_wishes.length === 0 ||
      (!data.ebp_wishes[0].according_to_architectural_plan &&
        !data.ebp_wishes[0].please_suggest_an_EPB_reporter)
    ) {
      setAdviceReportSectionState(true)
      return false
    }
    if (
      data.insulation_materials !== undefined ||
      data.insulation_materials.length === 0 ||
      data.insulation_materials[0].id !== undefined
    ) {
      for (var j = 0; j < data.insulation_materials.length; j++) {
        if (isValid) {
          for (
            var i = 0;
            i < data.insulation_materials[j].insulations.length;
            i++
          ) {
            if (
              data.insulation_materials[j].insulations[i].structure ===
                undefined ||
              data.insulation_materials[j].insulations[i].structure === null ||
              data.insulation_materials[j].insulations[i].structure === '' ||
              data.insulation_materials[j].insulations[i].thickness ===
                undefined ||
              data.insulation_materials[j].insulations[i].thickness === null ||
              data.insulation_materials[j].insulations[i].thickness === '' ||
              data.insulation_materials[j].insulations[i].type === undefined ||
              data.insulation_materials[j].insulations[i].type === null ||
              data.insulation_materials[j].insulations[i].type === ''
            ) {
              setAdviceReportSectionState(true)
              isValid = false
              break
            }
          }
        }
      }
    } else {
      if (
        !insulationMaterialRef[0].current.areFieldsFilled() ||
        !insulationMaterialRef[1].current.areFieldsFilled() ||
        !insulationMaterialRef[2].current.areFieldsFilled() ||
        !insulationMaterialRef[3].current.areFieldsFilled() ||
        !insulationMaterialRef[4].current.areFieldsFilled() ||
        !insulationMaterialRef[5].current.areFieldsFilled() ||
        !insulationMaterialRef[6].current.areFieldsFilled() ||
        !insulationMaterialRef[7].current.areFieldsFilled() ||
        !insulationMaterialRef[8].current.areFieldsFilled() ||
        !insulationMaterialRef[9].current.areFieldsFilled() ||
        !insulationMaterialRef[10].current.areFieldsFilled() ||
        !insulationMaterialRef[11].current.areFieldsFilled() ||
        !insulationMaterialRef[12].current.areFieldsFilled()
      ) {
        setAdviceReportSectionState(true)
        return false
      } else {
        setAdviceReportSectionState(false)
      }
    }

    if (
      data.joineries !== undefined ||
      data.joineries.length === 0 ||
      (!data.joineries[0].windows_PVC &&
        !data.joineries[0].Windows_wood &&
        !data.joineries[0].windows_aluminium) ||
      data.joineries[0].windows_brand === '' ||
      data.joineries[0].windows_type === '' ||
      data.joineries[0].windows_glass === '' ||
      (!data.joineries[0].windows_all_windows_renewed &&
        !data.joineries[0].windows_some_windows_renewed &&
        !data.joineries[0].windows_no_windows_renewed) ||
      data.joineries[0].roof_window_brand === '' ||
      data.joineries[0].roof_window_type === '' ||
      (!data.joineries[0].exterior_doors_PVC &&
        !data.joineries[0].exterior_doors_Wood &&
        !data.joineries[0].exterior_doors_aluminium) ||
      data.joineries[0].exterior_doors_brand === '' ||
      data.joineries[0].exterior_doors_type === '' ||
      (!data.joineries[0].gate_PVC &&
        !data.joineries[0].gate_wood &&
        !data.joineries[0].gate_aluminium) ||
      data.joineries[0].gate_brand === '' ||
      data.joineries[0].gate_type === ''
    ) {
      setAdviceReportSectionState(true)
      return false
    }
    if (
      data.ventilations !== undefined ||
      data.ventilations.length === 0 ||
      data.ventilations[0].system_C === '' ||
      data.ventilations[0].system_C === undefined ||
      data.ventilations[0].system_D === '' ||
      data.ventilations[0].system_D === undefined ||
      data.ventilations[0].brand === '' ||
      data.ventilations[0].brand === undefined ||
      data.ventilations[0].type === '' ||
      data.ventilations[0].type === undefined ||
      data.ventilations[0].reductiefactor === '' ||
      data.ventilations[0].reductiefactor === undefined
    ) {
      setAdviceReportSectionState(true)
      return false
    }

    if (
      data.heatings !== undefined ||
      data.heatings.length === 0 ||
      data.heatings[0].gas === '' ||
      data.heatings[0].gas === undefined ||
      data.heatings[0].fuel_oil === '' ||
      data.heatings[0].fuel_oil === undefined ||
      data.heatings[0].others_1 === '' ||
      data.heatings[0].others_1 === undefined ||
      data.heatings[0].others_text_1 === '' ||
      data.heatings[0].others_text_1 === undefined ||
      data.heatings[0].classic_CV === '' ||
      data.heatings[0].classic_CV === undefined ||
      data.heatings[0].heat_pump === '' ||
      data.heatings[0].heat_pump === undefined ||
      data.heatings[0].others_2 === '' ||
      data.heatings[0].others_2 === undefined ||
      data.heatings[0].others_text_2 === '' ||
      data.heatings[0].others_text_2 === undefined ||
      data.heatings[0].brand_1 === '' ||
      data.heatings[0].brand_1 === undefined ||
      data.heatings[0].type_1 === '' ||
      data.heatings[0].type_1 === undefined ||
      data.heatings[0].radiators === '' ||
      data.heatings[0].radiators === undefined ||
      data.heatings[0].underfloor_heating === '' ||
      data.heatings[0].underfloor_heating === undefined ||
      data.heatings[0].yes === '' ||
      data.heatings[0].yes === undefined ||
      data.heatings[0].no === '' ||
      data.heatings[0].no === undefined ||
      data.heatings[0].brand_2 === '' ||
      data.heatings[0].brand_2 === undefined ||
      data.heatings[0].type_2 === '' ||
      data.heatings[0].type_2 === undefined
    ) {
      setAdviceReportSectionState(true)
      return false
    }

    if (
      data.renewable_energies !== undefined ||
      data.renewable_energies.length === 0 ||
      data.renewable_energies[0].solar_water_heater === '' ||
      data.renewable_energies[0].solar_water_heater === undefined ||
      data.renewable_energies[0].solar_panels === '' ||
      data.renewable_energies[0].solar_panels === undefined ||
      data.renewable_energies[0].heat_pump === '' ||
      data.renewable_energies[0].heat_pump === undefined ||
      data.renewable_energies[0].how_many_panals === '' ||
      data.renewable_energies[0].how_many_panals === undefined
    ) {
      setAdviceReportSectionState(true)
      return false
    }
    return isValid
  }

  return (
    <Grid container className="fade-in-translate animatable-400">
      <Grid container>
        <PersonDetails
          personDetails={personDetails}
          setPersonDetails={setPersonDetails}
          personDetailsRef={personDetailsRef}
          setPersonDetailsRef={setPersonDetailsRef}
          hasError={errorStateList[0]}
          isButtonClick={isButtonClick}
          inputDisableState={inputDisableState}
        />
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <BuildingApplicationData
          buildingApplicationData={buildingApplicationData}
          setBuildingApplicationData={setBuildingApplicationData}
          buildingApplicationRef={buildingApplicationRef}
          setBuildingApplicationRef={setBuildingApplicationRef}
          hasError={errorStateList[1]}
          inputDisableState={inputDisableState}
        />
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <EpbWishes
          epbWishes={epbWishes}
          setEpbWishes={setEpbWishes}
          epbWishesRef={epbWishesRef}
          setEpbWishesRef={setEpbWishesRef}
          hasError={errorStateList[3]}
          showMore={toShowSubsections}
          setShowMore={setSubsectionState}
          inputDisableState={inputDisableState}
        />
      </Grid>
      {toShowSubsections && (
        <Grid container>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <InsulationMaterials
              insulationMaterials={insulationMaterials}
              setInsulationMaterials={setInsulationMaterials}
              insulationMaterialRef={insulationMaterialRef}
              setInsulationMaterialRef={setInsulationMaterialRef}
              hasError={errorStateList[4]}
              inputDisableState={inputDisableState}
            />
          </Grid>

          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <Joinery
              joinery={joinery}
              setJoinery={setJoinery}
              joineryDetailsRef={joineryDetailsRef}
              setJoineryDetailsRef={setJoineryDetailsRef}
              hasError={errorStateList[5]}
              inputDisableState={inputDisableState}
            />
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <Ventilation
              ventilations={ventilations}
              setVentilations={setVentilations}
              ventilationRef={ventilationRef}
              setVentilationRef={setVentilationRef}
              hasError={errorStateList[6]}
              inputDisableState={inputDisableState}
            />
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <Heating
              heating={heating}
              setHeating={setHeating}
              heatingRef={heatingRef}
              setHeatingRef={setHeatingRef}
              hasError={errorStateList[7]}
              inputDisableState={inputDisableState}
            />
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <RenewableEnergy
              renewableEnergy={renewableEnergy}
              setRenewableEnergy={setRenewableEnergy}
              renewableEnergyRef={renewableEnergyRef}
              setRenewableEnergyRef={setRenewableEnergyRef}
              hasError={errorStateList[8]}
              inputDisableState={inputDisableState}
            />
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
        </Grid>
      )}
      {showPopupState === true ? <Popup data={popupMessageState} /> : null}
    </Grid>
  )
}
