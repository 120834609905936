import React, { useState, useEffect, useRef } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import _ from 'lodash'
import axios from 'axios'

import Header from '../header/header'

import './_externe.scss'

function ExterneButton(props) {
  const [buttonState, setButtonState] = useState(false)
  const { clickFunction, toggleState } = props

  useEffect(() => {
    setButtonState(toggleState)
  }, [toggleState])

  const handleButtonState = () => {
    clickFunction()
    setButtonState(!buttonState)
  }

  return (
    <button
      type="button"
      className="externe-view-column-enable center-content animatable-400 ease-in-out-quart cursor-pointer"
      style={{ background: buttonState ? '#5cb555' : 'rgba(0, 0, 0, 0)' }}
      onClick={handleButtonState}
    >
      <img
        src={require('../../../assets/svg/icon/check_white.svg')}
        alt="check white"
        className="height-100-p width-100-p object-fit-contain animatable-400 ease-in-out-quart"
        style={{ opacity: buttonState ? 1 : 0 }}
      />
    </button>
  )
}

export default function Externe() {
  const [externalUsers, setExternalUsers] = useState([])
  const [externalUsersOriginal, setExternalUsersOriginal] = useState([])
  const [companies, setCompanies] = useState([])
  const [companiesOriginal, setCompaniesOriginal] = useState([])
  const [buildings, setBuildings] = useState([])
  const [buildingsOriginal, setBuildingsOriginal] = useState([])
  const [selectedUser, setSelectedUser] = useState(0)
  const [selectedCompany, setSelectedCompany] = useState(0)

  // references for each search state, we use the reference to reset the fields
  const externalUsersReference = useRef(0)
  const companyListReference = useRef(0)
  const buildingListReference = useRef(0)

  const token = localStorage.getItem('authToken')

  useEffect(() => {
    getAllExternalUsers()
  }, [])

  useEffect(() => {
    if (selectedUser !== 0) {
      getAllCompanies()
    }
  }, [selectedUser])

  useEffect(() => {
    if (selectedUser !== 0 && selectedCompany !== 0) {
      getBuildings()
    }
  }, [selectedUser, selectedCompany])

  useEffect(() => {
    setSelectedCompany(0)
    setBuildings([])
  }, [selectedUser])

  const debouncedSearchExterne = (event) => {
    searchExterne(event.target.value)
  }

  const debouncedSearchCompany = (event) => {
    searchCompany(event.target.value)
  }

  const debouncedSearchBuilding = (event) => {
    searchBuilding(event.target.value)
  }

  const searchExterne = _.debounce((value) => {
    let newExternalUserList = []
    const searchParams = value.toLowerCase()
    externalUsersOriginal.forEach((user) => {
      if (
        matchStrings(`${user.firstName} ${user.lastName}`, searchParams) ||
        matchStrings(user.email, searchParams) ||
        matchStrings(user.phone, searchParams)
      ) {
        newExternalUserList.push(user)
      }
    })
    selectedUser !== 0 && setSelectedUser(0)
    setExternalUsers(newExternalUserList)
  }, 500)

  const searchCompany = _.debounce((value) => {
    let newCompanyList = []
    const searchParams = value.toLowerCase()
    companiesOriginal.forEach((company) => {
      if (
        matchStrings(company.name, searchParams) ||
        matchStrings(company.generalEmail, searchParams) ||
        matchStrings(
          `${company.user[0].firstName} ${company.user[0].lastName}`,
          searchParams,
        )
      ) {
        newCompanyList.push(company)
      }
    })
    selectedCompany !== 0 && setSelectedCompany(0)
    setCompanies(newCompanyList)
  }, 500)

  const searchBuilding = _.debounce((value) => {
    let newBuildingList = []
    const searchParams = value.toLowerCase()
    buildingsOriginal.forEach((building) => {
      const buildingFileAddress = `${getBuildingNumber(building.id)} - ${
        building.fileName
      }`
      const buildingArea = `${building.zip} ${building.city}`
      const buildingDossiers = `dossiers: ${building.projects[0].projectServices}`
      if (
        matchStrings(buildingFileAddress, searchParams) ||
        matchStrings(buildingArea, searchParams) ||
        matchStrings(buildingDossiers, searchParams)
      ) {
        newBuildingList.push(building)
      }
    })
    setBuildings(newBuildingList)
  })

  function getAllExternalUsers() {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/user/all_external',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        setExternalUsers(response.data.data)
        setExternalUsersOriginal(response.data.data)
      })
      .catch((error) => {})
  }

  function getAllCompanies() {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/admin/company/get_all_by_users',
        {
          userId: selectedUser,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        setCompanies(response.data)
        setCompaniesOriginal(response.data)
      })
      .catch((error) => {})
  }

  function getBuildings() {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          '/buildlings/dashboard/getBuildingsByCompany',
        {
          companyId: selectedCompany,
          userId: selectedUser,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        setBuildings(response.data.data)
        setBuildingsOriginal(response.data.data)
      })
      .catch((error) => {})
  }

  function addExternalBuilding(building_id) {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/buildlings_external/add',
        {
          building_id,
          user_id: selectedUser,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {})
      .catch((error) => {})
  }

  function getBuildingNumber(id) {
    let value = `0000`
    value = value + id.toString()
    value = value.slice(value.length - 4, value.length)
    return value
  }

  function matchStrings(firstString, secondString = 'default') {
    return firstString.toLowerCase().includes(secondString.toLowerCase())
  }

  return (
    <div className="externe-container">
      <Grid container item sm={12}>
        <Header toShowHamburger={window.screen.width < 600 ? true : false} />
        <div className="externe-main-container">
          <Grid container item sm={12}>
            <div className="externe-view-container relative">
              <Hidden xsDown>
                <div className="externe-view-column-user-container">
                  <Grid container item sm={12}>
                    <div className="externe-input-container vertical-center">
                      <img
                        src={require('../../../assets/svg/icon/glass_grey.svg')}
                        alt="glass icon"
                        className="externe-input-icon"
                      />
                      <input
                        className="main-font-regular font-size-14 font-color-grey externe-input"
                        ref={externalUsersReference}
                        onChange={debouncedSearchExterne}
                        placeholder="Zoeken"
                      />
                    </div>
                  </Grid>
                  <div className="externe-view-column-user">
                    <div className="height-auto width-auto overflow-y-auto">
                      {externalUsers.length > 0 &&
                        externalUsers.map((user) => (
                          <div
                            className="externe-view-column-item flex-column horizontal-center cursor-pointer"
                            style={{
                              background:
                                selectedUser === user.id
                                  ? 'rgb(176, 236, 182)'
                                  : 'rgba(0, 0, 0, 0)',
                            }}
                            onClick={() => {
                              setSelectedUser(user.id)
                            }}
                          >
                            <p className="main-font-semi-bold font-size-14 font-color-navy-blue">{`${user.firstName} ${user.lastName}`}</p>
                            <p className="main-font-regular font-size-14 font-color-black">
                              {user.email}
                            </p>
                            <p className="main-font-regular font-size-14 font-color-grey">
                              {user.phone}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="externe-view-column-company-container">
                  <Grid container item sm={12}>
                    <div className="externe-input-container vertical-center">
                      <img
                        src={require('../../../assets/svg/icon/glass_grey.svg')}
                        alt="glass icon"
                        className="externe-input-icon"
                      />
                      <input
                        className="main-font-regular font-size-14 font-color-grey externe-input"
                        ref={companyListReference}
                        onChange={debouncedSearchCompany}
                        placeholder="Zoeken"
                      />
                    </div>
                  </Grid>
                  <div className="externe-view-column-company">
                    <div className="height-auto width-auto overflow-y-auto">
                      {companies.length > 0 && selectedUser !== 0 ? (
                        companies.map((company) => (
                          <div
                            className="externe-view-column-item vertical-center horizontal-between cursor-pointer"
                            style={{
                              background:
                                selectedCompany === company.id
                                  ? 'rgb(176, 236, 182)'
                                  : 'rgba(0, 0, 0, 0)',
                            }}
                            onClick={() => {
                              setSelectedCompany(company.id)
                            }}
                          >
                            <div className="flex-row">
                              <div className="flex-row">
                                {company && (
                                  <img
                                    src={
                                      company.profile_picture
                                        ? process.env.REACT_APP_API_URL +
                                          `/uploads/${company.profile_picture}`
                                        : require('../../../assets/svg/user.svg')
                                    }
                                    alt="profile pic"
                                    className="externe-view-column-profile"
                                  />
                                )}
                              </div>
                              <div className="flex-column">
                                <p className="main-font-semi-bold font-size-14 font-color-navy-blue">
                                  {company.name}
                                </p>
                                <p className="main-font-regular font-size-14 font-color-black">
                                  {company.generalEmail}
                                </p>
                                <p className="main-font-regular font-size-14 font-color-grey">{`${
                                  company.user[0]?.firstName || ''
                                } ${company.user[0]?.lastName || ''}`}</p>
                              </div>
                            </div>
                            <div className="flex-row">
                              <div
                                className="externe-view-column-exists"
                                style={{
                                  opacity: company.externalExists ? 1 : 0,
                                }}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="externe-view-column-item vertical-center">
                          <div className="externe-view-column-profile-blank center-content">
                            <img
                              src={require('../../../assets/svg/icon/add_white.svg')}
                              alt="profile pic"
                              className="externe-view-column-profile-blank-icon"
                            />
                          </div>
                          <p className="main-font-semi-bold font-size-14 font-color-navy-blue margin-left-25">
                            Select an externe
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Hidden>
              <Hidden smUp>
                <div
                  className="height-auto width-auto animatable-400 ease-in-out-quart flex-column"
                  style={{
                    opacity:
                      selectedCompany !== 0 && selectedUser !== 0 ? 0 : 1,
                    transform:
                      selectedCompany !== 0 && selectedUser !== 0
                        ? 'translateX(-100vw)'
                        : 'translateX(0px)',
                  }}
                >
                  <div className="externe-view-column-user">
                    <div className="height-auto width-auto overflow-y-auto">
                      {externalUsers.length > 0 &&
                        externalUsers.map((user) => (
                          <div
                            className="externe-view-column-item flex-column horizontal-center cursor-pointer"
                            style={{
                              background:
                                selectedUser === user.id
                                  ? 'rgb(176, 236, 182)'
                                  : 'rgba(0, 0, 0, 0)',
                            }}
                            onClick={() => {
                              setSelectedUser(user.id)
                            }}
                          >
                            <p className="main-font-semi-bold font-size-14 font-color-navy-blue">{`${user.firstName} ${user.lastName}`}</p>
                            <p className="main-font-regular font-size-14 font-color-black">
                              {user.email}
                            </p>
                            <p className="main-font-regular font-size-14 font-color-grey">
                              {user.phone}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="externe-view-column-company">
                    <div className="height-auto width-auto overflow-y-auto">
                      {companies.length > 0 &&
                        companies.map((company) => (
                          <div
                            className="externe-view-column-item vertical-center horizontal-between cursor-pointer"
                            style={{
                              background:
                                selectedCompany === company.id
                                  ? 'rgb(176, 236, 182)'
                                  : 'rgba(0, 0, 0, 0)',
                            }}
                            onClick={() => {
                              setSelectedCompany(company.id)
                            }}
                          >
                            <div className="flex-row">
                              <div className="flex-row">
                                {company && (
                                  <img
                                    src={
                                      company.profile_picture
                                        ? process.env.REACT_APP_API_URL +
                                          `/uploads/${company.profile_picture}`
                                        : require('../../../assets/svg/user.svg')
                                    }
                                    alt="profile pic"
                                    className="externe-view-column-profile"
                                  />
                                )}
                              </div>
                              <div className="flex-column">
                                <p className="main-font-semi-bold font-size-14 font-color-navy-blue">
                                  {company.name}
                                </p>
                                <p className="main-font-regular font-size-14 font-color-black">
                                  {company.generalEmail}
                                </p>
                                <p className="main-font-regular font-size-14 font-color-grey">{`${
                                  company.user[0]?.firstName || ''
                                } ${company.user[0]?.lastName || ''}`}</p>
                              </div>
                            </div>
                            <div className="flex-row">
                              <div
                                className="externe-view-column-exists"
                                style={{
                                  opacity: company.externalExists ? 1 : 0,
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Hidden>
              <Hidden xsDown>
                <div className="externe-view-column-file-container">
                  <Grid container item sm={12}>
                    <div className="externe-input-container vertical-center">
                      <img
                        src={require('../../../assets/svg/icon/glass_grey.svg')}
                        alt="glass icon"
                        className="externe-input-icon"
                      />
                      <input
                        className="main-font-regular font-size-14 font-color-grey externe-input"
                        ref={buildingListReference}
                        onChange={debouncedSearchBuilding}
                        placeholder="Zoeken"
                      />
                    </div>
                  </Grid>
                  <div className="externe-view-column-file">
                    <div className="height-auto width-auto overflow-y-auto">
                      {buildings.length > 0 &&
                        buildings.map((building) => (
                          <div className="externe-view-column-item vertical-center horizontal-between">
                            <div className="flex-column">
                              <p className="main-font-semi-bold font-size-14 font-color-navy-blue">{`${getBuildingNumber(
                                building.id,
                              )} - ${building.fileName}`}</p>
                              <p className="main-font-regular font-size-14 font-color-black">{`${building.zip} ${building.city}`}</p>
                              <p className="main-font-regular font-size-14 font-color-grey">{`Dossiers: ${building.projects[0]?.projectServices.length}`}</p>
                            </div>
                            <ExterneButton
                              clickFunction={() => {
                                addExternalBuilding(building.id, selectedUser)
                              }}
                              toggleState={
                                building.buildings_external &&
                                building.buildings_external.length > 0
                                  ? building.buildings_external[0].enabled
                                  : false
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Hidden>
              <Hidden smUp>
                <div
                  className="height-auto width-auto animatable-400 ease-in-out-quart absolute"
                  style={{
                    opacity:
                      selectedCompany !== 0 && selectedUser !== 0 ? 1 : 0,
                    transform:
                      selectedCompany !== 0 && selectedUser !== 0
                        ? 'translateX(0px)'
                        : 'translateX(100vw)',
                  }}
                >
                  <button
                    type="button"
                    className="externe-view-mobile-back center-content"
                    onClick={() => {
                      setSelectedCompany(0)
                      setSelectedUser(0)
                    }}
                  >
                    <p className="main-font-semi-bold font-size-14 font-color-white">
                      Back
                    </p>
                  </button>
                  <div className="externe-view-column-file">
                    <div className="height-auto width-auto overflow-y-auto">
                      {buildings.length > 0 &&
                        buildings.map((building) => (
                          <div className="externe-view-column-item vertical-center horizontal-between">
                            <div className="flex-column">
                              <p className="main-font-semi-bold font-size-14 font-color-navy-blue">{`${getBuildingNumber(
                                building.id,
                              )} - ${building.fileName}`}</p>
                              <p className="main-font-regular font-size-14 font-color-black">{`${building.zip} ${building.city}`}</p>
                              <p className="main-font-regular font-size-14 font-color-grey">{`Dossiers: ${building.projects[0]?.projectServices.length}`}</p>
                            </div>
                            <ExterneButton
                              clickFunction={() => {
                                addExternalBuilding(building.id, selectedUser)
                              }}
                              toggleState={
                                building.buildings_external &&
                                building.buildings_external.length > 0
                                  ? building.buildings_external[0].enabled
                                  : false
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Hidden>
            </div>
          </Grid>
        </div>
      </Grid>
    </div>
  )
}
