import React, { useEffect, useState } from 'react'
import { Grid, Dialog } from '@material-ui/core'
import './_profile_view.scss'

export default function ProfileView(props) {
  const { modalState, setModalState, data } = props
  const [userDetails, setUserDetails] = useState(0)

  useEffect(() => {
    if (!data) {
      setUserDetails(JSON.parse(localStorage.getItem('loggedInUserDetails')))
    }
  }, [])

  const handleButtonClick = () => {
    setModalState(false)
  }

  return (
    <Dialog open={modalState} disableBackdropClick disableEscapeKeyDown>
      <Grid container item className="profile-view-size">
        <div className="profile-view-size">
          <Grid
            container
            item
            justify="space-between"
            alignItems="center"
            className="profile-container-size"
          >
            <div className="flex-row center-content">
              <img
                alt="user"
                className="profile-user-icon"
                src={require('../../../assets/svg/icon/user.svg')}
              />
              <p className="profile-title vietnam-regular">Profiel</p>
            </div>
            <img
              className="profile-cross-icon"
              src={require('../../../assets/svg/icon/cross.svg')}
              onClick={handleButtonClick}
            />
          </Grid>
          <div className="profile-data-view">
            <Grid container item justify="center">
              {data ? (
                <Grid container item md={2} justify="center">
                  <img
                    alt="profile_picture"
                    className="profile-image"
                    src={
                      data.profilePicture
                        ? process.env.REACT_APP_API_URL +
                          `/uploads/${data.profilePicture}`
                        : require('../../../assets/svg/user.svg')
                    }
                  />
                </Grid>
              ) : (
                userDetails &&
                userDetails.company && (
                  <Grid container item md={2} justify="center">
                    <img
                      alt="profile_picture"
                      className="profile-image"
                      src={
                        userDetails && userDetails.company?.profile_picture
                          ? process.env.REACT_APP_API_URL +
                            `/uploads/${userDetails.company.profile_picture}`
                          : require('../../../assets/svg/user.svg')
                      }
                    />
                  </Grid>
                )
              )}
              <Grid
                container
                item
                md={5}
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regularding">Naam</p>
                  <p className="info-tab-details vietnam-regular">
                    {userDetails
                      ? userDetails.lastName
                        ? userDetails.lastName
                        : '-'
                      : data && data.lastName
                      ? data.lastName
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">Voornaam</p>
                  <p className="info-tab-details vietnam-regular">
                    {userDetails
                      ? userDetails.firstName
                        ? userDetails.firstName
                        : '-'
                      : data && data.firstName
                      ? data.firstName
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regularg">email</p>
                  <p className="info-tab-details vietnam-regular">
                    {userDetails
                      ? userDetails.email
                        ? userDetails.email
                        : '-'
                      : data && data.email
                      ? data.email
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">
                    Contact Tel.
                  </p>
                  <p className="info-tab-details vietnam-regular">
                    {userDetails
                      ? userDetails.phone
                        ? userDetails.phone
                        : '-'
                      : data && data.phoneNumber
                      ? data.phoneNumber
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">Beroep</p>
                  <p className="info-tab-details vietnam-regular">
                    {userDetails
                      ? userDetails.profession
                        ? userDetails.profession
                        : '-'
                      : data && data.profession
                      ? data.profession
                      : '-'}
                  </p>
                </Grid>
              </Grid>

              <Grid container item md={5} justify="flex-start" spacing={2}>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">Bedrijf</p>
                  <p className="info-tab-details vietnam-regular">
                    {userDetails
                      ? userDetails.company?.name
                        ? userDetails.company.name
                        : '-'
                      : data && data.companyName
                      ? data.companyName
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regularding">
                    BTW nummer
                  </p>
                  <p className="info-tab-details vietnam-regular">
                    {userDetails
                      ? userDetails.company?.BTW
                        ? userDetails.company.BTW
                        : '-'
                      : data && data.BTW
                      ? data.BTW
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regularg">
                    Algemeen email
                  </p>
                  <p className="info-tab-details vietnam-regular">
                    {userDetails
                      ? userDetails.company?.generalEmail
                        ? userDetails.company.generalEmail
                        : '-'
                      : data && data.generalEmail
                      ? data.generalEmail
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">
                    Algemeen Tel.
                  </p>
                  <p className="info-tab-details vietnam-regular">
                    {userDetails
                      ? userDetails.company?.generalTelephone
                        ? userDetails.company.generalTelephone
                        : '-'
                      : data && data.generalTelephone
                      ? data.generalTelephone
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">Adres</p>
                  <p className="info-tab-details vietnam-regular">
                    {userDetails
                      ? userDetails.company?.companyAddress
                        ? userDetails.company?.bus
                          ? userDetails.company.companyAddress +
                            ' ' +
                            userDetails.company.no +
                            ' ' +
                            userDetails.company.postalCode +
                            ' ' +
                            userDetails.company.city
                          : userDetails.company.companyAddress +
                            ' ' +
                            userDetails.company.no +
                            '/' +
                            userDetails.company.bus +
                            ' ' +
                            userDetails.company.postalCode +
                            ' ' +
                            userDetails.company.city
                        : '-'
                      : data && data?.address
                      ? data?.bus
                        ? data.address +
                          ' ' +
                          data.nr +
                          ' ' +
                          data.postalCode +
                          ' ' +
                          data.city
                        : data.address +
                          ' ' +
                          data.nr +
                          '/' +
                          data.bus +
                          ' ' +
                          data.postalCode +
                          ' ' +
                          data.city
                      : '-'}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Dialog>
  )
}
