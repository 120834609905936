import React, { useEffect, useState, useRef } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import './_epb_service_client.scss'
import { CustomSelect } from '../../shared_components/custom_select/custom_select'
import StepOne from './step_one/step_one'
import StepTwo from './step_two/step_two'
import StepThree from './step_three/step_three'
import StepFour from './step_four/step_four'
import StepFive from './step_five/step_five'
// import DashboardSidebar from '../../dashboard/dashboard_sidebar/dashboard_sidebar';
import axios from 'axios'
// import { isTokenValid } from "../../shared/shared_functions"

export default function EPBServiceClient(props) {
  const { setBuildingData } = props
  const token = localStorage.getItem('authToken')
  const [stepNumber, setStepNumber] = useState(1)
  const [projectId, setProjectId] = useState(0)
  const [projectServiceId, setProjectServiceId] = useState(0)
  const [epbFormId, setEpbFormId] = useState(0)
  const [isButtonClick, setButtonClick] = useState(false)
  const parameters = useParams()
  const [signedDeclarationFile, setSignedDeclarationFile] = useState('')
  const [startStatementBuilder, setStartStatementBuilder] = useState('')
  const [startStatementArchitect, setStartStatementArchitect] = useState('')
  const [ventilatievoorontwerp, setVentilatievoorontwerp] = useState(0)
  const [showMenuState, setShowMenuState] = useState(false)
  const [stepOneData, setStepOneData] = useState({})
  const [stepTwoData, setStepTwoData] = useState({})
  const [stepThreeData, setStepThreeData] = useState({})
  const [stepFourData, setStepFourData] = useState([])
  const [stepFiveData, setStepFiveData] = useState({})
  const [isThirdTabEnable, setThirdTabState] = useState(false)
  const [isFifthTabEnable, setFifthtabState] = useState(false)
  const [adviceReportSectionState, setAdviceReportSectionState] = useState(true)
  const dashboardRef = useRef(0)
  const loggedInUserDetails = JSON.parse(
    localStorage.getItem('loggedInUserDetails'),
  )
  const userRole = loggedInUserDetails.role.title

  const dropDownData = [
    { id: 1, title: '1. Startgegevens' },
    { id: 2, title: '2. Adviesrapport' },
    { id: 3, title: '3. Startverklaring' },
    { id: 4, title: '4. Stavingsstukken' },
    { id: 5, title: '5. Aangifte' },
  ]

  useEffect(() => {
    // isTokenValid(history)
  }, [])

  useEffect(() => {}, [adviceReportSectionState])

  useEffect(() => {
    let url = parameters.id.split('-')
    setProjectServiceId(url[0])
    setProjectId(url[1])
  }, [parameters.id])

  useEffect(() => {
    if (projectServiceId > 0) {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/ebp_form/get`,
          {
            projectServiceId: parseInt(projectServiceId),
          },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          let data = response.data
          if (data.details_of_people !== undefined) {
            setEpbFormId(data.id)
            setVentilatievoorontwerp(data.ventilatievoorontwerp)
            setStepOneData(data)
            setBuildingData(data.building)
            if (
              data.ondertekende_aangifte_document !== undefined &&
              data.ondertekende_aangifte_document !== null
            ) {
              setSignedDeclarationFile({
                viewName: data.ondertekende_aangifte_document,
                name: data.ondertekende_aangifte_document_orignal_name,
              })
            }
            if (
              data.startverklaring_bouwheer_document_orignal_name !==
                undefined &&
              data.startverklaring_bouwheer_document_orignal_name !== null
            ) {
              setStartStatementBuilder({
                viewName: data.startverklaring_bouwheer_document,
                name: data.startverklaring_bouwheer_document_orignal_name,
              })
            }
            if (data.startverklaring_architecten_document_orignal_name) {
              setStartStatementArchitect({
                name: data.startverklaring_architecten_document_orignal_name,
                viewName: data.startverklaring_architecten_document,
              })
            }
          } else {
            setBuildingData(data.building)
          }
        })
        .catch((error) => {})
    }
  }, [projectServiceId, stepNumber])

  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/service_document/get`,
        { serviceId: 2, projectServiceId: projectServiceId },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.success === true) {
          let data = response.data.data
          setStepTwoData(data[1])
          setStepThreeData(data[2])
          setStepFiveData(data[4])
        }
      })
      .catch((error) => {})
  }, [projectServiceId, stepNumber])

  useEffect(() => {
    if (epbFormId > 0 && stepNumber === 4) {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/ebp_form/epb_step4_admin_get`,
          { ebp_form_id: epbFormId },
          {
            headers: {
              authorization: `bearer ${token}`,
            },
          },
        )
        .then((response) => {
          if (response.data.message === 'Success!') {
            let data = response.data.data
            setStepFourData(data)
            if (data.length > 5) {
              setFifthtabState(true)
            } else {
              setFifthtabState(false)
            }
          }
        })
        .catch((error) => {})
    }
  }, [stepNumber])

  useEffect(() => {
    if (
      stepTwoData.service_document_categories !== undefined &&
      stepTwoData.service_document_categories.length > 0 &&
      stepTwoData.service_document_categories[0].service_documents.length > 0 &&
      ventilatievoorontwerp > 0
    ) {
      setThirdTabState(true)
    } else {
      setThirdTabState(false)
    }
  }, [ventilatievoorontwerp, stepTwoData])

  useEffect(() => {
    if (
      stepFourData.length > 5 &&
      startStatementBuilder !== '' &&
      startStatementArchitect !== ''
    ) {
      setFifthtabState(true)
    } else {
      setFifthtabState(false)
    }
  }, [stepFourData, startStatementBuilder, startStatementArchitect])

  const handleAdviceReportSectionState = (value) => {
    setAdviceReportSectionState(value)
  }

  return (
    <Grid container>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Hidden smDown>
        <Grid container>
          <Grid md={1}></Grid>
          <Grid container md={8} alignItems="center">
            <Grid md={2}>
              <span
                className={`animatable-600 animatable-fade-in-out-quart epb-service-tab-${
                  stepNumber === 1 ? 'selected' : 'unSelected'
                } vietnam-regular cursor-pointer `}
                onClick={() => {
                  setStepNumber(1)
                }}
              >
                Startgegevens
              </span>
            </Grid>
            <Grid md={2}>
              <span
                className={`animatable-400 animatable-fade-in-out-quart epb-service-tab-${
                  stepNumber === 2 ? 'selected' : 'unSelected'
                } vietnam-regular cursor-pointer `}
                onClick={() => {
                  setStepNumber(2)
                }}
              >
                Adviesrapport
              </span>
            </Grid>
            <Grid md={2}>
              <span
                className={`animatable-400 animatable-fade-in-out-quart epb-service-tab-${
                  // isThirdTabEnable ?  : 'disable'
                  stepNumber === 3 ? 'selected' : 'unSelected'
                } vietnam-regular cursor-pointer `}
                onClick={() => {
                  setStepNumber(3)
                }}
              >
                Startverklaring
              </span>
            </Grid>
            <Grid md={2}>
              <span
                className={`animatable-400 animatable-fade-in-out-quart epb-service-tab-${
                  stepNumber === 4 ? 'selected' : 'unSelected'
                } vietnam-regular cursor-pointer `}
                onClick={() => {
                  setStepNumber(4)
                }}
              >
                Stavingsstukken
              </span>
            </Grid>
            <Grid md={2}>
              <div
                className={`animatable-400 animatable-fade-in-out-quart epb-service-tab-${
                  // isFifthTabEnable ? :'disable'
                  stepNumber === 5 ? 'selected' : 'unSelected'
                } vietnam-regular cursor-pointer `}
                onClick={() => {
                  setStepNumber(5)
                }}
              >
                Aangifte
              </div>
            </Grid>
          </Grid>
          <Grid container md={2} justify="flex-end" alignItems="center">
            <button
              style={{ opacity: userRole === 'Extern' ? 0.2 : 1 }}
              className="epb-service-button"
              onClick={() => {
                setButtonClick(!isButtonClick)
              }}
            >
              Bewaren
            </button>
          </Grid>
          <Grid container md={1}></Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container justify="center" alignItems="center">
          <Grid container xs={7} sm={5}>
            <CustomSelect
              data={dropDownData}
              setParentSelectedValue={setStepNumber}
              value={stepNumber}
            />
          </Grid>
          <Grid container xs={3} sm={5} justify="flex-end">
            <button
              style={{ opacity: userRole === 'Extern' ? 0.2 : 1 }}
              className="epb-service-button"
              onClick={() => {
                setButtonClick(!isButtonClick)
              }}
            >
              Bewaren
            </button>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>

      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      {stepNumber === 1 ? (
        <StepOne
          token={token}
          projectServiceId={projectServiceId}
          isButtonClick={
            stepNumber === 1 && userRole !== 'Extern' ? isButtonClick : false
          }
          stepOneData={stepOneData}
          projectId={projectId}
          epbFormId={epbFormId}
          setEpbFormId={setEpbFormId}
          setSignedDeclarationFile={setSignedDeclarationFile}
          setStartStatementArchitect={setStartStatementArchitect}
          setStartStatementBuilder={setStartStatementBuilder}
          setVentilatievoorontwerp={setVentilatievoorontwerp}
          setAdviceReportSectionState={handleAdviceReportSectionState}
          userRole={userRole}
        />
      ) : stepNumber === 2 ? (
        <StepTwo
          token={token}
          projectServiceId={projectServiceId}
          isButtonClick={
            stepNumber === 2 && userRole !== 'Extern' ? isButtonClick : false
          }
          setStepNumber={setStepNumber}
          projectId={projectId}
          epbFormId={epbFormId}
          ventilatievoorontwerp={ventilatievoorontwerp}
          stepTwoData={stepTwoData}
          adviceReportSectionState={adviceReportSectionState}
          userRole={userRole}
        />
      ) : stepNumber === 3 ? (
        <StepThree
          token={token}
          projectServiceId={projectServiceId}
          isButtonClick={
            stepNumber === 3 && userRole !== 'Extern' ? isButtonClick : false
          }
          projectId={projectId}
          epbFormId={epbFormId}
          architectFileName={startStatementArchitect}
          builderFileName={startStatementBuilder}
          stepThreeData={stepThreeData}
          userRole={userRole}
        />
      ) : stepNumber === 4 ? (
        <StepFour
          token={token}
          projectServiceId={projectServiceId}
          isButtonClick={
            stepNumber === 4 && userRole !== 'Extern' ? isButtonClick : false
          }
          projectId={projectId}
          epbFormId={epbFormId}
          stepFourData={stepFourData}
          userRole={userRole}
        />
      ) : stepNumber === 5 ? (
        <StepFive
          token={token}
          projectServiceId={projectServiceId}
          isButtonClick={
            stepNumber === 5 && userRole !== 'Extern' ? isButtonClick : false
          }
          projectId={projectId}
          epbFormId={epbFormId}
          signedDeclarationFile={signedDeclarationFile}
          stepFiveData={stepFiveData}
          userRole={userRole}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Grid>
  )
}
