import React, { useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import './_document_selector.scss'

function DocumentItem(props) {
  const [buttonState, setButtonState] = useState(false)
  const { fileData, updateFile, isRadio } = props

  const handleButtonClick = () => {
    updateFile(!buttonState)
    setButtonState(!buttonState)
  }

  function getFileType(title) {
    let extension = title.split('.')
    extension = extension[extension.length - 1].toLowerCase() // we do this in case there is another dot before the extension i.e. filename123.something.pdf
    if (extension === 'pdf') {
      return require('../../../../assets/svg/doc/pdf.svg')
    } else if (extension === 'doc' || extension === 'docx') {
      return require('../../../../assets/svg/doc/doc.svg')
    } else if (extension === 'xls' || extension === 'xlsx') {
      return require('../../../../assets/svg/doc/xls.svg')
    } else if (extension === 'jpg') {
      return require('../../../../assets/svg/doc/jpg.svg')
    } else {
      return require('../../../../assets/svg/doc/other.svg')
    }
  }

  return (
    <div className="document-selector-modal-item vertical-center">
      <div
        className="document-selector-modal-check-container center-content relative flex-shrink-disable"
        onClick={handleButtonClick}
        style={{ background: buttonState ? '#7DA5C2' : 'rgba(0, 0, 0, 0)' }}
      >
        {isRadio && (
          <img
            src={require('../../../../assets/svg/icon/check_blue.svg')}
            alt="check blue"
            className="document-selector-modal-check-icon animatable-400 ease-in-out-quart absolute"
            style={{ opacity: buttonState ? 0 : 1 }}
          />
        )}
        <img
          src={require('../../../../assets/svg/icon/check_white.svg')}
          alt="check white"
          className="document-selector-modal-check-icon animatable-400 ease-in-out-quartabsolute"
          style={{ opacity: buttonState ? 1 : 0 }}
        />
      </div>
      <img
        src={getFileType(fileData.title)}
        alt="document type"
        className="document-selector-modal-type"
      />
      <p className="main-font-semi-bold font-size-14 font-color-black margin-left-5">
        {fileData.title}
      </p>
    </div>
  )
}

export const DocumentSelector = forwardRef((props, ref) => {
  const [modalState, setModalState] = useState(false)
  const [files, setFiles] = useState([])
  const [errorState, setErrorState] = useState(false)

  const { emailModalRef } = props

  useImperativeHandle(ref, () => ({
    openModal: (data) => {
      setModalState(true)
      setNewFiles(data)
    },
  }))

  const closeModal = () => {
    setFiles([])
    setModalState(false)
  }

  function setNewFiles(data) {
    let newData = []
    data.forEach((file) => {
      let newFile = file
      newFile.enabled = false
      newData.push(file)
    })
    setFiles(newData)
  }

  function updateFile(index, value) {
    let newData = JSON.parse(JSON.stringify(files))
    newData[index].enabled = value
    errorState && setErrorState(false)
    setFiles(newData)
  }

  const goNext = () => {
    let selectedFiles = []
    files.forEach((file) => {
      if (file.enabled) {
        selectedFiles.push(file)
      }
    })
    if (selectedFiles.length > 0) {
      setErrorState(false)
      closeModal()
      emailModalRef.current.openModal(selectedFiles)
    } else {
      setErrorState(true)
    }
  }

  return (
    <div
      className="fixed-origin animatable-400 ease-in-out-quart"
      style={{
        opacity: modalState ? 1 : 0,
        left: modalState ? '0px' : '100%',
        zIndex: 9999,
      }}
    >
      <Grid container>
        <div className="document-selector-container center-content">
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={9}
            lg={6}
            xl={6}
            justify="center"
            alignItems="center"
            className="height-auto padding-15"
          >
            <div className="document-selector-modal flex-column">
              <Grid container justify="space-between" alignItems="center">
                <p className="main-font-semi-bold font-size-18 font-color-black">
                  Documenten
                </p>
                <button
                  type="button"
                  className="document-selector-modal-close"
                  onClick={closeModal}
                >
                  <img
                    src={require('../../../../assets/svg/icon/cross.svg')}
                    alt="close"
                    className="height-auto width-auto object-fit-contain"
                  />
                </button>
              </Grid>
              <Grid
                container
                className=" margin-top-10 height-auto overflow-hidden"
              >
                <div className="document-selector-modal-documents">
                  {files.length > 0 &&
                    files.map((file, index) => (
                      <DocumentItem
                        fileData={file}
                        updateFile={(value) => {
                          updateFile(index, value)
                        }}
                      />
                    ))}
                </div>
              </Grid>
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                className="margin-top-10"
              >
                <p
                  className="main-font-regular font-size-14 font-color-red margin-right-10 animatable-400 ease-in-out-quart"
                  style={{
                    opacity: errorState ? 1 : 0,
                    transform: errorState
                      ? 'translateY(0px)'
                      : 'translateY(-25px)',
                  }}
                >
                  Select atleast one document
                </p>
                <button
                  type="button"
                  className="document-selector-modal-send center-content"
                  onClick={goNext}
                >
                  <p className="main-font-semi-bold font-size-14 font-color-white">
                    Volgende
                  </p>
                </button>
              </Grid>
            </div>
          </Grid>
        </div>
      </Grid>
    </div>
  )
})

DocumentItem.propTypes = {
  isRadio: PropTypes.bool,
  fileData: PropTypes.array,
  updateFile: PropTypes.func,
}

DocumentItem.defaultProps = {
  isRadio: false,
  fileData: [],
  updateFile: () => {},
}
