import React, { useState, useEffect } from 'react'
import CheckIcon from '../../../assets/svg/icon_check_white.svg'
import './_private_client.scss'
import { Grid } from '@material-ui/core'
import axios from 'axios'
import PropTypes from 'prop-types'
import { privateCompanyForm } from '../../shared/sample_data.js'

export default function PrivateClient(props) {
  const {
    setCompanyId,
    setContactId,
    companyId,
    setPopupState,
    setPopupMessageState,
  } = props
  const [companyFormState, setCompanyFormState] = useState([])
  const [animateState, setAnimateState] = useState(false)

  const [contactName, setContactName] = useState('')
  const [contactLastName, setContactLastName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactTelephone, setContactTelephone] = useState('')
  const [contactProfession, setContactProfession] = useState('')
  const [contactPassword, setContactPassword] = useState('')
  const [contactRole, setContactRole] = useState(2)

  // we keep this data as company since this isn't actually stored in the user table
  const [companyAddress, setCompanyAddress] = useState('')
  const [companyNr, setCompanyNr] = useState('')
  const [companyBus, setCompanyBus] = useState('')
  const [companyZip, setCompanyZip] = useState('')
  const [companyCity, setCompanyCity] = useState('')

  // contact form error state vars
  const [createdContactId, setCreatedContactId] = useState(0)
  const [contactNameError, setContactNameError] = useState(false)
  const [contactLastNameError, setContactLastNameError] = useState(false)
  const [contactEmailError, setContactEmailError] = useState(false)
  const [contactProfessionError, setContactProfessionError] = useState(false)
  const [contactTelephoneError, setContactTelephoneError] = useState(false)
  const [contactPasswordError, setContactPasswordError] = useState(false)

  const [companyAddressError, setCompanyAddressError] = useState(false)
  const [companyNrError, setCompanyNrError] = useState(false)
  const [companyZipError, setCompanyZipError] = useState(false)
  const [companyCityError, setCompanyCityError] = useState(false)

  const [buttonDisableState, setButtonDisableState] = useState(false)
  const token = localStorage.getItem('authToken')

  useEffect(() => {
    setCompanyFormState(JSON.parse(JSON.stringify(privateCompanyForm)))
    if (companyId) {
      getPrivateCompany()
    }
  }, [companyId])

  function getPrivateCompany() {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/admin/company/get/${companyId}`,
        {},
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        const company = response.data.company
        const user = response.data.user[0]
        setCreatedContactId(user.id)
        setContactName(user.firstName)
        setContactLastName(user.lastName)
        setContactEmail(user.email)
        setContactProfession(user.profession || '')
        setContactTelephone(user.phone)
        setContactRole(user.roleId)
        setCompanyAddress(company.name)
        setCompanyNr(company.no)
        setCompanyBus(company.bus)
        setCompanyZip(company.postalCode)
        setContactPassword(user.password)
        setCompanyCity(company.city)
      })
      .catch((error) => {})
  }

  const handleSubmitClick = () => {
    setAnimateState(true)
    const validate = isFieldsValid()
    if (validate) {
      setButtonDisableState(true)
      let apiData = {
        companyInfo: {
          id: typeof companyId === 'number' ? companyId : 0,
          name: contactName + contactLastName,
          companyAddress: companyAddress,
          BTW: '-',
          no: companyNr,
          bus: companyBus,
          postalCode: companyZip,
          city: companyCity,
          generalEmail: contactEmail,
          generalTelephone: contactTelephone,
          private: true,
        },
        contactInfo: {
          id: createdContactId !== 0 ? createdContactId : 0,
          companyId: companyId,
          firstName: contactName,
          lastName: contactLastName,
          phone: contactTelephone,
          profession: contactProfession,
          email: contactEmail,
          password: contactPassword,
          roleId: contactRole,
        },
      }
      axios
        .post(process.env.REACT_APP_API_URL + `/admin/company/add`, apiData, {
          headers: { authorization: `bearer ${token}` },
        })
        .then((response) => {
          const data = response.data
          let responseMessage = {
            success: true,
            heading: '',
            message: '',
          }
          if (data !== undefined) {
            if (data.companyId > 0) {
              setCompanyId(data.companyId)
            }
            if (data.message !== null && data.message !== undefined) {
              if (data.message.userId > 0) {
                setPopupState(true)
                responseMessage = {
                  isSuccess: true,
                  heading: 'Record Saved',
                  message: 'Company added successfully',
                  popupState: setPopupState,
                }
                setPopupMessageState(responseMessage)
                setContactId(data.message.userId)
                setCreatedContactId(data.message.userId)
              } else {
                setButtonDisableState(false)
                setPopupState(true)
                responseMessage = {
                  isSuccess: false,
                  heading: 'Record Not Saved',
                  message: data.message.message,
                  popupState: setPopupState,
                }
                setPopupMessageState(responseMessage)
              }
            } else {
              setPopupState(true)
              responseMessage = {
                isSuccess: true,
                heading: 'Record Saved',
                message: 'Company added successfully',
                popupState: setPopupState,
              }
              setPopupMessageState(responseMessage)
            }
          }
        })
        .catch((error) => {
          setButtonDisableState(false)
          setPopupState(true)
          let responseMessage = {
            isSuccess: false,
            heading: 'Record Not Saved',
            message: error.response.data.message
              ? error.response.data.message
              : 'Something wents wrong',
            popupState: setPopupState,
          }
          setPopupMessageState(responseMessage)
        })
    }
    setTimeout(() => {
      setAnimateState(false)
    }, 2000)
  }

  const handleContactName = (event) => {
    contactNameError && setContactNameError(false)
    setButtonDisableState(false)
    setContactName(event.target.value)
  }

  const handleContactLastName = (event) => {
    contactLastNameError && setContactLastNameError(false)
    setButtonDisableState(false)
    setContactLastName(event.target.value)
  }

  const handleContactTelephone = (event) => {
    if (event.target.validity.valid) {
      contactTelephoneError && setContactTelephoneError(false)
    } else {
      !contactTelephoneError && setContactTelephoneError(true)
    }
    setButtonDisableState(false)
    setContactTelephone(event.target.value)
  }

  const handleContactProfession = (event) => {
    contactProfessionError && setContactProfessionError(false)
    setButtonDisableState(false)
    setContactProfession(event.target.value)
  }

  const handleContactEmail = (event) => {
    if (event.target.validity.valid) {
      contactEmailError && setContactEmailError(false)
    } else {
      !contactEmailError && setContactEmailError(true)
    }
    setButtonDisableState(false)
    setContactEmail(event.target.value)
  }

  const handleContactPassword = (event) => {
    contactPasswordError && setContactPasswordError(false)
    setButtonDisableState(false)
    setContactPassword(event.target.value)
  }

  const handleContactRole = (event) => {
    setButtonDisableState(false)
    setContactRole(event.target.value)
  }

  const handleCompanyAddress = (event) => {
    companyAddressError && setCompanyAddressError(false)
    setButtonDisableState(false)
    setCompanyAddress(event.target.value)
  }

  const handleCompanyNr = (event) => {
    companyNrError && setCompanyNrError(false)
    setButtonDisableState(false)
    setCompanyNr(event.target.value)
  }

  const handleCompanyBus = (event) => {
    setButtonDisableState(false)
    setCompanyBus(event.target.value)
  }

  const handleCompanyZip = (event) => {
    if (event.target.validity.valid) {
      companyZipError && setCompanyZipError(false)
    } else {
      !companyZipError && setCompanyZipError(true)
    }
    setButtonDisableState(false)
    setCompanyZip(event.target.value)
  }

  const handleCompanyCity = (event) => {
    companyCityError && setCompanyCityError(false)
    setButtonDisableState(false)
    setCompanyCity(event.target.value)
  }

  function isFieldsValid() {
    let isValid = true
    if (contactName === '') {
      isValid = false
      setContactNameError(true)
    }
    if (contactLastName === '') {
      isValid = false
      setContactLastNameError(true)
    }
    if (contactTelephone === '' || contactTelephoneError) {
      isValid = false
      setContactTelephoneError(true)
    }
    if (contactProfession === '') {
      isValid = false
      setContactProfessionError(true)
    }
    if (contactEmail === '' || contactEmailError) {
      isValid = false
      setContactEmailError(true)
    }
    if (contactPassword === '' || contactPassword.length < 8) {
      isValid = false
      setContactPasswordError(true)
    }
    if (companyAddress === '') {
      isValid = false
      setCompanyAddressError(true)
    }
    if (companyNr === '') {
      isValid = false
      setCompanyNrError(true)
    }
    if (companyZip === '' || companyZipError) {
      isValid = false
      setCompanyZipError(true)
    }
    if (companyCity === '' || companyCityError) {
      isValid = false
      setCompanyCityError(true)
    }

    return isValid
  }

  return (
    <Grid container>
      <div className="private-client-main-div">
        <Grid container>
          <div>
            <span className="main-font-semi-bold font-color-black font-size-18">
              Prive persoon
            </span>
          </div>
        </Grid>
        <Grid container>
          <div className="flex-row width-auto margin-top-30" />
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">Naam</p>
            <input
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              value={contactLastName}
              onChange={handleContactLastName}
              style={{
                border: contactLastNameError
                  ? 'solid 1px red'
                  : 'solid 1px #DADADA',
              }}
            />
          </div>
          <div className="flex-column width-auto">
            <p className="main-font-light font-size-14 font-color-grey">
              Voornaam
            </p>
            <input
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              value={contactName}
              onChange={handleContactName}
              style={{
                border: contactNameError
                  ? 'solid 1px red'
                  : 'solid 1px #DADADA',
              }}
            />
          </div>
          <div className="flex-row width-auto horizontal-between">
            <div className="flex-column width-60-p">
              <span className="main-font-regular font-color-grey margin-top-10">
                Adres
              </span>
              <input
                placeholder=""
                value={companyAddress}
                className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
                onChange={handleCompanyAddress}
                style={{
                  border: companyAddressError
                    ? 'solid 1px red'
                    : 'solid 1px #DADADA',
                }}
              />
            </div>
            <div className="flex-column width-19-p">
              <span className="main-font-regular font-color-grey margin-top-10">
                Nr
              </span>
              <input
                placeholder=""
                value={companyNr}
                className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
                onChange={handleCompanyNr}
                style={{
                  border: companyNrError
                    ? 'solid 1px red'
                    : 'solid 1px #DADADA',
                }}
              />
            </div>
            <div className="flex-column width-19-p">
              <span className="main-font-regular font-color-grey margin-top-10">
                Bus
              </span>
              <input
                placeholder=""
                value={companyBus}
                className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
                onChange={handleCompanyBus}
              />
            </div>
          </div>
          <div className="flex-row width-auto horizontal-between">
            <div className="flex-column width-29-p">
              <span className="main-font-regular font-color-grey margin-top-10">
                Postcode
              </span>
              <input
                placeholder=""
                value={companyZip}
                className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
                pattern="[0-9]+"
                onChange={handleCompanyZip}
                autoComplete="new-password"
                style={{
                  border: companyZipError
                    ? 'solid 1px red'
                    : 'solid 1px #DADADA',
                }}
              />
            </div>
            <div className="flex-column width-69-p">
              <span className="main-font-regular font-color-grey margin-top-10">
                Gemeente
              </span>
              <input
                placeholder=""
                value={companyCity}
                className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
                onChange={handleCompanyCity}
                style={{
                  border: companyCityError
                    ? 'solid 1px red'
                    : 'solid 1px #DADADA',
                }}
              />
            </div>
          </div>
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">
              Telefoon
            </p>
            <input
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              pattern="^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$"
              value={contactTelephone}
              onChange={handleContactTelephone}
              style={{
                border: contactTelephoneError
                  ? 'solid 1px red'
                  : 'solid 1px #DADADA',
              }}
            />
          </div>
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">
              Beroep
            </p>
            <input
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              value={contactProfession}
              onChange={handleContactProfession}
              style={{
                border: contactProfessionError
                  ? 'solid 1px red'
                  : 'solid 1px #DADADA',
              }}
            />
          </div>
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">
              E-mail
            </p>
            <input
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              pattern="[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+"
              value={contactEmail}
              onChange={handleContactEmail}
              style={{
                border: contactEmailError
                  ? 'solid 1px red'
                  : 'solid 1px #DADADA',
              }}
            />
          </div>
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">
              Wachtwoord
            </p>
            <input
              className="input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"
              type="password"
              value={contactPassword}
              onChange={handleContactPassword}
              style={{
                border: contactPasswordError
                  ? 'solid 1px red'
                  : 'solid 1px #DADADA',
              }}
            />
          </div>
          <div className="flex-column width-auto margin-top-15">
            <p className="main-font-light font-size-14 font-color-grey">
              Gebruikersgroep
            </p>
            <select
              placeholder=""
              value={contactRole}
              onChange={handleContactRole}
              className="add-contact-person-select main-font-semi-bold font-size-14 font-color-black"
            >
              <option value={2}>Bouwen & verbouwen</option>
              <option value={1}>Verkopen & verhuren</option>
              <option value={5}>Geen Account</option>
              <option value={6}>Externe</option>
            </select>
          </div>
        </Grid>
        <div className="private-client-div-save font-size-14 margin-top-20">
          <button
            className="private-client-button-save"
            onClick={handleSubmitClick}
            disabled={buttonDisableState}
          >
            <img src={CheckIcon} className="private-client-check-icon" alt="" />
            Save
          </button>
        </div>
      </div>
    </Grid>
  )
}

PrivateClient.propTypes = {
  setContactId: PropTypes.func,
  setCompanyId: PropTypes.func,
  companyId: PropTypes.number,
  setShowService: PropTypes.func,
  setPopupState: PropTypes.func,
  setPopupMessageState: PropTypes.func,
}

PrivateClient.defaultProps = {
  setCompanyId: function () {},
  setContactId: function () {},
  setShowService: function () {},
  companyId: 0,
  setPopupState: function () {},
  setPopupMessageState: function () {},
}
