import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import './_residence_amboise.scss'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

export default function ResidenceAmboise(props) {
  const {
    projectId,
    sendCategoryId,
    sendData,
    token,
    buildingData,
    categoryId,
  } = props
  const [category, setCategory] = useState([])
  const [leftCategoryList, setLeftCategoryList] = useState([])
  const [rightCategoryList, setRightCategoryList] = useState([])
  const [update, setUpdate] = useState(false)
  const history = useHistory()

  useEffect(() => {
    console.log('===> projectId', projectId)
    if (projectId != null) {
      getAllCategory(projectId)
    }
  }, [projectId])

  useEffect(() => {
    if (categoryId > 0) {
      handleCategoryClick(category[categoryId - 1])
    }
  }, [categoryId])

  useEffect(() => {}, [update, leftCategoryList, rightCategoryList])

  const getAllCategory = (projectId) => {
    let List = []
    axios
      .post(
        process.env.REACT_APP_API_URL + `/serviceCategory/get/category`,
        {},
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        let data = response.data
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let temp = {
              id: data[i].id,
              title: data[i].title,
              isPresent: i === 0 || i === 7 ? true : false,
              isSelected: false,
            }
            List.push(temp)
          }
          getAllSelectCategory(List)
          setLeftCategoryList(List.slice(0, List.length / 2 + 1))
          setRightCategoryList(List.slice(List.length / 2 + 1, List.length))
        }
      })
      .catch((error) => {})
  }

  const getAllSelectCategory = (List) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          `/serviceCategory/get/projectCategory/` +
          projectId,
        {},
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        let data = response.data

        let CategoryLength = List.length
        if (data.length > 0) {
          for (let j = 0; j < CategoryLength; j++) {
            for (let k = 0; k < data.length; k++) {
              if (List[j].id === data[k].serviceCategoryId) {
                List[j].isPresent = true
                if (List[j].id === sendData.serviceCategoryId) {
                  List[j].isSelected = true
                }
              }
            }
          }
        }
        setCategory(List)
        sendCategoryId(sendData.serviceCategoryId)
      })
      .catch((error) => {
        setCategory(List)
      })
  }

  const handleCategoryClick = (item) => {
    if (item.isPresent) {
      category.forEach((x) => (x.isSelected = false))
      item.isSelected = true
      setUpdate(!update)
      sendCategoryId(item.id)
    } else {
      setUpdate(!update)
      sendCategoryId(item.id)
    }
  }

  return (
    <Grid container item justify="center">
      <Grid container justify="center">
        <Grid container xs={8} sm={9} md={9} direction="column">
          <p className="vietnam-bold font-size-18 font-color-black">
            {buildingData.buildingName === ''
              ? 'Building Name'
              : buildingData.buildingName}
          </p>
          <p className="vietnam-regular font-size-14 font-color-grey">
            {buildingData
              ? `${buildingData.zip} ${buildingData.city}`
              : 'Building Address'}
          </p>
        </Grid>
        <Grid container xs={3} sm={2} md={2}>
          <button
            type="button"
            className="selected-tab-button-style"
            onClick={() => {
              history.push(`/planning/timeline`)
            }}
          >
            Tijdslijn
          </button>
        </Grid>
      </Grid>
      <Grid container xs={1} sm={1} md={1} />
      <Grid container md={12} xs={12} sm={12} justify="center">
        <Grid md={3} sm={3} xs={3} justify="center" alignItems="center">
          {leftCategoryList.map((item, index) => {
            if (index > 0) {
              return (
                <button
                  onClick={() => {
                    item.isPresent && handleCategoryClick(item)
                  }}
                  type="button"
                  className={
                    item.isPresent
                      ? item.isSelected
                        ? 'selected-button-style margin-top-15 vietnam-semi-bold'
                        : 'enable-button-style margin-top-15 vietnam-semi-bold'
                      : 'disable-button-style margin-top-15 vietnam-semi-bold'
                  }
                >
                  <p>{item.title}</p>
                </button>
              )
            }
          })}
        </Grid>

        <Grid
          container
          md={5}
          sm={5}
          xs={5}
          justify="center"
          alignItems="flex-start"
        >
          <Grid
            container
            item
            md={2}
            sm={2}
            xs={2}
            justify="center"
            alignItems="center"
          >
            <div className="margin-top-30 width-auto">
              {leftCategoryList.length < 2 ? (
                <>
                  <div className="residence-amboise-line-style-first-left margin-top-40" />
                </>
              ) : leftCategoryList.length < 3 ? (
                <>
                  <div className="residence-amboise-line-style-first-left margin-top-40" />
                  <div className="residence-amboise-line-style-second-left margin-top-40" />
                </>
              ) : leftCategoryList.length < 4 ? (
                <>
                  <div className="residence-amboise-line-style-first-left margin-top-40" />
                  <div className="residence-amboise-line-style-second-left margin-top-40" />
                  <div className="residence-amboise-line-style-third-left margin-top-40" />
                </>
              ) : (
                <>
                  <div className="residence-amboise-line-style-first-left margin-top-40" />
                  <div className="residence-amboise-line-style-second-left margin-top-40" />
                  <div className="residence-amboise-line-style-third-left margin-top-40" />
                  <div className="residence-amboise-line-style-fourth-left margin-top-40" />
                </>
              )}
            </div>
          </Grid>
          <Grid
            container
            item
            md={7}
            sm={7}
            xs={7}
            justify="center"
            alignItems="center"
          >
            <div className="residence-left-margin-center-button flex-column center-content width-auto">
              {/* <button
                type="button"
                className="big-button-style residence-amboise-buttons-large vietnam-semi-bold "
              >
                <p>Algemene informatie</p>
              </button> */}
              {leftCategoryList.length > 0 && (
                <button
                  onClick={() => {
                    leftCategoryList[0].isPresent &&
                      handleCategoryClick(leftCategoryList[0])
                  }}
                  type="button"
                  className={
                    leftCategoryList[0].isPresent
                      ? leftCategoryList[0].isSelected
                        ? 'selected-button-style  vietnam-semi-bold'
                        : 'enable-button-style  vietnam-semi-bold'
                      : 'disable-button-style  vietnam-semi-bold'
                  }
                >
                  <p>{leftCategoryList[0].title}</p>
                </button>
              )}
              <div className="residence-amboise-line-style-vertical margin-top-20"></div>
              <div className="center-content">
                <img
                  alt="building"
                  className="residence-amboise-image vertical-center"
                  src={require('../../../../assets/svg/icon/business-and-trade.svg')}
                />
              </div>
            </div>
          </Grid>
          <Grid
            container
            item
            md={2}
            sm={2}
            xs={2}
            justify="center"
            alignItems="center"
          >
            <div className="width-auto margin-top-30">
              {rightCategoryList.length < 2 ? (
                <>
                  <div className="residence-amboise-line-style-first-right margin-top-40" />
                </>
              ) : rightCategoryList.length < 3 ? (
                <>
                  <div className="residence-amboise-line-style-first-right margin-top-40" />
                  <div className="residence-amboise-line-style-second-right margin-top-40" />
                </>
              ) : rightCategoryList.length < 4 ? (
                <>
                  <div className="residence-amboise-line-style-first-right margin-top-40" />
                  <div className="residence-amboise-line-style-second-right margin-top-40" />
                  <div className="residence-amboise-line-style-third-right margin-top-40" />
                </>
              ) : (
                <>
                  <div className="residence-amboise-line-style-first-right margin-top-40" />
                  <div className="residence-amboise-line-style-second-right margin-top-40" />
                  <div className="residence-amboise-line-style-third-right margin-top-40" />
                  <div className="residence-amboise-line-style-fourth-right margin-top-40" />
                </>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid md={3} sm={3} xs={3} justify="center" alignItems="center">
          {/* <div className=''> */}
          {rightCategoryList.map((item) => {
            return (
              <button
                onClick={() => {
                  item.isPresent && handleCategoryClick(item)
                }}
                type="button"
                className={
                  item.isPresent
                    ? item.isSelected
                      ? 'selected-button-style residence-amboise-buttons-small margin-top-15 vietnam-semi-bold'
                      : 'enable-button-style residence-amboise-buttons-small margin-top-15 vietnam-semi-bold'
                    : 'disable-button-style residence-amboise-buttons-small margin-top-15 vietnam-semi-bold'
                }
              >
                <p>{item.title}</p>
              </button>
            )
          })}
          {/* </div> */}
        </Grid>

        {/* </div> */}
      </Grid>
      <Grid container item justify="center">
        <div className="residence-amboise-end-line margin-top-20" />
      </Grid>
    </Grid>
  )
}
