import React, { useState, useEffect, createRef } from 'react'
import { Grid } from '@material-ui/core'
import axios from 'axios'
import { StatementDetails } from '../step_three/step_three'
import { restructureDate } from '../step_two/step_two'
import './_step_five.scss'

export default function StepFive(props) {
  const {
    token,
    signedDeclarationFile,
    stepFiveData,
    projectServiceId,
    userRole,
    epbFormId,
  } = props
  const [file, setFile] = useState([])
  const [firstDocumentDate, setFirstDocumentDate] = useState('')
  const [firstDocumentName, setFirstDocumentName] = useState('')
  const [firstDocumentOriginalName, setFirstDocumentOriginalName] = useState('')
  const [secondDocumentDate, setSecondDocumentDate] = useState('')
  const [secondDocumentName, setSecondDocumentName] = useState('')
  const [secondDocumentOriginalName, setSecondDocumentOriginalName] =
    useState('')
  const [formData, setFormData] = useState(new FormData())

  useEffect(() => {
    if (signedDeclarationFile !== undefined && signedDeclarationFile !== '') {
      setFile(signedDeclarationFile)
      // setFile(signedDeclarationFile)
    }
  }, [signedDeclarationFile])

  useEffect(() => {
    if (JSON.stringify(stepFiveData) !== '{}') {
      if (
        stepFiveData.service_document_categories[0].service_documents.length > 0
      ) {
        setFirstDocumentDate(
          restructureDate(
            stepFiveData.service_document_categories[0].service_documents[0]
              .createdAt,
          ),
        )
        setFirstDocumentName(
          stepFiveData.service_document_categories[0].service_documents[0]
            .fileName,
        )
        setFirstDocumentOriginalName(
          stepFiveData.service_document_categories[0].service_documents[0]
            .documentTitle,
        )
      }
      if (
        stepFiveData.service_document_categories[1].service_documents.length > 0
      ) {
        setSecondDocumentDate(
          restructureDate(
            stepFiveData.service_document_categories[1].service_documents[0]
              .createdAt,
          ),
        )
        setSecondDocumentName(
          stepFiveData.service_document_categories[1].service_documents[0]
            .fileName,
        )
        setSecondDocumentOriginalName(
          stepFiveData.service_document_categories[1].service_documents[0]
            .documentTitle,
        )
      }
      let tempFormData = new FormData()
      let serviceDocumentCategoryId =
        stepFiveData.service_document_categories.length > 0
          ? stepFiveData.service_document_categories[2].id
          : 0
      let serviceDocumentId =
        stepFiveData.service_document_categories.length > 0
          ? stepFiveData.service_document_categories[2].service_documents
              .length > 0
            ? stepFiveData.service_document_categories[2].service_documents[0]
                .id
            : 0
          : 0
      tempFormData.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData.append('projectServiceId', projectServiceId)
      tempFormData.append('serviceDocumentId', serviceDocumentId)
      setFormData(tempFormData)
    }
  }, [stepFiveData])
  useEffect(() => {}, [firstDocumentName, secondDocumentName])

  function downloadImage(documentName, documentOriginalName) {
    if (documentName) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/documents/download_file/${documentName}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          const imageUrl = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = imageUrl
          a.download = documentOriginalName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(imageUrl)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <Grid container className="fade-in-translate animatable-400 ">
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            <img
              alt="info"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            />
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Voorlopige eindaangifte
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <Grid container xs={10} sm={10} md={6}>
            <p className="step-three-description vietnam-regular">
              Na ingave van de stavingsstukken in jullie EPB-berekening hebben
              we de voorlopige eindaangifte opgemaakt. Gelieve deze na te kijken
              en ons door te geven of deze aangifte definitief ingediend mag
              worden (Hou er rekening mee dat er niets meer kan gewijzigd worden
              eens de definitieve eindaangifte is ingediend)
            </p>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container xs={10} sm={6} md={2}>
            <a
              // href={firstDocumentName!==''&& process.env.REACT_APP_API_URL+`/documents/download_file/${firstDocumentName}`}
              // download
              onClick={() =>
                downloadImage(firstDocumentName, firstDocumentOriginalName)
              }
              className={`${
                firstDocumentName !== ''
                  ? 'enabled-button-style'
                  : 'disabled-button-style'
              } cursor-pointer flex-row vertical-center`}
              justify="center"
            >
              <img
                alt=""
                src={
                  firstDocumentName !== ''
                    ? require('../../../../assets/svg/icon/download.svg')
                    : require('../../../../assets/svg/icon/download_grey.svg')
                }
                className={
                  firstDocumentName !== ''
                    ? ''
                    : 'width-2 height-2 margin-left-10'
                }
              />

              <p className="vietnam-semi-bold center-text width-auto">{`${
                firstDocumentName !== '' ? 'Download' : ''
              }`}</p>
            </a>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <p className="step-three-description vietnam-regular">
              {firstDocumentDate !== '' && `Opgemaakt op ${firstDocumentDate}`}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>

      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            <img
              alt="info"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            />
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Definitieve eindaangifte{' '}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <Grid container xs={10} sm={10} md={6}>
            <p className="step-three-description vietnam-regular">
              In bijlage de definitieve eindaangifte, deze documenten zijn reeds
              door ons ondertekend. Deze aangifte kan niet meer worden
              aangepast. Aan alle eisen is ruim voldaan, er volgen dus geen
              boetes. Uw EPB-dossier wordt hierbij dan ook afgesloten. Gelieve
              ons nog de laatste bladzijde van het hoofdformulier ondertekend
              terug te bezorgen aub
            </p>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container xs={10} sm={6} md={2}>
            <a
              // href={secondDocumentName!==''&& process.env.REACT_APP_API_URL+`/documents/download_file/${secondDocumentName}`}
              // download
              onClick={() =>
                downloadImage(secondDocumentName, secondDocumentOriginalName)
              }
              className={`${
                secondDocumentName !== ''
                  ? 'enabled-button-style'
                  : 'disabled-button-style'
              } cursor-pointer flex-row vertical-center`}
              justify="center"
            >
              <img
                alt=""
                src={
                  secondDocumentName !== ''
                    ? require('../../../../assets/svg/icon/download.svg')
                    : require('../../../../assets/svg/icon/download_grey.svg')
                }
                className={
                  secondDocumentName !== ''
                    ? ''
                    : 'width-2 height-2 margin-left-10'
                }
              />

              <p className="vietnam-semi-bold center-text width-auto">{`${
                secondDocumentName !== '' ? 'Download' : ''
              }`}</p>
            </a>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <p className="step-three-description vietnam-regular">
              {secondDocumentDate !== '' &&
                `Opgemaakt op ${secondDocumentDate}`}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            <img
              alt="info"
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            />
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Ondertekende aangifte
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={7}>
          <StatementDetails
            backendFileName={signedDeclarationFile.viewName}
            file={file}
            setFile={setFile}
            uploadPath={
              process.env.REACT_APP_API_URL +
              '/ebp_form/ondertekende_aangifte_document'
            }
            deletePath={
              process.env.REACT_APP_API_URL +
              '/ebp_form/ondertekende_aangifte_document_delete'
            }
            buttonText="Upload"
            token={token}
            projectServiceId={projectServiceId}
            formData={formData}
            buttonDisableState={userRole === 'Extern'}
            epbFormId={epbFormId}
          />
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
    </Grid>
  )
}
