import React, {
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  createRef,
} from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import LabelSelect from '../../residence/components/actions_information/document_details/label_select/label_select'
import axios from 'axios'

import StepSeven from '../step_seven/step_seven'

import './_stepsix.scss'
const FormData = require('form-data')

function DocumentButtonDropzone(props) {
  const { setModalState, setFiles } = props

  const onDrop = useCallback((files) => {
    setModalState(true)
    setFiles(files)
  }, [])

  const accept =
    'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,image/jpeg, image/png, video/webm, video/x-misvideo, video/mpeg, video/mp4, .dwg'
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept })

  return (
    <div {...getRootProps({ style: { outline: 'none' } })}>
      <button type="button" className="flex-row stepsix-import center-content">
        <input className="height-auto width-auto" {...getInputProps()} />
        <img
          src={require('../../../assets/svg/pin.svg')}
          alt="pin svg"
          className="stepsix-import-icon"
        />
        <p className="vietnam-medium font-size-12 font-color-semi-dark-grey margin-left-5">
          Import
        </p>
      </button>
    </div>
  )
}

const DocumentDropzone = forwardRef((props, ref) => {
  const { data, token, serviceId, services } = props
  // const [fileNames, setFileNames] = useState([])
  const [selectedValue, setSelectedValue] = useState(0)
  const userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'))
  const [fileNames, setFileNames] = useState([])
  const [selectedFiles, setFiles] = useState([])
  const [modalState, setModalState] = useState(false)
  const [forceUpdateState, setForceUpdateState] = useState(false)
  const [fileUploadProgress, setFileUploadProgress] = useState(0)

  useEffect(() => {
    const formData = new FormData()
    formData.append('serviceId', serviceId)
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('docsInput', selectedFiles[i])
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + `/documents/uploadDocumentByService`,
        formData,
        {
          headers: {
            authorization: `bearer ${token}`,
          },
          onUploadProgress: (progress) => {
            const { total, loaded } = progress
            const totalSize = total / 1000000
            const totalLoaded = loaded / 1000000
            const percentage = (totalLoaded / totalSize) * 100
            setFileUploadProgress(percentage.toFixed(2))
          },
        },
      )
      .then((response) => {
        setFileUploadProgress(0)
        if (response.data.success === true) {
          let uploadedDocNames = response.data.uploadedDocNames
          services.forEach((item) => {
            if (item.serviceId === serviceId) {
              selectedFiles.map((file, index) => {
                var obj = {}
                obj['title'] = file.name
                obj['size'] = file.size
                obj['label'] = data[selectedValue - 1].label
                obj['file'] = uploadedDocNames[index]
                item.documents.push(obj)
              })
            }
          })
        }
      })
      .catch((error) => {})
  }, [forceUpdateState])

  const onDrop = useCallback(
    (files) => {
      setModalState(true)
      setFiles(files)
    },
    [selectedFiles],
  )

  const accept =
    'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,image/jpeg, image/png, video/webm, video/x-misvideo, video/mpeg, video/mp4, .dwg'
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    noClick: true,
  })

  useImperativeHandle(ref, () => ({
    filesToReturn() {
      return fileNames
    },
  }))

  const handleFilesLabel = (value) => {
    const date = new Date()
    selectedFiles.forEach((item) => {
      item.label = data[value - 1].label
      item.date = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
      item.person = `${userDetails.firstName} ${userDetails.lastName}`
      fileNames.push(item)
    })
    setFileNames(fileNames)
  }

  const deleteFile = (data, i) => {
    let tempFiles = []
    fileNames.forEach((item) => {
      var obj = {}
      obj['date'] = item.date
      obj['label'] = item.label
      obj['title'] = item.path
      obj['path'] = item.path
      obj['person'] = item.person
      tempFiles.push(obj)
    })
    tempFiles.splice(i, 1)
    setFileNames(tempFiles)
    services.forEach((item) => {
      if (item.serviceId === serviceId) {
        item.documents = tempFiles
      }
    })
  }

  return (
    <div
      className="height-auto width-auto relative"
      {...getRootProps({ style: { outline: 'none' } })}
    >
      <input className="height-auto width-auto" {...getInputProps()} />
      <div className="flex-column stepsix-document-container">
        {fileNames.map((item, index) =>
          fileUploadProgress !== 0 ? (
            <div className="flex-row vertical-center">
              <p className="main-font-semi-bold vietnam-bold font-size-12 font-color-sky-blue">
                Upload
              </p>
              <div className="slider">
                <div
                  className="slider-thumb"
                  style={{ width: fileUploadProgress * 2 }}
                />
              </div>
              <p className="main-font-semi-bold font-size-12 font-color-sky-blue">
                {fileUploadProgress}%
              </p>
            </div>
          ) : (
            <div className="flex-row vertical-center width-auto horizontal-between margin-bottom-15">
              <div className="flex-row vertical-center">
                <img
                  alt=""
                  className="document-details-image"
                  src={
                    item.path.split('.')[1] === 'pdf'
                      ? require('../../../assets/svg/doc/pdf.svg')
                      : item.path.split('.')[1].includes('doc')
                      ? require('../../../assets/svg/doc/doc.svg')
                      : item.path.split('.')[1].includes('xls')
                      ? require('../../../assets/svg/doc/xls.svg')
                      : item.path.split('.')[1].includes('jpg')
                      ? require('../../../assets/svg/doc/jpg.svg')
                      : require('../../../assets/svg/doc/other.svg')
                  }
                />
                <h1 className="margin-left-15 vietnam-medium font-size-12 font-color-black">
                  {item.path}
                </h1>
              </div>
              <div className="flex-row">
                <button
                  type="button"
                  onClick={() => {
                    deleteFile(item, index)
                  }}
                >
                  <img
                    src={require('../../../assets/svg/close.svg')}
                    alt="close"
                    className="stepsix-document-close-icon cursor-pointer"
                  />
                </button>
              </div>
            </div>
          ),
        )}
      </div>
      <div className="flex-row flex-end float-item-right">
        <DocumentButtonDropzone
          fileNames={fileNames}
          setFileNames={setFileNames}
          modalState={modalState}
          setModalState={setModalState}
          data={data}
          selectedFiles={selectedFiles}
          setFiles={setFiles}
          token={token}
        />
      </div>
      <LabelSelect
        modalState={modalState}
        setModalState={setModalState}
        data={data}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        handleFilesLabel={handleFilesLabel}
        forceUpdateState={forceUpdateState}
        setForceUpdateState={setForceUpdateState}
      />
    </div>
  )
})

export default function StepSix(props) {
  const { stepSevenData, setStepSixButtonState } = props
  const [documentRef, setDocumentRef] = useState([])
  const [data, setData] = useState([])
  const [buttonDisableState, setButtonDisableState] = useState(false)
  const token = localStorage.getItem('authToken')
  const services = JSON.parse(localStorage.getItem('services'))
  const selectedServiceIds = JSON.parse(
    localStorage.getItem('selectedServiceIds'),
  )
  const labelData = [
    {
      id: 1,
      label: 'Intentieverklaringen',
      info: 'Alle ondertekende documenten van bouwheer en aannemers',
    },
    { id: 2, label: 'Bouwvergunning', info: '' },
    {
      id: 3,
      label: 'Plannen',
      info: 'As-built plannen, stabiliteitsplannen, leidingsplannen, …',
    },
    { id: 4, label: 'Verslagen', info: 'Werfverslagen of andere meldingen.' },
    {
      id: 5,
      label: 'Nutsleidingen – huisaansluitingen – verborgen elementen',
      info: 'Werfverslagen of andere meldingen.',
    },
    { id: 6, label: 'Plaatsbeschrijvingen', info: '' },
    { id: 7, label: 'Offertes/facturen', info: '' },
    { id: 8, label: 'Foto’s', info: 'Bouwmaterialen' },
    {
      id: 9,
      label: 'Gebruikte materialen',
      info: 'Bouwmaterialen, technieken + technische fiches.',
    },
    { id: 10, label: 'Handleidingen', info: '' },
    { id: 11, label: 'Keuringen', info: 'Keuringsattesten of metingen.' },
    {
      id: 12,
      label: 'Oplevering',
      info: 'PV voorlopige oplevering, PV definitieve oplevering',
    },
    { id: 13, label: 'Overige', info: '' },
  ]

  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/service/services_meta_by_sids`,
        {
          serviceIds: selectedServiceIds,
        },
        { headers: { authorization: `bearer ${token}` } },
      )

      .then((response) => {
        let data = response.data
        let refs = []
        data.forEach((item) => {
          refs.push(createRef(0))
        })
        setDocumentRef(refs)
        setData(data)
      })
      .catch((error) => {})
  }, [])

  const uploadDocument = (serviceId) => {}
  const handleSaveDocumentClick = (event) => {
    event.preventDefault()
    localStorage.setItem('services', JSON.stringify(services))
    setButtonDisableState(true)
    setStepSixButtonState(true)
  }

  return (
    <>
      {stepSevenData.message_1 === undefined && (
        <Grid container>
          <Grid container item sm={12}>
            <h1 className="vietnam-bold font-size-12 font-color-black margin-top-25">
              Heeft u reeds documenten die we nodig hebben voor deze dienst?
            </h1>
          </Grid>
          <Grid container item sm={12} className="height-disable">
            <Grid container item sm={12} className="height-disable">
              <div className="margin-top-50" />
            </Grid>
            {data.map((item, i) => (
              <>
                <Grid container item sm={5} className="height-disable">
                  <Grid container item sm={12}>
                    <h1 className="vietnam-bold font-size-16 margin-top-30 font-color-black">
                      {item.title}
                    </h1>
                  </Grid>
                  <Grid
                    container
                    item
                    sm={12}
                    justify="space-between"
                    alignItems="flex-end"
                    className="relative"
                  >
                    <div className="flex-row">
                      <p className="vietnam-regular font-size-14 font-color-semi-dark-grey">
                        Documenten
                      </p>
                    </div>
                    <div className="flex-column">
                      <p className="vietnam-regular font-size-14 font-color-semi-dark-grey text-direction-rtl">
                        Upload document : Drag & drop<br></br>het document
                      </p>
                    </div>
                    <div className="stepsix-arrow-container">
                      <img
                        src={require('../../../assets/svg/arrow_curve.svg')}
                        alt="curved arrow"
                        className="height-auto width-auto object-fit-contain"
                      />
                    </div>
                  </Grid>
                  <Grid container item sm={12}>
                    <div
                      className="stepsix-dropdown-container padding-25"
                      onDrag={uploadDocument(item.id)}
                    >
                      <DocumentDropzone
                        ref={documentRef[i]}
                        data={labelData}
                        token={token}
                        serviceId={item.id}
                        projectId={JSON.parse(
                          localStorage.getItem('projectId'),
                        )}
                        services={services}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container item sm={1} className="height-disable" />
              </>
            ))}
          </Grid>
          <button
            onClick={handleSaveDocumentClick}
            type="button"
            className={`project-application-billinfo-submit vietnam-semibold cursor-pointer project-application-submit`}
            disabled={buttonDisableState}
          >
            Volgende
            <img
              alt="arrow_right"
              className="width-1 height-1"
              src={require('../../../assets/svg/icon/arrow_right.svg')}
            />
          </button>
        </Grid>
      )}
      {stepSevenData.message_1 !== undefined && (
        <StepSeven data={stepSevenData} />
      )}
    </>
  )
}

DocumentDropzone.propTypes = {
  type: PropTypes.string,
}

DocumentDropzone.defaultProps = {
  type: 'document',
}

DocumentButtonDropzone.propTypes = {
  fileNames: PropTypes.array,
  setFileNames: PropTypes.func,
  type: PropTypes.string,
}

DocumentButtonDropzone.defaultProps = {
  fileNames: [],
  setFileNames: () => {},
  type: 'document',
}
