import React, { useState } from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import './_step_seven.scss'
import LikeIcon from '../../../assets/png/icon/like2.png'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  paper: {
    width: '80%',
    maxHeight: '90%',
    height: '380px',
  },
})

export default function StepSeven(props) {
  const { data } = props
  const classes = useStyles()
  const history = useHistory()

  const [modalState, setModalState] = useState(true)
  const [buttonDisableState, setButtonDisableState] = useState(false)

  const handleButtonClick = () => {
    setButtonDisableState(true)
    setModalState(false)
    history.push('/dashboard')
  }

  return (
    <Dialog
      open={modalState}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      scroll="paper"
      classes={{ paper: classes.paper }}
    >
      <div className="width-auto height-60 center-content flex-column step-seven-dialog-div">
        {/* <ThumbUp className="font-color-sky-blue"/> */}
        <div className="margin-top-10">
          <img alt="like" src={LikeIcon} />
        </div>
        <div className="margin-top-30 center-content">
          <span className="vietnam-bold center-text">{data.message_1}</span>
        </div>
        <div className="margin-top-10 center-content">
          <span className="vietnam-bold center-text">{data.message_2}</span>
        </div>
        <div className="width-50-p width-mobile-auto margin-top-25">
          <button
            className="core-custom-button vietnam-semibold cursor-pointer"
            onClick={handleButtonClick}
            disabled={buttonDisableState}
          >
            Terug naar de homepagina
          </button>
        </div>
      </div>
    </Dialog>
  )
}
