import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer'
import BeVietnamRegular from './BeVietnam-Regular.ttf'
import BeVietnamMedium from './BeVietnam-Medium.ttf'
import BeVietnamSemiBold from './BeVietnam-SemiBold.ttf'
import BeVietnamBold from './BeVietnam-Bold.ttf'
import Logo from './logo.png'
import pdfIcon from './pdf.png'
import docIcon from './doc.png'
import jpgIcon from './jpg.png'
import xlsIcon from './xls.png'
import otherIcon from './other.png'
import { standardStyles } from '../../../shared/shared_functions'

Font.register({
  family: 'vietnam',
  fonts: [
    { src: BeVietnamRegular, fontStyle: 'normal', fontWeight: 'normal' },
    { src: BeVietnamMedium, fontStyle: 'normal', fontWeight: 'medium' },
    { src: BeVietnamSemiBold, fontStyle: 'normal', fontWeight: 'semibold' },
    { src: BeVietnamBold, fontStyle: 'normal', fontWeight: 'bold' },
  ],
})

const styles = StyleSheet.create({
  ...standardStyles,
  ...{
    containerLarge: {
      width: 1280,
    },
    textGrey: {
      color: '#6F6F6F',
    },
    textDarkGrey: {
      color: '#4A4A4A',
    },
    blueBar: {
      backgroundColor: '#DDE6EC',
      height: 33,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
})

export const SafetyPDF = (props) => {
  const { data, building } = props
  return (
    <Document>
      <Page size={{ width: 1440 }} style={styles.pageLarge}>
        <View style={[styles.column, styles.widthAuto, styles.alignCenter]}>
          <View
            style={[
              styles.widthAuto,
              styles.row,
              styles.alignCenter,
              { height: 60, borderBottomWidth: 1, borderBottomColor: '#CCC' },
            ]}
          >
            <Image
              style={{
                width: 36,
                height: 36,
                objectFit: 'contain',
                marginLeft: 25,
              }}
              src={Logo}
            />
            <Text
              style={[styles.mainFontMedium, { fontSize: 16, marginLeft: 10 }]}
            >
              Veiligheidscoördinatie
            </Text>
          </View>
          <View style={[styles.column, styles.containerLarge]}>
            <Text
              style={[styles.mainFontSemiBold, { fontSize: 18, marginTop: 20 }]}
            >
              {building.fileName}
            </Text>
            <Text
              style={[
                styles.mainFontRegular,
                styles.textGrey,
                { fontSize: 14 },
              ]}
            >{`${building.zip} - ${building.city}`}</Text>
          </View>
          <View style={[styles.blueBar, { marginTop: 20 }]}>
            <View style={[styles.containerLarge, styles.row]}>
              <Text style={[styles.mainFontRegular, { fontSize: 16 }]}>
                Gegevens werken
              </Text>
            </View>
          </View>
          <View style={[styles.row, styles.containerLarge, { marginTop: 20 }]}>
            <View style={[styles.column]}>
              <Text
                style={[
                  styles.mainFontRegular,
                  styles.textDarkGrey,
                  { fontSize: 14, width: 305 },
                ]}
              >
                Startdatum werken
              </Text>
              <Text style={[styles.mainFontSemiBold, { fontSize: 14 }]}>
                {data.startDate.split(' ')[0]}
              </Text>
            </View>
            <View style={[styles.column]}>
              <Text
                style={[
                  styles.mainFontRegular,
                  styles.textDarkGrey,
                  { fontSize: 14, width: 305 },
                ]}
              >
                Einddatum werken
              </Text>
              <Text style={[styles.mainFontSemiBold, { fontSize: 14 }]}>
                {data.endDate.split(' ')[0]}
              </Text>
            </View>
          </View>
          <View style={[styles.blueBar, { marginTop: 20 }]}>
            <View style={[styles.containerLarge, styles.row]}>
              <Text style={[styles.mainFontRegular, { fontSize: 16 }]}>
                Planning
              </Text>
            </View>
          </View>
          <View style={[styles.column, styles.containerLarge]}>
            {data.PlanningData.map((item) => (
              <View style={[styles.row, styles.widthAuto, { marginTop: 20 }]}>
                <View style={[styles.column, { width: 305 }]}>
                  <Text
                    style={[
                      styles.mainFontRegular,
                      styles.textDarkGrey,
                      { fontSize: 14 },
                    ]}
                  >
                    Fase
                  </Text>
                  <Text style={[styles.mainFontSemiBold, { fontSize: 14 }]}>
                    {item.phase}
                  </Text>
                </View>
                <View style={[styles.column, { width: 305 }]}>
                  <Text
                    style={[
                      styles.mainFontRegular,
                      styles.textDarkGrey,
                      { fontSize: 14 },
                    ]}
                  >
                    Startdatum werken
                  </Text>
                  <Text style={[styles.mainFontSemiBold, { fontSize: 14 }]}>
                    {item.startDate.split(' ')[0]}
                  </Text>
                </View>
                <View style={[styles.column, { width: 305 }]}>
                  <Text
                    style={[
                      styles.mainFontRegular,
                      styles.textDarkGrey,
                      { fontSize: 14 },
                    ]}
                  >
                    Einddatum werken
                  </Text>
                  <Text style={[styles.mainFontSemiBold, { fontSize: 14 }]}>
                    {item.endDate.split(' ')[0]}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={[styles.blueBar, { marginTop: 20 }]}>
            <View style={[styles.containerLarge, styles.row]}>
              <Text style={[styles.mainFontRegular, { fontSize: 16 }]}>
                Contacten voor deze werf
              </Text>
            </View>
          </View>
          <View style={[styles.column, styles.containerLarge]}>
            {data.ResourceData.map((item) => (
              <View
                style={[
                  styles.row,
                  styles.widthAuto,
                  { justifyContent: 'space-between', marginTop: 25 },
                ]}
              >
                <View style={[styles.column, { flex: 1 }]}>
                  <Text
                    style={[
                      styles.mainFontRegular,
                      styles.textDarkGrey,
                      { fontSize: 14 },
                    ]}
                  >
                    Naam
                  </Text>
                  <Text style={[styles.mainFontSemiBold, { fontSize: 14 }]}>
                    {item.project}
                  </Text>
                </View>
                <View style={[styles.column, { flex: 1 }]}>
                  <Text
                    style={[
                      styles.mainFontRegular,
                      styles.textDarkGrey,
                      { fontSize: 14 },
                    ]}
                  >
                    Hoedanigheid
                  </Text>
                  <Text style={[styles.mainFontSemiBold, { fontSize: 14 }]}>
                    {item.capacity}
                  </Text>
                </View>
                <View style={[styles.column, { flex: 1 }]}>
                  <Text
                    style={[
                      styles.mainFontRegular,
                      styles.textDarkGrey,
                      { fontSize: 14 },
                    ]}
                  >
                    Telefoonnummer
                  </Text>
                  <Text style={[styles.mainFontSemiBold, { fontSize: 14 }]}>
                    {item.phone}
                  </Text>
                </View>
                <View style={[styles.column, { flex: 1 }]}>
                  <Text
                    style={[
                      styles.mainFontRegular,
                      styles.textDarkGrey,
                      { fontSize: 14 },
                    ]}
                  >
                    E-mail
                  </Text>
                  <Text style={[styles.mainFontSemiBold, { fontSize: 14 }]}>
                    {item.email}
                  </Text>
                </View>
                <View style={[styles.column, { flex: 1 }]}>
                  <Text
                    style={[
                      styles.mainFontRegular,
                      styles.textDarkGrey,
                      { fontSize: 14 },
                    ]}
                  >
                    Verstuur Email
                  </Text>
                  <Text style={[styles.mainFontSemiBold, { fontSize: 14 }]}>
                    {item.send_email !== undefined &&
                    item.send_email !== null &&
                    item.send_email
                      ? 'Ja'
                      : 'Nee'}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={[styles.blueBar, { marginTop: 20 }]}>
            <View style={[styles.containerLarge, styles.row]}>
              <Text style={[styles.mainFontRegular, { fontSize: 16 }]}>
                Veiligheids-en gezondheidsplan
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.row,
              styles.widthAuto,
              styles.justifyCenter,
              { marginTop: 20 },
            ]}
          >
            {data.safetyHealthPlanFile !== undefined &&
              data.safetyHealthPlanFile !== null &&
              data.safetyHealthPlanFile !== '' && (
                <View style={[styles.containerLarge, styles.row]}>
                  <View
                    style={{
                      height: 40,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Image
                      style={{ height: 40, width: 40, objectFit: 'contain' }}
                      src={
                        data.safetyHealthPlanFile.split('.')[1] === 'pdf'
                          ? pdfIcon
                          : data.safetyHealthPlanFile.split('.')[1] === 'doc'
                          ? docIcon
                          : data.safetyHealthPlanFile.split('.')[1] === 'xls'
                          ? xlsIcon
                          : data.safetyHealthPlanFile.split('.')[1] === 'jpg'
                          ? jpgIcon
                          : otherIcon
                      }
                    />
                    <Text
                      style={[
                        styles.mainFontSemiBold,
                        { fontSize: 14, marginLeft: 10 },
                      ]}
                    >
                      {data.safetyHealthPlanFile}
                    </Text>
                  </View>
                </View>
              )}
          </View>
          <View style={[styles.blueBar, { marginTop: 20 }]}>
            <View style={[styles.containerLarge, styles.row]}>
              <Text style={[styles.mainFontRegular, { fontSize: 16 }]}>
                Werfverslagen
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.row,
              styles.widthAuto,
              styles.justifyCenter,
              { marginTop: 20 },
            ]}
          >
            <View style={[styles.containerLarge, styles.column]}>
              {data.siteReports.map((item) => (
                <View
                  style={{
                    height: 40,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    style={{ height: 40, width: 40, objectFit: 'contain' }}
                    src={
                      item.split('.')[1] === 'pdf'
                        ? pdfIcon
                        : item.split('.')[1] === 'doc'
                        ? docIcon
                        : item.split('.')[1] === 'xls'
                        ? xlsIcon
                        : item.split('.')[1] === 'jpg'
                        ? jpgIcon
                        : otherIcon
                    }
                  />
                  <Text
                    style={[
                      styles.mainFontSemiBold,
                      { fontSize: 14, marginLeft: 10 },
                    ]}
                  >
                    {item}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={[styles.blueBar, { marginTop: 20 }]}>
            <View style={[styles.containerLarge, styles.row]}>
              <Text style={[styles.mainFontRegular, { fontSize: 16 }]}>
                Postinterventiedossier
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.row,
              styles.widthAuto,
              styles.justifyCenter,
              { marginTop: 20 },
            ]}
          >
            {data.postInterventionFile !== undefined &&
              data.postInterventionFile !== null &&
              data.postInterventionFile !== '' && (
                <View style={[styles.containerLarge, styles.column]}>
                  <View
                    style={{
                      height: 40,
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Image
                      style={{ height: 40, width: 40, objectFit: 'contain' }}
                      src={
                        data.postInterventionFile.split('.')[1] === 'pdf'
                          ? pdfIcon
                          : data.postInterventionFile.split('.')[1] === 'doc'
                          ? docIcon
                          : data.postInterventionFile.split('.')[1] === 'xls'
                          ? xlsIcon
                          : data.postInterventionFile.split('.')[1] === 'jpg'
                          ? jpgIcon
                          : otherIcon
                      }
                    />
                    <Text
                      style={[
                        styles.mainFontSemiBold,
                        { fontSize: 14, marginLeft: 10 },
                      ]}
                    >
                      {data.postInterventionFile}
                    </Text>
                  </View>
                </View>
              )}
          </View>
        </View>
      </Page>
    </Document>
  )
}
