import React, { createRef, useEffect, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Grid, Hidden } from '@material-ui/core'
import './_loginpage.scss'
import axios from 'axios'
import LoginLogo from '../../assets/png/Keuringsfirma_logo_pos_RGB.png'
import ArrowRight from '../../assets/svg/icon/arrow_right_white.svg'
import { CustomCheckBox } from '../shared_components/custom_checkbox/custom_checkbox'
import { isLocalDataValid } from '../shared/shared_functions'
import NewPasswordModal from './new_password_modal/new_password_modal'

export default function LoginPage() {
  const [emailState, setEmailState] = useState('')
  const [formErrorState, setFormErrorState] = useState({
    email: '',
    password: '',
  })
  const [checkboxRef, setCheckboxRef] = useState(createRef(0))
  const [isRedirect, setRedirectState] = useState(false)
  const [passwordState, setPasswordState] = useState('')
  const [animateState, setAnimateState] = useState(false)
  const [invalidCredentialState, setInvalidCredentialState] = useState(false)
  const [modalState, setModalState] = useState(false)
  const history = useHistory()
  const emailRegex = /[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+/
  const screenHeight = window.screen.height

  useEffect(() => {
    if (isLocalDataValid()) {
      let loggedInUserDetails = JSON.parse(
        localStorage.getItem('loggedInUserDetails'),
      )
      if (loggedInUserDetails !== undefined && loggedInUserDetails !== null) {
        if (loggedInUserDetails.isRememberClicked) {
          setRedirectState(true)
        }
      }
    }
  }, [])

  const keyDownHandler = ({ keyCode }) => {
    if (keyCode === 13) {
      handleLoginClick()
    }
  }

  const onEmailChange = (event) => {
    setEmailState(event.target.value)
    setInvalidCredentialState(false)
    const values = { ...formErrorState }
    if (event.target.value.length === 0) {
      values.email = 'Email is required'
      setFormErrorState(values)
    } else if (emailRegex.test(event.target.value) === true) {
      values.email = ''
      setFormErrorState(values)
    } else {
      values.email = 'Email not valid'
      setFormErrorState(values)
    }
  }

  const handleLoginClick = () => {
    const values = { ...formErrorState }
    setInvalidCredentialState(false)
    if (emailState.length === 0) {
      values.email = 'Email is required'
      setFormErrorState(values)
    }
    if (passwordState.length === 0) {
      values.password = 'Password is required'
      setFormErrorState(values)
    }
    if (
      emailState.length > 0 &&
      passwordState.length > 0 &&
      values.email.length === 0 &&
      values.password.length === 0
    ) {
      const loginDetails = {
        email: emailState,
        password: passwordState,
      }
      axios
        .post(process.env.REACT_APP_API_URL + `/user/login`, loginDetails)
        .then((response) => {
          localStorage.setItem('authToken', response.data.token)
          let loggedInUserDetails = response.data.user
          loggedInUserDetails['isRememberClicked'] =
            checkboxRef.current.getValue()
          localStorage.setItem(
            'loggedInUserDetails',
            JSON.stringify(loggedInUserDetails),
          )
          setInvalidCredentialState(false)
          if (
            response.data.user.role.title === 'Architect/Builder' ||
            response.data.user.role.title === 'Buyer/Seller'
          ) {
            setInvalidCredentialState(true)
          } else {
            if (!loggedInUserDetails.login_first) {
              setModalState(true)
            } else {
              history.push('/dashboard')
            }
          }
        })
        .catch((error) => {
          setInvalidCredentialState(true)
        })
    }
    setAnimateState(true)
    setTimeout(() => {
      setAnimateState(false)
    }, 2500)
  }

  const onPasswordChange = (event) => {
    setPasswordState(event.target.value)
    setInvalidCredentialState(false)
    const values = { ...formErrorState }
    if (event.target.value.length === 0) {
      values.password = 'Password is required'
      setFormErrorState(values)
    } else if (event.target.value.length < 8) {
      values.password = 'Password must be 8 digits long'
      setFormErrorState(values)
    } else {
      values.password = ''
      setFormErrorState(values)
    }
  }

  return (
    <Grid container style={{ height: '100vh' }}>
      <Hidden lgUp>
        <div className="loginpage-detail-container-mobile">
          <div className="flex-row horizontal-start width-90-p margin-top-25 height-6-p">
            <img
              alt="logo"
              src={LoginLogo}
              className="loginpage-mobile-logo object-fit-contain width-40-p  margin-top-5 horizontal-start"
            />
          </div>
          <img
            alt="login"
            src={LoginLogo}
            className={`loginpage-main-image-mobile horizontal-center object-fit-contain width-90-p ${
              screenHeight > 900
                ? 'height-40-p margin-top-10'
                : 'height-28-p margin-top-5'
            }`}
          />
          {/*<div className = ' loginpage-welcome-div'>*/}
          {/*  <p className={`vietnam-bold font-color-white ${screenHeight > 900 ? 'font-size-40' : 'font-size-25'} `}>*/}
          {/*    Welkom!*/}
          {/*  </p>*/}
          {/*</div>*/}
          <div className={`loginpage-detail-container `}>
            <input
              placeholder="Email"
              type="email"
              onChange={(event) => onEmailChange(event)}
              onKeyDown={keyDownHandler}
              className={`${
                formErrorState.email.length > 0 && animateState === true
                  ? 'input-error-border '
                  : ''
              }
                                        input-custom-control margin-top-10`}
            />
            {formErrorState.email.length > 0 && animateState === true ? (
              <span className="font-color-red font-size-15 margin-left-10 margin-top-5">
                {formErrorState.email}
              </span>
            ) : null}

            <input
              placeholder="Wachtwoord"
              type="password"
              onChange={(event) => onPasswordChange(event)}
              onKeyDown={keyDownHandler}
              className={`${
                formErrorState.password.length > 0 && animateState === true
                  ? 'input-error-border '
                  : ''
              }
                                input-custom-control margin-top-10`}
            />
            {formErrorState.password.length > 0 && animateState === true ? (
              <span className="font-color-red font-size-15 margin-left-10 margin-top-5">
                {formErrorState.password}
              </span>
            ) : null}

            <div className="width-auto flex-row horizontal-between margin-top-10 margin-bottom-5">
              <CustomCheckBox label="Ingelogd blijven" ref={checkboxRef} />

              <a
                href={
                  'mailto:info@docubuild.be?subject=Wachtwoord%20vergeten&body='
                }
                className="cursor-pointer font-color-sky-blue font-size-14 text-align-end"
              >
                Wachtwoord vergeten?
              </a>
            </div>

            <button
              className="core-custom-button vietnam-semibold cursor-pointer margin-top-10 relative center-content"
              onClick={handleLoginClick}
              type="submit"
            >
              Log In
              <img
                alt="arrow_right"
                src={ArrowRight}
                className="float-item-right loginpage-arrow-right absolute"
              />
            </button>

            <p
              className="font-color-red margin-top-10 margin-bottom-10"
              style={
                invalidCredentialState === true
                  ? { opacity: 100 }
                  : { opacity: 0 }
              }
            >
              Invalid credentials
            </p>

            <p className="font-color-black font-size-15">Nog geen account?</p>

            <a
              href={
                'mailto:info@docubuild.be?subject=Nog%20geen%20account&body='
              }
              className="cursor-pointer font-color-sky-blue font-size-15"
            >
              Contacteer ons!
            </a>
          </div>
          {/*<div className = ' loginpage-end-text-div width-90-p'>*/}
          {/*  <p className= {`vietnam-bold ${screenHeight < 900 ? 'font-size-14' : 'font-size-25'} font-color-white center-text`}>*/}
          {/*    DOCUBUILD is een 360° management tool voor bouw en vastgoedprojecten waar op een gebruiksvriendelijke manier documentenbeheer gecentraliseerd wordt!*/}
          {/*  </p>*/}
          {/*</div>*/}

          {isRedirect && <Redirect to="/dashboard" />}
        </div>
      </Hidden>
      <Hidden mdDown>
        <Grid container item xs={12} className="loginpage-grid-container">
          <Grid container item sm={9} style={{ height: '100vh' }}>
            <div className="width-auto height-auto padding-25 relative center-content">
              <div
                className="flex-row horizontal-start width-auto height-20-p absolute"
                style={{ left: '25px', top: '0px' }}
              >
                <img
                  alt="logo"
                  src={LoginLogo}
                  className="loginpage-mobile-logo object-fit-contain width-10-p margin-top-5 horizontal-start"
                />
              </div>
              <img
                alt="login"
                src={LoginLogo}
                className={`loginpage-main-image-mobile object-fit-contain width-60-p`}
              />
              {/*<div className = "absolute" style = {{bottom: '25px', left: '25px'}}>*/}
              {/*  <div className = 'width-auto'>*/}
              {/*    <p className={`vietnam-bold font-color-white  width-70-p ${screenHeight > 900 ? 'font-size-30' : 'font-size-25'} `}>*/}
              {/*      Welkom!*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*  <div className = ' loginpage-end-text-div width-60-p'>*/}
              {/*    <p className= {`vietnam-bold ${screenHeight < 900 ? 'font-size-14' : 'font-size-20'} font-color-white`}>*/}
              {/*      DOCUBUILD is een 360° management tool voor bouw en vastgoedprojecten waar op een gebruiksvriendelijke manier documentenbeheer gecentraliseerd wordt!*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </Grid>
          <Grid container item xs={3} className="loginpage-detail-container">
            <Grid
              container
              alignItems="center"
              justify="center"
              className="margin-top-20"
            >
              <Grid item xs={8} sm={8} md={6} lg={6}>
                <div className="flex-row horizontal-center">
                  <img
                    // src={require('../../assets/svg/logo_color/7a.svg')}
                    src={LoginLogo}
                    alt="logo color"
                    className="loginpage-logo object-fit-content"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center">
              <Grid
                item
                xs={10}
                sm={10}
                md={10}
                lg={10}
                className="center-text margin-top-20"
              >
                {/*<h1 className="vietnam-bold font-size-30 font-color-black">*/}
                {/*  Welkom*/}
                {/*</h1>*/}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              className="margin-top-15"
            >
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <input
                  placeholder="Email"
                  type="email"
                  onChange={(event) => onEmailChange(event)}
                  onKeyDown={keyDownHandler}
                  className={`${
                    formErrorState.email.length > 0 && animateState === true
                      ? 'input-error-border '
                      : ''
                  }
                                          input-custom-control`}
                />
                {formErrorState.email.length > 0 && animateState === true ? (
                  <span className="font-color-red font-size-15 margin-left-10 margin-top-5">
                    {formErrorState.email}
                  </span>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              className="margin-top-15"
            >
              <Grid item xs={10} sm={10} md={10} lg={10}>
                {/* <TextFieldComponent {...{variant:"outlined", placeholder:"Password", fullwidth:true,
                              state: passwordState, changeHandler: event => onPasswordChange(event),
                              type: "password", error: passwordErrorState.length > 0 ? true : false,
                              helperText: passwordErrorState }}/> */}
                <input
                  placeholder="Wachtwoord"
                  type="password"
                  onChange={(event) => onPasswordChange(event)}
                  onKeyDown={keyDownHandler}
                  className={`${
                    formErrorState.password.length > 0 && animateState === true
                      ? 'input-error-border '
                      : ''
                  }
                                  input-custom-control`}
                />
                {formErrorState.password.length > 0 && animateState === true ? (
                  <span className="font-color-red font-size-15 margin-left-10 margin-top-5">
                    {formErrorState.password}
                  </span>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              className="margin-top-15"
            >
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                className="loginpage-justify-start"
              >
                <CustomCheckBox label="Ingelogd blijven" ref={checkboxRef} />
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                className="loginpage-justify-end"
                justify="flex-end"
              >
                <a
                  href={
                    'mailto:info@docubuild.be?subject=Wachtwoord%20vergeten&body='
                  }
                  className="cursor-pointer font-color-sky-blue text-align-end"
                >
                  Wachtwoord vergeten?
                </a>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              className="margin-top-15"
            >
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <button
                  className="core-custom-button vietnam-semibold center-content relative cursor-pointer"
                  onClick={handleLoginClick}
                  type="submit"
                >
                  Log In
                  <img
                    alt="arrow_right"
                    src={ArrowRight}
                    className="float-item-right loginpage-arrow-right absolute"
                  />
                </button>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center">
              <span
                className="font-color-red"
                style={
                  invalidCredentialState === true
                    ? { opacity: 100 }
                    : { opacity: 0 }
                }
              >
                Invalid credentials
              </span>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              className="margin-top-25"
            >
              <Grid
                item
                xs={10}
                sm={10}
                md={10}
                lg={10}
                className="center-content"
              >
                <span className="font-color-black font-size-20">
                  Nog geen account?
                </span>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              className="margin-top-5"
            >
              <Grid item xs={8} sm={8} md={8} lg={8} className="center-text">
                <a
                  href={
                    'mailto:info@docubuild.be?subject=Nog%20geen%20account&body='
                  }
                  className="cursor-pointer font-color-sky-blue font-size-20"
                >
                  Contacteer ons!
                </a>
              </Grid>
            </Grid>
          </Grid>
          {isRedirect && <Redirect to="/dashboard" />}
        </Grid>
      </Hidden>
      <NewPasswordModal modalState={modalState} setModalState={setModalState} />
    </Grid>
  )
}
