import React, { createRef, useEffect, useState } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { StepFiveA } from './step_five_a/step_five_a'

export default function StepFive(props) {
  const {
    setStepNumber,
    data,
    addData,
    isAddButtonClick,
    setAddButtonState,
    selectedUser,
  } = props

  const [disableSubmitState, setDisableSubmitState] = useState(true)
  const [isEmailValid, setEmailValidity] = useState(false)
  const [isSubmitClick, setSubmitState] = useState(false)
  const emailRegex = /[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+/
  var i = 0
  const [facturateData, setFacturateData] = useState([])
  const FormData = {
    id: 0,
    userId: selectedUser ? selectedUser.id : 0,
    companyId: selectedUser.company && selectedUser.company.id,
    companyName: selectedUser.company !== null ? selectedUser.company.name : '',
    BTW: selectedUser.company !== null ? selectedUser.company.BTW : '',
    emailAddress:
      selectedUser.company !== null ? selectedUser.company.generalEmail : '',
    address:
      selectedUser.company !== null ? selectedUser.company.companyAddress : '',
    no: selectedUser.company !== null ? selectedUser.company.no : '',
    bus: selectedUser.company !== null ? selectedUser.company.bus : '',
    postalCode:
      selectedUser.company !== null ? selectedUser.company.postalCode : '',
    city: selectedUser.company !== null ? selectedUser.company.city : '',
    billingPercent: 100,
    isCompany: selectedUser?.company?.private ? false : true,
    name: selectedUser.lastName,
    firstName: selectedUser.firstName,
    private: selectedUser.company.private || false,
  }
  const [forceUpdateState, setForceUpdateState] = useState(false)
  const [formRef, setFormRefs] = useState([])

  useEffect(() => {
    let tempArray = []
    Array(2)
      .fill()
      .map((item) => {
        tempArray.push(createRef(0))
      })
    setFormRefs(tempArray)
  }, [])

  useEffect(() => {
    if (isEmailValid) {
      if (!isAddButtonClick) {
        addData([data[0]])
        setForceUpdateState(true)
      }
      setStepNumber(6)
    }
  }, [isEmailValid])

  useEffect(() => {
    if (forceUpdateState) {
      setStepNumber(6)
    }
  }, [forceUpdateState])

  useEffect(() => {
    if (data.length === 0) {
      getCompanyDetails()
      handleFormsArray(FormData)
    }
    var arr = []
    while (i < 101) {
      arr.push(i)
      i += 5
    }
    setFacturateData(arr)
    checkSelectedType()
  }, [data])

  useEffect(() => {
    getCompanyDetails()
  }, [selectedUser])

  function handleFormsArray(FormData) {
    addData([...data, FormData])
    //another array
  }

  const checkSelectedType = () => {
    data.map((item) => isFormValid(item))
  }

  function isFormValid(FormData) {
    if (
      FormData.isCompany &&
      FormData.companyName &&
      FormData.BTW &&
      FormData.emailAddress &&
      FormData.address &&
      FormData.no &&
      FormData.postalCode &&
      FormData.city
    ) {
      setDisableSubmitState(false)
    } else if (
      !FormData.isCompany &&
      FormData.name &&
      FormData.firstName &&
      FormData.emailAddress &&
      FormData.address &&
      FormData.no &&
      FormData.postalCode &&
      FormData.city
    ) {
      setDisableSubmitState(false)
    } else {
      setDisableSubmitState(true)
    }
  }

  const setSecondFormVisibility = () => {
    setAddButtonState(true)
    if (data.length === 1) {
      var tempData = [...data]
      tempData[0].billingPercent = 50
      addData(tempData)
      FormData.billingPercent = 50
      handleFormsArray(FormData)
      checkSelectedType()
    }
  }

  const handleTypeSubmit = () => {
    setSubmitState(true)
    checkAllFormsEmailValidity(data)
  }

  const checkAllFormsEmailValidity = (data) => {
    data.map((item, i) => {
      if (i < 2) {
        if (emailRegex.test(item.emailAddress)) {
          setEmailValidity(true)
          return true
        } else {
          setEmailValidity(false)
          return false
        }
      }
    })
  }

  const handleChangeCompanyForm = (i) => (evt) => {
    const value = evt.target.value
    let tempData = [...data]
    let formData = ''
    if (evt.target.name === 'postalCode') {
      if (value.length < 5) {
        formData = {
          ...tempData[i],
          [evt.target.name]: value,
        }
        tempData[i] = formData
        addData(tempData)
      }
    } else if (evt.target.name === 'billingPercent') {
      if (value !== '100%') {
        var remainingPercent = 100 - parseInt(value)
        formData = {
          ...tempData[i],
          [evt.target.name]: value,
        }
        tempData[i] = formData
        addData(tempData)
        if (i === 1) {
          formData = {
            ...tempData[i - 1],
            [evt.target.name]: remainingPercent,
          }
          tempData[i - 1] = formData
          addData(tempData)
        } else if (i === 0) {
          formData = {
            ...tempData[i + 1],
            [evt.target.name]: remainingPercent,
          }
          tempData[i + 1] = formData
          addData(tempData)
        }
      }
    } else {
      formData = {
        ...tempData[i],
        [evt.target.name]: value,
      }
      tempData[i] = formData
      addData(tempData)
    }
    checkSelectedType()
  }

  const getCompanyDetails = () => {
    addData([FormData])
  }

  function handleButtonClick() {
    if (data.length === 2) {
      var tempData = [...data]
      tempData.splice(1, 1)
      tempData.billingPercent = 100
      addData(tempData)
      setAddButtonState(false)
    } else {
      setAddButtonState(false)
    }
  }

  return (
    <Grid container item spacing={2}>
      <Grid container item>
        <p className="project-application-billinfo-edit-label vietnam-regular">
          Facturatie gegevens
        </p>
      </Grid>
      <Grid container item>
        {data.map((item, i) =>
          i < 2 ? (
            <Grid container item md={6} xs={12} sm={12}>
              <StepFiveA
                ref={formRef[i]}
                FormData={item}
                handleChangeCompanyForm={handleChangeCompanyForm}
                isSubmitClick={isSubmitClick}
                facturateData={facturateData}
                checkSelectedType={checkSelectedType}
                formIndex={i}
                isFacturateActive={data.length > 1 ? true : false}
                isEmailValid={isEmailValid}
                setEmailValidity={setEmailValidity}
                setSecondFormVisibility={setSecondFormVisibility}
                isAddButtonClick={isAddButtonClick}
                handleButtonClick={handleButtonClick}
                setFinalData={addData}
                finalData={data}
              />
            </Grid>
          ) : null,
        )}
        {!isAddButtonClick && (
          <Hidden smDown>
            <Grid
              container
              item
              md={6}
              xs={12}
              sm={6}
              justify="center"
              alignItems="center"
            >
              <Grid container item justify="center" alignItems="center">
                <button
                  type="button"
                  className="project-application-billinfo-second-invoice-box flex-column center-content"
                  onClick={setSecondFormVisibility}
                >
                  <img
                    alt="add"
                    className="project-application-billinfo-second-invoice-box-image"
                    src={require('../../../assets/svg/icon/Add.svg')}
                  />
                  <p className="margin-top-20 vietnam-regular">
                    facturatie toevoegen
                  </p>
                </button>
              </Grid>
            </Grid>
          </Hidden>
        )}
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={3}>
        <button
          disabled={disableSubmitState}
          onClick={handleTypeSubmit}
          type="button"
          className={`project-application-billinfo-submit vietnam-semi-bold center-content
              ${
                disableSubmitState === true
                  ? 'cursor-disable'
                  : 'cursor-pointer'
              }`}
        >
          Volgende
          <img
            alt="arrow_right"
            className="width-1 height-1 horizontal-end"
            src={require('../../../assets/svg/icon/arrow_right.svg')}
          />
        </button>
      </Grid>
    </Grid>
  )
}
