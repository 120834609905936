import React from 'react'
import { Grid, Hidden, Container } from '@material-ui/core'

export default function FormState(props) {
  const { stepState, setStepState, hasStepTwo } = props
  return (
    <Grid container item xs={12} justify="center">
      <Hidden smDown>
        <Grid container item xs={12}>
          <Container className="margin-top-25 margin-bottom-20">
            <Grid container item xs={12} alignItems="center">
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 1
                    ? 'project-application-step-blue '
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 0 && setStepState(1)
                }}
              >
                <span className="main-font-semi-bold font-size-14">
                  1. Diensten
                </span>
              </div>
              <div className="project-application-step-hr"></div>
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 2
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 1 && hasStepTwo && setStepState(2)
                }}
              >
                <span className="main-font-semi-bold font-size-14">
                  2. Gebouwen
                </span>
              </div>
              <div className="project-application-step-hr"></div>
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 3
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 2 && setStepState(3)
                }}
              >
                <span className="main-font-semi-bold font-size-14">
                  3. Informatie
                </span>
              </div>
              <div className="project-application-step-hr"></div>
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 4
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 3 && setStepState(4)
                }}
              >
                <p className="main-font-semi-bold font-size-14">
                  4. Contactpersoon
                </p>
              </div>
              <div className="project-application-step-hr"></div>
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 5
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 4 && setStepState(5)
                }}
              >
                <span className=" main-font-semi-bold font-size-14">
                  5. Facturatie
                </span>
              </div>
              <div className="project-application-step-hr"></div>
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 6
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 5 && setStepState(6)
                }}
              >
                <span className="main-font-semi-bold font-size-14">
                  6. Documenten
                </span>
              </div>
            </Grid>
          </Container>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container item xs={12} justify="center">
          <Container maxWidth="lg" className="margin-top-15 margin-bottom-20">
            <Grid container item xs={12} justify="center" alignItems="center">
              <div
                className={
                  'center-content ' +
                  (stepState >= 1
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 0 && setStepState(1)
                }}
              >
                <span className="font-size-12 vietnam-regular">1</span>
              </div>
              <div className="project-application-step-hr"></div>
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 2
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 1 && hasStepTwo && setStepState(2)
                }}
              >
                <span className="font-size-12 vietnam-regular">2</span>
              </div>
              <div className="project-application-step-hr"></div>
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 3
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 2 && setStepState(3)
                }}
              >
                <span className="font-size-12 vietnam-regular">3</span>
              </div>
              <div className=" project-application-step-hr"></div>
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 4
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 3 && setStepState(4)
                }}
              >
                <span className="font-size-12 vietnam-regular">4</span>
              </div>
              <div className="project-application-step-hr"></div>
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 5
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 4 && setStepState(5)
                }}
              >
                <span className="font-size-12 vietnam-regular">5</span>
              </div>
              <div className="project-application-step-hr"></div>
              <div
                className={
                  'center-content height-9 ' +
                  (stepState >= 6
                    ? 'project-application-step-blue'
                    : 'project-application-step-outline')
                }
                onClick={() => {
                  stepState > 5 && setStepState(6)
                }}
              >
                <span className="vietnam-regular">6</span>
              </div>
            </Grid>
          </Container>
        </Grid>
      </Hidden>
    </Grid>
  )
}
