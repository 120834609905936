import React, { useRef } from 'react'
import { Grid } from '@material-ui/core'
import { StatementDetails } from '../../../epb_forms/epb_form_admin/step_three/step_three'
import './_step_1.scss'
import PropTypes from 'prop-types'
import axios from 'axios'

export default function Step1(props) {
  const {
    epbFileState,
    adviseFileState,
    ventType,
    brand,
    type,
    setEPBFileState,
    setAdviseFileState,
    setVentType,
    setBrand,
    setType,
    projectServiceId,
    hasEPB,
  } = props

  const token = localStorage.getItem('authToken')

  return (
    <div className="height-auto width-auto animatable-400 fade-in-translate">
      <div className="ventilation-service-blue-strip margin-top-20 vertical-center">
        <Grid container>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={11} sm={11} md={11}>
            <span className="main-font-regular font-color-black font-size-16">
              Algemene gegevens
            </span>
          </Grid>
        </Grid>
      </div>
      <Grid container alignItems="center">
        <Grid sm={1} xs={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container md={3} direction="column">
            <p className="step-one-heading-label">Type ventilatiesysteem</p>
            <p className="step-one-data-label">{ventType}</p>
          </Grid>
          <Grid container md={3} direction="column">
            <p className="step-one-heading-label">Merk</p>
            <p className="step-one-data-label">{brand}</p>
          </Grid>
          <Grid container md={3} direction="column">
            <p className="step-one-heading-label">Type</p>
            <p className="step-one-data-label">{type}</p>
          </Grid>
        </Grid>
      </Grid>
      {hasEPB !== undefined && !hasEPB && (
        <Grid container>
          <div className="ventilation-service-blue-strip margin-top-30 vertical-center">
            <Grid container>
              <Grid item xs={1} sm={1} md={1}></Grid>
              <Grid item xs={11} sm={11} md={11}>
                <span className="main-font-regular font-color-black font-size-16">
                  EPB rapport / adviesrapport
                </span>
              </Grid>
            </Grid>
          </div>
          <Grid container className="margin-top-0">
            <Grid xs={1} sm={1} md={1} />
            <Grid xs={11} sm={11} md={11}>
              <StatementDetails backendFileName={epbFileState} />
              <StatementDetails backendFileName={adviseFileState} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

Step1.propTypes = {
  adviseFileState: PropTypes.object,
  epbFileState: PropTypes.object,
  ventType: PropTypes.string,
  brand: PropTypes.string,
  type: PropTypes.string,
  setEPBFileState: PropTypes.func,
  setAdviseFileState: PropTypes.func,
  setVentType: PropTypes.func,
  setBrandType: PropTypes.func,
  setType: PropTypes.func,
  ventTypeOptions: PropTypes.array,
  error: PropTypes.bool,
}

Step1.defaultProps = {
  epbFileState: {},
  adviseFileState: {},
  ventType: '',
  brand: '',
  type: '',
  setEPBFileState: function () {},
  setAdviseFileState: function () {},
  setVentType: function () {},
  setBrandType: function () {},
  setType: function () {},
  ventTypeOptions: [],
  error: false,
}
