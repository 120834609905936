import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react'
import { Grid } from '@material-ui/core'
import { Editor } from 'react-draft-wysiwyg'
import { stateToHTML } from 'draft-js-export-html'
import _, { reduce } from 'lodash'
import axios from 'axios'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './_email_modal.scss'

import { EditorState, convertFromHTML, ContentState } from 'draft-js'

function Space() {
  return (
    <Grid container>
      <div className="margin-top-15" />
    </Grid>
  )
}

const EmailEditor = forwardRef((props, ref) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  )

  const { selectedType, buildingData } = props

  useEffect(() => {
    if (selectedType === 1) {
      const factuurMarkup = `<span><bold>Beste<bold/></span><br><br/><span class="gr-content-text">In bijlage vind u de factuur terug voor ${buildingData.shift} te ${buildingData.fileName}.</span><br><span class="gr-content-text">Met vriendelijke groeten het DocuBuild team</span>`
      const blocksFromHtml = convertFromHTML(factuurMarkup)
      const newState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap,
      )
      setEditorState(EditorState.createWithContent(newState))
    } else if (selectedType === 2) {
      const offerMarkup = `<span><bold>Beste<bold/></span><br><br/><span class="gr-content-text">In bijlage vind u de offerte terug voor ${buildingData.shift} te ${buildingData.fileName}.</span><br><span class="gr-content-text">Gelieven ons te laten weten of u de offerte aanvaart</span><br><span class="gr-content-text">Met vriendelijke groeten het DocuBuild team</span>`
      const blocksFromHtml = convertFromHTML(offerMarkup)
      const newState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap,
      )
      setEditorState(EditorState.createWithContent(newState))
    } else {
      setEditorState(EditorState.createEmpty())
    }
  }, [selectedType])

  useImperativeHandle(ref, () => ({
    getEditorContent: () => {
      return editorState.getCurrentContent()
    },
    resetEditorState: () => {
      setEditorState(EditorState.createEmpty())
    },
  }))

  const handleEditorStateChange = (editorStateChange) => {
    setEditorState(editorStateChange)
  }

  return (
    <Editor
      editorState={editorState}
      toolbarClassName="toolbarClassName font-color-black toolbarHide"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName overflow-y-scroll"
      onEditorStateChange={handleEditorStateChange}
    />
  )
})

export const EmailModal = forwardRef((props, ref) => {
  const [modalState, setModalState] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedType, setSelectedType] = useState(0)
  const [emailList, setEmailList] = useState([])
  const [fromState, setFromState] = useState('')
  const [fromStateError, setFromStateError] = useState('')
  const [toState, setToState] = useState('')
  const [toStateError, setToStateError] = useState('')
  const [toDropdownState, setToDropdownState] = useState(false)
  const [bccState, setBccState] = useState('')
  const [bccStateError, setBccStateError] = useState('')
  const [bccDropdownState, setBccDropdownState] = useState(false)
  const [subjectState, setSubjectState] = useState('')
  const [emailSentState, setEmailSentState] = useState(false)
  const [emailAddModalState, setEmailAddModalState] = useState(false)
  const [emailAddModalMouseState, setEmailAddModalMouseState] = useState(false)
  const [selectedEmailList, setSelectedEmailList] = useState([])
  const [buttonDisableState, setButtonDisableState] = useState(false)
  const { buildingData, buildingId, projectServiceId } = props
  const token = localStorage.getItem('authToken')
  const userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'))
  const editorRef = useRef(0)

  useImperativeHandle(ref, () => ({
    openModal: (data) => {
      setSelectedFiles(data)
      setModalState(true)
      getEmails()
    },
  }))

  useEffect(() => {
    if (buildingId && projectServiceId) {
      let subject = `${generateFileNumber()}-${buildingData.shift}-${
        buildingData.buildingName
      }, ${buildingData.zip} ${buildingData.city}`
      setSubjectState(subject)
      setFromState(userDetails.email)
    }
  }, [buildingId, projectServiceId, buildingData])

  function getEmails() {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/admin/company/getAllEmails',
        {
          companyId: buildingData.companyId,
          buildingId,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        // reduce redundancy
        const reduced = _.uniqBy(response.data, 'email')
        setEmailList(reduced)
      })
      .catch((error) => {})
  }

  function generateFileNumber() {
    const buildingZero = '0000'
    let generatedBuildingId =
      buildingZero.substring(0, 4 - buildingId.toString().length) +
      buildingId.toString()
    let generatedProjectServiceId =
      buildingZero.substring(0, 4 - projectServiceId.toString().length) +
      projectServiceId.toString()
    return `${generatedBuildingId}-${generatedProjectServiceId}`
  }

  const sendEmail = () => {
    if (areFieldsValid()) {
      setButtonDisableState(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/user/send_email',
          {
            from_email: fromState,
            to_email: selectedEmailList,
            subject: subjectState,
            bcc: bccState,
            files: selectedFiles,
            service_title: buildingData.shift,
            selectedType: selectedType,
            message: stateToHTML(editorRef.current.getEditorContent()),
            fileNumber:
              '0000'.substring(0, 4 - projectServiceId.toString().length) +
              projectServiceId.toString(),
            building: buildingData,
            admin: `${userDetails.firstName} ${userDetails.lastName}`,
          },
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          },
        )
        .then((response) => {
          setEmailSentState(true)
          setTimeout(() => {
            setEmailSentState(false)
            handleModalClose()
          }, 5000)
        })
        .catch((error) => {
          setButtonDisableState(false)
          console.log(error)
        })
    }
  }

  const handleModalClose = () => {
    resetFields()
    setModalState(false)
  }

  const handleFromState = (event) => {
    if (event.target.validity.valid) {
      fromStateError && setFromStateError(false)
    } else {
      !fromStateError && setFromStateError(true)
    }
    setButtonDisableState(false)
    setFromState(event.target.value)
  }

  const handleToState = (event) => {
    if (event.target.validity.valid) {
      toStateError && setToStateError(false)
    } else {
      !toStateError && setToStateError(true)
    }
    setButtonDisableState(false)
    setToState(event.target.value)
  }

  const handleBccState = (event) => {
    if (event.target.validity.valid) {
      bccStateError && setBccStateError(false)
    } else {
      !bccStateError && setBccStateError(true)
    }
    setButtonDisableState(false)
    setBccState(event.target.value)
  }

  const handleSubjectState = (event) => {
    setButtonDisableState(false)
    setSubjectState(event.target.value)
  }

  const areFieldsValid = () => {
    let validate = true
    if (fromState === '' || fromStateError) {
      validate = false
      setFromStateError(true)
    }
    if (selectedEmailList.length === 0) {
      validate = false
    }
    if (bccStateError) {
      validate = false
    }
    return validate
  }

  const resetFields = () => {
    setToState('')
    setFromState(userDetails.email)
    setBccState('')
    setSelectedType(0)
    let subject = `${generateFileNumber()}-${buildingData.shift}-${
      buildingData.buildingName
    }, ${buildingData.zip} ${buildingData.city}`
    setSubjectState(subject)
    editorRef.current.resetEditorState()

    setToStateError(false)
    setFromStateError(false)
    setBccStateError(false)
  }

  const addSelectedEmail = () => {
    if (!toStateError) {
      setButtonDisableState(false)
      const newSelectedEmailList = [...selectedEmailList]
      newSelectedEmailList.push(toState)
      setEmailAddModalState(false)
      setTimeout(() => {
        setSelectedEmailList(newSelectedEmailList)
        setToState('')
      }, 400)
    }
  }

  function removeSelectedEmail(index) {
    const newSelectedEmailList = [...selectedEmailList]
    newSelectedEmailList.splice(index, 1)
    setSelectedEmailList(newSelectedEmailList)
  }

  return (
    <div
      className="fixed-origin animatable-400 ease-in-out-quart"
      style={{
        opacity: modalState ? 1 : 0,
        left: modalState ? '0px' : '-100%',
        zIndex: 9999,
      }}
    >
      <Grid container>
        <div className="email-modal-container center-content">
          <div className="email-modal">
            <Grid container className="flex-shrink-disable" direction="column">
              <Grid container justify="space-between" alignItems="center">
                <p className="main-font-semi-bold font-size-18 font-color-black">
                  Nieuw email
                </p>
                <img
                  src={require('../../../../assets/svg/icon/cross.svg')}
                  alt="cross"
                  className="email-modal-close"
                  onClick={handleModalClose}
                />
              </Grid>
              <Space />
              <Grid container direction="column">
                <p className="main-font-regular font-size-14 font-color-light-grey margin-bottom-5">
                  Van
                </p>
                <div
                  className="email-modal-field-container"
                  style={{
                    border: fromStateError
                      ? 'solid 1px red'
                      : 'solid 1px #DCDCDC',
                  }}
                >
                  <input
                    className="email-modal-field main-font-semi-bold font-size-14 font-color-black"
                    pattern="[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+"
                    value={fromState}
                    onChange={handleFromState}
                  />
                </div>
              </Grid>
              <Space />
              <Grid container>
                <Grid container item sm={12} className="height-disable">
                  <p className="main-font-regular font-size-14 font-color-light-grey margin-bottom-5">
                    Naar
                  </p>
                  <div className="email-modal-selected-list">
                    {selectedEmailList.length > 0 &&
                      selectedEmailList.map((email, index) => (
                        <div className="email-modal-selected center-content margin-right-15">
                          <p className="main-font-semi-bold font-size-14 font-color-black">
                            {email}
                          </p>
                          <img
                            src={require('../../../../assets/svg/icon/cross.svg')}
                            alt="cross"
                            className="email-modal-close"
                            onClick={() => {
                              removeSelectedEmail(index)
                            }}
                          />
                        </div>
                      ))}
                    <div
                      className="flex-column relative outline-none"
                      tabIndex="2"
                      onBlur={() => {
                        if (!emailAddModalMouseState)
                          setEmailAddModalState(false)
                      }}
                      onMouseOver={() => setEmailAddModalMouseState(true)}
                      onMouseLeave={() => setEmailAddModalMouseState(false)}
                    >
                      <div
                        className="email-modal-selected center-content cursor-pointer"
                        onClick={() =>
                          setEmailAddModalState(!emailAddModalState)
                        }
                      >
                        <img
                          src={require('../../../../assets/svg/icon/add_black.svg')}
                          alt="add black"
                          className="email-modal-add"
                        />
                      </div>
                      <div
                        className="email-modal-selected-dropdown animatable-400 ease-in-out-quart overflow-hidden"
                        style={{
                          opacity: emailAddModalState ? 1 : 0,
                          maxHeight: emailAddModalState ? '300px' : '0px',
                        }}
                      >
                        <div
                          className="email-modal-field-container flex-column animatable-400 ease-in-out-quart overflow-hidden margin-right-10"
                          style={{
                            height: toDropdownState
                              ? `${40 * (emailList.length + 1)}px`
                              : '40px',
                            border: toStateError
                              ? 'solid 1px red'
                              : 'solid 1px #DCDCDC',
                          }}
                        >
                          <div className="email-modal-field flex-row vertical-center">
                            <input
                              className="email-modal-field main-font-semi-bold font-size-14 font-color-black"
                              pattern="[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+"
                              value={toState}
                              onChange={handleToState}
                            />
                            <img
                              src={require('../../../../assets/svg/icon/arrow_down_grey.svg')}
                              alt="arrow down"
                              className="email-modal-field-arrow"
                              onClick={() =>
                                setToDropdownState(!toDropdownState)
                              }
                            />
                          </div>
                          <div className="email-modal-field-main-container">
                            {emailList.length > 0 &&
                              emailList.map((email) => (
                                <div
                                  className="email-modal-field vertical-center cursor-pointer"
                                  onClick={() => {
                                    setToState(email.email)
                                    setToDropdownState(false)
                                  }}
                                >
                                  <p className="main-font-semi-bold font-size-14 font-color-black">
                                    {email.email}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="flex-row width-auto horizontal-end">
                          <button
                            type="button"
                            className="email-modal-selected-dropdown-button"
                            onClick={addSelectedEmail}
                          >
                            <p className="main-font-semi-bold font-size-14 font-color-white">
                              Selecteer
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Space />
              <Grid container>
                <Grid container item sm={12} className="height-disable">
                  <p className="main-font-regular font-size-14 font-color-light-grey margin-bottom-5">
                    BCC
                  </p>
                  <div
                    className="email-modal-field-container flex-column animatable-400 ease-in-out-quart overflow-hidden"
                    style={{
                      maxHeight: bccDropdownState ? `300px` : '40px',
                      border: bccStateError
                        ? 'solid 1px red'
                        : 'solid 1px #DCDCDC',
                    }}
                  >
                    <div className="email-modal-field flex-row vertical-center">
                      <input
                        className="email-modal-field main-font-semi-bold font-size-14 font-color-black"
                        pattern="[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+"
                        value={bccState}
                        onChange={handleBccState}
                      />
                      <img
                        src={require('../../../../assets/svg/icon/arrow_down_grey.svg')}
                        alt="arrow down"
                        className="email-modal-field-arrow"
                        onClick={() => setBccDropdownState(!bccDropdownState)}
                      />
                    </div>
                    <div className="email-modal-field-main-container">
                      {emailList.length > 0 &&
                        emailList.map((email) => (
                          <div
                            className="email-modal-field vertical-center cursor-pointer"
                            onClick={() => {
                              setBccState(email.email)
                              setBccDropdownState(false)
                            }}
                          >
                            <p className="main-font-semi-bold font-size-14 font-color-black">
                              {email.email}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Space />
              <Grid container>
                <p className="main-font-regular font-size-14 font-color-light-grey margin-bottom-5">
                  Onderwerp
                </p>
                <div className="email-modal-field-container">
                  <input
                    className="email-modal-field main-font-semi-bolf font-size-14 font-color-black"
                    value={subjectState}
                    onChange={handleSubjectState}
                  />
                </div>
              </Grid>
              <Space />
              <Grid container>
                <button
                  type="button"
                  className="email-modal-button center-content animatable-400 ease-in-out-quart margin-right-15"
                  onClick={() => setSelectedType(0)}
                  style={{
                    background:
                      selectedType === 0 ? '#7DA5C2' : 'rgba(0, 0, 0, 0)',
                  }}
                >
                  <p
                    className="main-font-semi-bold font-size-14 font-color-sky-blue animatable-400 ease-in-out-quart"
                    style={{ color: selectedType === 0 ? 'white' : '#7DA5C2' }}
                  >
                    Leeg
                  </p>
                </button>
                <button
                  type="button"
                  className="email-modal-button center-content margin-right-15 animatable-400 ease-in-out-quart"
                  onClick={() => setSelectedType(1)}
                  style={{
                    background:
                      selectedType === 1 ? '#7DA5C2' : 'rgba(0, 0, 0, 0)',
                  }}
                >
                  <p
                    className="main-font-semi-bold font-size-14 font-color-sky-blue animatable-400 ease-in-out-quart"
                    style={{ color: selectedType === 1 ? 'white' : '#7DA5C2' }}
                  >
                    Factuur
                  </p>
                </button>
                <button
                  type="button"
                  className="email-modal-button center-content margin-right-15 animatable-400 ease-in-out-quart"
                  onClick={() => setSelectedType(2)}
                  style={{
                    background:
                      selectedType === 2 ? '#7DA5C2' : 'rgba(0, 0, 0, 0)',
                  }}
                >
                  <p
                    className="main-font-semi-bold font-size-14 font-color-sky-blue animatable-400 ease-in-out-quart"
                    style={{ color: selectedType === 2 ? 'white' : '#7DA5C2' }}
                  >
                    Offer
                  </p>
                </button>
                <button
                  type="button"
                  className="email-modal-button center-content margin-right-15 animatable-400 ease-in-out-quart"
                  onClick={() => setSelectedType(3)}
                  style={{
                    background:
                      selectedType === 3 ? '#7DA5C2' : 'rgba(0, 0, 0, 0)',
                  }}
                >
                  <p
                    className="main-font-semi-bold font-size-14 font-color-sky-blue animatable-400 ease-in-out-quart"
                    style={{ color: selectedType === 3 ? 'white' : '#7DA5C2' }}
                  >
                    Nieuw document
                  </p>
                </button>
              </Grid>
              <Space />
            </Grid>
            <Grid
              container
              className="height-auto"
              direction="column"
              wrap="nowrap"
            >
              <p className="main-font-regular font-size-14 font-color-light-grey margin-bottom-5">
                Email
              </p>
              <div className="email-modal-editor">
                <EmailEditor
                  ref={editorRef}
                  buildingData={buildingData}
                  selectedType={selectedType}
                />
              </div>
              <div className="width-auto horizontal-between vertical-center">
                <p
                  className="main-font-semi-bold font-size-14 font-color-green animatable-400 ease-in-out-quart"
                  style={{
                    opacity: emailSentState ? 1 : 0,
                    transform: emailSentState
                      ? 'translateX(0px)'
                      : 'translateX(-25px)',
                  }}
                >
                  Email successfully sent
                </p>
                <button
                  type="button"
                  className="email-modal-send center-content"
                  onClick={sendEmail}
                  disabled={buttonDisableState}
                >
                  <p className="main-font-semi-bold font-size-14 font-color-white">
                    Verstuur
                  </p>
                </button>
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  )
})
