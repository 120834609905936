import React, { useState, useEffect } from 'react'
import { Grid, Hidden, Dialog, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import './_users.scss'
import axios from 'axios'
import MobileView from '../mobile_view/mobile_view'
import { allUserHeader } from '../../shared/sample_data'
import ViewIcon from '../../../assets/png/icon/view.png'
import DeleteIcon from '../../../assets/png/icon/trash.png'
import SearchIcon from '../../../assets/png/icon/search_icon.png'
import CrossIcon from '../../../assets/svg/close.svg'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import Popup from '../../shared/popup/custom_popup.js'
import Header from '../header/header'
import { useHistory } from 'react-router-dom'
import DeleteConfirm from '../../shared/delete_confirm/delete_confirm.js'
import ProfileView from '../profile_view/profile_view'

export default function Users(props) {
  const { activeTab } = props
  const [originalUserState, setOriginalUserState] = useState([])
  const [filterUserState, setFilterUserState] = useState([])
  const [searchState, setSearchState] = useState('')
  const [sortByState, setSortByState] = useState({
    id: 1,
    title: 'Naam',
    asc: true,
  })
  const [showResponseState, setShowResponseState] = useState(false)
  const [responseMessageState, setResponseMessageState] = useState({})
  const [showViewPopupState, setShowViewPopupState] = useState(false)
  const [userViewDataState, setUserViewDataState] = useState({})
  const [showDeletePopupState, setShowDeletePopupState] = useState(false)
  const [deleteIdState, setDeleteIdState] = useState(0)

  const token = localStorage.getItem('authToken')
  const history = useHistory()

  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/user/all_users`,
        {},
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        setOriginalUserState(response.data)
        setFilterUserState(response.data)
      })
      .catch((error) => {
        let responseMessage = {
          isSuccess: false,
          heading: 'Geen record gevonden',
          message: 'Er is iets fout gegaan',
          popupState: setShowResponseState,
        }
        setShowResponseState(true)
        setResponseMessageState(responseMessage)
      })
  }, [])

  useEffect(() => {
    if (originalUserState.length > 0) {
      getFilteredData()
    }
  }, [searchState, sortByState])

  const getFilteredData = () => {
    const originalData = [...originalUserState]
    const searchData = searchState.toLowerCase()
    let filteredData = []
    originalData.forEach((user) => {
      const fullName = user.firstName + ' ' + user.lastName
      const userRole =
        user.roleId === 1
          ? 'verkopen & v.'
          : user.roleId === 2
          ? 'bouwen & v.'
          : user.roleId === 3
          ? 'admin'
          : 'superadmin'
      if (
        fullName.toLowerCase().includes(searchData) ||
        user.email.toLowerCase().includes(searchData) ||
        (user.company &&
          user.company.name.toLowerCase().includes(searchData)) ||
        (user.profession &&
          user.profession.toLowerCase().includes(searchData)) ||
        userRole.toLowerCase().includes(searchData)
      ) {
        filteredData.push(user)
      }
    })
    getSortedList(filteredData)
  }

  const onSearchChange = (event) => {
    if (event !== undefined) {
      const value = event.target.value
      if (value !== null) {
        setSearchState(value)
      } else {
        setSearchState('')
      }
    } else {
      setSearchState('')
    }
  }

  const handleHeaderClick = (item) => {
    if (item !== null && item !== undefined) {
      const prevState = { ...sortByState }
      if (prevState.id !== item.id) {
        setSortByState({ id: item.id, title: item.title, asc: true })
      } else {
        if (prevState.asc === true)
          setSortByState({ id: item.id, title: item.title, asc: false })
        else setSortByState({ id: item.id, title: item.title, asc: true })
      }
    } else {
      setSortByState({ id: 1, title: 'Naam', asc: true })
    }
  }
  const getSortedList = (filteredData) => {
    const sortBy = { ...sortByState }
    console.log(sortBy)
    let sortedData = []
    if (filteredData !== undefined && filteredData.length > 0) {
      if (sortBy !== {} && sortBy !== null) {
        if (sortBy.title.includes('Voornaam')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.firstName !== null
                ? a.firstName.localeCompare(
                    b.firstName !== null ? b.firstName : '',
                  )
                : '',
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.firstName !== null
                ? b.firstName.localeCompare(
                    a.firstName !== null ? a.firstName : '',
                  )
                : '',
            )
        } else if (sortBy.title.includes('Naam')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.lastName !== null
                ? a.lastName.localeCompare(
                    b.lastName !== null ? b.lastName : '',
                  )
                : '',
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.lastName !== null
                ? b.lastName.localeCompare(
                    a.lastName !== null ? a.lastName : '',
                  )
                : '',
            )
        } else if (sortBy.title.includes('E-mail')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.email !== null
                ? a.email.localeCompare(b.email !== null ? b.email : '')
                : '',
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.email !== null
                ? b.email.localeCompare(a.email !== null ? a.email : '')
                : '',
            )
        } else if (sortBy.title.includes('Bedrijf')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.company !== null
                ? a.company.name.localeCompare(
                    b.company !== null ? b.company.name : '',
                  )
                : '',
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.company !== null
                ? b.company.name.localeCompare(
                    a.company !== null ? a.company.name : '',
                  )
                : '',
            )
        } else if (sortBy.title.includes('Beroep')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.profession !== null
                ? a.profession.localeCompare(
                    b.profession !== null ? b.profession : '',
                  )
                : '',
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.profession !== null
                ? b.profession.localeCompare(
                    a.profession !== null ? a.profession : '',
                  )
                : '',
            )
        } else if (sortBy.title.includes('Gebruikersgroep')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.role !== null
                ? a.role.title.localeCompare(
                    b.role !== null ? b.role.title : '',
                  )
                : '',
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.role !== null
                ? b.role.title.localeCompare(
                    a.role !== null ? a.role.title : '',
                  )
                : '',
            )
        }
      } else {
        sortedData = filteredData.sort((a, b) => a.firstName)
      }
    }
    setFilterUserState(sortedData)
  }

  const handleDeleteClick = () => {
    if (deleteIdState > 0) {
      axios
        .put(
          process.env.REACT_APP_API_URL + `/user/delete/${deleteIdState}`,
          {},
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.data === true) {
            const originalData = [...originalUserState]
            const remainingData = originalData.filter(
              (a) => a.id !== deleteIdState,
            )
            if (remainingData !== null && remainingData !== undefined) {
              setOriginalUserState(remainingData)
              setSortByState({ id: 1, title: 'Naam', asc: true })
            }
            let responseMessage = {
              isSuccess: true,
              heading: 'Succesrecord verwijderd',
              message: 'Succesvol verwijderd!',
              popupState: setShowResponseState,
            }
            setShowResponseState(true)
            setResponseMessageState(responseMessage)
            setDeleteIdState(0)
          } else {
            let responseMessage = {
              isSuccess: false,
              heading: 'Geen record verwijderd',
              message: 'Er is iets fout gegaan',
              popupState: setShowResponseState,
            }
            setShowResponseState(true)
            setResponseMessageState(responseMessage)
          }
        })
        .catch((error) => {
          let responseMessage = {
            isSuccess: false,
            heading: 'Geen record verwijderd',
            message: 'Er is iets fout gegaan',
            popupState: setShowResponseState,
          }
          setShowResponseState(true)
          setResponseMessageState(responseMessage)
        })
    }
  }

  const handleAddUserClick = () => {
    activeTab(6)
    history.push('/klanten/company/add')
  }

  const handleViewClick = (id) => {
    const originalData = [...originalUserState]
    if (originalData !== null && originalData !== undefined) {
      const userObj = originalData.find((a) => a.id === id)
      if (userObj !== null && userObj !== undefined) {
        setUserViewDataState(userObj)
        setShowViewPopupState(true)
      }
    }
  }

  const handleDeleteConfirmation = (id) => {
    if (id > 0) {
      setDeleteIdState(id)
      setShowDeletePopupState(true)
    } else {
      setDeleteIdState(0)
    }
  }

  return (
    <Grid container>
      <Grid container>
        <Hidden smDown>
          <Header />
        </Hidden>
        <Hidden smUp>
          <Header toShowHamburger />
        </Hidden>
      </Grid>
      <Hidden smUp>
        <MobileView />
      </Hidden>
      <Hidden xsDown>
        <div className="all-users-main-div">
          <Grid container item xs={12} className="height-disable fade-in-right">
            <Grid container item xs={12}>
              <Grid item xs={8} sm={8} md={6} lg={4}>
                <div className="all-users-search-bar">
                  <img
                    src={SearchIcon}
                    alt=""
                    className="all-users-search-icon"
                  />
                  <input
                    type="text"
                    placeholder="Zoeken"
                    className="width-auto font-color-black margin-left-5"
                    onChange={(event) => onSearchChange(event)}
                    value={searchState}
                  />
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={2} lg={2}>
                <button
                  type="button"
                  className="all-users-add-user width-auto cursor-pointer"
                  onClick={handleAddUserClick}
                >
                  Nieuwe gebruiker
                </button>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid container className="all-users-header-main vertical-center">
                {allUserHeader !== null
                  ? allUserHeader.map((item) => {
                      return (
                        <div
                          className="all-users-header-item"
                          key={item.id}
                          onClick={
                            item.title !== ''
                              ? () => handleHeaderClick(item)
                              : null
                          }
                        >
                          <span className="main-font-regular font-color-grey">
                            {item.title}
                          </span>
                          {sortByState !== null ? (
                            sortByState.id === item.id &&
                            sortByState.asc === true ? (
                              <span className="font-color-grey">
                                <ExpandMore />
                              </span>
                            ) : sortByState.id === item.id &&
                              sortByState.asc === false ? (
                              <span className="font-color-grey">
                                <ExpandLess />
                              </span>
                            ) : null
                          ) : null}
                        </div>
                      )
                    })
                  : null}
                <Grid item xs={2}>
                  <div></div>
                </Grid>
              </Grid>
              <Grid container>
                <div className="all-users-card-container flex-column width-auto">
                  {filterUserState.length > 0 ? (
                    filterUserState.map((item, index) => {
                      return index % 2 === 0 ? (
                        <Grid
                          container
                          className="all-users-card-main background-color-white"
                          key={item.id}
                        >
                          <CardDetail
                            data={item}
                            handleDeleteClick={handleDeleteConfirmation}
                            handleViewClick={handleViewClick}
                          />
                        </Grid>
                      ) : (
                        <Grid
                          container
                          className="all-users-card-main background-color-transparent"
                          key={item.id}
                        >
                          <CardDetail
                            data={item}
                            handleDeleteClick={handleDeleteConfirmation}
                            handleViewClick={handleViewClick}
                          />
                        </Grid>
                      )
                    })
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {showResponseState === true ? (
            <Popup data={responseMessageState} />
          ) : null}
          {showViewPopupState === true ? (
            <ProfileView
              modalState={showViewPopupState}
              setModalState={setShowViewPopupState}
              data={userViewDataState}
            />
          ) : null}
          {showDeletePopupState === true ? (
            <DeleteConfirm
              id={deleteIdState}
              setIdState={setDeleteIdState}
              setOpenState={setShowDeletePopupState}
              handleDeleteClick={handleDeleteClick}
            />
          ) : null}
        </div>
      </Hidden>
    </Grid>
  )
}

Users.propTypes = {
  activeTab: PropTypes.func,
}
Users.defaultProps = {
  activeTab: function () {},
}

const CardDetail = (props) => {
  const { data, handleDeleteClick, handleViewClick } = props

  return (
    <React.Fragment>
      {data !== null && data !== {} ? (
        <Grid container>
          <div className="height-auto width-auto vertical-center">
            {/* <Grid item xs={2} sm={3} md={2} lg={2}> */}
            <div className="all-users-card-item">
              <span className="main-font-regular font-color-grey all-users-word-break">
                {data.firstName}
              </span>
            </div>
            {/* </Grid> */}
            {/* <Grid item xs={2} sm={3} md={2} lg={2}> */}
            <div className="all-users-card-item">
              <p className="main-font-regular font-color-grey all-users-word-break">
                {data.lastName}
              </p>
            </div>
            {/* </Grid> */}
            {/* <Grid item xs={2} sm={2} md={2} lg={2}> */}
            <div className="all-users-card-item">
              <p className="main-font-regular font-color-grey all-users-word-break">
                {data.email}
              </p>
            </div>
            {/* </Grid> */}
            {/* <Grid item xs={2} sm={2} md={2} lg={2}> */}
            <div className="all-users-card-item">
              <span className="main-font-regular font-color-grey all-users-word-break">
                {data.company !== null ? data.company.name : ''}
              </span>
            </div>
            {/* </Grid> */}
            {/* <Grid item xs={2} sm={2} md={1} lg={1}> */}
            <div className="all-users-card-item">
              <span className="main-font-regular font-color-grey all-users-word-break">
                {data.profession}
              </span>
            </div>
            {/* </Grid> */}
            {/* <Grid item xs={2} sm={2} md={1} lg={1}> */}
            <div className="all-users-card-item">
              <span className="main-font-regular font-color-grey all-users-word-break">
                {data.roleId && data.roleId === 2
                  ? 'Bouwen & V.'
                  : data.roleId === 1
                  ? 'Verkopen & V.'
                  : data.roleId === 3
                  ? 'Admin'
                  : data.roleId === 4
                  ? 'Superadmin'
                  : data.roleId === 6
                  ? 'Externe'
                  : '-'}
              </span>
            </div>
            {/* </Grid> */}
            {/* <Grid item xs={12} sm={12} md={2} lg={2}> */}
            <div className="all-users-card-item">
              <Grid
                container
                justify="center"
                alignItems="center"
                className="height-auto"
              >
                <Hidden smDown>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <div className="height-auto">
                      <span className="all-users-verticle-line"></span>
                    </div>
                  </Grid>
                </Hidden>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <button
                    className="cursor-pointer"
                    onClick={() => handleViewClick(data.id)}
                  >
                    <img
                      src={ViewIcon}
                      alt="View"
                      className="all-users-icon-button"
                    />
                  </button>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <button
                    className="cursor-pointer"
                    onClick={() => handleDeleteClick(data.id)}
                  >
                    <img
                      src={DeleteIcon}
                      alt="Delete"
                      className="all-users-icon-button"
                    />
                  </button>
                </Grid>
              </Grid>
            </div>
            {/* </Grid> */}
          </div>
        </Grid>
      ) : null}
    </React.Fragment>
  )
}
CardDetail.propTypes = {
  data: PropTypes.object,
  handleDeleteClick: PropTypes.func,
  handleViewClick: PropTypes.func,
}
CardDetail.defaultProps = {
  data: {},
  handleDeleteClick: function () {},
  handleViewClick: function () {},
}

const useStyles = makeStyles({
  paper: {
    width: '60%',
    maxHeight: '80%',
  },
})

const UserView = (props) => {
  const { data, setOpenState } = props
  const classes = useStyles()
  const [popupState, setPopupState] = useState(true)

  const handleButtonClick = () => {
    setPopupState(false)
    if (setOpenState !== undefined && setOpenState !== null) {
      setOpenState(false)
    }
  }
  return (
    <React.Fragment>
      {data !== null && data !== {} ? (
        <Dialog
          open={popupState}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="sm"
          scroll="paper"
          classes={{ paper: classes.paper }}
        >
          <div className="horizontal-end">
            <div className="margin-top-10 margin-right-10">
              <button onClick={handleButtonClick} className="cursor-pointer">
                <img src={CrossIcon} alt="" className="all-users-icon-close" />
              </button>
            </div>
          </div>
          <div className="width-auto height-auto flex-column all-users-view-popup">
            <div className="flex font-size-16">
              <span className="main-font-semi-bold font-color-black">
                User Details
              </span>
            </div>
            <div className="flex-column font-size-14 margin-top-15">
              <span className="main-font-regular font-color-grey margin-top-10">
                Naam
              </span>
              <span className="main-font-semi-bold font-color-black margin-top-10">
                {data.name !== null ? data.name : ''}
              </span>
            </div>
            <div className="flex-column font-size-14 margin-top-15">
              <span className="main-font-regular font-color-grey margin-top-10">
                Email
              </span>
              <span className="main-font-semi-bold font-color-black margin-top-10">
                {data.email !== null ? data.email : ''}
              </span>
            </div>
            <div className="flex-column font-size-14 margin-top-15">
              <span className="main-font-regular font-color-grey margin-top-10">
                Watchword
              </span>
              <span className="main-font-semi-bold font-color-black margin-top-10">
                ********
              </span>
            </div>
            <div className="flex-column font-size-14 margin-top-15">
              <span className="main-font-regular font-color-grey margin-top-10">
                Telefoon
              </span>
              <span className="main-font-semi-bold font-color-black margin-top-10">
                {data.phone !== null ? data.phone : ''}
              </span>
            </div>
          </div>
        </Dialog>
      ) : null}
    </React.Fragment>
  )
}

UserView.propTypes = {
  data: PropTypes.shape({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  }),
  setOpenState: PropTypes.func,
}
UserView.defaultProps = {
  data: null,
  setOpenState: function () {},
}
