import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import './_step_one.scss'
import { useHistory } from 'react-router-dom'
export default function SafetyStepOne(props) {
  const {
    stepOneData,
    dataWorks,
    planningData,
    setPlanningData,
    contactsData,
    setContactsData,
  } = props

  return (
    <Grid container className="fade-in-translate animatable-400">
      <DataWorks stepOneData={stepOneData} dataWorks={dataWorks} />
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Planning
        stepOneData={stepOneData}
        planningData={planningData}
        setPlanningData={setPlanningData}
      />
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <YardContacts
        stepOneData={stepOneData}
        contactsData={contactsData}
        setContactsData={setContactsData}
      />
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
    </Grid>
  )
}

const DataWorks = (props) => {
  const {
    stepOneData,
    setStepOneData,
    isButtonClick,
    dataWorks,
    setDataWorks,
  } = props
  return (
    <Grid container>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        >
          {/* <Grid container justify="flex-end" md={10}>
            <img
              className="epb-service-Category-heading-image-size"
              src={require('../../../assets/svg/icon/info.svg')}
            />
          </Grid> */}
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Gegevens werken
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container alignItems="center">
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <Grid container md={3} direction="column">
            <p className="step-one-heading-label">Startdatum werken</p>
            <p className="step-one-data-label">
              {dataWorks.startDate !== undefined && dataWorks.startDate !== null
                ? dataWorks.startDate.split(' ')[0]
                : '-'}
            </p>
          </Grid>
          <Grid container md={3} direction="column">
            <p className="step-one-heading-label">Einddatum werken</p>
            <p className="step-one-data-label">
              {dataWorks.endDate !== undefined && dataWorks.endDate !== null
                ? dataWorks.endDate.split(' ')[0]
                : '-'}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Planning = (props) => {
  const { stepOneData, setStepOneData, isButtonClick, planningData } = props

  const history = useHistory()

  // useEffect(() => {
  //   let tempData = { ...stepOneData }

  //   setStepOneData(tempData)
  // }, [isButtonClick])

  return (
    <Grid container>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        ></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Planning
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Grid container>
        <Grid container xs={10} sm={10} md={10}>
          <div className="margin-left-50" />

          {planningData.map((item) => (
            <Grid container>
              <PlanningRow data={item} />
              <Grid container>
                <div className="margin-top-10" />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container md={2}>
          <button
            className="safety-step-one-planning-button vietnam-regular cursor-pointer"
            onClick={() => {
              history.push(`/planning/timeline`)
            }}
          >
            Naar planning gaan
          </button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const PlanningRow = (props) => {
  const { data } = props
  return (
    <Grid container alignItems="flex-end" style={{ paddingLeft: '10px' }}>
      <Grid xs={1} sm={1} md={1}></Grid>
      <div className="margin-left-20" />
      <Grid container md={3} direction="column">
        <p className="step-one-heading-label">Fase</p>
        <p className="step-one-data-label">{data?.phase || '-'}</p>
      </Grid>
      <div className="margin-right-37" />
      <Grid container md={3} direction="column">
        <p className="step-one-heading-label">Startdatum werken</p>
        <p className="step-one-data-label">
          {data?.startDate ? data.startDate.split(' ')[0] : '-'}
        </p>
      </Grid>
      <div className="margin-right-37" />
      <Grid container md={3} direction="column">
        <p className="step-one-heading-label">Einddatum werken</p>
        <p className="step-one-data-label">
          {data?.endDate ? data.endDate.split(' ')[0] : '-'}
        </p>
      </Grid>
    </Grid>
  )
}

const YardContacts = (props) => {
  const { isButtonClick, contactsData } = props

  return (
    <Grid container>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid
          container
          xs={1}
          sm={1}
          md={1}
          justify="center"
          alignItems="center"
        ></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Contacten voor deze werf
            </p>
          </Grid>
        </Grid>
      </Grid>
      {contactsData.map((item) => (
        <Grid container>
          <ContactFormRow data={item} />
        </Grid>
      ))}
    </Grid>
  )
}

const ContactFormRow = (props) => {
  const { data } = props
  return (
    <Grid container alignItems="flex-end">
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid xs={1} sm={1} md={1}></Grid>
      <Grid container xs={11} sm={11} md={11}>
        <Grid container md={2} direction="column">
          <p className="step-one-heading-label">Naam</p>
          <p className="step-one-data-label">
            {data.project !== undefined && data.project !== null
              ? data.project
              : '-'}
          </p>
        </Grid>
        <Grid container md={2} direction="column">
          <p className="step-one-heading-label">Hoedanigheid</p>
          <p className="step-one-data-label">
            {data.capacity !== undefined && data.capacity !== null
              ? data.capacity
              : '-'}
          </p>
        </Grid>
        <Grid container md={2} direction="column">
          <p className="step-one-heading-label">Telefoonnummer</p>
          <p className="step-one-data-label">
            {data.phone !== undefined && data.phone !== null ? data.phone : '-'}
          </p>
        </Grid>
        <Grid container md={2} direction="column">
          <p className="step-one-heading-label">E-mail</p>
          <p className="step-one-data-label">
            {data.email !== undefined && data.email !== null ? data.email : '-'}
          </p>
        </Grid>
        <Grid container md={2} direction="column">
          <p className="step-one-heading-label">Verstuur Email</p>
          <p className="step-one-data-label">
            {data.send_email !== undefined &&
            data.send_email !== null &&
            data.send_email
              ? 'Ja'
              : 'Nee'}
          </p>
        </Grid>
      </Grid>
    </Grid>
  )
}
